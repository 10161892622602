// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import {viewerConfigurations} from '../../../constants/viewerConfigurations';

import '../../../styles/DicomViewer/viewerSelectPopup.css'

class ViewerSelectPopup extends Component {

    getViewerSelectOptionBtn = (attributeName) => {  
        return (
            <img
                className="headerButton" 
                key={"btnHeader_" + attributeName}
                src={"/resources/icons/" + attributeName + ".png"} 
                alt = {attributeName}
                onClick={()=> this.props.setViewerConfiguration(attributeName)}
            />
        )    
    }

    render = () => {
        let items = [];

        for(let attributeName in viewerConfigurations) {
            items.push(this.getViewerSelectOptionBtn(attributeName));
        }
        
        return (
            <div className="viewerSelectContainer" style={this.props.getStyleModifier()}>
                {items}
            </div>
        )
    }
}

export default ViewerSelectPopup;