// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

// Styles
import '../../../styles/DicomViewer/dicomViewer.css';

// Constants
import { viewerVisibilityProperties } from '../../../constants/viewerConfigurations';

class AnnotationTogglebar extends Component {

    
    render = () => {


        return (
            <div className="viewerTogglebar"
                onMouseMove = {this.props.onMouseMove}
                onMouseDown = {(e) => {e.stopPropagation()}}
            >

                {viewerVisibilityProperties.map((annotationData, idx) => {

                    let visible = this.props.visibilities[annotationData.name];
                    
                    // Annotation toggle button
                    return (
                        <div 
                            className={"toggleButtonContainer" + (visible ? " toggleButtonContainerActive" : "")}
                            key = {idx}
                        >
                            
                            <img 
                                className={"toggleButton" + (visible ? " toggleButtonActive" : "")} 
                                title={annotationData.toolTip}
                                src = {annotationData.iconPath}
                                alt = {annotationData.name}
                                onClick = {() => this.props.updateVisibilities(annotationData.name)}
                            />

                        </div>
                    )

                })}

            </div>
        )
    }
}

export default AnnotationTogglebar;


