// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';


// Base Header
import Header from '../../Header';
import HeaderButton from '../../HeaderButton';


class DicomHeader extends Component {

    state = {

        // Viewer selection pop up
        viewerSelectOpened: false,
    }

    componentDidMount = async () => {

        // Componnent did mount function

    }


    render = () => {

        return (

            <div>

                <Header
                    project={this.props.project}
                    shiftProjectIndex={this.props.shiftProjectIndex}
                    onLogout={this.props.onLogout}
                >
            

                    <div style={{ float: 'right' }}>

                        {/* New Viewer Page button*/}
                        <HeaderButton
                            id={"btnNewViewerWindow"}
                            ref={"btnNewViewerWindow"}
                            name={"btnNewViewerWindow"}
                            toolTip={"New Dicom Viewer Window"}
                            icon={"/resources/icons/NewWindow.png"} 
                            onButtonClick = { () => {this.props.newDicomViewerWindow()}}
                            active={false}
                        />  

                        
                        {/* New Report Generator Page button*/}
                        <HeaderButton
                            id={"btnNewReportWindow"}
                            ref={"btnNewReportWindow"}
                            name={"btnNewReportWindow"}
                            toolTip={"New Report Generator Window"}
                            icon={"/resources/icons/NewReportWindow.png"} 
                            onButtonClick = { () => {this.props.newReportWindow()}}
                            active={false}
                        />   
                    </div>


                </Header>

            </div>
        )
    }

}

export default DicomHeader;