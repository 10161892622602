// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory


export const dummyStudyTypes = [

    {   
        name: 'Lumbar',
        anatomy : [
            {anatomyName: 'Disc.T12-L1', abbreviation: 'T12-L1'},
            {anatomyName: 'Disc.L1-L2', abbreviation: 'L1-L2'},
            {anatomyName: 'Disc.L2-L3', abbreviation: 'L2-L3'},
            {anatomyName: 'Disc.L3-L4', abbreviation: 'L3-L4'},
            {anatomyName: 'Disc.L4-L5', abbreviation: 'L4-L5'},
            {anatomyName: 'Disc.L5-S1', abbreviation: 'L5-S1'},
            {anatomyName: 'Lumbar'}
        ],
    },

    {   
        name: 'Cervical',
        anatomy : [
            {anatomyName: 'Disc.C2-C3', abbreviation: 'C2-C3'},
            {anatomyName: 'Disc.C3-C4', abbreviation: 'C3-C4'},
            {anatomyName: 'Disc.C4-C5', abbreviation: 'C4-C5'},
            {anatomyName: 'Disc.C5-C6', abbreviation: 'C5-C6'},
            {anatomyName: 'Disc.C6-C7', abbreviation: 'C6-C7'},
            {anatomyName: 'Disc.C7-T1', abbreviation: 'C7-T1'},
            'Cervical',

        ],
    },

    {
        name: 'Thoracic'
    }


]

export const dummyInjuryTypes = [

    {
        name: 'Lordosis',
        properties: [
            {
                name: 'Acuity',
                type: 'boolean',
                defaultValue: true
            },
            {
                name: 'Severity',
                type: 'option',
                options: [
                    'mild',
                    'moderate',
                    'severe',
                ],
                defaultValue: 'moderate'
            },
        ],
        metrics: [
            {
                name: 'Lordosis Angle',
                type: 'angle',
            },
        ],
        template: 'Lordosis template text.'
    },

    {
        name: 'Foraminal Stenosis',
        properties: [
            {
                name: 'Severity',
                type: 'option',
                options: [
                    'mild',
                    'moderate',
                    'severe',
                ],
                defaultValue: 'moderate'
            },
        ],
        metrics: [
            {
                name: 'Left Probability',
                type: 'probability',
            },
            {
                name: 'Right Probability',
                type: 'probability',
            },
        ],
        template: 'Foraminal Stenosis template text.'
    },

    {
        name: 'Impingement',
        properties: [
            {
                name: 'Severity',
                type: 'option',
                options: [
                    'mild',
                    'moderate',
                    'severe',
                ],
                defaultValue: 'moderate'
            },
        ],
        metrics: [
            {
                name: 'Box',
                type: 'rectangle',
            },
            {
                name: 'Acuity Probability',
                type: 'probability',
            },
            {
                name: 'Herniation Probability',
                type: 'probability',
            },
            {
                name: 'Impingement Probability',
                type: 'probability',
            },
        ],
        template: 'Impingement template text.'
    },
]

const dicomAnnotations = {

    'T2_SAG': {
        0: [
            {
                dataType: 'rectangle',
                points: [
                    {x: 0, y: 0},
                    {x: 0.5, y: 0.5},
                ]
            },
        ]
    },
    // Should be around the 12th dicom slice
    'global': [
        {
            dataType: 'label3D',
            points: [{ x: -0.09, y: -0.06, z: 0.0216 }],
            text: 'Test Label3D'
        }
    ]


}


export const dummyProjectData = {

    "_id": "5d5dbd9949243ad537ac22df",
    "patient": {
        "name": "dev",
        "sex": "M",
        "dateOfBirth": "01/01/0001"
    },
    "studyDate": "07/03/2018",
    "attendingPhysician": {
        "name": "DEGROOT AARON"
    },
    "center": {
        "name": "MRI OF AZ.",
        "group": "mriofarizona"
    },
    "studyType": "Lumbar",
    "case": 8,
    "accessKey": "08091964",
    "lastAccess": "2020-03-09T16:58:56.724Z",
    "receiveDate": "2019-08-21T21:54:33.777Z",
    "status": "Segment",
    "studyUID": "1.2.392.200036.9123.100.12.11.12773.201807033080106",
    "tracker": [
        {
            "status": "Awaiting Docs",
            "timestamp": "2019-08-21T21:54:33.777Z"
        }
    ],
    "__v": 1,
    "files": [
        {
            "docRef": "Other",
            "path": "/Lumbar/ECHOLS LUMBAR REPORT.pdf",
            "key": "951dc59d-c6a6-11e9-b559-b42e9939396c"
        }
    ],
    "notes": [],
    "filesCount": 1,
    'dicomAnnotations': dicomAnnotations,
    "ai": {
        "radiologyRWR": {
            "status": "Queued",
            "startTime": "2020-01-22T18:34:14.757Z",
            "worker": "5e2891f8d9e452000148bb51"
        },
        "segment": {
            "status": ""
        },
        "radiologyMRI": {
            "status": "Finished",
            "startTime": "2020-03-02T13:34:54.555Z",
            "worker": "0",
            "progress": 100,
            "state": null,
            "endTime": "2020-03-02T20:35:06.683Z",
            "error": null,
            "kill": null
        },
        "renderer": {
            "status": ""
        },
        "assigningUser": "5d62ff25b3a848001940b8d3",
        "injuryRef": {
            "status": ""
        },
        "injury": {
            "injuries": [
                {
                    "location": "Disc.L2-L3",
                    "objects": [
                        {
                            "type": "BiforaminalStenosis",
                            "severity": "N/A",
                            "acuity": true,
                            "summary": "Probability of left neural foraminal stenosis:0.672. Probability of right neural foraminal stenosis0.83.",
                            "rationale": {
                                "dicoms": [],
                                "images": [],
                                "logKey": "LumbarFSDetector-5d5dbd9949243ad537ac22df-1583181302_109963.log",
                                "versions": {
                                    "repoHash": "05e3c84152dd6cd0cc95d031dca6104d79c46c02",
                                    "modelHash": "NO_MODEL_HASH"
                                },
                                "metrics": [
                                    {
                                        "name": "LeftFSProbability",
                                        "value": 0.671758770942688,
                                        "dataType": "probability"
                                    },
                                    {
                                        "name": "RightFSProbability",
                                        "value": 0.8297817707061768,
                                        "dataType": "probability"
                                    }
                                ]
                            },
                            "location": "Disc.L2-L3"
                        },
                    ]
                },
                {
                    "location": "Disc.L5-S1",
                    "objects": [
                        {
                            "type": "RightForaminalStenosis",
                            "severity": "N/A",
                            "acuity": true,
                            "summary": "Probability of left neural foraminal stenosis:0.477. Probability of right neural foraminal stenosis0.786.",
                            "rationale": {
                                "dicoms": [],
                                "images": [],
                                "logKey": "LumbarFSDetector-5d5dbd9949243ad537ac22df-1583181302_109963.log",
                                "versions": {
                                    "repoHash": "05e3c84152dd6cd0cc95d031dca6104d79c46c02",
                                    "modelHash": "NO_MODEL_HASH"
                                },
                                "metrics": [
                                    {
                                        "name": "LeftFSProbability",
                                        "value": 0.476703405380249,
                                        "dataType": "probability"
                                    },
                                    {
                                        "name": "RightFSProbability",
                                        "value": 0.7864975929260254,
                                        "dataType": "probability"
                                    }
                                ]
                            },
                            "location": "Disc.L5-S1"
                        }
                    ]
                },
                {
                    "location": "Disc.L4-L5",
                    "objects": [
                        {
                            "type": "BiforaminalStenosis",
                            "severity": "N/A",
                            "acuity": true,
                            "summary": "Probability of left neural foraminal stenosis:0.902. Probability of right neural foraminal stenosis0.933.",
                            "rationale": {
                                "dicoms": [],
                                "images": [],
                                "logKey": "LumbarFSDetector-5d5dbd9949243ad537ac22df-1583181302_109963.log",
                                "versions": {
                                    "repoHash": "05e3c84152dd6cd0cc95d031dca6104d79c46c02",
                                    "modelHash": "NO_MODEL_HASH"
                                },
                                "metrics": [
                                    {
                                        "name": "LeftFSProbability",
                                        "value": 0.9017760753631592,
                                        "dataType": "probability"
                                    },
                                    {
                                        "name": "RightFSProbability",
                                        "value": 0.9329391121864319,
                                        "dataType": "probability"
                                    }
                                ]
                            },
                            "location": "Disc.L4-L5"
                        }
                    ]
                },
                {
                    "location": "Lumbar",
                    "objects": [
                        {
                            "type": "Hyperlordosis",
                            "severity": "None",
                            "acuity": "None",
                            "summary": "The patient was diagnosed with Hyperlordosis with a probability of 53.60% due to a lumbar lordosis angle of 45.43",
                            "rationale": {
                                "dicoms": [
                                    {
                                        "axis": "Sagittal",
                                        "seriesName": "T2_SAG",
                                        "sliceIdx": 5
                                    }
                                ],
                                "images": null,
                                "logKey": "LumbarLordosisDetector-5d5dbd9949243ad537ac22df-1583181301_616063.log",
                                "versions": {
                                    "repoHash": "05e3c84152dd6cd0cc95d031dca6104d79c46c02",
                                    "modelHash": "TestModelHash"
                                },
                                "metrics": [
                                    {
                                        "name": "Lumbar lordosis angle",
                                        "value": 45.42598307664317,
                                        "points": [
                                            {
                                                x: 0.48828125,
                                                y: 0.16015625
                                            },
                                            {
                                                x: 1.0852888607011069,
                                                y: 0.23098766143911437
                                            },
                                            {
                                                x: 0.4609375,
                                                y: 0.73046875
                                            }
                                        ],
                                        "dataType": "angle",
                                        "seriesName": "n/a",
                                        "sliceIdx": 5
                                    },
                                    {
                                        "name": "Class probability",
                                        "value": 0.5360321148766307
                                    }
                                ]
                            },
                            "location": "Lumbar"
                        },
                        {
                            "type": "TestRectangle",
                            "severity": "None",
                            "acuity": "None",
                            "summary": "The patient was diagnosed with a test rectangle.",
                            "rationale": {
                                "dicoms": [
                                    {
                                        "axis": "Sagittal",
                                        "seriesName": "T2_SAG",
                                        "sliceIdx": 5
                                    }
                                ],
                                "images": null,
                                "logKey": "LumbarLordosisDetector-5d5dbd9949243ad537ac22df-1583181301_616063.log",
                                "versions": {
                                    "repoHash": "05e3c84152dd6cd0cc95d031dca6104d79c46c02",
                                    "modelHash": "TestModelHash"
                                },
                                "metrics": [
                                    {
                                        "name": "Rectangle",
                                        "value": 45.42598307664317,
                                        "points": [
                                            {
                                                x: 0.48828125,
                                                y: 0.16015625
                                            },
                                            {
                                                x: 1.0852888607011069,
                                                y: 0.23098766143911437
                                            }
                                        ],
                                        "dataType": "rectangle",
                                        "seriesName": "T1_SAG",
                                        "sliceIdx": 5
                                    },
                                    {
                                        "name": "Class probability",
                                        "value": 0.5360321148766307
                                    }
                                ]
                            },
                            "location": "Lumbar"
                        },
                        {
                            "type": "TestCircle",
                            "severity": "None",
                            "acuity": "None",
                            "summary": "The patient was diagnosed with a test circle.",
                            "rationale": {
                                "dicoms": [
                                    {
                                        "axis": "Sagittal",
                                        "seriesName": "T2_SAG",
                                        "sliceIdx": 5
                                    }
                                ],
                                "images": null,
                                "logKey": "LumbarLordosisDetector-5d5dbd9949243ad537ac22df-1583181301_616063.log",
                                "versions": {
                                    "repoHash": "05e3c84152dd6cd0cc95d031dca6104d79c46c02",
                                    "modelHash": "TestModelHash"
                                },
                                "metrics": [
                                    {
                                        "name": "Circle",
                                        "value": 45.42598307664317,
                                        "points": [
                                            {
                                                x: 0.48828125,
                                                y: 0.16015625
                                            },
                                        ],
                                        "radius": 0.2,
                                        "dataType": "circle",
                                        "seriesName": "T1_SAG",
                                        "sliceIdx": 5
                                    },
                                    {
                                        "name": "Class probability",
                                        "value": 0.5360321148766307
                                    }
                                ]
                            },
                            "location": "Lumbar"
                        }
                    ]
                },
                {
                    "location": "Disc.L3-L4",
                    "objects": [
                        {
                            "type": "RightForaminalStenosis",
                            "severity": "N/A",
                            "acuity": true,
                            "summary": "Probability of left neural foraminal stenosis:0.412. Probability of right neural foraminal stenosis0.611.",
                            "rationale": {
                                "dicoms": [],
                                "images": [],
                                "logKey": "LumbarFSDetector-5d5dbd9949243ad537ac22df-1583181302_109963.log",
                                "versions": {
                                    "repoHash": "05e3c84152dd6cd0cc95d031dca6104d79c46c02",
                                    "modelHash": "NO_MODEL_HASH"
                                },
                                "metrics": [
                                    {
                                        "name": "LeftFSProbability",
                                        "value": 0.4122360646724701,
                                        "dataType": "probability"
                                    },
                                    {
                                        "name": "RightFSProbability",
                                        "value": 0.6109977960586548,
                                        "dataType": "probability"
                                    }
                                ]
                            },
                            "location": "Disc.L3-L4"
                        }
                    ]
                },
                {
                    "location": "Disc.T12-L1",
                    "objects": [
                        {
                            "type": "RightForaminalStenosis",
                            "severity": "N/A",
                            "acuity": true,
                            "summary": "Probability of left neural foraminal stenosis:0.356. Probability of right neural foraminal stenosis0.72.",
                            "rationale": {
                                "dicoms": [],
                                "images": [],
                                "logKey": "LumbarFSDetector-5d5dbd9949243ad537ac22df-1583181302_109963.log",
                                "versions": {
                                    "repoHash": "05e3c84152dd6cd0cc95d031dca6104d79c46c02",
                                    "modelHash": "NO_MODEL_HASH"
                                },
                                "metrics": [
                                    {
                                        "name": "LeftFSProbability",
                                        "value": 0.3558657169342041,
                                        "dataType": "probability"
                                    },
                                    {
                                        "name": "RightFSProbability",
                                        "value": 0.7199508547782898,
                                        "dataType": "probability"
                                    }
                                ]
                            },
                            "location": "Disc.T12-L1"
                        },
                        {
                            "type": "Hyperlordosis",
                            "severity": "None",
                            "acuity": "None",
                            "summary": "The patient was diagnosed with Hyperlordosis with a probability of 53.60% due to a lumbar lordosis angle of 45.43",
                            "rationale": {
                                "dicoms": [
                                    {
                                        "axis": "Sagittal",
                                        "seriesName": "T2_SAG",
                                        "sliceIdx": 5
                                    }
                                ],
                                "images": null,
                                "logKey": "LumbarLordosisDetector-5d5dbd9949243ad537ac22df-1583181301_616063.log",
                                "versions": {
                                    "repoHash": "05e3c84152dd6cd0cc95d031dca6104d79c46c02",
                                    "modelHash": "TestModelHash"
                                },
                                "metrics": [
                                    {
                                        "name": "Lumbar lordosis angle",
                                        "value": 45.42598307664317,
                                        "points": [
                                            {
                                                x: 0.48828125,
                                                y: 0.16015625
                                            },
                                            {
                                                x: 1.0852888607011069,
                                                y: 0.23098766143911437
                                            },
                                            {
                                                x: 0.4609375,
                                                y: 0.73046875
                                            }
                                        ],
                                        "dataType": "angle",
                                        "seriesName": "n/a",
                                        "sliceIdx": 5
                                    },
                                    {
                                        "name": "Class probability",
                                        "value": 0.5360321148766307
                                    }
                                ]
                            },
                            "location": "Lumbar"
                        },
                    ]
                },
                {
                    "location": "Disc.L1-L2",
                    "objects": [
                        {
                            "type": "BiforaminalStenosis",
                            "severity": "N/A",
                            "acuity": true,
                            "summary": "Probability of left neural foraminal stenosis:0.571. Probability of right neural foraminal stenosis0.593.",
                            "rationale": {
                                "dicoms": [],
                                "images": [],
                                "logKey": "LumbarFSDetector-5d5dbd9949243ad537ac22df-1583181302_109963.log",
                                "versions": {
                                    "repoHash": "05e3c84152dd6cd0cc95d031dca6104d79c46c02",
                                    "modelHash": "NO_MODEL_HASH"
                                },
                                "metrics": [
                                    {
                                        "name": "LeftFSProbability",
                                        "value": 0.5705596804618835,
                                        "dataType": "probability"
                                    },
                                    {
                                        "name": "RightFSProbability",
                                        "value": 0.5926206707954407,
                                        "dataType": "probability"
                                    }
                                ]
                            },
                            "location": "Disc.L1-L2"
                        }
                    ]
                },
                {
                    "location": "DiscName",
                    "objects": [
                        {
                            "type": "TestType",
                            "severity": "TestSeverity",
                            "acuity": false,
                            "summary": "TestSummary.TestSummary.TestSummary.",
                            "rationale": {
                                "dicoms": [
                                    {
                                        "axis": "TestAxis",
                                        "seriesName": "TestSeriesName",
                                        "sliceIdx": 99999
                                    }
                                ],
                                "images": [
                                    {
                                        "title": "TestImageTitle",
                                        "key": "TestAwsKey"
                                    }
                                ],
                                "logKey": "NullDetector-5d5dbd9949243ad537ac22df-1583181301_6154902.log",
                                "versions": {
                                    "repoHash": "TestRepoHash",
                                    "modelHash": "TestModelHash"
                                },
                                "metrics": [
                                    {
                                        "name": "TestMetric",
                                        "value": 99999,
                                        "dataType": "probability"
                                    }
                                ]
                            },
                            "location": "DiscName"
                        }
                    ]
                }
            ],
            "source": "AI:Radiology (MRI)"
        }
    },
    "assignee": "5d62ff25b3a848001940b8d3",
    "dicomData": [
        {
            "seriesName": "T2_AX_STACKED",
            "slices": [
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -113.07566,
                        "y": -68.699371,
                        "z": 87.545705
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/1.jpg",
                    "imageKey": "09b775ed-8624-4f8f-875a-ea8674630579"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -113.34784,
                        "y": -68.69942,
                        "z": 81.551882
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/2.jpg",
                    "imageKey": "d1a1bf97-26d5-4ecb-a10d-884cd5870ff8"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -113.62002,
                        "y": -68.699469,
                        "z": 75.558058
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/3.jpg",
                    "imageKey": "dbc767d6-2948-4273-be46-de9d0ba5f82b"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -113.8922,
                        "y": -68.699518,
                        "z": 69.564235
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/4.jpg",
                    "imageKey": "c2c383bd-fa20-4b18-b2c8-a7c177058601"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -114.16438,
                        "y": -68.699567,
                        "z": 63.570411
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/5.jpg",
                    "imageKey": "cc7a601a-9ba2-45b4-b12a-c481ceb7e3f2"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -114.43655,
                        "y": -68.699616,
                        "z": 57.576588
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/6.jpg",
                    "imageKey": "8bec7370-0e6a-4204-93f0-f5449df8a1c4"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -114.70873,
                        "y": -68.699666,
                        "z": 51.582765
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/7.jpg",
                    "imageKey": "66c5f97d-31f6-4408-827b-5832fa9cdd5d"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -114.98091,
                        "y": -68.699715,
                        "z": 45.588941
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/8.jpg",
                    "imageKey": "ce1065cd-48f7-464e-a453-74d138b28b16"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -115.25309,
                        "y": -68.699764,
                        "z": 39.595118
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/9.jpg",
                    "imageKey": "930a7298-8abd-4385-a733-246ba2ace2c7"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -115.52527,
                        "y": -68.699813,
                        "z": 33.601294
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/10.jpg",
                    "imageKey": "93dab3ee-c36d-4f52-b64a-b5b9c76c994e"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -115.79744,
                        "y": -68.699862,
                        "z": 27.607471
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/11.jpg",
                    "imageKey": "2947125c-037a-4926-998b-46c2b8b2b2da"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -116.06962,
                        "y": -68.699911,
                        "z": 21.613647
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/12.jpg",
                    "imageKey": "e016658c-3ec8-43d9-a5e6-fa8f6eb257bb"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -116.3418,
                        "y": -68.699961,
                        "z": 15.619824
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/13.jpg",
                    "imageKey": "95451907-0283-4864-be99-12892355bc90"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -116.61398,
                        "y": -68.70001,
                        "z": 9.6260006
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/14.jpg",
                    "imageKey": "1e88e8c3-755a-444e-99ce-8b8c3e7a8bb7"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -116.88615,
                        "y": -68.700059,
                        "z": 3.6321772
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/15.jpg",
                    "imageKey": "d55f631c-ce28-472c-923c-a2ba0b4fbd69"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -117.15833,
                        "y": -68.700108,
                        "z": -2.3616463
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/16.jpg",
                    "imageKey": "1fc54770-c8ff-410e-b11a-8274e50d3633"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -117.43051,
                        "y": -68.700157,
                        "z": -8.3554697
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/17.jpg",
                    "imageKey": "fc0c9d97-71e4-4895-8fd4-a06c5cf89b12"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -117.70269,
                        "y": -68.700206,
                        "z": -14.349293
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/18.jpg",
                    "imageKey": "3e595135-2070-4f60-b139-7170a33259dd"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -117.97487,
                        "y": -68.700256,
                        "z": -20.343117
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/19.jpg",
                    "imageKey": "da5b53cd-a6dd-498b-b082-08e50f2f5b18"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -118.24704,
                        "y": -68.700305,
                        "z": -26.33694
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/20.jpg",
                    "imageKey": "2408123e-c503-4720-baf2-bedb95cd2d91"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -118.51922,
                        "y": -68.700354,
                        "z": -32.330763
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/21.jpg",
                    "imageKey": "fa83d6a6-bf62-402b-9ac1-93da2a1b569d"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -118.7914,
                        "y": -68.700403,
                        "z": -38.324587
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/22.jpg",
                    "imageKey": "477bfa96-b4b6-4a97-a712-7b8d7eaf51fb"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -119.06358,
                        "y": -68.700452,
                        "z": -44.31841
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/23.jpg",
                    "imageKey": "733ab018-f376-4796-b5d4-ba79fefeaabe"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -119.33576,
                        "y": -68.700501,
                        "z": -50.312234
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/24.jpg",
                    "imageKey": "7cbbbccf-f0e1-4422-b32c-530b327b48ef"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -119.60793,
                        "y": -68.700551,
                        "z": -56.306057
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/25.jpg",
                    "imageKey": "fd57a271-d213-4471-9510-fd3a183b70d1"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -119.88011,
                        "y": -68.7006,
                        "z": -62.29988
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/26.jpg",
                    "imageKey": "5bb52b84-d59e-4d60-8bc0-03b74289e0a7"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -120.15229,
                        "y": -68.700649,
                        "z": -68.293704
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/27.jpg",
                    "imageKey": "066923b1-1d21-437a-8d68-fcc5c695d028"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99897057,
                            "y": 0,
                            "z": -0.045362987
                        },
                        {
                            "x": -3.7176221e-7,
                            "y": 1,
                            "z": -0.0000081868399
                        }
                    ],
                    "imagePosition": {
                        "x": -120.42447,
                        "y": -68.700698,
                        "z": -74.287527
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_STACKED/28.jpg",
                    "imageKey": "ef760fc5-4d8c-4b96-8618-e9fba388bb52"
                }
            ],
            "annotations": {
                "0": []
            }
        },
        {
            "seriesName": "T2_SAG",
            "slices": [
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 57.371039,
                        "y": -74.892944,
                        "z": 148.37641
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_SAG/1.jpg",
                    "imageKey": "30f9531b-28a1-4a35-a7da-217d4e59cb80"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 50.87773,
                        "y": -74.894117,
                        "z": 148.67126
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_SAG/2.jpg",
                    "imageKey": "298eb699-3120-460a-9039-3c5354606f60"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 44.384422,
                        "y": -74.89529,
                        "z": 148.96612
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_SAG/3.jpg",
                    "imageKey": "10afb5a4-3993-4fca-a9d3-a69f553fd894"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 37.891113,
                        "y": -74.896463,
                        "z": 149.26098
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_SAG/4.jpg",
                    "imageKey": "9dc921bb-271b-4a30-96da-ba28958a1d98"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 31.397804,
                        "y": -74.897636,
                        "z": 149.55584
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_SAG/5.jpg",
                    "imageKey": "dac0aace-8889-476f-a160-5c2fa5d31990"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 24.904496,
                        "y": -74.898809,
                        "z": 149.8507
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_SAG/6.jpg",
                    "imageKey": "2a9d75ff-b468-4ec5-85c2-743aac46461c"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 18.411187,
                        "y": -74.899982,
                        "z": 150.14556
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_SAG/7.jpg",
                    "imageKey": "f3972376-1d73-44d8-97e7-03fd41ccd980"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 11.917879,
                        "y": -74.901155,
                        "z": 150.44042
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_SAG/8.jpg",
                    "imageKey": "edb14ecb-0e69-4067-bed3-813937bb1812"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 5.42457,
                        "y": -74.902329,
                        "z": 150.73528
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_SAG/9.jpg",
                    "imageKey": "09b576a5-c1a0-4a7e-b69f-0be9e0b22957"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": -1.0687386,
                        "y": -74.903502,
                        "z": 151.03014
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_SAG/10.jpg",
                    "imageKey": "a3c97efe-55c4-44f8-9e2c-fb9d98c26705"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": -7.5620472,
                        "y": -74.904675,
                        "z": 151.325
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_SAG/11.jpg",
                    "imageKey": "15928ceb-37d5-4c22-94d5-555f07d723f7"
                }
            ],
            "annotations": {
                "5": []
            }
        },
        {
            "seriesName": "T2_AX_ANGLES",
            "slices": [
                {
                    "imageOrientation": [
                        {
                            "x": 0.99950643,
                            "y": 0,
                            "z": -0.031414985
                        },
                        {
                            "x": -0.0049140974,
                            "y": 0.9876898,
                            "z": -0.15634806
                        }
                    ],
                    "imagePosition": {
                        "x": -113.59861,
                        "y": -70.528524,
                        "z": 70.979481
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/1.jpg",
                    "imageKey": "dca7081b-994a-4ce4-9b1b-e9894bff2b5b"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99950643,
                            "y": 0,
                            "z": -0.031414985
                        },
                        {
                            "x": -0.0049140974,
                            "y": 0.9876898,
                            "z": -0.15634806
                        }
                    ],
                    "imagePosition": {
                        "x": -113.78478,
                        "y": -71.467075,
                        "z": 65.056267
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/2.jpg",
                    "imageKey": "3517f2cf-6960-41ca-8766-bc805b0c8daa"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99950643,
                            "y": 0,
                            "z": -0.031414985
                        },
                        {
                            "x": -0.0049140974,
                            "y": 0.9876898,
                            "z": -0.15634806
                        }
                    ],
                    "imagePosition": {
                        "x": -113.97095,
                        "y": -72.405627,
                        "z": 59.133053
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/3.jpg",
                    "imageKey": "b8257af5-0ee8-44e5-ad27-2104f795b2fe"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99950643,
                            "y": 0,
                            "z": -0.031414985
                        },
                        {
                            "x": -0.0049140974,
                            "y": 0.9876898,
                            "z": -0.15634806
                        }
                    ],
                    "imagePosition": {
                        "x": -114.15712,
                        "y": -73.344178,
                        "z": 53.209839
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/4.jpg",
                    "imageKey": "04d87a59-ba87-4820-8b11-3184db990746"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99950697,
                            "y": 0,
                            "z": -0.031397677
                        },
                        {
                            "x": -0.001533798,
                            "y": 0.99880609,
                            "z": -0.048826598
                        }
                    ],
                    "imagePosition": {
                        "x": -115.34554,
                        "y": -78.898384,
                        "z": 21.688822
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/5.jpg",
                    "imageKey": "68c4476c-0c3c-43ab-9581-19e40c3cf8e4"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99950697,
                            "y": 0,
                            "z": -0.031397677
                        },
                        {
                            "x": -0.001533798,
                            "y": 0.99880609,
                            "z": -0.048826598
                        }
                    ],
                    "imagePosition": {
                        "x": -115.53371,
                        "y": -79.191488,
                        "z": 15.69894
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/6.jpg",
                    "imageKey": "4bda6203-7452-4266-95bc-e4910e8e03de"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99950697,
                            "y": 0,
                            "z": -0.031397677
                        },
                        {
                            "x": -0.001533798,
                            "y": 0.99880609,
                            "z": -0.048826598
                        }
                    ],
                    "imagePosition": {
                        "x": -115.72187,
                        "y": -79.484593,
                        "z": 9.7090577
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/7.jpg",
                    "imageKey": "a77cad17-7a5d-4365-917d-9652a3f232e0"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99950697,
                            "y": 0,
                            "z": -0.031397677
                        },
                        {
                            "x": -0.001533798,
                            "y": 0.99880609,
                            "z": -0.048826598
                        }
                    ],
                    "imagePosition": {
                        "x": -115.91003,
                        "y": -79.777697,
                        "z": 3.7191758
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/8.jpg",
                    "imageKey": "c76f9224-6e98-47fb-953e-fee5cfdebe8f"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99950656,
                            "y": 0,
                            "z": -0.031410759
                        },
                        {
                            "x": 0.0085516281,
                            "y": 0.96222611,
                            "z": 0.27211722
                        }
                    ],
                    "imagePosition": {
                        "x": -116.84397,
                        "y": -72.96868,
                        "z": -54.716985
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/9.jpg",
                    "imageKey": "971b7d80-ee41-4ea3-a476-8ebd2a50facb"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99950656,
                            "y": 0,
                            "z": -0.031410759
                        },
                        {
                            "x": 0.0085516281,
                            "y": 0.96222611,
                            "z": 0.27211722
                        }
                    ],
                    "imagePosition": {
                        "x": -117.02532,
                        "y": -71.33517,
                        "z": -60.487493
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/10.jpg",
                    "imageKey": "d3c4b19d-a09b-450b-95f8-ff4df840e8c1"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99950656,
                            "y": 0,
                            "z": -0.031410759
                        },
                        {
                            "x": 0.0085516281,
                            "y": 0.96222611,
                            "z": 0.27211722
                        }
                    ],
                    "imagePosition": {
                        "x": -117.20666,
                        "y": -69.701661,
                        "z": -66.258001
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/11.jpg",
                    "imageKey": "02bbbfed-52ad-441f-b749-7c7e1eb920e2"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99950656,
                            "y": 0,
                            "z": -0.031410759
                        },
                        {
                            "x": 0.0085516281,
                            "y": 0.96222611,
                            "z": 0.27211722
                        }
                    ],
                    "imagePosition": {
                        "x": -117.38801,
                        "y": -68.068152,
                        "z": -72.028508
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/12.jpg",
                    "imageKey": "70f01297-f36f-4e79-82bf-b5ee7db64411"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99954315,
                            "y": 0,
                            "z": -0.030223975
                        },
                        {
                            "x": 0.017635443,
                            "y": 0.81211899,
                            "z": 0.58322529
                        }
                    ],
                    "imagePosition": {
                        "x": -118.99946,
                        "y": -44.906277,
                        "z": -123.87756
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/13.jpg",
                    "imageKey": "ce7819fa-23e7-466e-8186-829244dcf44e"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99954315,
                            "y": 0,
                            "z": -0.030223975
                        },
                        {
                            "x": 0.017635443,
                            "y": 0.81211899,
                            "z": 0.58322529
                        }
                    ],
                    "imagePosition": {
                        "x": -119.14674,
                        "y": -41.405326,
                        "z": -128.74805
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/14.jpg",
                    "imageKey": "a8270f01-258d-4563-9c7d-492819c62c21"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99954315,
                            "y": 0,
                            "z": -0.030223975
                        },
                        {
                            "x": 0.017635443,
                            "y": 0.81211899,
                            "z": 0.58322529
                        }
                    ],
                    "imagePosition": {
                        "x": -119.29401,
                        "y": -37.904375,
                        "z": -133.61854
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/15.jpg",
                    "imageKey": "0b6f15b4-8d84-4cdf-b015-63fc3f5a484d"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99954315,
                            "y": 0,
                            "z": -0.030223975
                        },
                        {
                            "x": 0.017635443,
                            "y": 0.81211899,
                            "z": 0.58322529
                        }
                    ],
                    "imagePosition": {
                        "x": -119.44128,
                        "y": -34.403423,
                        "z": -138.48902
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2_AX_ANGLES/16.jpg",
                    "imageKey": "5bdccda9-c100-472b-881a-ee13e23e4a8f"
                }
            ]
        },
        {
            "seriesName": "T2AX_ANGLE_UPPER",
            "slices": [
                {
                    "imageOrientation": [
                        {
                            "x": 0.99996192,
                            "y": 0,
                            "z": -0.0087265355
                        },
                        {
                            "x": -0.00038064613,
                            "y": 0.99904822,
                            "z": -0.043617726
                        }
                    ],
                    "imagePosition": {
                        "x": -122.3442,
                        "y": -47.478935,
                        "z": 58.154422
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2AX_ANGLE_UPPER/1.jpg",
                    "imageKey": "14e90ba7-2617-486b-8567-2d1dc0e86603"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99996192,
                            "y": 0,
                            "z": -0.0087265355
                        },
                        {
                            "x": -0.00038064613,
                            "y": 0.99904822,
                            "z": -0.043617726
                        }
                    ],
                    "imagePosition": {
                        "x": -122.39651,
                        "y": -47.740652,
                        "z": 52.160361
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2AX_ANGLE_UPPER/2.jpg",
                    "imageKey": "1b0a8ad6-8793-4522-95fc-7531587c6f42"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99996192,
                            "y": 0,
                            "z": -0.0087265355
                        },
                        {
                            "x": -0.00038064613,
                            "y": 0.99904822,
                            "z": -0.043617726
                        }
                    ],
                    "imagePosition": {
                        "x": -122.44882,
                        "y": -48.002368,
                        "z": 46.1663
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2AX_ANGLE_UPPER/3.jpg",
                    "imageKey": "9c7a51bb-cfbf-49f1-af05-260cdb910c37"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99996192,
                            "y": 0,
                            "z": -0.0087265355
                        },
                        {
                            "x": -0.00038064613,
                            "y": 0.99904822,
                            "z": -0.043617726
                        }
                    ],
                    "imagePosition": {
                        "x": -122.50112,
                        "y": -48.264084,
                        "z": 40.172239
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2AX_ANGLE_UPPER/4.jpg",
                    "imageKey": "f3eb8c5d-a8db-49d4-80cb-ad01248f8ae2"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99996192,
                            "y": 0,
                            "z": -0.0087265355
                        },
                        {
                            "x": -0.0012597295,
                            "y": 0.98952579,
                            "z": -0.1443507
                        }
                    ],
                    "imagePosition": {
                        "x": -122.47846,
                        "y": -47.586776,
                        "z": 42.768776
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2AX_ANGLE_UPPER/5.jpg",
                    "imageKey": "d1f5ed03-ebb5-4558-9085-3891194a41a4"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99996192,
                            "y": 0,
                            "z": -0.0087265355
                        },
                        {
                            "x": -0.0012597295,
                            "y": 0.98952579,
                            "z": -0.1443507
                        }
                    ],
                    "imagePosition": {
                        "x": -122.53028,
                        "y": -48.452913,
                        "z": 36.831847
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2AX_ANGLE_UPPER/6.jpg",
                    "imageKey": "cd8848e6-c2ff-4a8e-9bda-d1b2e83e2dd3"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99996192,
                            "y": 0,
                            "z": -0.0087265355
                        },
                        {
                            "x": -0.0012597295,
                            "y": 0.98952579,
                            "z": -0.1443507
                        }
                    ],
                    "imagePosition": {
                        "x": -122.58209,
                        "y": -49.31905,
                        "z": 30.894918
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2AX_ANGLE_UPPER/7.jpg",
                    "imageKey": "42ea1cf5-440c-4eec-93db-e0da95421698"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99996192,
                            "y": 0,
                            "z": -0.0087265355
                        },
                        {
                            "x": -0.0012597295,
                            "y": 0.98952579,
                            "z": -0.1443507
                        }
                    ],
                    "imagePosition": {
                        "x": -122.6339,
                        "y": -50.185187,
                        "z": 24.95799
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2AX_ANGLE_UPPER/8.jpg",
                    "imageKey": "5e8e39f2-6583-4a8b-b232-dffe5323abb7"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99996192,
                            "y": 0,
                            "z": -0.0087265355
                        },
                        {
                            "x": -0.0016202263,
                            "y": 0.9826128,
                            "z": -0.18565955
                        }
                    ],
                    "imagePosition": {
                        "x": -122.70174,
                        "y": -52.381728,
                        "z": 17.184439
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2AX_ANGLE_UPPER/9.jpg",
                    "imageKey": "c31fe26d-753d-46cb-a0cf-a31389ae2cd0"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99996192,
                            "y": 0,
                            "z": -0.0087265355
                        },
                        {
                            "x": -0.0016202263,
                            "y": 0.9826128,
                            "z": -0.18565955
                        }
                    ],
                    "imagePosition": {
                        "x": -122.75318,
                        "y": -53.495728,
                        "z": 11.288987
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2AX_ANGLE_UPPER/10.jpg",
                    "imageKey": "1b99a5a9-0b9a-4822-bcef-f7930536d452"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99996192,
                            "y": 0,
                            "z": -0.0087265355
                        },
                        {
                            "x": -0.0016202263,
                            "y": 0.9826128,
                            "z": -0.18565955
                        }
                    ],
                    "imagePosition": {
                        "x": -122.80463,
                        "y": -54.609727,
                        "z": 5.3935343
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2AX_ANGLE_UPPER/11.jpg",
                    "imageKey": "fb4f016a-627b-4384-96f6-ee4516a120b7"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 0.99996192,
                            "y": 0,
                            "z": -0.0087265355
                        },
                        {
                            "x": -0.0016202263,
                            "y": 0.9826128,
                            "z": -0.18565955
                        }
                    ],
                    "imagePosition": {
                        "x": -122.85608,
                        "y": -55.723727,
                        "z": -0.501918
                    },
                    "imagePixelSpacing": {
                        "x": 1.0546875,
                        "y": 1.0546875
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2AX_ANGLE_UPPER/12.jpg",
                    "imageKey": "88c6c97b-637e-4323-86ef-9879cb12d478"
                }
            ]
        },
        {
            "seriesName": "T1_SAG",
            "slices": [
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 57.371039,
                        "y": -74.892944,
                        "z": 148.37641
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T1_SAG/1.jpg",
                    "imageKey": "14197d2c-9e16-4910-a0d9-4e9d15944beb"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 50.87773,
                        "y": -74.894117,
                        "z": 148.67126
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T1_SAG/2.jpg",
                    "imageKey": "d0c96a79-ea81-4601-8980-b46ca8e6054c"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 44.384422,
                        "y": -74.89529,
                        "z": 148.96612
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T1_SAG/3.jpg",
                    "imageKey": "5d9175f8-ac62-4c28-a943-90a304d4fb43"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 37.891113,
                        "y": -74.896463,
                        "z": 149.26098
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T1_SAG/4.jpg",
                    "imageKey": "935685c3-03be-4074-960c-ac1440aeae85"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 31.397804,
                        "y": -74.897636,
                        "z": 149.55584
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T1_SAG/5.jpg",
                    "imageKey": "9f71c8c5-1952-4ecd-a6a4-e837c4180b82"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 24.904496,
                        "y": -74.898809,
                        "z": 149.8507
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T1_SAG/6.jpg",
                    "imageKey": "f9a4d4e3-072b-4d37-8397-9e5f2c1c2678"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 18.411187,
                        "y": -74.899982,
                        "z": 150.14556
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T1_SAG/7.jpg",
                    "imageKey": "3c5b6960-e314-4fd6-986a-65cf4d275cd4"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 11.917879,
                        "y": -74.901155,
                        "z": 150.44042
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T1_SAG/8.jpg",
                    "imageKey": "211ccfef-fc5e-4b03-85eb-9ffdc3ac8e2a"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 5.42457,
                        "y": -74.902329,
                        "z": 150.73528
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T1_SAG/9.jpg",
                    "imageKey": "f2973748-b5f6-431e-989a-03991c552a5b"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": -1.0687386,
                        "y": -74.903502,
                        "z": 151.03014
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T1_SAG/10.jpg",
                    "imageKey": "2297b622-e804-43a8-9d05-a8c31e09564f"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": -7.5620472,
                        "y": -74.904675,
                        "z": 151.325
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T1_SAG/11.jpg",
                    "imageKey": "95b7ab78-28d1-408f-9efc-668ebf5894b1"
                }
            ]
        },
        {
            "seriesName": "STIR_SAG",
            "slices": [
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 57.371039,
                        "y": -74.892944,
                        "z": 148.37641
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "STIR_SAG/1.jpg",
                    "imageKey": "aa904a7f-fd68-4eae-a634-d0208a9fb104"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 50.87773,
                        "y": -74.894117,
                        "z": 148.67126
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "STIR_SAG/2.jpg",
                    "imageKey": "bf27af93-b0c6-4b4a-89a1-1c623c5ac191"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 44.384422,
                        "y": -74.89529,
                        "z": 148.96612
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "STIR_SAG/3.jpg",
                    "imageKey": "e0a67b78-8ece-4017-9112-26cb7693c298"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 37.891113,
                        "y": -74.896463,
                        "z": 149.26098
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "STIR_SAG/4.jpg",
                    "imageKey": "a8dc56fc-0d82-4a3c-875d-c51feb2adb6c"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 31.397804,
                        "y": -74.897636,
                        "z": 149.55584
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "STIR_SAG/5.jpg",
                    "imageKey": "d16c6886-9c14-42f5-a164-639e889ee570"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 24.904496,
                        "y": -74.898809,
                        "z": 149.8507
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "STIR_SAG/6.jpg",
                    "imageKey": "ad197b8f-0278-4a64-a512-ab6b38c0464c"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 18.411187,
                        "y": -74.899982,
                        "z": 150.14556
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "STIR_SAG/7.jpg",
                    "imageKey": "e674032b-ef6e-4d97-814f-359477ad6d70"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 11.917879,
                        "y": -74.901155,
                        "z": 150.44042
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "STIR_SAG/8.jpg",
                    "imageKey": "ff704f2a-b362-4bf5-b5ae-cf4789e4f697"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 5.42457,
                        "y": -74.902329,
                        "z": 150.73528
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "STIR_SAG/9.jpg",
                    "imageKey": "8dff9934-65af-4829-931e-9284415529b2"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": -1.0687386,
                        "y": -74.903502,
                        "z": 151.03014
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "STIR_SAG/10.jpg",
                    "imageKey": "d4b3c316-a56e-4e69-8bd3-9465b3c540de"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": -7.5620472,
                        "y": -74.904675,
                        "z": 151.325
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "STIR_SAG/11.jpg",
                    "imageKey": "72f191ba-e9af-437b-a0e4-59320e89705f"
                }
            ]
        },
        {
            "seriesName": "T2SAG_LG_FOV_LOC",
            "slices": [
                {
                    "imageOrientation": [
                        {
                            "x": -3.2060771e-18,
                            "y": 1,
                            "z": 0
                        },
                        {
                            "x": -0.0087265355,
                            "y": -2.7977946e-20,
                            "z": -0.99996192
                        }
                    ],
                    "imagePosition": {
                        "x": 34.631773,
                        "y": -146.5,
                        "z": 169.30875
                    },
                    "imagePixelSpacing": {
                        "x": 1.640625,
                        "y": 1.640625
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2SAG_LG_FOV_LOC/1.jpg",
                    "imageKey": "7ec1d77d-104d-49ee-a4ed-df7fb08f7ee3"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -3.2060771e-18,
                            "y": 1,
                            "z": 0
                        },
                        {
                            "x": -0.0087265355,
                            "y": -2.7977946e-20,
                            "z": -0.99996192
                        }
                    ],
                    "imagePosition": {
                        "x": 27.632039,
                        "y": -146.5,
                        "z": 169.36983
                    },
                    "imagePixelSpacing": {
                        "x": 1.640625,
                        "y": 1.640625
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2SAG_LG_FOV_LOC/2.jpg",
                    "imageKey": "c9b43555-c23a-4c91-9101-057d6f262ddb"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -3.2060771e-18,
                            "y": 1,
                            "z": 0
                        },
                        {
                            "x": -0.0087265355,
                            "y": -2.7977946e-20,
                            "z": -0.99996192
                        }
                    ],
                    "imagePosition": {
                        "x": 20.632306,
                        "y": -146.5,
                        "z": 169.43092
                    },
                    "imagePixelSpacing": {
                        "x": 1.640625,
                        "y": 1.640625
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2SAG_LG_FOV_LOC/3.jpg",
                    "imageKey": "6aec0bcf-899a-4405-bb8d-87088667ffc2"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -3.2060771e-18,
                            "y": 1,
                            "z": 0
                        },
                        {
                            "x": -0.0087265355,
                            "y": -2.7977946e-20,
                            "z": -0.99996192
                        }
                    ],
                    "imagePosition": {
                        "x": 13.632572,
                        "y": -146.5,
                        "z": 169.492
                    },
                    "imagePixelSpacing": {
                        "x": 1.640625,
                        "y": 1.640625
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2SAG_LG_FOV_LOC/4.jpg",
                    "imageKey": "ea6a1f99-7e3a-43c1-9ec1-6a74d01eee91"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -3.2060771e-18,
                            "y": 1,
                            "z": 0
                        },
                        {
                            "x": -0.0087265355,
                            "y": -2.7977946e-20,
                            "z": -0.99996192
                        }
                    ],
                    "imagePosition": {
                        "x": 6.632839,
                        "y": -146.5,
                        "z": 169.55309
                    },
                    "imagePixelSpacing": {
                        "x": 1.640625,
                        "y": 1.640625
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2SAG_LG_FOV_LOC/5.jpg",
                    "imageKey": "42b0836a-da1f-486a-8197-a88b17d446f5"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -3.2060771e-18,
                            "y": 1,
                            "z": 0
                        },
                        {
                            "x": -0.0087265355,
                            "y": -2.7977946e-20,
                            "z": -0.99996192
                        }
                    ],
                    "imagePosition": {
                        "x": -0.36689447,
                        "y": -146.5,
                        "z": 169.61418
                    },
                    "imagePixelSpacing": {
                        "x": 1.640625,
                        "y": 1.640625
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2SAG_LG_FOV_LOC/6.jpg",
                    "imageKey": "93b33c52-c8d5-4fc9-9574-2dbb50760e15"
                },
                {
                    "imageOrientation": [
                        {
                            "x": -3.2060771e-18,
                            "y": 1,
                            "z": 0
                        },
                        {
                            "x": -0.0087265355,
                            "y": -2.7977946e-20,
                            "z": -0.99996192
                        }
                    ],
                    "imagePosition": {
                        "x": -7.3666279,
                        "y": -146.5,
                        "z": 169.67526
                    },
                    "imagePixelSpacing": {
                        "x": 1.640625,
                        "y": 1.640625
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "T2SAG_LG_FOV_LOC/7.jpg",
                    "imageKey": "06958e61-ce9c-43c9-8de1-6f40316c3622"
                }
            ]
        },
        {
            "seriesName": "Scan_6_-_1-_Scout_-1",
            "slices": [
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 24.904496,
                        "y": -74.898809,
                        "z": 149.8507
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "Scan_6_-_1-_Scout_-1/1.jpg",
                    "imageKey": "fa84fc72-d249-459b-9b60-19938fe345eb"
                }
            ]
        },
        {
            "seriesName": "Scan_6_-_2-_Scout_-1",
            "slices": [
                {
                    "imageOrientation": [
                        {
                            "x": -3.2060771e-18,
                            "y": 1,
                            "z": 0
                        },
                        {
                            "x": -0.0087265355,
                            "y": -2.7977946e-20,
                            "z": -0.99996192
                        }
                    ],
                    "imagePosition": {
                        "x": 13.632572,
                        "y": -146.5,
                        "z": 169.492
                    },
                    "imagePixelSpacing": {
                        "x": 1.640625,
                        "y": 1.640625
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "Scan_6_-_2-_Scout_-1/1.jpg",
                    "imageKey": "2de0f5e0-8ebe-432f-94e2-cfce573d65d3"
                }
            ]
        },
        {
            "seriesName": "Scan_7_-_1-_Scout_-1",
            "slices": [
                {
                    "imageOrientation": [
                        {
                            "x": -0.00018065998,
                            "y": 0.99999998,
                            "z": 0
                        },
                        {
                            "x": -0.045362987,
                            "y": -0.0000081952764,
                            "z": -0.99897057
                        }
                    ],
                    "imagePosition": {
                        "x": 24.904496,
                        "y": -74.898809,
                        "z": 149.8507
                    },
                    "imagePixelSpacing": {
                        "x": 1.1328125,
                        "y": 1.1328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "Scan_7_-_1-_Scout_-1/1.jpg",
                    "imageKey": "83917c95-8a56-4797-bf86-97426e22c3b9"
                }
            ]
        },
        {
            "seriesName": "COR_LOC",
            "slices": [
                {
                    "imageOrientation": [
                        {
                            "x": 1,
                            "y": 0,
                            "z": 0
                        },
                        {
                            "x": 0,
                            "y": 0,
                            "z": -1
                        }
                    ],
                    "imagePosition": {
                        "x": -157.5,
                        "y": 101.8,
                        "z": 180.6
                    },
                    "imagePixelSpacing": {
                        "x": 1.328125,
                        "y": 1.328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "COR_LOC/1.jpg",
                    "imageKey": "cbf24860-2f86-48e4-8b0e-25841bce4334"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 1,
                            "y": 0,
                            "z": 0
                        },
                        {
                            "x": 0,
                            "y": 0,
                            "z": -1
                        }
                    ],
                    "imagePosition": {
                        "x": -157.5,
                        "y": 87.8,
                        "z": 180.6
                    },
                    "imagePixelSpacing": {
                        "x": 1.328125,
                        "y": 1.328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "COR_LOC/2.jpg",
                    "imageKey": "6afde50a-ec91-4dc1-bf01-c1bfcecd57f2"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 1,
                            "y": 0,
                            "z": 0
                        },
                        {
                            "x": 0,
                            "y": 0,
                            "z": -1
                        }
                    ],
                    "imagePosition": {
                        "x": -157.5,
                        "y": 73.8,
                        "z": 180.6
                    },
                    "imagePixelSpacing": {
                        "x": 1.328125,
                        "y": 1.328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "COR_LOC/3.jpg",
                    "imageKey": "8cfd83db-fbd6-4894-a6e9-854374123dc4"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 1,
                            "y": 0,
                            "z": 0
                        },
                        {
                            "x": 0,
                            "y": 0,
                            "z": -1
                        }
                    ],
                    "imagePosition": {
                        "x": -157.5,
                        "y": 59.8,
                        "z": 180.6
                    },
                    "imagePixelSpacing": {
                        "x": 1.328125,
                        "y": 1.328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "COR_LOC/4.jpg",
                    "imageKey": "94407109-396e-492d-a464-27e08a9abee9"
                },
                {
                    "imageOrientation": [
                        {
                            "x": 1,
                            "y": 0,
                            "z": 0
                        },
                        {
                            "x": 0,
                            "y": 0,
                            "z": -1
                        }
                    ],
                    "imagePosition": {
                        "x": -157.5,
                        "y": 45.8,
                        "z": 180.6
                    },
                    "imagePixelSpacing": {
                        "x": 1.328125,
                        "y": 1.328125
                    },
                    "rows": 256,
                    "columns": 256,
                    "imagePath": "COR_LOC/5.jpg",
                    "imageKey": "4c095a6d-03a9-4540-a954-a2b415926367"
                }
            ]
        }
    ]
}
