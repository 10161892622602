// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import { vec2, vec3 } from '../../../utilities/vectors';
import { plane } from '../../../utilities/plane';
import mathUtils from '../../../utilities/mathUtils';

// Helper function for the DICOM VIEWER component:
// TOOL FUNCTIONALITY

// These functions are intended to handle the logic behind tool operations within the Dicom Viewer component. 



// LABEL 2D TOOL
export const Label2DTool = {

    // Datatype 
    dataType: 'label',

    // Mouse Down
    handleMouseDown: async function (workspaceCoord) {

        let defaultText = "New Label Annotation";
        let annotationBoxDims = this.getLabel2DDimensions(defaultText);

        // Create new text annotation
        let annotation = { dataType: Label2DTool.dataType, points: [workspaceCoord], text: defaultText, width: annotationBoxDims[0], height: annotationBoxDims[1] };
        this.updateCurrentOperation( annotation );

        await this.saveCurrentOperation();

    },

    // Check if data is complete
    isComplete: function (data) {

        if (data.points.length > 0 && data.text)
            return true;
        else
            return false;
    },

    // Check if a point is within a given annotation
    isWithin: function (labelData, workspaceCoord) {

        // Check if the point is within the pixel bounding box...
        let textBoxWidth = labelData.width / this.state.workspace.width;
        let textBoxHeight = labelData.height / (this.state.workspace.width / this.state.workspace.aspectRatio);

        let offset = vec2.subtract(workspaceCoord, labelData.points[0]);
        if (offset.x > 0 && offset.x < textBoxWidth && offset.y < 0 && offset.y > -textBoxHeight)
            return true;

        return false;
    },

    getArea: function (labelData) {

        // Get area
        let textBoxWidth = labelData.width / this.state.workspace.width;
        let textBoxHeight = labelData.height / (this.state.workspace.width / this.state.workspace.aspectRatio);
        return textBoxWidth * textBoxHeight;

    },

    // Update point
    updatePoint: async function (pointData, newCoord) {

        if (pointData.location === "Annotations")
            this.genericUpdateAnnotationPoint(pointData, newCoord);

    }


}



// LABEL 3D TOOL
export const Label3DTool = {

    // Datatype 
    dataType: 'label3D',

    // Mouse Down
    handleMouseDown: async function (workspaceCoord) {

        let defaultText = "New 3D Label";

        let location3D = this.workspaceTo3D(workspaceCoord);
        location3D = plane.worldToStarkCoords(location3D);

        // Create new text annotation
        let annotation = { dataType: Label3DTool.dataType, points: [location3D], text: defaultText};
        this.updateCurrentOperation( annotation );

        await this.saveCurrentOperation();

    },

    // Check if data is complete
    isComplete: function (data) {

        if (data.points.length > 0 && data.text)
            return true;
        else
            return false;
    },

    // Check if a point is within a given annotation
    isWithin: function (labelData, workspaceCoord) {

        // TODO: Add functionality based on the projected 3D coord if needed. This should be passed in so it does not need to be recalculated frequently. 

        return false;
    },

    getArea: function (labelData) {

        // Get area
        // (Not needed)
        return 0;

    },

    // Update point
    updatePoint: async function (pointData, newCoord) {

        if (pointData.location === "GlobalAnnotations")
            this.genericUpdateGlobalPoint(pointData, newCoord);

    }


}




// CIRCLE 2D TOOL
export const Circle2DTool = {

    // Datatype 
    dataType: 'circle',

    // Mouse Down
    handleMouseDown: async function (workspaceCoord) {

        // First point?
        if (!this.props.currentOperation.points || this.props.currentOperation.points.length === 0)
            await this.initializeCurrentOperation(workspaceCoord);
    },

    handleMouseUp: async function (workspaceCoord) {

        let operation = this.props.currentOperation;

        // Set the circle radius? 
        if (operation.points.length === 1 && operation.radius === undefined) {
            operation.radius = vec2.distance(operation.points[0], workspaceCoord);

            // Dud click
            if (operation.radius < 0.00001) {
                this.updateCurrentOperation({});
                return;
            }

            await this.updateCurrentOperation( operation );

            // Save annotation
            this.saveCurrentOperation();
        }

    },

    // Check if data is complete
    isComplete: function (data) {

        if (data.points.length >= 1 && data.radius !== undefined)
            return true;

        else
            return false;
    },

    // Check if a point is within a given annotation
    isWithin: function (circleData, point) {

        let center = circleData.points[0];
        let radius = circleData.radius;

        if (vec2.distance(center, point) <= radius)
            return true;
        else
            return false;

    },

    getArea: function (circleData) {

        return Math.PI * circleData.radius * circleData.radius;

    },

    // Update point
    updatePoint: async function (pointData, newCoord) {

        // For all tools: 
        // If we ever desire multiple point movement at once: move these by a delta!
        // This should take multiple points, to perform only one state update.

        if (pointData.location === "Annotations")
            this.genericUpdateAnnotationPoint(pointData, newCoord);

    }


}

// ARROW TOOL
export const ArrowTool = {

    // Datatype 
    dataType: 'arrow',

    // Mouse Down
    handleMouseDown: async function (workspaceCoord) {

        let operation = this.props.currentOperation;

        // First point?
        if (!operation.points || operation.points.length === 0) {
            await this.initializeCurrentOperation(workspaceCoord);
            return;
        }

        let completed = ArrowTool.isComplete(operation);

        // Next arrow point
        if (!completed) {
            // Set the length text
            operation.points.push(workspaceCoord);
            await this.updateCurrentOperation( operation );
        }

        // Completed now? 
        if (ArrowTool.isComplete(operation))
            this.saveCurrentOperation();

    },

    // Check if data is complete
    isComplete: function (data) {

        if (data.points.length >= 2)
            return true;

        else
            return false;
    },

    // Check if a point is within a given annotation
    isWithin: function (arrowData, point) {

        let v1 = vec2.subtract(arrowData.points[1], arrowData.points[0]);
        let v2 = vec2.subtract(point, arrowData.points[0]);
        let magV1 = vec2.magnitude(v1);
        let magV2 = vec2.magnitude(v2);

        let cosTheta = (vec2.dot(v1, v2) / (magV1 * magV2));

        // Are we within bounds of the line? 
        let normalizedProjection = (magV2 * cosTheta) / magV1;
        if (normalizedProjection < 0 || normalizedProjection > 1)
            return false;

        // Now check the distance to the line
        let theta = Math.acos(cosTheta);
        let distToLine = magV2 * Math.sin(theta);

        // Consider 8 pixels to be over the line
        let pixelThreshold = 8;
        let pixelDistance = distToLine * this.state.workspace.width;

        if (pixelDistance < pixelThreshold)
            return true;
        else
            return false;

    },

    getArea: function (arrowData) {

        // For the circumstances, arrows will be considered to have an area of 0.
        return 0;
    },

    // Update point
    updatePoint: async function (pointData, newCoord) {

        if (pointData.location === "Annotations")
            this.genericUpdateAnnotationPoint(pointData, newCoord);

    }

}

// RECTANGLE 2D TOOL
export const Rectangle2DTool = {

    // Datatype 
    dataType: 'rectangle',

    // Mouse Down
    handleMouseDown: async function (workspaceCoord) {

        let operation = this.props.currentOperation;

        // First point
        if (!operation.points || operation.points.length === 0) {
            await this.initializeCurrentOperation(workspaceCoord);
            return;
        }

        let completed = Rectangle2DTool.isComplete(operation);

        // Next corner point
        if (!completed) {
            operation.points.push(workspaceCoord);
            await this.updateCurrentOperation( operation );
        }

        // Completed Now? 
        if (Rectangle2DTool.isComplete(operation)) {
            this.saveCurrentOperation();
        }

    },

    // Check if data is complete
    isComplete: function (data) {

        if (data.points.length >= 2)
            return true;

        else
            return false;
    },

    // Check if a point is within a given annotation
    isWithin: function (rectangleData, point) {

        let minXIdx, minYIdx
        if (rectangleData.points[0].x < rectangleData.points[1].x)
            minXIdx = 0;
        else
            minXIdx = 1;

        if (rectangleData.points[0].y < rectangleData.points[1].y)
            minYIdx = 0;
        else
            minYIdx = 1;

        let minX = rectangleData.points[minXIdx].x;
        let maxX = rectangleData.points[(minXIdx + 1) % 2].x;
        let minY = rectangleData.points[minYIdx].y;
        let maxY = rectangleData.points[(minYIdx + 1) % 2].y;

        if (point.x > minX && point.x < maxX && point.y > minY && point.y < maxY)
            return true;

        else
            return false;

    },

    getArea: function (rectangleData) {

        let width = Math.abs(rectangleData.points[0].x - rectangleData.points[1].x);
        let height = Math.abs(rectangleData.points[0].y - rectangleData.points[1].y);

        return width * height;

    },

    // Update point
    updatePoint: async function (pointData, newCoord) {

        if (pointData.location === "Annotations")
            this.genericUpdateAnnotationPoint(pointData, newCoord);

    }

}

// LENGTH MEASUREMENT TOOL
export const LengthTool = {

    // Datatype 
    dataType: 'length',

    // Mouse Dwon
    handleMouseDown: async function (workspaceCoord) {

        let operation = { ...this.props.currentOperation };

        // First point
        if (!operation.points || operation.points.length === 0) {
            await this.initializeCurrentOperation(workspaceCoord);
            return;
        }

        // Case: Operation Completion
        if (operation.points.length === 1) {
            // Set the length text
            let p1World = this.workspaceTo3D(operation.points[0]);
            let p2World = this.workspaceTo3D(workspaceCoord);
            let length = vec3.distance(p1World, p2World);

            operation.points.push(workspaceCoord);
            operation.value = length;
            await this.updateCurrentOperation( operation );

            // Save operation (only works if requested)
            this.saveCurrentOperation();
        }

        // Clear measurement
        else if (LengthTool.isComplete(operation) && !this.operationActive())
            this.updateCurrentOperation({});

    },

    // Check if data is complete
    isComplete: function (data) {

        if (data.points.length >= 2)
            return true;

        else
            return false;
    },

    // Check if a point is within a given annotation
    isWithin: function (lengthData, point) {

        const distToLine = plane.distanceToLine(lengthData.points[0], lengthData.points[1], point);

        // Consider 8 pixels to be over the line
        let pixelThreshold = 8;
        let pixelDistance = distToLine * this.state.workspace.width;

        if (pixelDistance < pixelThreshold)
            return true;
        else
            return false;

    },

    getArea: function (lengthData) {

        // For the circumstances, arrows will be considered to have an area of 0.
        return 0;
    },

    // Update point
    updatePoint: function (pointData, newCoord) {

        let newOp = this.props.currentOperation;

        // Make sure operation is complete
        if (newOp.points.length < 2)
            return;

        // Edit operation
        newOp.points[pointData.pointIdx] = newCoord;

        // Recompute distance
        let p1World = this.workspaceTo3D(newOp.points[0]);
        let p2World = this.workspaceTo3D(newOp.points[1]);
        let length = vec3.distance(p1World, p2World);
        newOp.value = length;

        // Update at the operation
        this.updateCurrentOperation( newOp );

    }

}

// ANGLE MEASUREMENT TOOL
export const AngleTool = {

    // Datatype 
    dataType: 'angle',

    handleMouseDown: async function (workspaceCoord) {

        let operation = { ...this.props.currentOperation };

        // First point
        if (!operation.points || operation.points.length === 0) {
            await this.initializeCurrentOperation(workspaceCoord);
            return;
        }

        // Case: Operation Ongoing
        if (operation.points.length < 2) {
            operation.points.push(workspaceCoord);
            this.updateCurrentOperation( operation );
        }

        // Case: Operation Completion
        else if (operation.points.length === 2) {

            // Update points
            operation.points.push(workspaceCoord);

            // Set the angle text
            let angle = mathUtils.pointsToAngle(operation.points);
            operation.value = angle;

            await this.updateCurrentOperation( operation );

            // Save operation (only works if requested)
            this.saveCurrentOperation();

        }

        // Clear measurement
        else if ((AngleTool.isComplete(operation) && !this.operationActive()))
            this.updateCurrentOperation({});

    },

    // Check if data is complete
    isComplete: function (data) {

        if (data.points.length >= 3)
            return true;

        else
            return false;
    },

    // Check if a point is within a given annotation
    isWithin: function (angleData, point) {

        // Consider 8 pixels to be over the line
        const pixelThreshold = 8;

        // Case of intersect: 
        if (angleData.points.length === 4) {

            // Two lines 
            for (let i=0; i<3 ; i+=2) {
                // Find dist
                const distToLine = plane.distanceToLine(angleData.points[i], angleData.points[i+1], point);
                const pixelDistance = distToLine * this.state.workspace.width;
                if (pixelDistance < pixelThreshold)
                    return true;
            } 
        }

        // Case of 3 points angle:
        else if (angleData.points.length === 3) {

            // Two lines coming out of the central point
            for (let i=0; i<2; i++) {
                // Find dist
                const distToLine = plane.distanceToLine(angleData.points[i], angleData.points[i+1], point);
                const pixelDistance = distToLine * this.state.workspace.width;
                if (pixelDistance < pixelThreshold)
                    return true;
            }
        }

        // Nothing close; return false
        return false;

    },

    getArea: function (lengthData) {

        // For the circumstances, arrows will be considered to have an area of 0.
        return 0;
    },


    updatePoint: function (pointData, newCoord) {

        let newOp = this.props.currentOperation;

        // Make sure operation is complete
        if (newOp.points.length < 3)
            return;

        // Edit the point
        newOp.points[pointData.pointIdx] = newCoord;

        // Update the angle measurement
        let angle = mathUtils.pointsToAngle(newOp.points);
        newOp.value = angle;


        // Update at the current operation
        this.updateCurrentOperation( newOp );

    }


}


// INTERSECT ANGLE MEASUREMENT TOOL
export const IntersectAngleTool = {

    // Datatype 
    dataType: 'angle',

    handleMouseDown: async function (workspaceCoord) {

        let operation = { ...this.props.currentOperation };

        // First point
        if (!operation.points || operation.points.length === 0) {
            await this.initializeCurrentOperation(workspaceCoord);
            return;
        }

        // Case: Operation Ongoing
        if (operation.points.length < 3) {
            operation.points.push(workspaceCoord);
            this.updateCurrentOperation( operation );
        }

        // Case: Operation Completion
        else if (operation.points.length === 3) {

            // Update points
            operation.points.push(workspaceCoord);

            // Set the angle text
            let angle = mathUtils.pointsToAngle(operation.points);
            operation.value = angle;

            await this.updateCurrentOperation( operation );

            // Save operation (only works if requested)
            this.saveCurrentOperation();

        }

        // Clear measurement
        else if ((AngleTool.isComplete(operation) && !this.operationActive()))
            this.updateCurrentOperation({});

    },

    // Check if data is complete
    isComplete: function (data) {

        if (data.points.length >= 3)
            return true;

        else
            return false;
    },

    // Check if a point is within a given annotation
    isWithin: function (angleData, point) {

        // Consider 8 pixels to be over the line
        const pixelThreshold = 8;

        // Case of intersect: 
        if (angleData.points.length === 4) {

            // Two lines 
            for (let i=0; i<3 ; i+=2) {
                // Find dist
                const distToLine = plane.distanceToLine(angleData.points[i], angleData.points[i+1], point);
                const pixelDistance = distToLine * this.state.workspace.width;
                if (pixelDistance < pixelThreshold)
                    return true;
            } 
        }

        // Case of 3 points angle:
        else if (angleData.points.length === 3) {

            // Two lines coming out of the central point
            for (let i=0; i<2; i++) {
                // Find dist
                const distToLine = plane.distanceToLine(angleData.points[i], angleData.points[i+1], point);
                const pixelDistance = distToLine * this.state.workspace.width;
                if (pixelDistance < pixelThreshold)
                    return true;
            }
        }

        // Nothing close; return false
        return false;

    },

    getArea: function (lengthData) {

        // For the circumstances, arrows will be considered to have an area of 0.
        return 0;
    },


    updatePoint: function (pointData, newCoord) {

        let newOp = this.props.currentOperation;

        // Make sure operation is complete
        if (newOp.points.length < 3)
            return;

        // Edit the point
        newOp.points[pointData.pointIdx] = newCoord;

        // Update the angle measurement
        let angle = mathUtils.pointsToAngle(newOp.points);
        newOp.value = angle;


        // Update at the current operation
        this.updateCurrentOperation( newOp );

    }


}

export const DeleteTool = {

    // Handle mouse down
    handleMouseDown: async function (e) {

        // Delete the hovered over annotation

        if (this.state.hoveredObject.location === "Annotations") {  

            this.deleteAnnotation(this.state.hoveredObject.listIdx);

            return;
        }

        // Delete hovered over injury metric

        if (this.state.hoveredObject.location === 'Injuries') {

            // Get the metric data
            const metricData = this.props.injuryMetrics[this.state.hoveredObject.listIdx];

            // Call the function @ App level to delete metric given data
            this.props.deleteInjuryMetric(metricData.location, metricData.fullData.type, metricData.metricIdx);

            // Clear the hover 
            this.setState({ hoveredObject: {} });

            return;

        }

    }

}