// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import {vec2} from '../utilities/vectors';

export const canvasUtils = {


    // ------ TEXT ------

    // Text rendering
    drawText : async (ctx, text, textCenter, fontSize = "32px", color = "blue", fontFamily = "sans-serif", fontStyle = "", textAlign = 'center') => {

        let font = fontStyle + " " + fontSize + " " + fontFamily;
        
        // Set context
        ctx.font = font;
        ctx.textAlign = textAlign;
        ctx.textBaseline = 'middle';
        ctx.fillStyle = color;

        ctx.fillText(text, textCenter.x, textCenter.y);

        return;
    },

    // Text with background
    drawTextWithBacking : async (ctx, text, textCenter, fontSize=32, backgroundColor="rgba(0,0,0,0.6)", backgroundMargin=4, fontColor='white', fontFamily='sans-serif', fontStyle = '') => {
        
        // Set context font
        let font = fontStyle + " " + fontSize + "px " + fontFamily;
        ctx.font = font;

        // Render background
        let boxMargin = backgroundMargin;
        let boxHeight = fontSize + 2*(boxMargin);

        let textWidth = ctx.measureText(text).width + 2*boxMargin;

        let TL = vec2.subtract(textCenter, {x: textWidth/2, y: boxHeight/2});
        let BR = vec2.add(TL, {x: textWidth, y: boxHeight});
        
        canvasUtils.drawRectangleFilled(ctx, TL, BR, backgroundColor, "rgba(0,0,0,0)", 0);

        // Set text context
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = fontColor;

        // Render text
        ctx.fillText(text, textCenter.x, textCenter.y);

    }, 


    // ------ LINES ------

    // Line segment
    drawLineSegment : (ctx, p1, p2, color, thickness) => {

        // Set context
        ctx.strokeStyle = color;
        ctx.lineJoin = "round";
        ctx.lineCap = "round";
        ctx.lineWidth = thickness;

        // Draw
        ctx.beginPath();

        ctx.moveTo(p1.x, p1.y);
        ctx.lineTo(p2.x, p2.y);
        ctx.stroke();
    },

    // Dotted Line segment
    drawDottedLineSegment : (ctx, p1, p2, color, thickness, solidLen=5, clearLen=5) => {
        
        // Set context
        ctx.strokeStyle = color;
        ctx.lineJoin = "round";
        ctx.lineCap = "round";
        ctx.lineWidth = thickness;
        ctx.setLineDash([solidLen, clearLen]);
        ctx.lineDashOffset = 0.0;

        // Draw
        ctx.beginPath();

        ctx.moveTo(p1.x, p1.y);
        ctx.lineTo(p2.x, p2.y);
        ctx.stroke();

        ctx.setLineDash([]);
    },

    // Crosshairs
    drawCrosshairs : (ctx, canvas, center, innerColor = "white", outerColor = "white", thicknessIn = 1.5, thicknessOut = 1, innerLength = 14) => {

        // Cursor points
        let uC = { ...center, y: (center.y - innerLength) };
        let bC = { ...center, y: (center.y + innerLength) };
        let lC = { ...center, x: (center.x - innerLength) };
        let rC = { ...center, x: (center.x + innerLength) };

        // Edge points
        let uEdge = { ...center, y: 0 };
        let bEdge = { ...center, y: canvas.height };
        let lEdge = { ...center, x: 0 };
        let rEdge = { ...center, x: canvas.width };

        canvasUtils.drawLineSegment(ctx, uC, bC, innerColor, thicknessIn);
        canvasUtils.drawLineSegment(ctx, lC, rC, innerColor, thicknessIn);
        canvasUtils.drawLineSegment(ctx, lEdge, rEdge, outerColor, thicknessOut);
        canvasUtils.drawLineSegment(ctx, uEdge, bEdge, outerColor, thicknessOut)

    },

    drawCrosshairsCenter : (ctx, center, color = "white", thickness = 1.5, innerLength = 14) => {

        // Cursor points
        let uC = { ...center, y: (center.y - innerLength) };
        let bC = { ...center, y: (center.y + innerLength) };
        let lC = { ...center, x: (center.x - innerLength) };
        let rC = { ...center, x: (center.x + innerLength) };

        canvasUtils.drawLineSegment(ctx, uC, bC, color, thickness);
        canvasUtils.drawLineSegment(ctx, lC, rC, color, thickness);

    },

    // ------ SQUARE ------   

    // Outline
    drawCircleOutline : (ctx, center, radius, outlineColor = "white", outlineThickness = 1) => {

        // Set context
        ctx.lineWidth = outlineThickness;
        ctx.strokeStyle = outlineColor;

        // Path
        ctx.beginPath();
        ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI, false);

        // Stroke
        ctx.stroke();
    },

    // Filled
    drawCircleFilled : (ctx, center, radius, centerColor = "black", borderColor = "black", borderThickness = 1) => {

        // Set context
        ctx.lineWidth = borderThickness;
        ctx.strokeStyle = borderColor;
        ctx.fillStyle = centerColor;

        // Path
        ctx.beginPath();
        ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI, false);

        // Fill and stroke
        ctx.fill();
        ctx.stroke();
    },


    // ------ SQUARE ------

    // Outline
    drawRectangleOutline : (ctx, TL, BR, color = "blue", thickness = 5) => {

        // Generate square points
        let squarePoints = [
            TL, // TL
            { x: BR.x, y: TL.y },// TR
            BR, // BR
            { x: TL.x, y: BR.y }// BL
        ];

        canvasUtils.drawPolygonOutline(ctx, squarePoints, color, thickness);

    },

    // Filled
    drawRectangleFilled : (ctx, TL, BR, color = "blue", outlineColor = "white", outlineThickness=0) => {

        // Generate square points
        let squarePoints = [
            TL, // TL
            { x: BR.x, y: TL.y },// TR
            BR, // BR
            { x: TL.x, y: BR.y }// BL
        ];

        // this.drawPolygon(ctx, squarePoints, filled);
        canvasUtils.drawPolygonFilled(ctx, squarePoints, color, outlineColor, outlineThickness);

    },

    // ------ ARROW ------

    drawArrow : (ctx, tail, head, baseWidth=10, outlineColor = "white", outlineThickness = 0, headHeight = 35, headWidth = 35) => {

        let points = [];

        // Define base
        let traj = {x: head.x - tail.x, y: head.y - tail.y};
        let unitTraj = vec2.normalize(traj);
        let magnitudeTraj = vec2.magnitude(traj);
        let normal = {x: -unitTraj.y, y: unitTraj.x};

        // Base
        let leftBase = vec2.add(tail, vec2.negate(vec2.scale(normal, baseWidth)));
        let rightBase = vec2.add(tail, vec2.scale(normal, baseWidth));

        // Neck Base
        let leftHeadBase = vec2.scale(unitTraj, magnitudeTraj - headHeight);
        leftHeadBase = vec2.add(leftBase, leftHeadBase);

        let rightHeadBase = vec2.scale(unitTraj, magnitudeTraj - headHeight);
        rightHeadBase = vec2.add(rightBase, rightHeadBase);

        // Neck Points points
        let leftPoint = vec2.add(leftHeadBase, vec2.negate(vec2.scale(normal, headWidth)));
        let rightPoint = vec2.add(rightHeadBase, vec2.scale(normal, headWidth));

        // Add to points, walking clockwise
        points = [leftBase, leftHeadBase, leftPoint, head, rightPoint, rightHeadBase, rightBase];

        canvasUtils.drawPolygonOutline(ctx, points, outlineColor, outlineThickness);

    },

    drawArrowFilled : (ctx, tail, head, baseWidth=10, outlineColor = "white", outlineThickness = 0, headHeight = 35, headWidth = 35, fillColor = "white") => {

        let points = [];

        // Define base
        let traj = {x: head.x - tail.x, y: head.y - tail.y};
        let unitTraj = vec2.normalize(traj);
        let magnitudeTraj = vec2.magnitude(traj);
        let normal = {x: -unitTraj.y, y: unitTraj.x};

        // Base
        let leftBase = vec2.add(tail, vec2.negate(vec2.scale(normal, baseWidth)));
        let rightBase = vec2.add(tail, vec2.scale(normal, baseWidth));

        // Neck Base
        let leftHeadBase = vec2.scale(unitTraj, magnitudeTraj - headHeight);
        leftHeadBase = vec2.add(leftBase, leftHeadBase);

        let rightHeadBase = vec2.scale(unitTraj, magnitudeTraj - headHeight);
        rightHeadBase = vec2.add(rightBase, rightHeadBase);

        // Neck Points points
        let leftPoint = vec2.add(leftHeadBase, vec2.negate(vec2.scale(normal, headWidth)));
        let rightPoint = vec2.add(rightHeadBase, vec2.scale(normal, headWidth));

        // Add to points, walking clockwise
        points = [leftBase, leftHeadBase, leftPoint, head, rightPoint, rightHeadBase, rightBase];

        canvasUtils.drawPolygonFilled(ctx, points, fillColor, outlineColor, outlineThickness);

    },


    // ------ GENERIC POLYGON ------

    // Outline
    drawPolygonOutline : (ctx, polygonPoints, outlineColor = "blue", outlineThickness = 0) => {

        ctx.lineJoin = "round";
        ctx.lineCap = "round";

        // Outline
        ctx.strokeStyle = outlineColor;
        ctx.lineWidth = outlineThickness;

        // Begin @ first point
        ctx.beginPath();
        ctx.moveTo(polygonPoints[0].x, polygonPoints[0].y)

        // Line to points, sequentially
        for (let i = 1; i <= polygonPoints.length; i++)
            ctx.lineTo(polygonPoints[i%polygonPoints.length].x, polygonPoints[i%polygonPoints.length].y);

        // Fill the shape
        ctx.stroke();

        return;
    },


    // Filled
    drawPolygonFilled : (ctx, polygonPoints, color = "black", outlineColor = "blue", outlineThickness = 0) => {

        ctx.lineJoin = "round";
        ctx.lineCap = "round";

        // Color
        ctx.fillStyle = color;

        // Outline
        ctx.strokeStyle = outlineColor;
        ctx.lineWidth = outlineThickness;

        // Begin @ first point
        ctx.beginPath();
        ctx.moveTo(polygonPoints[0].x, polygonPoints[0].y)

        // Line to points, sequentially
        for (let i = 1; i <= polygonPoints.length; i++)
            ctx.lineTo(polygonPoints[i%polygonPoints.length].x, polygonPoints[i%polygonPoints.length].y);


        // Fill the shape
        ctx.fill();
        ctx.stroke();

        return;
    },


    getWrappedLines : (ctx, text, maxWidth) => {

        // Returns text broken into lines.

        // NOTE: Make sure the context is set (text size and all)

        // All of these operations will be occuring within the space of the canvas... and in canvas coordinates... so the transform from canvas to page is crucial. 
        // Additionally, if this data is desired on the outside (for hover), we will need to push the data out in that direction

        // The width and height of the text box could be handled at the level of the viewer... chunk the text, and figure out the necessary height... 
        // This would provide us with a template to fill here, and a region to look for mouse over event within the DOM...


        // Split into words
        let words = text.split(' ');
        let lines = [];

        let curLine = "";

        for (let word of words) {

            // Measure the word width new line
            let projectedLine = curLine;
            if (projectedLine.length > 0)
                projectedLine += ' ';
            projectedLine += word;

            let projectedWidth = ctx.measureText(projectedLine).width;

            // Will it fit on the line? 
            if (projectedWidth <= maxWidth) 
                curLine = projectedLine;

            else {
                // Save line
                lines.push(curLine);

                // Start new line
                curLine = word;
            }
        }

        // Last line
        lines.push(curLine);

        return lines;

    },


    getTextBoxDims : (ctx, lines, text, fontSize, lineHeight, maxWidth, boxMargin = 5) => {

        // Compute dimensions of box needed. 
        let boxHeight = (lineHeight*lines.length) + (2*boxMargin) ;
        
        let boxWidth = maxWidth;
        if (lines.length === 1)
            boxWidth = ctx.measureText(text).width + 2*boxMargin;

        boxWidth += 2*boxMargin;

        return [boxWidth, boxHeight];
    }



}