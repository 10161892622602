// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import { vec2 } from './vectors';

export default {


    pointsToAngle : (points) => {

        if (points.length === 3) {

            // Given three points (hinge in the center), compute the angle in degrees.

            let p1 = points[0];
            let p2 = points[1];
            let p3 = points[2];

            let v1 = vec2.add(p1, vec2.negate(p2));
            let v2 = vec2.add(p3, vec2.negate(p2));

            let angle = Math.acos(vec2.dot(v1, v2) / (vec2.magnitude(v1) * vec2.magnitude(v2)))
            angle = (angle * 180 / Math.PI)

            return angle;
        }

        else if (points.length === 4) {

            // Given 3 points (line1, and line2), compute the angle in degrees. 

            let v1 = vec2.subtract(points[1], points[0]);
            let v2 = vec2.subtract(points[3], points[2]);

            let angle = Math.acos(vec2.dot(v1, v2) / (vec2.magnitude(v1) * vec2.magnitude(v2)))
            angle = (angle * 180 / Math.PI);

            // Make sure acute
            if (angle > 90)
                angle = 180 - angle;

            return angle;

        }


    }   


}