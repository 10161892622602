// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import { vec2 } from '../../../utilities/vectors';
import { typeToTool } from '../../../constants/tools';


// HELPER FUNCTIONS FOR THE DICOM VIEWER COMPONENT: 
    // HOVERED DATA FINDING

    // These functions are used by the DicomViewer, to update hovered, and active points in the state. 

    
// ANNOTATION ACTIVE POINTS

export function getActiveAnnotationPoints (annotationData, workspaceCursor, threshold = 0.05) {

    // Get acctive points from a given annotation

    for (let i = 0; i < annotationData.points.length; i++) {

        // Near point? 
        let point = annotationData.points[i];

        if (vec2.distance(point, workspaceCursor) < threshold) {
            // Set the activatedPoint
            let activatedPoint = { type: "Point", pointIdx: i };
            return [activatedPoint];
        }
    }

    // Check if we're near a projected point.
    // A ProjectedPoint is a point that is not specified within the object, such as the TR corner of a rectangle.
    // These points may be adjusted, and will in effect adjust the primary two points. 

    // Data-type: 
    // Point = {
    //     location: "",
    //     type: "PointProjection",
    //     listIdx: "Index of the object in its location (i.e. annotationIdx)",
    //     projection: {
    //         x: "The index of the object's point to attach the x value to.",
    //         y: "The index of the object's point to attach the y value to."
    //     }
    // }

    if (['rectangle'].includes(annotationData.dataType)) {

        let realPoints = annotationData.points;
        
        // Iterate over the combinations, and return the combination if near. 
        for (let i=0; i<2; i++) {

            let xIdx = i;
            let yIdx = (i+1)%2;

            let projectedPoint = {x: realPoints[xIdx].x, y: realPoints[yIdx].y};

            if (vec2.distance(projectedPoint, workspaceCursor) < threshold) {
                // Set the activatedPoint
                let activatedPoint = { type: "PointProjection", projection: {x: xIdx, y: yIdx} };
                return [activatedPoint];
            }

        }
    
    }

    // Handle the point/ radius case
    if (['circle'].includes(annotationData.dataType)) {

        // Check if we are are within the threshold of the radial ring...
        let center = annotationData.points[0];
        let distFromRadius = Math.abs(vec2.distance(center, workspaceCursor) - annotationData.radius);
        if (distFromRadius < threshold) {
            let activatedPoint = { type: "Radius" };
            return [activatedPoint];
        }
    }

    return [];

}


// INJURY ACTIVE POINTS

// Get activated points for a given injury metric
export function getActiveInjuryPoints (injuryMetricData, workspaceCursor, threshold = 0.05) {

    let metric = injuryMetricData.metric;
    let points = metric.points
    
    for (let i=0; i<points.length; i++) {

        // Near point? 
        let point = points[i];

        if (vec2.distance(point, workspaceCursor) < threshold) {
            // Set the activatedPoint
            let activatedPoint = { 
                type: "Point", 
                location: "Injuries",
                pointIdx: i 
            };
            return [activatedPoint];
        }
    }

    // Projected rectangle checking
    if (['rectangle'].includes(metric.dataType)) {

        let realPoints = points;
        
        // Iterate over the combinations, and return the combination if near. 
        for (let i=0; i<2; i++) {

            let xIdx = i;
            let yIdx = (i+1)%2;

            let projectedPoint = {x: realPoints[xIdx].x, y: realPoints[yIdx].y};

            if (vec2.distance(projectedPoint, workspaceCursor) < threshold) {

                // Set the activatedPoint
                let activatedPoint = { 
                    type: "PointProjection", 
                    projection: {x: xIdx, y: yIdx},
                    location: 'Injuries' 
                };
                return [activatedPoint];
            }

        }
    
    }

    // Radius checking 
    if (['circle'].includes(metric.dataType)) {

        // Check if we are are within the threshold of the radial ring...
        let center = points[0];

        let distFromRadius = Math.abs(vec2.distance(center, workspaceCursor) - metric.radius);

        if (distFromRadius < threshold) {

            let activatedPoint = { 
                type: "Radius",
                location: 'Injuries' 
            };
            return [activatedPoint];
        }
    }

    return [];

}


// Update hovered data, given the cursor location

export async function updateHoveredData  (workspaceCursor) {

    // Update the hoved over data based on the cursor location

    // Hovered points allow users to move to click to activate/ drag the point

    // Hovered object (text boxes) allow users to click to activate the box.

    // Don't update if we're in an active editing operation!
    if (this.state.activatedPoints.length > 0)
        return;

    // Get distance threshold (pixel-dist to workspace-dist)
    let thresholdPix = 12;
    let threshold = thresholdPix / this.state.workspace.width;

    let currentOperation = this.props.currentOperation;

    // Hovered over current operation?
    if (currentOperation.points && this.operationIsComplete(currentOperation)) {

        let activeOperationPoints = this.getActiveAnnotationPoints(currentOperation, workspaceCursor, threshold);
        for (let hoveredPoint of activeOperationPoints) {

            // Set the location, and add to activated
            let updatedPoint = { ...hoveredPoint, location: "CurrentOp" };

            this.setState({ hoveredPoints: [updatedPoint] });
            return;
        }
    }

    // Annotations? 
    let annotations = this.getAnnotations();
    for (let a = 0; a < annotations.length; a++) {

        // Get annotation points near cursor
        let activeAnnotationPoints = this.getActiveAnnotationPoints(annotations[a], workspaceCursor, threshold);

        for (let hoveredPoint of activeAnnotationPoints) {

            // Set the location
            let activePoint = { ...hoveredPoint, location: "Annotations", listIdx: a };
            // Add to state
            this.setState({ hoveredPoints: [activePoint], hoveredObject: {} });

            return;
        }

        // Are we within a textbox? 
        if (annotations[a].dataType === "label") {

            let tool = typeToTool[annotations[a].dataType];

            let textBoxHovered = this.Tools[tool].isWithin(annotations[a], workspaceCursor);

            if (textBoxHovered) {
                this.setState({
                    hoveredObject: {location: "Annotations", listIdx: a},
                    hoveredPoints: []    
                });
                return;
            }

        }
    }

    // Injuries? 
    for (let i=0; i<this.props.injuryMetrics.length; i++) {

        const injuryMetric = this.props.injuryMetrics[i];

        let activeInjuryPoints = this.getActiveInjuryPoints(injuryMetric, workspaceCursor, threshold);

        for (let hoveredPoint of activeInjuryPoints) {

            let updatedPoint = {...hoveredPoint, listIdx: i};
            this.setState({ hoveredPoints: [updatedPoint] });
            return;
        }

        // Are we within a textbox? 
        if (injuryMetric.metric.dataType === "label") {

            const tool = typeToTool[injuryMetric.metric.dataType];

            const textBoxHovered = this.Tools[tool].isWithin(injuryMetric.metric, workspaceCursor);

            if (textBoxHovered) {

                this.setState({
                    hoveredObject: {location: "Injuries", listIdx: i},
                    hoveredPoints: []    
                });
                return;
            }

        }
    }

    // Nothing captured
    this.setState({ 
        hoveredPoints: [],
        hoveredObject: {}
    });
}



// Update the currently hovered over annotation or injury

export async function updateHoveredObject (workspaceCursor) {

    let annotations = this.getAnnotations();

    // Locate the smallest area anotation we're within
    let nearestObject = null;
    let smallestArea = null;

    for (let a=0; a<annotations.length; a++) {

        // Are we within this annotation? 
        const annotation = annotations[a];
        const toolType = typeToTool[annotation.dataType];

        const within = this.Tools[toolType].isWithin(annotation, workspaceCursor);

        if (within) {

            let area = this.Tools[toolType].getArea(annotation);

            if (smallestArea === null || area < smallestArea) {
                nearestObject = {location: "Annotations", listIdx: a};
                smallestArea = area;
            }

        }            
    }

    for (let m=0; m<this.props.injuryMetrics.length; m++) {

        // Are we within this metric? 
        const metricRef = this.props.injuryMetrics[m];
        const metric = metricRef.metric;
        const toolType = typeToTool[metric.dataType];

        const within = this.Tools[toolType].isWithin(metric, workspaceCursor);

        if (within) {

            let area = this.Tools[toolType].getArea(metric);

            if (smallestArea === null || area < smallestArea) {
                nearestObject = {location: "Injuries", listIdx: m};
                smallestArea = area;
            }

        }     

    }


    // Found hovered object
    if (smallestArea !== null && nearestObject !== null)
        this.setState({hoveredObject: nearestObject});
    
    // Found nothing
    else
        this.setState({hoveredObject: {}});

}