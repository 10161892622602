// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

// Styles
import '../styles/DicomViewer/canvas.css';

// Utils
import { vec2 } from '../utilities/vectors';
import { canvasUtils } from '../utilities/canvas';

import { colorSchemes, textSchemes, sizeSchemes } from '../constants/canvasConstants';

const canvasWidth = 1200

class Canvas extends Component {

    state = {

        // Canvas state
        canvasDimensions: [1200, 1200],
        viewerRectangle: {},

    }

    componentDidMount = async () => {

        // Handle canvas dimensionResizing
        window.addEventListener('resize', this.syncCanvasDimensions);

        // Update canvas dimensions
        await this.syncCanvasDimensions();

        // Initial draw
        await this.renderFromProps();

        // Test: Set canvas border
        let canvas = this.refs.mainCanvas;
        canvas.style.border = "2px solid rgb(56,56,56)";

    }


    componentDidUpdate = async (prevProps, prevState) => {

        // Refactor canvas dimensions?
        if (prevProps.viewerConfiguration !== this.props.viewerConfiguration)
            await this.syncCanvasDimensions();

        // Rerendering conditions
        if (prevProps.cursorPosition["2D"] !== this.props.cursorPosition["2D"] ||
            prevProps.cursorPosition['3D'] !== this.props.cursorPosition['3D'] ||
            prevProps.workspace !== this.props.workspace || 
            prevProps.annotations !== this.props.annotations ||
            prevProps.hoveredObject.listIdx !== this.props.hoveredObject.listIdx ||
            prevProps.hoveredEditorObjects !== this.props.hoveredEditorObjects ||
            prevProps.currentOperation !== this.props.currentOperation ||
            prevProps.visibilities !== this.props.visibilities || 
            prevProps.injuries !== this.props.injuries) {

            // Draw
            requestAnimationFrame(() => this.renderFromProps());
        }
    }

    // Sync canvas aspet ratio
    syncCanvasDimensions = async () => {

        // Canvas div width 
        let canvas = this.refs.mainCanvas;

        if (!canvas)
            return;

        let canvasRect = canvas.getBoundingClientRect();

        const currentAspect = canvasRect.width / canvasRect.height;

        let newCanvasWidth = canvasWidth;

        // Use the window resolution if higher-res
        if (canvasRect.width > canvasWidth)
            newCanvasWidth = canvasRect.width;
        
        newCanvasWidth = canvasRect.width;

        // Update the canvas dims / cached viewer dims 
        await this.setState({ 
            canvasDimensions: [newCanvasWidth, newCanvasWidth / currentAspect],
            viewerRectangle: canvasRect 
        });

        // Rerender
        this.clearCanvas();
        this.renderFromProps();

    }


    // ------- RENDER MANAGEMENT FUNCTIONS -----------

    // Render the canvas from props
    renderFromProps = async (nextProps = undefined) => {

        
        let renderProps = nextProps === undefined ? this.props : nextProps;
        const hoveredEditorObject = renderProps.hoveredEditorObjects[0];

        // Canvas
        let canvas = this.refs.mainCanvas;
        let ctx = canvas.getContext('2d');

        // Clear canvas
        await ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Render Annotations
        for (let a=0; a<renderProps.annotations.length; a++) {

            const annotation = renderProps.annotations[a];

            // Hovered?
            if (renderProps.hoveredObject.location === "Annotations" && renderProps.hoveredObject.listIdx === a)
                this.renderOperation(annotation, null, ctx, "Delete");

            // Not Hovered    
            else 
                this.renderOperation(annotation, null, ctx);
        }

        // Render Global Annotations 
        for (let annotation of renderProps.globalAnnotations)
            this.renderOperation(annotation, null, ctx);


        // Render Injuries
        for (let i=0; i<renderProps.injuries.length; i++) {

            const injury = renderProps.injuries[i];

            const injuryName = injury.fullData.type;

            // Deletion Hovered? 
            if (renderProps.hoveredObject.location === "Injuries" && renderProps.hoveredObject.listIdx === i)
                this.renderInjuryMetric(injury.metric, null, ctx, injuryName, "Delete");
            
            // Report Eitor hovered? 
            else if (   hoveredEditorObject && 
                        hoveredEditorObject.location === "Injuries" && 
                        hoveredEditorObject.injuryLocation === injury.location && 
                        hoveredEditorObject.injuryType === injury.fullData.type && 
                        hoveredEditorObject.metricIdx === injury.metricIdx)
                this.renderInjuryMetric(injury.metric, null, ctx, injuryName, "Hovered");

            // Not Hoverd
            else
                this.renderInjuryMetric(injury.metric, null, ctx, injuryName);
        }

        // Hovered Points
        for (let point of renderProps.hoveredPoints) 
            this.render_MouseOverPoint(ctx, renderProps, point, "Hovered");

        // Activated Points
        for (let point of renderProps.activatedPoints)
            this.render_MouseOverPoint(ctx, renderProps, point, "Activated");

        
        // Slice intersection
        if (renderProps.visibilities.intersections && this.props.sliceIntersection.length >= 2) {

            const p1 = this.workspaceToCanvas(this.props.sliceIntersection[0]);
            const p2 = this.workspaceToCanvas(this.props.sliceIntersection[1]);

            canvasUtils.drawLineSegment(ctx, p1, p2, colorSchemes.sliceIntersectionColor, this.pixelsToThickness(sizeSchemes.sliceIntersection));

        }

        // Crosshairs
        if (renderProps.inFocus) {

            // Get cursor
            let canvasCursor = this.workspaceToCanvas(renderProps.cursorPosition["2D"]);

            // Render current operation with cursor data
            this.renderOperation(renderProps.currentOperation, canvasCursor, ctx);

            // Crosshairs (last)
            const innerThickness = this.pixelsToThickness(sizeSchemes.crossHairsInnerThickness);
            const outterThickness = this.pixelsToThickness(sizeSchemes.crossHairsOuterThickness);
            const innerLength = this.pixelsToThickness(sizeSchemes.crossHairsInnerLength);
            canvasUtils.drawCrosshairs(ctx, canvas, canvasCursor, colorSchemes.crosshairsCenter, colorSchemes.crosshairs, innerThickness, outterThickness, innerLength);

        }

        else {

            // Render current operation w/o cursor data
            this.renderOperation(renderProps.currentOperation, null, ctx);

            // Global cursor
            if (renderProps.visibilities.globalCursor && renderProps.showGlobalCursor && renderProps.globalCursorPosition.x <= 1 && renderProps.globalCursorPosition.y <= 1 && renderProps.globalCursorPosition.x >= 0 && renderProps.globalCursorPosition.y >= 0) {

                // Global cursor position
                let canvasGlobalCursor = this.workspaceToCanvas(renderProps.globalCursorPosition);

                // Render global crosshairs
                const cursorThickness = this.pixelsToThickness(sizeSchemes.globalCursorThickness);
                const cursorLength = this.pixelsToThickness(sizeSchemes.globalCursorLength);
                canvasUtils.drawCrosshairsCenter(ctx, canvasGlobalCursor, colorSchemes.globalCursor, cursorThickness, cursorLength);
            }
        }

    }

    // Helper function to render the two types of points (hovered and active)
    render_MouseOverPoint = (ctx, renderProps, pointData, activeType) => {

        if (pointData.type === "Point" || pointData.type === 'PointProjection') {

            // Locate the point
            let pointLocation = null;

            // Distinct point
            if (pointData.type === 'Point') {
                // Current Op
                if (pointData.location === 'CurrentOp')
                    pointLocation = renderProps.currentOperation.points[pointData.pointIdx];
                // Annotations
                else if (pointData.location === 'Annotations')
                    pointLocation = renderProps.annotations[pointData.listIdx].points[pointData.pointIdx];
                // Injuries
                else if (pointData.location === 'Injuries') 
                    pointLocation = renderProps.injuries[pointData.listIdx].metric.points[pointData.pointIdx];
                
            }

            // Point Projection
            else if (pointData.type === 'PointProjection') {
                if (pointData.location === 'Annotations') {
                    const parentPoints = renderProps.annotations[pointData.listIdx].points;
                    pointLocation = {x: parentPoints[pointData.projection.x].x, y: parentPoints[pointData.projection.y].y};
                }
                // Injury projection
                else if (pointData.location === 'Injuries') {
                    const parentPoints = renderProps.injuries[pointData.listIdx].metric.points;
                    pointLocation = {x: parentPoints[pointData.projection.x].x, y: parentPoints[pointData.projection.y].y};
                }
            }
            
            // Render the point
            if (pointLocation !== null && activeType === "Activated")
                this.render_ActivePoint(ctx, this.workspaceToCanvas(pointLocation));
            else if (pointLocation !== null && activeType === "Hovered")
                this.render_HoverPoint(ctx, this.workspaceToCanvas(pointLocation));

        }

        else if (pointData.type === 'Radius') {

            // Locate points of interes
            let center = null;
            let radius = null;

            if (pointData.location === 'CurrentOp') {
                center = this.workspaceToCanvas(renderProps.currentOperation.points[0]);
                radius = this.workspaceDistanceToCanvas(renderProps.currentOperation.radius);
            }

            else if (pointData.location === 'Annotations') {
                center = this.workspaceToCanvas(renderProps.annotations[pointData.listIdx].points[0]);
                radius = this.workspaceDistanceToCanvas(renderProps.annotations[pointData.listIdx].radius);
            }

            else if (pointData.location === 'Injuries') {
                center = renderProps.injuries[pointData.listIdx].metric.points[0];
                center = this.workspaceToCanvas(center);
                radius = this.workspaceDistanceToCanvas(renderProps.injuries[pointData.listIdx].metric.radius);
            }

            // Render hover radius
            if (center !== null && radius !== null && activeType === "Activated")
                this.render_ActiveRadius(ctx, center, radius);
            else if (center !== null && radius !== null && activeType === "Hovered")
                this.render_HoverRadius(ctx, center, radius);

        }

    }

    // Rendering for Annotations, and Current Operations
    renderOperation = (operationData, canvasCursor, ctx, hoveredType = null) => {

        // console.log("Render Operation: ", operationData)

        // Measurments

        // Length Operation
        if (operationData.dataType === "length")
            this.render_Length2D(ctx, operationData, canvasCursor, null, hoveredType);

        // Intersect Angle Operation
        else if (operationData.dataType === "angle" && (operationData.intersectAngle || operationData.points.length === 4))
            this.render_IntersectAngle(ctx, operationData, canvasCursor, null, hoveredType);

        // Angle Operation
        else if (operationData.dataType === "angle")
            this.render_Angle(ctx, operationData, canvasCursor, null, hoveredType);

        // Annotations

        // Label Operation
        else if (operationData.dataType === "label")
            this.render_Label2D(ctx, operationData, canvasCursor, null, hoveredType);

        // Circle Operation
        else if (operationData.dataType === "circle")
            this.render_Circle2D(ctx, operationData, canvasCursor, null, hoveredType);

        // Rectangle Operation
        else if (operationData.dataType === "rectangle")
            this.render_Rectangle2D(ctx, operationData, canvasCursor, null, hoveredType);

        // Arrow Operation
        else if (operationData.dataType === "arrow")
            this.render_Arrow(ctx, operationData, canvasCursor, null, hoveredType);


        // 3D Annotations 

        // Label 3D
        else if (operationData.dataType === 'label3D')
            this.render_Label3D(ctx, operationData);

    }

    // Rendering for Injury Metrics
    renderInjuryMetric = (operationData, canvasCursor, ctx, injuryName = '', hoveredType = null) => {

        // Intersect Angle Operation
        if (operationData.dataType === "angle" && operationData.points.length === 4)
            this.render_IntersectAngle(ctx, operationData, canvasCursor, injuryName, hoveredType);

        // ANGLE METRICS
        else if ( operationData.dataType === 'angle' ) {
            this.render_Angle(ctx, operationData, canvasCursor, injuryName, hoveredType);
        }

        // LENGTH METRICS
        else if ( operationData.dataType === 'length') {
            this.render_Length2D(ctx, operationData, canvasCursor, injuryName, hoveredType);
        }

        // RECTANGLE METRICS
        else if ( operationData.dataType === 'rectangle') {
            this.render_Rectangle2D(ctx, operationData, canvasCursor, injuryName, hoveredType);
        }

        // CIRCLE METRICS 
        else if ( operationData.dataType === 'circle') {
            this.render_Circle2D(ctx, operationData, canvasCursor, injuryName, hoveredType);
        }

        // LABEL METRICS 
        else if ( operationData.dataType === 'label') {
            this.render_Label2D(ctx, operationData, canvasCursor, injuryName, hoveredType);

        }

    }



    // ------- TOOL RENDERING HELPER FUNCTIONS -----------

    // Length Measurment 
    render_Length2D = (ctx, lengthData, canvasCursor, indicatorText, hoveredType) => {

        if (lengthData.points.length === 1 && canvasCursor !== null) {
            // Line from prev to current 
            const anchor = this.workspaceToCanvas(lengthData.points[0]);
            canvasUtils.drawLineSegment(ctx, anchor, canvasCursor, colorSchemes.rulerPending, this.pixelsToThickness(sizeSchemes.rulerThickness));
        }

        else if (lengthData.points.length === 2) {

            // Determine color set
            const rulerColor = hoveredType === 'Delete' ? colorSchemes.objectDeleteColor : hoveredType === 'Hovered' ? colorSchemes.objectHoveredColor : colorSchemes.rulerSet;

            // Line from p1 to p2
            const p1 = this.workspaceToCanvas(lengthData.points[0]);
            const p2 = this.workspaceToCanvas(lengthData.points[1]);
            canvasUtils.drawLineSegment(ctx, p1, p2, rulerColor, this.pixelsToThickness(sizeSchemes.rulerThickness));

            // Render text with background
            const fontHeight = this.pixelsToThickness(16);
            const backgroundMargin = this.pixelsToThickness(2);
            let textCenter = vec2.midPoint(p1, p2);

            const backgroundColor = colorSchemes.measureTextBacking;
            const textColor = colorSchemes.rulerTextColor;

            let text = lengthData.value.toFixed(2).toString() + ' mm';

            canvasUtils.drawTextWithBacking(ctx, text, textCenter, fontHeight, backgroundColor, backgroundMargin, textColor);

            // Render indicator text
            if (indicatorText) {

                // Render below the lower point
                const anchor = lengthData.points[0].y > lengthData.points[1].y ? lengthData.points[0] : lengthData.points[1];
                const canvasOffset = {x: 0, y: this.pixelsToThickness(textSchemes.metricIndicatorText.fontSize + 2)};

                // Convert center 
                const center = vec2.add(this.workspaceToCanvas(anchor), canvasOffset);

                this.render_MetricIndicatorText(ctx, center, indicatorText, rulerColor);

            }
        }

    }


    // Intersect Angle Measurment
    render_IntersectAngle = async (ctx, angleData, canvasCursor, indicatorText, hoveredType) => {

        // Line color
        const angleColor = hoveredType === 'Delete' ? colorSchemes.objectDeleteColor : hoveredType === 'Hovered' ? colorSchemes.objectHoveredColor : colorSchemes.angleSet;
        const lineColor = angleData.points.length === 4 ? angleColor : colorSchemes.anglePending;

        // Convert points
        let canvasPoints = angleData.points.map(elem => { return this.workspaceToCanvas(elem) });

        // Render necessary lines
        for (let i = 1; i < canvasPoints.length; i+=2)
            canvasUtils.drawLineSegment(ctx, canvasPoints[i - 1], canvasPoints[i], lineColor, this.pixelsToThickness(sizeSchemes.angleThickness));


        // Case: In focus and pending points 
        if ((canvasPoints.length ===1 || canvasPoints.length === 3) && canvasCursor !== null) {
            // Draw line to cursor
            const p1 = canvasPoints[canvasPoints.length - 1];
            canvasUtils.drawLineSegment(ctx, p1, canvasCursor, lineColor, this.pixelsToThickness(sizeSchemes.angleThickness));
        }

        // Case: Completed
        else if (canvasPoints.length === 4 && angleData.value !== undefined) {

            // Compute text center

            // Center of each line
            const c1 = vec2.avg(canvasPoints[0], canvasPoints[1]);
            const c2 = vec2.avg(canvasPoints[2], canvasPoints[3]);

            const textCenter = vec2.avg(c1, c2);

            // Render line between centers
            canvasUtils.drawDottedLineSegment(ctx, c1, c2, colorSchemes.angleAltitude, this.pixelsToThickness(sizeSchemes.angleThickness), 3, 7);

            // Render text with background
            const fontHeight = this.pixelsToThickness(16);
            const backgroundMargin = this.pixelsToThickness(4);

            const backgroundColor = colorSchemes.measureTextBacking;
            const textColor = colorSchemes.angleTextColor;

            // Parse angle text
            const text = angleData.value.toFixed(2).toString() + '°';

            await canvasUtils.drawTextWithBacking(ctx, text, textCenter, fontHeight, backgroundColor, backgroundMargin, textColor);

            // Render indicator text
            if (indicatorText) {

                // Render under of the lower left point
                let anchor = angleData.points[0];
                if (angleData.points[1].y > anchor.y)
                    anchor = angleData.points[1];
                if (angleData.points[2].y > anchor.y)
                    anchor = angleData.points[2];
                if (angleData.points[3].y > anchor.y)
                    anchor = angleData.points[3];

                anchor = this.workspaceToCanvas(anchor);

                const canvasOffset = {x: 0, y: this.pixelsToThickness(textSchemes.metricIndicatorText.fontSize + 2)};

                // Convert center 
                const center = vec2.add(anchor, canvasOffset);

                // Convert center 
                this.render_MetricIndicatorText(ctx, center, indicatorText, lineColor);

            }

        }


    }

    // Angle Measurment
    render_Angle = async (ctx, angleData, canvasCursor, indicatorText, hoveredType) => {

        // Line color
        const angleColor = hoveredType === 'Delete' ? colorSchemes.objectDeleteColor : hoveredType === 'Hovered' ? colorSchemes.objectHoveredColor : colorSchemes.angleSet;
        const lineColor = angleData.points.length === 3 ? angleColor : colorSchemes.anglePending;

        // Convert points
        let canvasPoints = angleData.points.map(elem => { return this.workspaceToCanvas(elem) });

        // Render necessary lines
        for (let i = 1; i < canvasPoints.length; i++)
            canvasUtils.drawLineSegment(ctx, canvasPoints[i - 1], canvasPoints[i], lineColor, this.pixelsToThickness(sizeSchemes.angleThickness));


        // Case: In focus and pending points 
        if (canvasPoints.length < 3 && canvasCursor !== null) {
            // Draw line to cursor
            const p1 = canvasPoints[canvasPoints.length - 1];
            canvasUtils.drawLineSegment(ctx, p1, canvasCursor, lineColor, this.pixelsToThickness(sizeSchemes.angleThickness));
        }

        // Case: Completed
        else if (canvasPoints.length === 3 && angleData.value !== undefined) {

            // Compute text center
            const v1 = vec2.add(canvasPoints[0], vec2.negate(canvasPoints[1]));
            const v2 = vec2.add(canvasPoints[2], vec2.negate(canvasPoints[1]));
            const textOffset = vec2.scale(vec2.avg(v2, v1), 0.32);
            const textCenter = vec2.add(canvasPoints[1], textOffset);

            // Render text with background
            const fontHeight = this.pixelsToThickness(16);
            const backgroundMargin = this.pixelsToThickness(4);

            const backgroundColor = colorSchemes.measureTextBacking;
            const textColor = colorSchemes.angleTextColor;

            // Parse angle text
            const text = angleData.value.toFixed(2).toString() + '°';

            await canvasUtils.drawTextWithBacking(ctx, text, textCenter, fontHeight, backgroundColor, backgroundMargin, textColor);

            // Render indicator text
            if (indicatorText) {

                // Render under of the top most point
                let anchor = angleData.points[0];
                if (angleData.points[1].y > anchor.y)
                    anchor = angleData.points[1];
                if (angleData.points[2].y > anchor.y)
                    anchor = angleData.points[2];

                anchor = this.workspaceToCanvas(anchor);

                const canvasOffset = {x: 0, y: this.pixelsToThickness(textSchemes.metricIndicatorText.fontSize + 2)};

                // Convert center 
                const center = vec2.add(anchor, canvasOffset);

                // Convert center 
                this.render_MetricIndicatorText(ctx, center, indicatorText, lineColor);

            }

        }


    }

    // Label 2D Annotation
    render_Label2D = (ctx, labelData, canvasCursor, indicatorText, hovered) => {

        // Don't render labels here in DicomViewer!
        if (!this.props.renderLabels)
            return;

        // Initialize Render Params

        let textParams = textSchemes.labelText;

        // Font parameters
        const fontSize = this.pixelsToThickness(textParams.fontSize);
        const fontFamily = textParams.fontFamily;
        const fontStyle = textParams.fontStyle;
        const font = fontStyle + " " + fontSize + "px " + fontFamily;

        // Box parameters
        const backgroundMargin = this.pixelsToThickness(textParams.backgroundMargin);
        const maxWidth = this.pixelsToThickness(textParams.maxWidth);
        const lineHeight = this.pixelsToThickness(textParams.lineHeight);

        // Color parameters
        const backgroundColor = colorSchemes.measureTextBacking;
        const fontColor = colorSchemes.angleTextColor;

        // Label specific data
        let anchor = this.workspaceToCanvas(labelData.points[0]);


        // Set context font
        ctx.font = font;

        // Split the lines based on maxWidth
        let lines = canvasUtils.getWrappedLines(ctx, labelData.text, maxWidth);

        // Compute dimensions of box needed. 
        let boxWidth = labelData.width, boxHeight = labelData.height;


        // Render background
        let TL = vec2.add(anchor, {x: 0, y: -boxHeight});
        let BR = vec2.add(TL, {x: boxWidth, y: boxHeight});
        
        // Hovered Border? 
        if (hovered)
            canvasUtils.drawRectangleFilled(ctx, TL, BR, backgroundColor, "rgba(255,255,255,0.8)", this.pixelsToThickness(1));
        
        // Default: No border    
        else
            canvasUtils.drawRectangleFilled(ctx, TL, BR, backgroundColor, "rgba(0,0,0,0)", 0);


        // Render Text

        // Set text context
        ctx.textAlign = 'start';
        ctx.textBaseline = 'bottom';
        ctx.fillStyle = fontColor;

        // Starting at the bottom line...
        for (let l=0; l<lines.length; l++) {
            
            let idxFromBottom = lines.length - l - 1;

            // Compute x and y
            let x = anchor.x + backgroundMargin;
            let y = anchor.y - backgroundMargin;
            y -= idxFromBottom * lineHeight;

            // Render text
            ctx.fillText(lines[l], x, y);

        }

        // Render indicator text
        if (indicatorText) {

            // Render below the text box
            const anchor = labelData.points[0]
            const canvasOffset = {x: 0, y: this.pixelsToThickness(textSchemes.metricIndicatorText.fontSize + 2)};

            // Convert center 
            const center = vec2.add(this.workspaceToCanvas(anchor), canvasOffset);

            this.render_MetricIndicatorText(ctx, center, indicatorText);

        }
        
    }

    // Label 3D Annotation 
    render_Label3D = (ctx, labelData) => {

        // Render text with its center at this location. 

        // Get font data, etc. 
        const textParams = textSchemes.label3DText;

        // Set font
        const fontSize = this.pixelsToThickness(textParams.fontSize);
        const fontFamily = textParams.fontFamily;
        const fontStyle = textParams.fontStyle;
        const font = fontStyle + " " + fontSize + "px " + fontFamily;
        const fontColor = colorSchemes.label3DTextColor;
        ctx.font = font;

        // Convert center 
        const center = this.workspaceToCanvas(labelData.points[0]);

        // Render the text centered
        canvasUtils.drawText(ctx, labelData.text, center, fontSize, fontColor, fontFamily, fontStyle);
        


    }


    // Circle 2D Annotation
    render_Circle2D = (ctx, circleData, canvasCursor, indicatorText, hoveredType) => {

        // Radius pending?
        if (circleData.points.length === 1 && circleData.radius === undefined && canvasCursor !== null) {
            // Circle with center at p1, radius to canvasCursor
            const center = this.workspaceToCanvas(circleData.points[0]);
            const radius = vec2.distance(center, canvasCursor);

            canvasUtils.drawCircleOutline(ctx, center, radius, colorSchemes.circle2DPending, this.pixelsToThickness(sizeSchemes.circleRadiusThickness));
        }

        // Radius defined
        else if (circleData.points.length === 1 && circleData.radius !== undefined) {

            // Get color scheme 
            const radiusColor = hoveredType === 'Delete' ? colorSchemes.objectDeleteColor : hoveredType === 'Hovered' ? colorSchemes.objectHoveredColor : colorSchemes.circle2DSet;


            // Circle with center, and scaled radius
            const center = this.workspaceToCanvas(circleData.points[0]);
            const radius = this.workspaceDistanceToCanvas(circleData.radius);

            canvasUtils.drawCircleOutline(ctx, center, radius, radiusColor, this.pixelsToThickness(sizeSchemes.circleRadiusThickness));

            // Render indicator text
            if (indicatorText) {

                // Render below the lower point
                const anchor = circleData.points[0]
                const canvasOffset = {x: 0, y: radius + this.pixelsToThickness(textSchemes.metricIndicatorText.fontSize + 2)};

                // Convert center 
                const center = vec2.add(this.workspaceToCanvas(anchor), canvasOffset);

                this.render_MetricIndicatorText(ctx, center, indicatorText, radiusColor);

            }

        }

    }

    render_Rectangle2D = (ctx, rectangleData, canvasCursor, indicatorText, hoveredType) => {

        // Rectangle location pending?
        if (rectangleData.points.length === 1 && canvasCursor !== null) {
            // Arrow from prev to current 
            const anchor = this.workspaceToCanvas(rectangleData.points[0]);
            canvasUtils.drawRectangleOutline(ctx, anchor, canvasCursor, colorSchemes.rectangle2DPending, this.pixelsToThickness(sizeSchemes.rectangleThickness));
        }

        // Rectangle location set
        else if (rectangleData.points.length === 2) {

            // Get color 
            const boxColor = hoveredType === 'Delete' ? colorSchemes.objectDeleteColor : hoveredType === 'Hovered' ? colorSchemes.objectHoveredColor : colorSchemes.rectangle2DSet;
            

            // Line from p1 to p2
            const p1 = this.workspaceToCanvas(rectangleData.points[0]);
            const p2 = this.workspaceToCanvas(rectangleData.points[1]);
            canvasUtils.drawRectangleOutline(ctx, p1, p2, boxColor, this.pixelsToThickness(sizeSchemes.rectangleThickness));

            // Render indicator text
            if (indicatorText) {

                // Render above the upper left point
                const anchor = {x: rectangleData.points[0].x < rectangleData.points[1].x ? rectangleData.points[0].x : rectangleData.points[1].x, y: rectangleData.points[0].y < rectangleData.points[1].y ? rectangleData.points[0].y : rectangleData.points[1].y};
                const canvasOffset = {x: 0, y: this.pixelsToThickness(-textSchemes.metricIndicatorText.fontSize - 2)};

                // Convert center 
                const center = vec2.add(this.workspaceToCanvas(anchor), canvasOffset);
                this.render_MetricIndicatorText(ctx, center, indicatorText, boxColor, 'left');

            }

        }

    }

    render_Arrow = (ctx, arrowData, canvasCursor, indicatorText, hoveredType) => {

        const arrowHeadWidth = sizeSchemes.arrowHeadWidth;
        const arrowHeadHeight = sizeSchemes.arrowHeadHeight;
        const arrowWidth = sizeSchemes.arrowWidth;
        const outlineThickness = sizeSchemes.arrowOutlineThickness

        // Arrow location pending?
        if (arrowData.points.length === 1 && canvasCursor !== null) {
            // Arrow from prev to current 
            const anchor = this.workspaceToCanvas(arrowData.points[0]);
            canvasUtils.drawArrow(ctx, anchor, canvasCursor, this.pixelsToThickness(arrowWidth), colorSchemes.arrowPending, this.pixelsToThickness(outlineThickness), this.pixelsToThickness(arrowHeadHeight), this.pixelsToThickness(arrowHeadWidth));
        }

        // Arrow location set
        else if (arrowData.points.length === 2) {

            // Get color
            const arrowEdgeColor = hoveredType === 'Delete' ? colorSchemes.objectDeleteColor : hoveredType === 'Hovered' ? colorSchemes.objectHoveredColor : colorSchemes.arrowSet;
            const arrowFillColor = hoveredType === 'Delete' ? colorSchemes.objectDeleteColor : hoveredType === 'Hovered' ? colorSchemes.objectHoveredColor : 'white';

            // Line from p1 to p2
            const p1 = this.workspaceToCanvas(arrowData.points[0]);
            const p2 = this.workspaceToCanvas(arrowData.points[1]);
            canvasUtils.drawArrowFilled(ctx, p1, p2, this.pixelsToThickness(arrowWidth), arrowEdgeColor, this.pixelsToThickness(outlineThickness), this.pixelsToThickness(arrowHeadHeight), this.pixelsToThickness(arrowHeadWidth), arrowFillColor);

            // Render indicator text
            if (indicatorText) {

                // Render at the tail
                // Convert center 
                const center = this.workspaceToCanvas(arrowData.points[0]);
                this.render_MetricIndicatorText(ctx, center, indicatorText, arrowFillColor);

            }

        }



    }

    // Render hovered point circle
    render_HoverPoint = (ctx, center) => {

        canvasUtils.drawCircleOutline(ctx, center, this.pixelsToThickness(sizeSchemes.activePointRadius), colorSchemes.hoveredPoint, this.pixelsToThickness(sizeSchemes.activePointThickness));

    }

    // Render hovered radius
    render_HoverRadius = (ctx, center, radius) => {

        canvasUtils.drawCircleOutline(ctx, center, radius, colorSchemes.hoveredRadius, this.pixelsToThickness(sizeSchemes.activeRadiusThickness));

    }   

    // Render activated point circle
    render_ActivePoint = (ctx, center) => {
        
        canvasUtils.drawCircleOutline(ctx, center, this.pixelsToThickness(sizeSchemes.activePointRadius), colorSchemes.activatedPoint, this.pixelsToThickness(sizeSchemes.activePointThickness));

    }

    // Render activated radius
    render_ActiveRadius = (ctx, center, radius) => {

        canvasUtils.drawCircleOutline(ctx, center, radius, colorSchemes.activatedRadius, this.pixelsToThickness(sizeSchemes.activeRadiusThickness));

    }   

    // Render metric indicator text at a given location
    render_MetricIndicatorText = async (ctx, center, text, manualColor = null, textAlign = 'center') => {

        // Get font data, etc. 
        const textParams = textSchemes.metricIndicatorText;

        // Set font
        const fontSize = this.pixelsToThickness(textParams.fontSize);
        const fontFamily = textParams.fontFamily;
        const fontStyle = textParams.fontStyle;
        const font = fontStyle + " " + fontSize + "px " + fontFamily;
        const fontColor = manualColor ? manualColor : colorSchemes.label3DTextColor;
        ctx.font = font;

        // Render the text centered
        canvasUtils.drawText(ctx, text, center, fontSize, fontColor, fontFamily, fontStyle, textAlign);

    }


    // ----- DRAWING HELPER FUNCTIONS -----

    clearCanvas = () => {
        let canvas = this.refs.mainCanvas;
        let ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    // Scale pixel count to canvas pixel count
    pixelsToThickness = (pixels) => {
        const canvasWidth = this.state.canvasDimensions[0];
        const divWidth = this.state.viewerRectangle.width;
        return (pixels * (canvasWidth / divWidth));
    }


    // ----- COORDINATE TRANSFORMS -----

    // Workspace to normalized canvas coords
    workspaceToCanvas = (location, inputCanvasRect = undefined) => {

        // Conver the point to viewer space 
        let viewerPoint = this.workspaceToViewer(location);

        // Convert the point to canvas space
        let canvasPoint = this.viewerToCanvas(viewerPoint, inputCanvasRect);

        return canvasPoint;

    }

    // Distance transform from workspace to canvas
    workspaceDistanceToCanvas = (distance) => {

        // Distance scaled to workspace
        const distanceViewer = distance * this.props.workspace.width;

        // Convert to canvas
        let canvasRef = this.refs.mainCanvas;
        let canvasRect = this.state.viewerRectangle;
        const distanceCanvas = distanceViewer * (canvasRef.width / canvasRect.width);

        return distanceCanvas;

    }

    workspaceToViewer = (location) => {

        const x = (location.x * this.props.workspace.width) + this.props.workspace.x;
        const y = (location.y * (this.props.workspace.width / this.props.workspace.aspectRatio)) + this.props.workspace.y;

        return { x: x, y: y };

    }

    viewerToWorkspace = (location) => {
        const x = (location.x - this.props.workspace.x) / this.props.workspace.width;
        const y = (location.y - this.props.workspace.y) / (this.props.workspace.width / this.props.workspace.aspectRatio);
        return { x: x, y: y };
    }

    // Viewer pixel coords to normalized canvas coords
    viewerToCanvas = (location, inputCanvasRect = undefined) => {

        let canvasRect = this.state.viewerRectangle;

        const x = (location.x) / (canvasRect.width), y = (location.y) / (canvasRect.height);

        const canvasPoint = { x: x * this.state.canvasDimensions[0], y: y * this.state.canvasDimensions[1] };

        return canvasPoint;

    }

    pageToCanvas = (location, inputCanvasRect = undefined) => {

        let canvasRect = this.state.viewerRectangle;

        let x = (location.x - canvasRect.left) / (canvasRect.right - canvasRect.left), y = (location.y - canvasRect.top) / (canvasRect.bottom - canvasRect.top);

        return { x: x, y: y };
    }


    render = () => {

        return (
            <div className="canvasContainer" ref="canvasContainer" style={{
                pointerEvents: "none",
                position: 'absolute',
                left: this.props.left,
                right: this.props.right,
                top: this.props.top,
                bottom: this.props.bottom,
                }}
            >
                <canvas ref="mainCanvas"
                    width={this.state.canvasDimensions[0]} height={this.state.canvasDimensions[1]}
                    style={{
                        width: "100%", height: "100%", zIndex: 5,
                        margin: '0', padding: '0'
                    }}
                >
                </canvas>
            </div>
        )
    }

}

Canvas.defaultProps = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    renderData: {},
    canvasDimensions: [1200, 1200]
}


export default Canvas;