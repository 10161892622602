// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

// Styling
import '../styles/header.css'

class HeaderButton extends Component {


    state = {

        // HeaderButton state

    }

    componentDidMount = async () => {

        // Componnent did mount function

    }
    



    render = () => {

        return (

            <div className={"headerButtonContainer" + (this.props.active ? " headerButtonContainerActive" : "")}>
                <img
                    className={"headerButton" + (this.props.active ? " headerButtonActive" : "")} 
                    title={this.props.toolTip}
                    src={this.props.icon}
                    alt = {this.props.toolTip}
                    onClick={()=> this.props.onButtonClick()}
                    onDoubleClick={this.props.onButtonDoubleClick}
                />
            </div>
            
        )
    }

}

export default HeaderButton;