// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
// import { Parser as HtmlParser } from 'html-to-react';
import date from "date-and-time";

// Styles
import "../../../styles/ReportGenerator/reportPreview.css";

// Templates
// import rwrTemplate from '../../../constants/rwrTemplate';


// Set up template / parsing
// const parser = new HtmlParser();
// const testRWR = rwrTemplate.template;


const noInjuries = "There is no sign of disc herniation, canal stenosis, or neural foraminal stenosis."

class ReportPreview extends Component {


    renderHeader = () => {
        
        const p = this.props.project;

        let studyDate, birthDate, age;

        if (p) {

            const dob = new Date(Date.parse(p.patient.dateOfBirth.split('T')[0] + "T00:00"));

            // today = date.format(new Date(), 'MMMM D, YYYY');
            studyDate = date.format(new Date(Date.parse(p.studyDate.split('T')[0] + "T00:00")), 'MMMM D, YYYY');
            birthDate = date.format(dob, 'MMMM D, YYYY');
            age = new Date().getFullYear() - dob.getFullYear();

        }

        return (<div className="rwrHeader">

            <div className="rwrHeaderLetterhead">
                3033 N. 44th Street, Suite 375<br />
                info@multusmedical.com<br />
                www.multusmedical.com
            </div>
            <div className="rwrHeaderLineLeft"></div>
            <div className="rwrHeaderLineRight"></div>

            {p && <div className="patientMeta">

                <div className="patientMetaLeft">

                    <div className="patientMetaField">
                        <div className="patientMetaLabel">Patient:</div>
                        <div className="patientMetaValue">{p.patient.name}</div>
                    </div>

                    <div className="patientMetaField">
                        <div className="patientMetaLabel">Date of Birth:</div>
                        <div className="patientMetaValue">{birthDate}</div>
                    </div>

                </div>
                
                <div className="patientMetaRight">

                    <div className="patientMetaField">
                        <div className="patientMetaLabel">Age / Sex:</div>
                        <div className="patientMetaValue">{age} / {p.patient.sex}</div>
                    </div>

                    <div className="patientMetaField">
                        <div className="patientMetaLabel">Date of Exam:</div>
                        <div className="patientMetaValue">{studyDate}</div>
                    </div>

                </div>

                <div className="patientMetaField">
                    <div className="patientMetaLabel patientMetaLabelLong">Referring Physician:</div>
                    <div className="patientMetaValue">{p.attendingPhysician.name}</div>
                </div>

                <div className="patientMetaField">
                    <div className="patientMetaLabel patientMetaLabelLong">Exam Modality:</div>
                    <div className="patientMetaValue">MR</div>
                </div>

            </div>}

        </div>);

    }

    renderFooter = (pageIdx, pages) => {

        return (<div className="rwrFooter">
            {/* Page {pageIdx} of {pages} */}
        </div>);

    }

    generateRWRPreview = () => {

        // Compile content and estimate height to calculate number of pages
        // TODO - for now, just put everything onto one page... (hack)

        const p = this.props.project;

        if (!p)
            return '';

        if (!(p.studyType in this.props.studyTypes))
            return '';

        // Get possible injury locations
        let injLocations = this.props.studyTypes[p.studyType].anatomy.map(anatomyObj => anatomyObj.abbreviation);
        injLocations = injLocations.filter(name => name!== p.studyType)

        let inj = [];

        try {
            inj = p.ai.injury.injuries;
        } catch (e) {
            // Skip
        }

        if (!inj)
            return '';

        // Impression
        let impression = [];

        // Global summary
        let globalSummary = '';

        // Find global group
        const locGlobal = inj.filter(i => i.location === p.studyType);

        if (locGlobal.length > 0) {

            const lordosis = locGlobal[0].objects.filter(o => ["Hyperlordosis", "Hypolordosis"].includes(o.type));

            if (lordosis.length > 0) {
                globalSummary += lordosis[0].summary + ". ";
                impression.push("The lumbar spine shows signs of " + lordosis[0].type.toLowerCase() + ".");
            }

        }

        if (globalSummary === "")
            globalSummary = "Lumbar Lordosis is maintained";


        return (<div className="rwrPage rwrPageFirst rwrPageLast">
            
            {this.renderHeader()}

            {inj.length > 0 && <div className="rwrContent">

                <div className="reportMetaField">
                    <div className="reportMetaLabel">PROCEDURE:</div>
                    <div className="reportMetaValue">MRI LUMBAR SPINE WITHOUT CONTRAST</div>
                </div>

                <div className="reportMetaField">
                    <div className="reportMetaLabel">COMPARISON:</div>
                    <div className="reportMetaValue">None</div>
                </div>

                <div className="reportMetaField">
                    <div className="reportMetaLabel">TECHNIQUE:</div>
                    <div className="reportMetaValue">Multisequence T1 and T2 weighted images were obtained.</div>
                </div>

                <div className="reportSection">
                    <h1>FINDINGS:</h1>

                    {injLocations.map((loc,idx) => {
                    
                        const discName = 'Disc.' + loc;
                        let locInjuries = inj.filter((i) => (i.location === discName));

                        const locSummary = locInjuries.length > 0 ? locInjuries[0].objects.map(o => o.summary).join('. ') : '';

                        if (locSummary.length > 0)
                            impression.push('At ' + loc + ', ' + locSummary[0].toLowerCase() + locSummary.substring(1));

                        // join summaries in divs with click function to toggle injury drop
                        let locSummaryList = locInjuries.length > 0 ? locInjuries[0].objects.map( (obj,idx) => 
                            <span className='reportLocSummary_single' key={idx} onClick={() => this.props.openSingleInjury(obj.location, obj.type, obj)}>
                                {obj.summary + '. '}
                            </span>
                        ) : [];
                        
                        return (<div className="reportLoc" key={idx}>                        
                            <div className="reportLocLabel" onClick={() => this.props.openAllInjuries(discName)}><span className="reportLocLabelText">{loc}</span>:</div>
                            <div className="reportLocSummary" >{locSummaryList.length > 0 && locSummary.length > 0 ? locSummaryList : noInjuries}</div>
                        </div>);
                    })}

                    <div className="reportGlobal">{globalSummary}</div>

                </div>

                <div className="reportSection">
                    <h1>IMPRESSION:</h1>

                    <div className="reportGlobal">
                        {impression.map((i,idx) => <div className="reportLine" key={idx}>{i}</div>)}
                    </div>

                    {/* Images */}
                </div>

                <div className="reportAttribute">
                    Report generated by: Multus Medical, LLC on {date.format(new Date(), 'MMMM D, YYYY')}.<br />

                    {/* Signature */}
                    {p.ai.injury.signed && p.ai.injury.signed.tag ? 
                        // Approved / signed
                        <div>Electronically signed by: {p.ai.injury.signed.tag}.<br /> </div>
                        :   
                        // Else
                        <div className="signReport" onClick={this.props.approveReport}>
                            Approve & Sign
                        </div>
                    }
                    

                </div>

            </div>}

            {this.renderFooter()}

        </div>);

    }

    render = () => {

        return (<div>
            {this.generateRWRPreview()}
        </div>)
    }


}

export default ReportPreview;