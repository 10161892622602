// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Header from '../../Header';
import HeaderButton from '../../HeaderButton'

import '../../../styles/ReportGenerator/reportGenerator.css'

class DocumentHeader extends Component {

    state = {

        // Document Header state

    }

    componentDidMount = async () => {

    }



    render = () => {

        // Cloud synced text
        let syncedText = "Editing...";
        let synced = false;
        
        if (this.props.injuryCloudSynced === 'true' && this.props.annotationCloudSynced === 'true') {
            syncedText = "All Changes Saved";
            synced = true;
        }
        else if (this.props.injuryCloudSynced === 'false' || this.props.annotationCloudSynced === 'false')
            syncedText = "Editing...";
        else if (this.props.injuryCloudSynced === 'syncing' || this.props.annotationCloudSynced === 'syncing')
            syncedText = "Saving Changes..."
        else if (this.props.injuryCloudSynced === 'error' || this.props.annotationCloudSynced === 'error')
            syncedText = 'Error Saving';

        return (

            <Header
                project={this.props.project}
                shiftProjectIndex={this.props.shiftProjectIndex}
            >

                <div className={'cloudSyncIndicator' + (synced ? ' cloudSynced' : '')} style={{float: 'left'}}>
                    {syncedText}
                </div>

                <div style={{ float: 'right' }}>
                    
                    <HeaderButton
                        id={"btnCreateInjuryType"}
                        ref={"btnCreateInjuryType"}
                        name={"btnCreateInjuryType"}
                        toolTip={"Create new custom injury"}
                        icon={"/resources/icons/CustomInjury.png"}
                        onButtonClick={this.props.createCustomInjury}
                        active={this.state.viewerSelectOpened}
                    />   

                    {/* New Viewer Page button*/}
                    <HeaderButton
                        id={"btnNewViewerWindow"}
                        ref={"btnNewViewerWindow"}
                        name={"btnNewViewerWindow"}
                        toolTip={"New Dicom Viewer Window"}
                        icon={"/resources/icons/NewWindow.png"} 
                        onButtonClick = { () => {this.props.newDicomViewerWindow()}}
                        active={false}
                    />
                </div>

                


            </Header>
        )
    }

}

export default DocumentHeader;