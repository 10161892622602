// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
// import { toast } from 'react-toastify';
import { configure, HotKeys } from 'react-hotkeys';

import '../../../styles/ReportGenerator/injuryTypeGenerator.css'

class InjuryTypeGeneratorOptionChooser extends Component {

    state = {
        elements: [''],

        windowDrag: {
            draggable: true,
            dragging: false,
            windowPosition: {
                x: 'calc(50% - 250px/2)',
                y: 150,
            },
            maxLeft: 1810,
            maxTop: 900,
            width: 175,
        },
    }

    componentDidMount = () => {
        configure({
            ignoreTags: ['select'],
        });
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.options.length !== this.props.options.length)
            this.setState({
                elements: [...this.props.options]
            })

        else if (!prevProps.isVisible && this.props.isVisible) {
            await this.setState({
                elements: ['']
            });
            this.focusTextBox();
        }
    }


    // #region Hotkeys

    hotkeyMap = {
        // actions
        newDicomViewerWindow: 'Escape',
    };

    hotkeyHandlers = {
        // actions
        newDicomViewerWindow: e => this.triggerHotkey(e, () => this.props.cancel()),
    };

    triggerHotkey = (event, callback) => {
        event.stopPropagation();
        event.preventDefault();

        if (window.event)
            window.event.cancelBubble = true;

        callback();
    }

    // #endregion Hotkeys

    // focus text box: -1 gives last element
    focusTextBox = (idx = -1) => {
        const idxStr = idx >= 0 && idx < this.state.elements.length ? idx : this.state.elements.length - 1;
        const key = 'optionInput_' + idxStr;
        const lastTextbox = document.getElementById(key);
        if (lastTextbox)
            lastTextbox.focus();
    }

    setElement = (value, idx) => {
        let elems = this.state.elements;
        elems[idx] = value;
        this.setState({ elements: elems });
    }

    addElement = async () => {
        await this.setState({ elements: [ ...this.state.elements, '' ] });
        this.focusTextBox();
    }

    removeElement = idx => {
        let elems = this.state.elements;
        elems.splice(idx, 1);
        this.setState({ elements: elems });
    }

    save = () => {

        // filter out blank elements
        let list = this.state.elements.filter(x => x !== '').map(y => y.trim());

        if (list.length === 0) {
            this.props.cancel();
            return;
        } 

        // find duplicates
        for (let i=0; i<list.length; i++) {
            if (list.filter(e => e.toLowerCase() === list[i].toLowerCase()).length !== 1) {
                let err = 'Duplicate option. The option "' + list[i] + '" appears multiple times. Options are not case sensitive.';
                this.props.showToastError(err);
                return;
            }
        }

        this.props.finish(list);
    }


    // #region drag
    onContainerMouseUp = (e) => {

        if (e.button !== 0 || !this.state.windowDrag.dragging) return;

        this.cancelDragging();

        e.stopPropagation();
        e.preventDefault();
    }

    onContainerMouseDown = (e) => {
        if (e.button !== 0 || !e.shiftKey) return;

        e.stopPropagation();
        e.preventDefault();

        let currWindowDrag = this.state.windowDrag;
        currWindowDrag.dragging = true;


        this.setState({
            windowDrag: currWindowDrag,
        });
    }

    cancelDragging = () => {

        if (!this.state.windowDrag.dragging) return;

        let currWindowDrag = this.state.windowDrag;
        currWindowDrag.dragging = false;
        currWindowDrag.deltaX = 0;
        currWindowDrag.deltaY = 0;

        this.setState({
            windowDrag: currWindowDrag,
        });

    }

    onContainerMouseMove = (e) => {

        if (!this.state.windowDrag.dragging) return;

        const deltaX = this.state.windowDrag.deltaX + e.movementX
        const deltaY = this.state.windowDrag.deltaY + e.movementX

        if (Math.sqrt(deltaX ** 2 + deltaY ** 2) < 0)
            return;

        let newX;
        if (!isNaN(this.state.windowDrag.windowPosition.x)) {
            newX = this.state.windowDrag.windowPosition.x + e.movementX;
        }
        else {
            newX = e.pageX + e.movementX - this.state.windowDrag.width / 2;
        }
        let newY = this.state.windowDrag.windowPosition.y + e.movementY;

        if (newX < 0) newX = 0;
        if (newY < 0) newY = 0;

        if (newX > this.state.windowDrag.maxLeft) newX = this.state.windowDrag.maxLeft;
        if (newY > this.state.windowDrag.maxTop) newY = this.state.windowDrag.maxTop;

        let currWindowDrag = this.state.windowDrag;
        currWindowDrag.deltaX = deltaX;
        currWindowDrag.deltaY = deltaY;
        currWindowDrag.windowPosition.x = newX;
        currWindowDrag.windowPosition.y = newY;

        this.setState({
            windowDrag: currWindowDrag,
        });
        e.stopPropagation()
        e.preventDefault()
    }
    // #endregion drag

    render = () => {
        return (
            <HotKeys keyMap={this.hotkeyMap} handlers={this.hotkeyHandlers}>
                {/* <div> */}
                <div className='injuryTypeGeneratorChooser_background'
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    style={{
                        display: this.props.isVisible ? 'block' : 'none',
                    }}>
                    

                    <div 
                        className='injuryTypeGeneratorOptionChooser '
                        style={{
                            display: this.props.isVisible ? 'block' : 'none',
                            left: this.state.windowDrag.windowPosition.x,
                            top: this.state.windowDrag.windowPosition.y,
                            width: this.state.windowDrag.width,
                        }}
                        onMouseUp={this.onContainerMouseUp}
                        onMouseDown={this.onContainerMouseDown}
                        onMouseMove={this.onContainerMouseMove}
                        onMouseLeave={() => this.cancelDragging()}
                    >

                        <div className='optionsContainer tertiaryContainer scrollable'>

                            {/* Options Header */}
                            <div className='propertyTypeHeader tertiaryHeader'>
                                Property Options
                            </div>
                        
                            
                            {/* Render options */}
                            {this.state.elements.map((elem, idx) =>
                            
                                <div
                                    className='injuryTypeGeneratorOptionChooserElement'
                                    key={'injuryTypeGeneratorOptionChooser_' + idx} 
                                >
                                    {/* option input textbox */}
                                    <input 
                                        className='generatorInput' 
                                        id={'optionInput_' + idx}
                                        ref={'optionInput_' + idx}
                                        type='text'
                                        value={elem} 
                                        onChange={(evt) => this.setElement(evt.target.value, idx)}
                                        onKeyPress={evt => { if (evt.key === 'Enter') this.addElement() }}
                                        // onClick={evt => {this.refs['optionInput_' + idx].focus()}}
                                    />

                                    {/* Delete Property Button */}
                                    <img
                                        className = 'injuryGeneratorButton optionDeleteButton' 
                                        src = '/resources/icons/close.png'
                                        alt = 'Delete'
                                        title = 'Delete option'
                                        onClick = {() => this.removeElement(idx)}    
                                    />

                                </div>
                            )}   

                            {/* New Option Button */}
                            {/* <div className='newOptionButtonContainer'>
                                <img
                                    className = 'injuryGeneratorButton newPropButton' 
                                    src = '/resources/icons/add.png'
                                    alt = 'add'
                                    title = 'Add new option'
                                    onClick = {this.addElement}    
                                />
                            </div> */}
                            
                            <div className='newOptionButtonContainer'>
                                <img
                                    className='injuryGeneratorButton newPropButton'
                                    src='/resources/icons/Cursor.png'
                                    alt='Create new option'
                                    title='Create new option'
                                    onClick={this.addElement}    
                                />

                                {/* <div className='newPropButtonText'>
                                    Create new option
                                </div> */}
                            </div>

                            <div className='optionsFooterContainer'>
                                {/* Clear Options Button */}
                                <img
                                    className='injuryGeneratorButton btnCancelOptions'
                                    src='/resources/icons/close.png'
                                    alt='cancel'
                                    title='Cancel'
                                    onClick={this.props.cancel}
                                />

                                {/* Save Options Button */}
                                <img
                                    className='injuryGeneratorButton btnSaveOptions'
                                    src='/resources/icons/confirm.png'
                                    alt='cancel'
                                    title='Save options'
                                    onClick={this.save}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </HotKeys>
        );
    }
}

export default InjuryTypeGeneratorOptionChooser;