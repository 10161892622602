// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory


// Canvas Color Schemes

export const colorSchemes = {

    // Object Hover Colors
    objectDeleteColor : 'rgba(255, 70, 70, 0.7)',
    objectHoveredColor: 'rgba(73, 229, 25, 0.74)',

    // Ruler
    rulerPending : "rgba(0, 255, 255, 0.8)",
    rulerSet : "rgba(0, 255, 255, 0.4)",
    rulerTextColor: "white",
    rulerObjectHovered: 'rgba(100, 100, 255, 0.5)',

    // Angle
    anglePending: "rgba(0, 255, 255, 0.8)",
    angleSet : "rgba(0, 255, 255, 0.4)",
    angleTextColor: "white",
    angleAltitude: "rgba(255, 255, 255, 0.5)",
    angleObjectHovered: 'rgba(100, 100, 255, 0.5)',

    // Measurement Box
    measureTextBox: "rgba(200, 200, 200, 0.6)",
    measureTextBacking: "rgba(0, 0, 0, 0.6)",

    // Circle 2D
    circle2DPending: "rgba(255, 230, 0, 0.4)",
    circle2DSet: "rgba(255, 230, 0, 0.8)",
    circle2DObjectDelete: "rgba(255, 70, 70, 0.7)",
    circle2DObjectHovered: 'rgba(100, 100, 255, 0.5)',

    // Rectangle
    rectangle2DPending: "rgba(255, 230, 0, 0.4)",
    rectangle2DSet: "rgba(255, 230, 0, 0.8)",
    rectangle2DObjectDelete: "rgba(255, 70, 70, 0.7)",
    rectangle2DObjectHovered: 'rgba(100, 100, 255, 0.5)',

    // Arrow
    arrowPending: "rgba(255, 230, 0, 0.4)",
    arrowSet: "rgba(255, 230, 0, 0.8)",
    arrowObjectDelete: "rgba(255, 70, 70, 0.7)",
    arrowObjectHovered: 'rgba(100, 100, 255, 0.5)',

    // Crosshairs
    crosshairs: "rgba(210, 210, 210, 0.5)",
    crosshairsCenter: "rgb(210, 210, 210)",

    // Global Cursor
    globalCursor: "rgb(0, 255, 160)",

    // Slice Intersection
    sliceIntersectionColor: "rgba(3, 252, 252, 0.5)",

    // Annotation Text
    annotationBoxColor: "rgba(255, 255, 255, 0.7)",
    annotationTextColor: "white",

    // Label3D Text
    label3DTextColor: 'rgb(0,220,220)',

    // Points
    hoveredPoint: "rgba(255, 155, 100, 0.7)",
    hoveredRadius: "rgba(255, 0, 0, 0.5)",
    activatedPoint: "rgba(255, 100, 100, 0.7)",
    activatedRadius: "rgba(255, 0, 0, 0.5)",

}


export const sizeSchemes = {

    // Note: Sizes are specified in pixels. 

    // Slice intersect thickness
    sliceIntersection: 3,

    // Cursor Crosshairs
    crossHairsInnerThickness: 2,
    crossHairsOuterThickness: 1,
    crossHairsInnerLength: 14,

    // Global cursor
    globalCursorThickness: 2,
    globalCursorLength: 10,


    // Rulser
    rulerThickness: 2,

    // Angle
    angleThickness: 2,

    // Circle
    circleRadiusThickness: 3,

    // Rectangle
    rectangleThickness: 3,

    // Arrow
    arrowHeadWidth: 3,
    arrowHeadHeight: 7,
    arrowWidth: 1,
    arrowOutlineThickness: 1,

    // Active Points
    activePointRadius: 7,
    activePointThickness: 6,

    // Active Radius
    activeRadiusThickness: 5,

}


// Text Configurations

export const textSchemes = {

    // Label text
    labelText: {
        fontSize: 14, // px
        fontFamily: 'Barlow',
        fontStyle: '', 
        lineHeight: 19, // px
        maxWidth: 150, // px

        backgroundMargin: 5, // px
    },

    // Label 3D text
    label3DText: {
        fontSize: 16, // px
        fontFamily: 'Barlow', 
        fontStyle: '',
        backgroundMargin: 5, // px

    },

    // Metric Indicator
    metricIndicatorText: {
        fontSize: 12, // px
        fontFamily: 'Barlow',
        fontStyle: '',
        backgroundMargin: 5, // px

    },

    // Measurement text
    measureText: {

    }

}