// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { configure, HotKeys } from 'react-hotkeys';

import InjuryTypeGeneratorProperty from './injuryTypeGeneratorProperty';
import InjuryTypeGeneratorOptionChooser from './injuryTypeGeneratorOptionChooser'

import { getInjurySummary } from '../../../utilities/injuryTemplateFill'

import '../../../styles/ReportGenerator/injuryTypeGenerator.css'

class InjuryTypeGenerator extends Component {
    //#region State

    state = {
        
        injuryName: '',

        global: false,

        // injuryProperty format
        // {
        //     name: 'propName',
        //     dataType: {
        //         name: 'option',
        //         options: [
        //             'opt1',
        //             'opt2'
        //         ],
        //     },
        //     defaultValue: 'opt1'
        // },
        injuryProperties: [],

        // injuryMetric format
        // {
        //     name: 'metricName',
        //     dataType: {
        //         name: 'angle'
        //     },
        // },
        injuryMetrics: [],

        // for template rendering
        txtRwrTemplate: '',
        txtRwrRendered: '',
        rwrRenderedSuccess: false,

        // for injuryTypeGeneratorOptionChooser
        propertyOptionChooser: {
            isVisible: false,
            options: [],
            propIdx: -1,
        },

        windowDrag: {
            draggable: true,
            dragging: false,
            position: {
                left: 'calc(50% - 650px/2)',
                top: 34,
            },
            maxLeft: 2000,
            maxTop: 1000,
            width: 650,
        },

        confirmDialogOpened: false,

    }

    //#endregion State
    
    //#region built in functions

    componentDidMount = async () => {
        // load injury if given from reportGeneratorPage
        if (this.props.injuryToLoad) {
            this.loadInjury(this.props.injuryToLoad);
        }

        configure({
            ignoreTags: ['select'],
        });
    }

    loadInjury = async injury => {
        try {
            await this.setState({
                injuryName: injury.name,

                injuryProperties: injury.properties.map(prop => {
                    let options = prop.options && prop.options.length > 0 ? prop.options.map(opt => opt.toString()) : [];

                    if (prop.type === 'boolean') {
                        options = [ 'true', 'false' ]
                    }

                    return {
                        name: prop.name,
                        dataType: {
                            name: prop.type,
                            options: options
                        },
                        defaultValue: prop.defaultValue.toString()
                    }
                }),

                injuryMetrics: injury.metrics.map(prop => {
                    return {
                        name: prop.name,
                        dataType: {
                            name: prop.type,
                        },
                    }
                }),

                txtRwrTemplate: injury.template
            });

            await this.setRwrText(injury.template)
        }
        catch (err) {
            let msg = 'Error loading injury:' + err.message;
            throw new Error(msg);
        }
    }

    //#endregion built in functions


    // #region Hotkeys

    hotkeyMap = {
        // actions
        newDicomViewerWindow: 'Escape',
    };

    hotkeyHandlers = {
        // actions
        newDicomViewerWindow: e => this.triggerHotkey(e, () => this.props.closeInjuryGenerator()),
    };

    triggerHotkey = (event, callback) => {
        event.stopPropagation();
        event.preventDefault();

        if (window.event)
            window.event.cancelBubble = true;

        callback();
    }

    // #endregion Hotkeys


    // #region child state control functions

    // property functions

    // type is 'property' or 'metric'
    addBlankProperty = async (type, name='') => {
        let blankProp = { name: name };

        if (type === 'property') {
            blankProp.defaultValue = ''
            blankProp.dataType = {
                name: '',
                options: [],
            };

            let curProp = this.state.injuryProperties;
            curProp.push(blankProp);

            this.setState({
                injuryProperties: curProp
            });
        }

        if (type === 'metric') {
            blankProp.dataType = '';

            let curProp = this.state.injuryMetrics;
            curProp.push(blankProp);

            this.setState({
                injuryMetrics: curProp
            });
        }
    }

    removeProperty = async (type, idx) => {
        if (type === 'property') {
            let curState = this.state.injuryProperties;
            curState.splice(idx, 1);
            await this.setState({ injuryProperties: curState });
        }
        else if (type === 'metric') {
            let curState = this.state.injuryMetrics;
            curState.splice(idx, 1);
            await this.setState({ injuryMetrics: curState });
        }
        await this.setRwrText();
    }

    changePropertyName = async (propType, idx, newName) => {
        if (propType === 'property') {
            let curState = this.state.injuryProperties;
            curState[idx].name = newName;
            await this.setState({ injuryProperties: curState });
        }
        else if (propType === 'metric') {
            let curState = this.state.injuryMetrics;
            curState[idx].name = newName;
            await this.setState({ injuryMetrics: curState });
        }
        await this.setRwrText();
    }

    changePropertyDataType = async (propType, idx, newValue, options = []) => {
        if (propType === 'property') {
            let curProps = this.state.injuryProperties;
            curProps[idx].dataType = {
                name: newValue,
                options: options,
            }            
            await this.setState({ injuryProperties: curProps });
        }
        else if (propType === 'metric') {
            let curProps = this.state.injuryMetrics;
            curProps[idx].dataType = {
                name: newValue
            }
            await this.setState({ injuryMetrics: curProps });
        }
        await this.setRwrText();
    }

    changePropertyDefaultValue = async (propType, idx, defaultValue) => {
        //only properties have a default value
        if (propType === 'property') {
            let curProps = this.state.injuryProperties;
            curProps[idx].defaultValue = defaultValue;
            await this.setState({ injuryProperties: curProps });
        }
        await this.setRwrText();
    }


    startPropertyOptionChooser = async (idx, isNewOption) => {
        //leave old property in place until finish function
        this.setState({
            propertyOptionChooser: {
                isVisible: true,
                options: isNewOption ? [] : this.state.injuryProperties[idx].dataType.options,
                propIdx: idx,
            },
        });
    }

    cancelPropertyOptionChooser = () => {
        //reset propertyOptionChooser in state
        this.setState({
            propertyOptionChooser: {
                isVisible: false,
                options: [],
                propIdx: -1,
            },
        })
    }

    finishPropertyOptionChooser = async options => {
        let curProps = this.state.injuryProperties;

        //reset property if no options
        if (!options || options.length === 0) {
            curProps[this.state.propertyOptionChooser.propIdx].defaultValue = ''
            curProps[this.state.propertyOptionChooser.propIdx].dataType = {
                name: '',
                options: [],
            };
        }
        else {
            // set property with the chosen options
            curProps[this.state.propertyOptionChooser.propIdx].dataType = {
                name: 'option',
                options: options
            };

            // retain old default if it is still in options list
            curProps[this.state.propertyOptionChooser.propIdx].defaultValue = options.includes(curProps[this.state.propertyOptionChooser.propIdx].defaultValue) ?curProps[this.state.propertyOptionChooser.propIdx].defaultValue : options[0];
        }

        this.setState({
            propertyOptionChooser: {
                isVisible: false,
                options: [],
                propIdx: -1,
            },

            injuryProperties: curProps,
        });
    }

    //#endregion child state control functions
    
    //#region button functions

    // Clear btn: action functions
    clear = async () => {
        await this.setState({
            injuryName: '',
            injuryProperties: [],
            injuryMetrics: [],
            txtRwrTemplate: '',
            confirmDialogOpened: false,
        });
        this.setRwrText();
    }

    // Create injury btn: validate entries and save custom injury
    submit = async () => {
        
        try {
            // verify properties of custom injury
            // check for blank name
            if (this.state.injuryName.length === 0) 
                throw new Error('Injury name cannot be blank.');

            // check if name already exists
            // unless editing an injury. if so, this.props.injuryType will not be null
            if (!this.props.injuryToLoad && Object.keys(this.props.injuryTypes).filter(key => key.toLowerCase() === this.state.injuryName.toLowerCase()).length > 0)
                throw new Error('The injury type "' + this.state.injuryName + '" already exists.')
            
            // check for blank prop/metric keys
            if (this.state.injuryProperties.filter(x => x.name === '').length > 0 || this.state.injuryMetrics.filter(x => x.name === '').length > 0) 
                throw new Error('An injury cannot have any blank keys.');
            
            // check for blank prop/metric values
            if (this.state.injuryProperties.filter(x => !x.dataType || x.dataType.length === 0).length > 0 || this.state.injuryMetrics.filter(x => !x.dataType || x.dataType.length === 0).length > 0) 
                throw new Error('A type value must be selected F each property.');

            // check if default values are filled, make sure they're the right type
            for (let i=0; i<this.state.injuryProperties.length; i++) {
                let prop = this.state.injuryProperties[i];

                if (prop.dataType.name === 'number') {
                    if (prop.defaultValue.length > 0 && isNaN(prop.defaultValue)) 
                        throw new Error('Type mismatch.  The default value of "' + prop.defaultValue + '" for the property "' + prop.name + '" must be a number.');
                }
            }

            // check for duplicate property/metric names
            let allProps = [
                ...this.state.injuryProperties,
                ...this.state.injuryMetrics
            ]

            for (let i=0; i<allProps.length; i++) {
                if (allProps.filter(p => p.name.toLowerCase() === allProps[i].name.toLowerCase()).length !== 1) 
                    throw new Error('Duplicate key. The key "' + allProps[i].name + '" appears multiple times. Keys are not case sensitive.');
            }

            // assert template is valid.
            // this will throw an error if parsing does not succeed
            // getInjuryText(this.getDummyInjury(), this.state.txtRwrTemplate);

            getInjurySummary(this.getSaveSchema(), this.getDummyInjury());
        }
        catch (err) {
            this.showToastError(err.message);
            return;
        }        
        
        // if all props are ok, save injury and finish
        this.showToastAlert('Saving "' + this.state.injuryName + '" as a custom injury.');

        // get final injury schema and send to save function in App level
        this.saveGeneratedInjuryType(this.getSaveSchema(), this.props.injuryToLoad !== null);
    }

    saveGeneratedInjuryType = (injurySchema, isEditing = false) => {
        this.resetState();        
        this.props.saveGeneratedInjuryType(injurySchema, isEditing);
    }

    resetState = () => {
        this.setState({
            injuryName: 'Hyperlordosis',
            injuryProperties: [],
            injuryMetrics: [],
            txtRwrTemplate: '',

            propertyOptionChooser: {
                isVisible: false,
                options: [],
                propIdx: -1,
            },
        })
    }

    getSaveSchema = () => {
        // schema format (for example)
        // let injuryTypeSchema = {
        //     name: 'Hyperlordosis',
        //     properties: [
        //         {
        //             name: 'severity',
        //             type: 'option',
        //             defaultValue: 'moderate',
        //             options: ['mild', 'moderate', 'severe']
        //         }
        //     ],
        //     metrics: [
        //         {
        //             name: 'angle',
        //             type: 'angle',
        //         }
        //     ],
        //     template: 'This is a test template.'
        // }

        return {
            name: this.state.injuryName,
            global: this.state.global,
            properties: this.state.injuryProperties.map(prop => {
                // convert numbers to floats and booleans to bools
                let defaultValue = prop.defaultValue;
                let options = prop.dataType.options;

                if (prop.dataType.name === 'number') {
                    defaultValue = parseFloat(defaultValue);
                    options = options.map(opt => parseFloat(opt));
                }
                else if (prop.dataType.name === 'boolean') {
                    return {
                        name: prop.name,
                        type: prop.dataType.name,
                        defaultValue: defaultValue === 'true',
                    };
                }

                return {
                    name: prop.name,
                    type: prop.dataType.name,
                    defaultValue: defaultValue,
                    options: options,
                };
            }),
            metrics: this.state.injuryMetrics.map(prop => {
                return {
                    name: prop.name,
                    type: prop.dataType.name,
                };
            }),
            template: this.state.txtRwrTemplate,
        };
    }

    // process template text and modify rendered text accordingly
    setRwrText = async (value = undefined) => {
        
        let template = value !== undefined ? value : this.state.txtRwrTemplate;
        let success = false;
        let result = template;

        await this.setState({
            txtRwrTemplate: template,
        });

        try {
            // result = getInjuryText(this.getDummyInjury(), template);
            result = getInjurySummary(this.getSaveSchema(), this.getDummyInjury());
            success = true;
        }
        catch (err) {
            result = err.message;
            success = false;
        }

        await this.setState({
            txtRwrRendered: result ? result : '',
            rwrRenderedSuccess: success,
        });
    }

    //#endregion button functions

    //#region template text handling
    
    // function to test fake injury with 
    testGetInjuryText = async () => {

        let expectedResult = 'There is acute hyperlordosis in the lumbar section with an angle of 32.  It is moderate!!\n\nyes\nyes\n'

        await this.setState({
            injuryName: 'Hyperlordosis',

            injuryProperties: [{
                name: 'Acuity',
                dataType: {
                    name: 'boolean',
                    options: ['true', 'false']
                },
                defaultValue: 'true'
            }],

            injuryMetrics: [{
                name: 'Lordosis Angle',
                dataType: {
                    name: 'angle',
                },
            }],

            txtRwrTemplate: 'There is (({{Acuity}} == true ? acute : chronic )) hyperlordosis in the lumbar section with an angle of {{Lordosis Angle}}.  It is (( {{Lordosis Angle}} < 30 ? mild :  (( {{Lordosis Angle}} <= 40 ? moderate : severe )) ))!!\n\n((1<2&&2<3?yes:no))\n((1<2||4<3?yes:no))\n',
        })

        console.log('\n\nTesting RWR Text Generation...');

        let txtResult = getInjurySummary(this.getSaveSchema(), this.getDummyInjury());

        console.log('\n\nResult:', txtResult, '\n\nExpected result:', expectedResult);
        console.log('\n\nRWR Text Generation', txtResult === expectedResult ? 'passed!' : 'failed!', '\n\n\n');
    }

    // creates dummy injury from injuryProperties and injuryMetrics
    // pass output to getInjuryText() with this.state.txtRwrTemplate to verify template
    getDummyInjury = () => {

        let injuryObj = {};

        injuryObj['type'] = this.state.injuryName;
        injuryObj['location'] = 'Disc.L2-L3';
        injuryObj['summary'] = 'This is a fake summary for a fake injury object';
        injuryObj['rationale'] = {
            metrics: []
        };

        // get dummy values for injury properties
        for (let i = 0; i < this.state.injuryProperties.length; i++) {

            let prop = this.state.injuryProperties[i];
            let value;

            // populate dummy default values for properties
            if (!this.state.injuryProperties[i].dataType || !this.state.injuryProperties[i].dataType.name)
                continue;
            else if (this.state.injuryProperties[i].dataType.name === 'boolean')
                value = prop.defaultValue && prop.defaultValue.length > 0 ? prop.defaultValue : true;
            else if (this.state.injuryProperties[i].dataType.name === 'text')
                value = prop.defaultValue && prop.defaultValue.length > 0 ? prop.defaultValue : '<sample_text>';
            else if (this.state.injuryProperties[i].dataType.name === 'number')
                value = prop.defaultValue && prop.defaultValue.length > 0 ? prop.defaultValue : '<sample_number>';
            else if (this.state.injuryProperties[i].dataType.name === 'option')
                value = prop.defaultValue && prop.defaultValue.length > 0 ? prop.defaultValue : prop.options[0];

            injuryObj[this.state.injuryProperties[i].name] = this.state.injuryProperties[i].defaultValue && this.state.injuryProperties[i].defaultValue.length > 0 ? this.state.injuryProperties[i].defaultValue : value;
        }

        // get dummy values for injury metrics
        for (let i = 0; i < this.state.injuryMetrics.length; i++) {

            let value;

            // populate dummy default values for metrics
            if (!this.state.injuryMetrics[i].dataType || !this.state.injuryMetrics[i].dataType.name)
                continue;
            else if (this.state.injuryMetrics[i].dataType.name === 'angle')
                value = '<sample_angle>';
            else if (this.state.injuryMetrics[i].dataType.name === 'probability')
                value = '<sample_probability>';
            else if (this.state.injuryMetrics[i].dataType.name === 'length')
                value = '<sample_length>';
            else if (this.state.injuryMetrics[i].dataType.name === 'circle')
                value = '<sample_circle>';
            else if (this.state.injuryMetrics[i].dataType.name === 'rectangle')
                value = '<sample_rectangle>';
            else if (this.state.injuryMetrics[i].dataType.name === 'label')
                value = '<sample_label>'
            else
                throw new Error('Error building injury.  InjuryTypeGenerator does not recognize the data type: ' + this.state.injuryMetrics[i].dataType.name);

            injuryObj.rationale.metrics.push({
                name: this.state.injuryMetrics[i].name,
                value: value,
                dataType: this.state.injuryMetrics[i].dataType.name
            });
        }

        return injuryObj;
    }

    //#endregion template text handling

    //#region Helper function

    showToastAlert = msg => {
        toast(msg, {
            position: toast.POSITION.TOP_CENTER,
            className: 'toastInjuryTypeGenerator_alert',
            autoClose: 3000,
            pauseOnFocusLoss: true,
            toastId: 1,
        });
    };

    showToastError = msg => {
        toast.error(msg, {
            position: toast.POSITION.TOP_CENTER,
            className: 'toastInjuryTypeGenerator_error',
            autoClose: 5000,
            pauseOnFocusLoss: false,
            toastId: 2,
        });
    };

    //#endregion Helper function

    // #region drag
    onContainerMouseUp = (e) => {

        if (e.button !== 0 || !this.state.windowDrag.dragging) return;
        
        this.cancelDragging();

        e.stopPropagation();
        e.preventDefault();
    }

    onContainerMouseDown = (e) => { 
        if (e.button !== 0 || !e.shiftKey) return;

        e.stopPropagation();
        e.preventDefault();

        let currWindowDrag = this.state.windowDrag;
        currWindowDrag.dragging = true;


        this.setState({
            windowDrag: currWindowDrag,      
        });
    }

    cancelDragging = () => {

        if (!this.state.windowDrag.dragging) return;

        let currWindowDrag = this.state.windowDrag;
        currWindowDrag.dragging = false;
        currWindowDrag.deltaX = 0;
        currWindowDrag.deltaY = 0;

        this.setState({
            windowDrag: currWindowDrag,
        });

    }

    onContainerMouseMove = (e) => {

        if (!this.state.windowDrag.dragging) return;
        
        const deltaX = this.state.windowDrag.deltaX + e.movementX
        const deltaY = this.state.windowDrag.deltaY + e.movementX

        if (Math.sqrt(deltaX ** 2 + deltaY ** 2) < 0)
            return;

        let newX;
        if (!isNaN(this.state.windowDrag.position.left)) {
            newX = this.state.windowDrag.position.left + e.movementX;
        }
        else {
            newX = e.pageX + e.movementX - this.state.windowDrag.width / 2;
        }
        let newY = this.state.windowDrag.position.top + e.movementY;

        if (newX < 0) newX = 0;
        if (newY < 0) newY = 0;

        if (newX > this.state.windowDrag.maxLeft) newX = this.state.windowDrag.maxLeft;
        if (newY > this.state.windowDrag.maxTop) newY = this.state.windowDrag.maxTop;

        let currWindowDrag = this.state.windowDrag;
        currWindowDrag.deltaX = deltaX;
        currWindowDrag.deltaY = deltaY;
        currWindowDrag.position.left = newX;
        currWindowDrag.position.top = newY;

        this.setState({
            windowDrag: currWindowDrag,           
        });
        e.stopPropagation()
        e.preventDefault()
    }
    // #endregion drag

    //#region Render

    // render
    render = () => {
        return (
            <HotKeys keyMap={this.hotkeyMap} handlers={this.hotkeyHandlers}>
                {/* <div> */}
                <div className='injuryTypeGenerator_background' 
                    ref='injuryTypeGenerator_background'
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                        
                    <div
                        className='injuryTypeGeneratorContainer scrollable'
                        ref='injuryTypeGeneratorContainer'
                        style={{
                            visibility: this.props.isVisible ? 'visible' : 'hidden',
                            left: this.state.windowDrag.position.left,
                            top: this.state.windowDrag.position.top,
                            width: this.state.windowDrag.width,
                        }}
                        onMouseUp={this.onContainerMouseUp}
                        onMouseDown={this.onContainerMouseDown}
                        onMouseMove={this.onContainerMouseMove}
                        onMouseLeave={() => this.cancelDragging()}
                    >
                        <div className='injuryTypeGenerator_titleContainer'>
                            <div className='injuryTypeGenerator_title'>{this.props.injuryToLoad ? 'Update' : 'Create'} Custom Injury</div>
                        </div>

                        <div className='propertyPickerContainer'>

                            <div className='propertyPickerHeader secondaryContainer'>
                                {/* Injury Globality */}
                                <div className='injuryGlobalityContainer'>

                                    <div className='globalityTitle generatorHeaderField'> Global </div>
                                    <Switch className='globalitySwitch'
                                        checked={this.state.global}
                                        onChange={async checked => this.setState({ global: checked })}
                                        height={17}
                                        width={34}
                                        tabIndex={2}
                                    />

                                </div>

                                {/* Injury Name */}
                                <div className='injuryNameContainer txtLabel'>
                                    <div className='injuryNameTitle generatorHeaderField' style={{display: "inline-block"}}>
                                        Injury Name: &nbsp;&nbsp;
                                    </div>
                                    <input className='generatorInput injuryNameTextbox'
                                        id="injuryTypeGenerator_name"
                                        ref="injuryTypeGenerator_name"
                                        value={this.state.injuryName}
                                        onChange={evt => { this.setState({ injuryName: evt.target.value }); }}
                                        tabIndex={1}
                                    />
                                </div>
                            </div>

                            <div className='variablesContainer secondaryContainer scrollable'>

                                <div className='tertiaryContainer'>
                                
                                    {/* Properties Header */}
                                    <div className='propertyTypeHeader tertiaryHeader'>
                                        Properties
                                    </div>

                                    <div className='propertyItemsContainer'>
                                        {this.state.injuryProperties.map((prop, idx) => {
                                            return (
                                                <InjuryTypeGeneratorProperty
                                                    key={'injuryTypeGeneratorProperty_prop' + idx}
                                                    propIdx={idx}
                                                    propName={prop.name}
                                                    dataType={prop.dataType.name}
                                                    options={prop.dataType.options}
                                                    defaultValue={prop.defaultValue}
                                                    propType='property'

                                                    removeProperty={this.removeProperty}
                                                    changePropertyDataType={this.changePropertyDataType}
                                                    changePropertyName={this.changePropertyName}
                                                    changePropertyDefaultValue={this.changePropertyDefaultValue}
                                                    startPropertyOptionChooser={this.startPropertyOptionChooser}
                                                />
                                            );
                                        })}
                                    </div>

                                    {/* New Property Button */}
                                    <div 
                                        className='newPropButtonContainer'
                                        onClick={() => this.addBlankProperty('property')}
                                    >
                                        <img
                                            className = 'injuryGeneratorButton newPropButton' 
                                            src = '/resources/icons/Cursor.png'
                                            alt = 'Create new property'
                                            title = 'Create new property'
                                            tabIndex={3}
                                        />

                                        <div className='newPropButtonText'>
                                            Create new property
                                        </div>
                                    </div>
                                </div>
                                
                                {/* <br /><br /><hr /> */}
                            
                                <div className='tertiaryContainer'>

                                    {/* Metrics Header */}
                                    <div className='propertyTypeHeader tertiaryHeader'>
                                        Metrics
                                    </div>
                                
                                    <div className='propertyItemsContainer'>
                                        {this.state.injuryMetrics.map((prop, idx) => {
                                            return (
                                                <InjuryTypeGeneratorProperty
                                                    key={'injuryTypeGeneratorProperty_metric' + idx}
                                                    propIdx={idx}
                                                    propName={prop.name}
                                                    dataType={prop.dataType.name}
                                                    propType='metric'

                                                    removeProperty={this.removeProperty}
                                                    changePropertyDataType={this.changePropertyDataType}
                                                    changePropertyName={this.changePropertyName}
                                                    changePropertyDefaultValue={this.changePropertyDefaultValue}
                                                />
                                            );
                                        })}           
                                    </div>

                                    {/* New Metric Button */}
                                    <div 
                                        className='newPropButtonContainer'
                                        onClick={() => this.addBlankProperty('metric')}
                                    >
                                        <img
                                            className='injuryGeneratorButton newPropButton'
                                            src='/resources/icons/Cursor.png'
                                            alt='Create new property'
                                            title='Create new property'
                                            tabIndex={4}
                                        />

                                        <div className='newPropButtonText'>
                                            Create new metric
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='secondaryContainer' >

                                <div className='tertiaryContainer'>

                                    {/* RWR Gen Header */}
                                    <div className='propertyTypeHeader tertiaryHeader'>
                                        RWR Template
                                    </div>

                                    <textarea
                                        className='txtAreaRwr txtArea'
                                        value={this.state.txtRwrTemplate}
                                        spellCheck='false'
                                        onChange={evt => this.setRwrText(evt.target.value)}
                                        tabIndex={5}
                                    />

                                    {/* Sample Summary */}
                                    {this.state.txtRwrRendered.length > 0 ? 
                                    <div className='summaryContainer'>
                                        <div className='summaryHeader'>
                                            Sample Summary
                                        </div>

                                        <div className={'generatedSummary' + (this.state.rwrRenderedSuccess ? ' generatedSummarySuccess' : ' generatedSummaryFailed')} >
                                            {this.state.txtRwrRendered}

                                        </div>
                                    </div>
                                    : <div className='summaryContainer'/>}

                                </div>

                            </div>

                            <div className='footerButtonContainer'>
                                <div className='btnClear btnOval' onClick={() => this.setState({ confirmDialogOpened: true})} title='Clear' tabIndex={6}>
                                    Clear
                                </div>

                                <div className='btnCreateInjury btnOval' onClick={this.submit} title='Create Injury' tabIndex={7}>
                                    {this.props.injuryToLoad ? 'Update' : 'Create'} Injury
                                </div>
                            </div>
                        </div>

                        {/* Option Chooser */}
                        <InjuryTypeGeneratorOptionChooser 
                            isVisible={this.state.propertyOptionChooser.isVisible}
                            cancel={this.cancelPropertyOptionChooser}
                            finish={this.finishPropertyOptionChooser}
                            options={this.state.propertyOptionChooser.options}
                            showToastError={this.showToastError}
                        />

                        {/* Cancel Button */}
                        <img
                            className = 'injuryGeneratorButton injuryGeneratorClose' 
                            src = '/resources/icons/close.png'
                            alt = 'cancel'
                            title = 'Cancel injury creation'
                            onClick = {this.props.closeInjuryGenerator}     
                        />

                        {/* confirm dialog */}
                        <div
                            className='confirmDialogBackground'
                            style={{ display: this.state.confirmDialogOpened ? 'block' : 'none'}}
                        >
                            <div className='confirmDialog tertiaryContainer'>
                                <div className='confirmDialogTitle tertiaryHeader'>
                                    Clear all properties and metrics?
                                </div>
                                <div className="confirmDialogButtonContainer">
                                    <div className="btnConfirmDialog_no btnOval" onClick={() => this.setState({ confirmDialogOpened: false })}>No</div>
                                    <div className="btnConfirmDialog_yes btnOval" onClick={() => this.clear()}>Yes</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </HotKeys>
        );
    }
    //#endregion Render
}

export default InjuryTypeGenerator;

