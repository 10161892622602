// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory


// Find a matching series, given an axis, t-level, and indicator
export const findMatchingSeries = (matchValues, seriesNames) => {

    let axis = matchValues.axis;
    let t = matchValues.t;
    let indicator = matchValues.indicator;

    let buffer = seriesNames;

    // Filter by axis
    if (axis)
        seriesNames = findAllIndicatedSeries(seriesNames, axisIndicators[axis])
    
    // No matches?
    if (seriesNames.length === 0)
        seriesNames = buffer;
    else 
        buffer = seriesNames;

    if (t)
        seriesNames = seriesNames.filter(name => {
            return (name.toLowerCase().includes("t"+t));
        })

    // No matches?
    if (seriesNames.length === 0)
        seriesNames = buffer;
    else 
        buffer = seriesNames;

    if (indicator)
        seriesNames = seriesNames.filter(name => {
            return (name.toLowerCase().includes(indicator.toLowerCase()));
        })

    // Return the discovered series
    if (seriesNames.length === 0)
        return buffer[0];

    else 
        return seriesNames[0];

}

// Identify a series based on name
export const identifySeries = (seriesName) => {

    let seriesLower = seriesName.toLowerCase();

    let data = {};

    // Axis
    let axes = ['ax', 'sag', 'cor'];
    for (let axis of axes)
        if (seriesLower.includes(axis)) {
            data.axis = axis;
            break;
        }

    // T
    if (seriesLower.includes('t1'))
        data.t = 1
    else if (seriesLower.includes('t2'))
        data.t = 2;

    // Indicator
    let indicators = ['loc', 'scout']
    for (let indicator of indicators)
        if (seriesLower.includes(indicator)) {
            data.indicator = indicator;
            break;
        }
    
    return data;

}


// Rotate within a series, given the current name, and the seriesNames 

// if axis is the same as the current axis, then it will cycle through all of that type
// if axis is different than the current axis, then it will switch to the T1 version if available, otherwise, T2
// axis: 'ax', 'sag', 'cor'
export const getRotatedSeriesName = (lastSeriesName, rotationAxis, seriesNames) => {

    // Identify cur series
    let curSeriesType = identifySeries(lastSeriesName);

    // Check if same axis
    const isSameAxis = curSeriesType.axis && rotationAxis === curSeriesType.axis ? true : false;

    // New Axis? 
    if (!isSameAxis) {
        // Get t 1 
        const newSeriesName = findMatchingSeries({axis: rotationAxis, t: 1 }, seriesNames);
        return newSeriesName;
    }

    // Otherwise, rotate along the same axis

    // Find the series with the matching axis
    const matchingSeries = findAllIndicatedSeries(seriesNames, axisIndicators[rotationAxis]);

    const newSeriesName = matchingSeries[(matchingSeries.indexOf(lastSeriesName) + 1) % matchingSeries.length];
    return newSeriesName;
}




// Series Finding for specific types 

const axisIndicators = {

    'ax': ['ax', 'trs', 'trb'],
    'sag': ['sag'],
    'cor': ['cor']

}


// generic 
const findAllIndicatedSeries = (seriesNames, indicators) => {

    // No indicators : return all!
    if (!indicators)
        return seriesNames;

    // Find names with any of the indicaotrs in them 
    const filtered = seriesNames.filter( name => {

        for (let indicator of indicators) {
            if (name.toLowerCase().includes(indicator.toLowerCase()))
                return true;
        }

        return false;
    });

    return filtered;
}

// axial
export const findAxialSeries = (seriesNames) => {
    return findAllIndicatedSeries(axisIndicators['ax']);
}

// sagital
export const findSagitalSeries = (seriesNames) => {
    return findAllIndicatedSeries(axisIndicators['sag']);
}

// coronal 
export const findCoronalSeries = (seriesNames) => {
    return findAllIndicatedSeries(axisIndicators['cor']);
}
