import React, { Component } from 'react';
import { HotKeys } from 'react-hotkeys';

import "../styles/ConfirmButton.css";

const NUM_ANIMATION_FRAMES = 20;

export default class ComfirmButton extends Component {
    
    // can pass props of maxRatio, height, float (left,right)
    // txtStyle: style for test
    // containerStyle: extra styling for button
    // interval: time of animation, in milli seconds
    
    state = {
        defaultHeight: 30,
        defaultMaxRatio: 2, // width/height
        defaultInterval: 100,

        clickedOnce: false,
        inTransition: false,
        currentRatio: 1,
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if (this.refs.mainContainer && !this.refs.mainContainer.contains(e.target)) 
            if (this.state.clickedOnce)
                this.unclick();
    }

    // #region Hotkeys

    hotkeyMap = {
        // actions
        cancel: 'Escape',
    };

    hotkeyHandlers = {
        // actions
        cancel: e => this.triggerHotkey(e, () => this.unclick()),
    };

    triggerHotkey = (event, callback) => {
        event.stopPropagation();
        event.preventDefault();

        if (window.event)
            window.event.cancelBubble = true;

        callback();
    }

    // #endregion Hotkeys

    // #region get functions

    getHeight = () => {
        return this.props.height && !isNaN(this.props.height) ? this.props.height : this.state.defaultHeight;
    }

    getMaxRatio = () => {
        return this.props.maxRatio && !isNaN(this.props.maxRatio) ? this.props.maxRatio : this.state.defaultMaxRatio;
    }

    getInterval = () => {
        return this.props.interval && !isNaN(this.props.interval) ? this.props.interval : this.state.defaultInterval;
    }

    getButtonText = () => {
        let result;

        if (!this.state.inTransition && this.state.clickedOnce)
            result = this.props.confirmTerm ? this.props.confirmTerm : 'Confirm';
        else if (!this.state.inTransition)
            result = '✕'; // '✕' = U+00D7
        else 
            result = '';
        
        return result;
    }

    // #endregion get functions

    // #region style

    getStyle_Background = () => {
        const height = this.getHeight();
        return {
            height: height,
            width: height * this.getMaxRatio(),
        }
    }
    
    getStyle_Container = () => {
        const height = this.getHeight();
        return {
            height: height,
            width: height * this.state.currentRatio,
            float: this.props.float && this.props.float === 'left' ? 'left' : 'right',
            ...this.props.containerStyle,
        }
    }

    getStyle_Txt = () => {
        return {...this.props.txtStyle};
    }

    // #endregion style

    // #region click functions

    firstClick = () => {
        this.setState({ clickedOnce: true });
        this.animate(1, this.getMaxRatio())
    }

    secondClick = (triggerCallback) => {
        if (this.props.callback && triggerCallback)
            this.props.callback();
        else if (triggerCallback)
            console.log('callback not provided for button');

        this.setState({ clickedOnce: false });
        this.animate(this.state.currentRatio, 1);
    }

    unclick = () => {
        this.secondClick(false);
    }

    click = () => {
        if (this.state.clickedOnce) 
            this.secondClick(true);
        else 
            this.firstClick();
    }

    animate = async (start, end) => {

        const dRatio = (end-start)/NUM_ANIMATION_FRAMES;
        const dt = this.getInterval() / NUM_ANIMATION_FRAMES;

        await this.setState({inTransition: true});
        
        for (let i=0; i<NUM_ANIMATION_FRAMES; i++) 
            setTimeout(() => {this.setState({ currentRatio: this.state.currentRatio + dRatio })}, dt * i);

        setTimeout(() => {this.setState({inTransition: false})}, NUM_ANIMATION_FRAMES * dt);
    }
    
    // #endregion click functions


    render = () => {
        return (
            <HotKeys keyMap={this.hotkeyMap} handlers={this.hotkeyHandlers}>
                <div 
                    className='confirmBtnBackground' 
                    style={this.getStyle_Background()}
                    // ref={this.setWrapperRef}
                >

                    <div 
                        className={'confirmBtnContainer' + (!this.state.clickedOnce && !this.state.inTransition ? ' unclickedContainer' : '')} 
                        style={this.getStyle_Container()} 
                        onClick={() => this.click(true)}
                        // ref={this.setWrapperRef}
                        ref='mainContainer'                    >
                        <div className='confirmBtnTxt' style={this.getStyle_Txt()}>{this.getButtonText()}</div>
                    </div>

                </div>
            </HotKeys>
        );
    }
}
