// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import uuid4 from 'uuid4';
import { HotKeys } from 'react-hotkeys';
import { toast } from 'react-toastify';

// Components
import DicomViewer from '../DicomViewer/DicomViewer';
import { withRouter } from 'react-router-dom';
import DicomHeader from './DicomHeader';
import DicomViewerToolbar from './DicomViewerToolbar';
import ViewerConfigMenu from '../SubComponents/ViewerConfigMenu';

// Styles
import "../../../styles/DicomViewer/dicomViewerPage.css";

// Constants
import { viewerLayouts } from '../../../constants/viewerConfigurations';
import { viewerConfigurations, defaultViewerConfigurations } from '../../../constants/viewerConfigurations';
import { typeToTool } from '../../../constants/tools';

// Utils
import { findMatchingSeries, identifySeries, getRotatedSeriesName } from '../../../utilities/series';
import { plane } from '../../../utilities/plane';
import { vec3 } from '../../../utilities/vectors';
import sleep from '../../../utilities/sleep';

const ANATOMY_LABEL_THRESHOLD = 10;


// ------ DICOM VIEWER PAGE DEFINITION ------

// The DicomViewerPage is responsible for : 
// - Tracking, and allocating sereis, slices, and metadata for viewers
// - Computing slice intersections when needed
// - Receiving annotation requests from windows
// - Fullfilling annotation requests from windows. 
// - Handling project changes (from this window and others).


class DicomViewerPage extends Component {

    state = {

        // Session ID (for cross browser communication)
        sessionUUID: null,
        identifyingSiblings: false,
        siblings: [],

        // Active tool
        activeTool: '',

        // Configuration of viewers
        viewerConfiguration: {
            configuration: viewerConfigurations.SINGLE,
            viewers: defaultViewerConfigurations[viewerConfigurations.SINGLE]
        },

        // Configuration editor
        configEditorOpen: false,

        // Rendered viewers
        viewers: {
            0: null,
            1: null,
            2: null,
            3: null,
            4: null,
            5: null
        },

        // Cursor position
        cursorPosition: {
            siblingBrowserCursor: false,
            viewerIdx: -1,
            "2D": { x: 0, y: 0 },
            "3D": { x: 0, y: 0, z: 0 }
        },

        // Active Request from Report Editor
        activeRequest: null,

        // Injury visibility
        blockedInjuryLocations: [],
        blockedInjuryTypes: [],
        blockedInjuries: []
    }



    // #region Hotkeys

    hotkeyMap = {
        // annotation tools
        activeTool_annotation: 'Control+l',
        activeTool_arrow: 'Control+a',
        activeTool_circle: 'Control+c',
        activeTool_rectangle: 'Control+r',
        activeTool_delete: 'Control+d',

        // measurement tools
        activeTool_angle: 'Shift+a',
        activeTool_length: 'Shift+l',

        // other tool operations
        activeTool_deactivate: 'Escape',

        // viewer configurations
        viewerConfig_1: 'Control+1',
        viewerConfig_2_horiz: 'Control+2',
        viewerConfig_2_vert: 'Control+3',
        viewerConfig_4: 'Control+4',
        viewerConfig_6_horiz: 'Control+5',
        viewerConfig_6_vert: 'Control+6',

        // actions
        newDicomViewerWindow: 'Alt+d',
        newReportWindow: 'Alt+r',
        prevProject: 'Control+Shift+left',
        nextProject: 'Control+Shift+right',
    };

    hotkeyHandlers = {
        // annotation tools
        activeTool_annotation: e => this.triggerHotkey(e, () => this.toggleActiveTool('Label')),
        activeTool_arrow: e => this.triggerHotkey(e, () => this.toggleActiveTool('Arrow')),
        activeTool_circle: e => this.triggerHotkey(e, () => this.toggleActiveTool('Circle')),
        activeTool_rectangle: e => this.triggerHotkey(e, () => this.toggleActiveTool('Rectangle')),
        activeTool_delete: e => this.triggerHotkey(e, () => this.toggleActiveTool('Delete')),

        // measurement tools
        activeTool_angle: e => this.triggerHotkey(e, () => this.toggleActiveTool('Angle')),
        activeTool_length: e => this.triggerHotkey(e, () => this.toggleActiveTool('Length')),

        // other tool operations
        activeTool_deactivate: e => this.triggerHotkey(e, () => this.cancelCurrentOp()),

        // viewer configurations
        viewerConfig_1: e => this.triggerHotkey(e, () => this.updateViewerConfiguration(viewerConfigurations.SINGLE)),
        viewerConfig_2_horiz: e => this.triggerHotkey(e, () => this.updateViewerConfiguration(viewerConfigurations.DOUBLE_HORIZONTAL)),
        viewerConfig_2_vert: e => this.triggerHotkey(e, () => this.updateViewerConfiguration(viewerConfigurations.DOUBLE_VERTICAL)),
        viewerConfig_4: e => this.triggerHotkey(e, () => this.updateViewerConfiguration(viewerConfigurations.QUADRANTS)),
        viewerConfig_6_horiz: e => this.triggerHotkey(e, () => this.updateViewerConfiguration(viewerConfigurations.TRIPLE_HORIZONTAL)),
        viewerConfig_6_vert: e => this.triggerHotkey(e, () => this.updateViewerConfiguration(viewerConfigurations.TRIPLE_VERTICAL)),

        // actions
        newDicomViewerWindow: e => this.triggerHotkey(e, () => this.newDicomViewerWindow()),
        newReportWindow: e => this.triggerHotkey(e, () => this.newReportWindow()),
        prevProject: e => this.triggerHotkey(e, () => this.shiftProjectIndex(-1)),
        nextProject: e => this.triggerHotkey(e, () => this.shiftProjectIndex(1)),
    };

    triggerHotkey = (event, callback) => {
        event.stopPropagation();
        event.preventDefault();

        if (window.event)
            window.event.cancelBubble = true;

        callback();
    }

    // #endregion Hotkeys


    // VIEWER STRUCTURE
    // {
    //     active: false, // Mouse in? 
    //     cursor: {x: 0, y: 0}, // Workspace coords
    //     sliceIntersection: [{x: 0, y: 0}, {x: 0, y: 0}], // Intersecting slice points
    //     seriesIdx: 0, // Index of the series in project.dicomData
    //     sliceIdx: 0, // Currently displayed slice
    //     siblingIdx: 0, // Index of sibling viewer
    //     currentOperation: {}, // Current viewer operation
    //     // Positioning (in % of viewer-page)
    //     left: "0%",
    //     top: "0%",
    //     width: "0%",
    //     height: "0%",
    // }


    componentDidMount = async () => {


        // Componnent did mount function

        let loaded = false;

        // Load a specified project? 
        if (this.props.match.params.projectId)
            loaded = await this.props.loadProject(this.props.match.params.projectId);

        // Load default project?
        else
            loaded = await this.props.loadDefaultProject();


        // Project loading error 
        if (!loaded)
            return;

        // Load user configuration
        let user = {};
        if (user.data && user.data.dicomConfiguration)
            await this.updateViewerConfiguration(user.data.dicomConfiguration);

        // Load default configuration
        else if (this.props.inGenerator === true)
            await this.updateViewerConfiguration(viewerConfigurations.DOUBLE_VERTICAL);
        else
            await this.updateViewerConfiguration(viewerConfigurations.QUADRANTS);


        // Set any inhereted session UUID
        await this.setSessionUUID(this.props.match.params.uuid);

        // Initialize Broadcasting
        await this.initializeBroadcasting();

        // Identify sibling instances
        this.identifySiblingInstances();

    }


    componentDidUpdate = (prevProps, prevState) => {

        // For ViewerPage within ReportGen: 
        if (this.props.inGenerator) {

            // Update viewers to show specific object? 
            if (this.props.openEditorObject !== null && prevProps.openEditorObject !== this.props.openEditorObject) {
                // Injury
                if (this.props.openEditorObject.location === 'Injuries')
                    this.displayInjuryMetrics(this.props.openEditorObject.data);
                // Injury metric / annotations objects
                else if (this.props.openEditorObject.location === 'Object')
                    this.displaySpecificSlice(this.props.openEditorObject.data);
                // 3D Point
                else if (this.props.openEditorObject.location === '3DPoint')
                    this.setProximalViewerSlices(this.props.openEditorObject.data);

            }

            // New project loaded ? => Configure viewers to this project. 
            let projectId = this.props.project ? this.props.project._id : null;
            let prevId = prevProps.project ? prevProps.project._id : null;
            if (projectId !== prevId && projectId !== null)
                this.updateViewerConfiguration(this.state.viewerConfiguration.configuration);


            // New activeRequest from parent? 
            if (prevProps.activeRequest !== this.props.activeRequest)
                if (this.props.activeRequest === null)
                    this.acceptAnnotationCompletion(this.props.activeRequest);
                else
                    this.acceptRequestedAnnotation(this.props.activeRequest);
        }

    }

    // Set dicom viewer slices based on proximity to a 3D coord (stark space)
    setProximalViewerSlices = coordinate => {

        const viewers = this.state.viewers;

        // get stark coord
        const loc3D = plane.starkCoordsToWorld(coordinate);

        for (const viewerIdx in Object.keys(viewers)) {

            if (viewers[viewerIdx] === null)
                continue;

            const slices = this.props.project.dicomData[viewers[viewerIdx].seriesIdx].slices;

            // find slice closest to the 3D location point
            let newSliceIdx = 0;
            let minDistance = plane.distanceFromPointToPlane(loc3D, plane.planeFromDicom(slices[0]));

            for (let i = 1; i < slices.length; i++) {
                const distanceFromLocation = plane.distanceFromPointToPlane(loc3D, plane.planeFromDicom(slices[i]));

                if (distanceFromLocation < minDistance) {
                    newSliceIdx = i;
                    minDistance = distanceFromLocation;
                }
            }

            this.updateViewerSlice(viewerIdx, newSliceIdx);
        }
    }


    // ------ PROJECT NAVIGATION FUNCTIONS ------

    // Shift the project index
    shiftProjectIndex = async (shiftValue) => {

        // Get next project index
        let newProjectId = await this.props.getNextProjectId(shiftValue);

        // Load the new project
        await this.loadProject(newProjectId);

        // Broadcast new project._id
        this.broadcastProjectChange(newProjectId);

    }

    // Load a new project
    loadProject = async (newProjectId) => {

        // Clear viewers
        await this.clearViewers();

        // Call parent
        await this.props.loadProject(newProjectId);

        // Update the url
        let urlPrefix = this.props.inGenerator ? '/report/' : '/dicom/';
        this.props.history.push(urlPrefix + newProjectId + '/' + this.state.sessionUUID);

        // Update Viewers
        this.updateViewerConfiguration(this.state.viewerConfiguration.configuration);

    }

    // Helper function to clear all dicom viewer
    clearViewers = () => {

        let nullViewers = { 0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null };

        this.setState({ viewers: nullViewers });
    }


    // ------ BROWSER COMMUNICATION HANDLING FUNCTIONS ------

    // ACTIONS: 

    // IdentifySelf
    // Request sent from a single instance, to identify other instances.
    // [No Params]

    // WhoAmI
    // The response sent from a sibling to make itself known.
    // [pId]: The ID of the project displayed.
    // [whoAmI]: The page-type of the instance.

    // RequestLength
    // injuryType (str)

    // AnnotationRequest
    // A request made by the Report Editor, for an annotation to be created. 
    // request (obj): An object holding the requested annotation info.

    // CompletedAnnotation
    // A responst sent by a DicomViewerPage, with the completed annotation requested
    // annotation (obj): An object holding the requested annotation. 

    setSessionUUID = async (manualUUID = undefined) => {

        // Manual setting? 
        if (manualUUID !== undefined && manualUUID !== null) {
            await this.setState({ sessionUUID: manualUUID });
            sessionStorage.setItem("ViewerUUID", manualUUID);
        }

        // Check for a session saved UUID (handles case of original refreshing)
        else if (sessionStorage.getItem('ViewerUUID')) {
            await this.setState({ sessionUUID: sessionStorage.getItem('ViewerUUID') });
        }

        else {
            let viewerUUID = uuid4().toString();
            sessionStorage.setItem("ViewerUUID", viewerUUID);
            await this.setState({ sessionUUID: viewerUUID })
        }

        console.log("SessionUUID: ", this.state.sessionUUID);

    }

    initializeBroadcasting = () => {

        window.addEventListener('storage', async (e) => { await this.receiveData(e) });

    }

    receiveData = async (e) => {

        if (e.key === this.state.sessionUUID && e.newValue !== null) {

            // Receive data over the UUID 
            let data = JSON.parse(e.newValue);

            // Handle the received data conditionally.

            // Handle incoming requests for annotations
            if (data.action === 'AnnotationRequest')
                this.acceptRequestedAnnotation(data.request);

            // Clear the current annotation request
            else if (data.action === 'CompletedAnnotation')
                this.acceptAnnotationCompletion(data);

            // Clear the current annotation request
            else if (data.action === 'CancelAnnotationRequest')
                this.acceptAnnotationCompletion(data);

            // New global cursor position
            else if (data.action === "UpdateCursor3D")
                await this.acceptCuror3D(data);

            // New active tool
            else if (data.action === 'UpdateActiveTool')
                await this.acceptActiveTool(data);

            // Injury visibilities
            else if (data.action === 'InjuryVisibilities') {
                this.acceptInjuryVisibilities(data);
            }

            // New project
            else if (data.action === 'ProjectChange') {
                console.log('Received broadcast: shifting proj idx:', data.shiftValue);
                this.loadProject(data.newProjectId);
            }

            // New annotations
            else if (data.action === 'SyncAnnotations') {
                this.props.receiveSiblingAnnotations(data.seriesName, data.sliceIdx, data.annotationData);
            }

            // New injuries
            else if (data.action === 'SyncInjuries') {
                this.props.receiveSiblingInjuries(data.location, data.injuryData);
            }

            // Identify self to sibling
            else if (data.action === "IdentifySelf")
                this.pingSibling();

            // Receiving identification from sibling
            else if (this.state.identifyingSiblings && data.action === "WhoAmI") {
                let siblings = this.state.siblings;
                siblings.push({ pId: data.pId, whoAmI: data.whoAmI });
                this.setState({ siblings: siblings });
            }

        }
    }

    // Accept a requested annotation from Report Editor
    acceptRequestedAnnotation = (reqData) => {

        this.setState({
            activeRequest: reqData,
            activeTool: typeToTool[reqData.dataType],
        })

    }

    // The annotation was completed by another viewer... so cancel any current op.
    acceptAnnotationCompletion = (reqData) => {

        this.setState({
            activeRequest: null,
            activeTool: ''
        })

    }

    // Accept a new 3D cursor position from sibling
    acceptCuror3D = async (data) => {

        let currState = this.state.cursorPosition;
        currState['3D'] = data.cursorPosition3D

        await this.setState(prevState => ({
            cursorPosition: {
                ...prevState.cursorPosition,
                viewerIdx: -1,
                siblingBrowserCursor: true,
                '3D': data.cursorPosition3D
            }
        }));
    }

    // Accept a new active tool from sibling
    acceptActiveTool = async (data) => {
        await this.setDicomViewerTool(data.activeTool, false);
        // await this.setState({ activeTool: data.activeTool});
    }

    broadcastData = (data) => {

        // Broadcast data over the UUID 
        localStorage.setItem(this.state.sessionUUID, JSON.stringify(data));
        localStorage.removeItem(this.state.sessionUUID);

    }

    // Broadcast Communication Functions

    broadcastCommand = (cmdStr, data) => {
        data.action = cmdStr;
        this.broadcastData(data);
    }

    broadcastAnnotations = async (seriesName, sliceIdx, newAnnotation) => {

        this.broadcastCommand('SyncAnnotations', {
            seriesName,
            sliceIdx,
            annotationData: newAnnotation
        });
    }

    // Helper function to broadcast the current global annotations. 
    broadcastGlobalAnnotations = async () => {

        const p = this.props.project;
        const globalAnnotations = (p && p.dicomAnnotations && p.dicomAnnotations.global) ? p.dicomAnnotations.global : null;

        if (globalAnnotations === null)
            return;
        
        await this.broadcastAnnotations('global', null, globalAnnotations);
        
        return;

    }

    // Broadcast injuries at a given location
    broadcastInjuries = async (injuryLocation) => {

        // Get the objects 
        let injuryLocationData = await this.props.project.ai.injury.injuries.find(elem => elem.location === injuryLocation);

        if (!injuryLocationData)
            return;

        this.broadcastCommand('SyncInjuries', {
            location: injuryLocation,
            injuryData: injuryLocationData
        });
    }

    broadcastCursor3D = async cursorPosition3D => {
        await this.broadcastCommand('UpdateCursor3D', {
            cursorPosition3D: cursorPosition3D
        });
    }

    broadcastActiveTool = async (activeTool) => {
        await this.broadcastCommand('UpdateActiveTool', {
            activeTool: activeTool
        })
    }

    broadcastProjectChange = async newProjectId => {
        await this.broadcastCommand('ProjectChange', {
            newProjectId
        })
    }

    // Send a requested annotation back to the Report Generator
    broadCastRequestedAnnotation = async (annotation) => {

        // Broadcast the annotation 
        let data = {
            action: 'CompletedAnnotation',
            annotation: annotation
        }

        // If in within ReportGen, pass the data up!
        if (this.props.inGenerator) {

            // Send annotation
            this.props.completeGeneratorRequest(data);

            // Broadcst so siblings know to stop 
            await this.broadcastData(data);

            return;
        }

        // Else, broadcast
        else {
            await this.broadcastData(data);

            // Reset the active request!
            this.setState({
                activeRequest: null,
                activeTool: ''
            });
        }


    }

    // Cancel active request in self and all siblings.
    cancelAnnotationRequests = async () => {

        // Cancel operation in DicomViewerPage (self)
        this.setState({ activeRequest: null });

        // If in ReportGen:
        if (this.props.inGenerator)
            // Cancel in ReportGen
            this.props.clearActiveRequest()

        // Cancel in siblings
        this.broadcastData({ action: "CancelAnnotationRequest" });

    }

    acceptInjuryVisibilities = async (data) => {

        // Set the state visibilities
        await this.setState({
            blockedInjuryLocations: data.blockedLocations,
            blockedInjuries: data.blockedInjuries,
        })

    }

    // Update list of sibling instances
    identifySiblingInstances = async () => {

        // Set the stage for sibling identification
        await this.setState({
            identifyingSiblings: true,
            siblings: []
        })

        // Ping siblings
        this.broadcastData({ action: "IdentifySelf" });

        // Wait...
        let timeout = 1;

        await sleep(1000 * timeout);
        this.setState({ identifyingSiblings: false });

        console.log("Identified Siblings: ", this.state.siblings);

    }

    // Send data back to sibling identifying type, etc. 
    pingSibling = () => {
        this.broadcastData({ action: "WhoAmI", pId: this.props.project._id, whoAmI: "DicomViewerPage" });
    }


    // ------ STATE HANDLING FUNCTIONS ------

    // Set active tool
    setDicomViewerTool = (toolName, broadcast = true) => {

        // Clear any viewer operations
        let viewers = this.state.viewers;
        for (let viewerIdx of Object.keys(viewers))
            if (viewers[viewerIdx] !== null)
                viewers[viewerIdx].currentOperation = {};

        // Set tool
        this.setState({
            activeTool: toolName,
            viewers: viewers,
        });

        // Broadcast
        if (broadcast)
            this.broadcastActiveTool(toolName);
    }

    toggleActiveTool = toolName => {
        if (this.state.activeTool === toolName)
            this.setDicomViewerTool('');
        else
            this.setDicomViewerTool(toolName);
    }

    // Clear any current operation, including any requests 
    cancelCurrentOp = () => {

        // Clear the tool 
        this.setDicomViewerTool('');

        // Reset any active requests. 
        this.cancelAnnotationRequests();
    }


    // ------ VIEWER HANDLING FUNCTIONS ------

    // Given a series name, find the index in dicomData
    getSeriesIdx = (seriesName) => {

        for (let i = 0; i < this.props.project.dicomData.length; i++)
            if (this.props.project.dicomData[i].seriesName === seriesName)
                return i;

        return -1;
    }

    // Returns the styling for a dicom viewer, given idx and current config
    getViewerDims = (viewerIdx, viewerConfiguration) => {

        let style = {};

        let viewerPageDims = viewerLayouts[viewerConfiguration];

        // Get viewer formatting
        let row = Math.floor(viewerIdx / viewerPageDims.cols);
        let col = viewerIdx % viewerPageDims.cols;

        style.left = `${100 * col / viewerPageDims.cols}%`;
        style.top = `${100 * row / viewerPageDims.rows}%`;
        style.width = `${100 / viewerPageDims.cols}%`;
        style.height = `${100 / viewerPageDims.rows}%`;

        return style;

    }

    getViewerSibling = (viewerIdx, viewerConfiguration) => {

        let viewerPageDims = viewerLayouts[viewerConfiguration];

        // Single viewer
        if (viewerConfiguration === viewerConfigurations.SINGLE)
            return -1;

        // Horizontally Paired : Odd cols pair to the left. Even cols pair to the right
        else if (viewerPageDims.cols === 2 && viewerPageDims.rows !== 2)
            return viewerIdx + (-2 * (viewerIdx % 2) + 1); // Left of self if odd, right of self if even

        // Vertically Paired: Pair with the viewer above or below
        else
            return (viewerIdx + viewerPageDims.cols) % (viewerPageDims.rows * viewerPageDims.cols);

    }

    // Switch viewer configuration
    updateViewerConfiguration = async (configType) => {

        // Get new configuration
        let newConfig;

        // Check if the user has a default config for this orientation.
        if (this.props.user && this.props.user.data && this.props.user.data.dicomViewerConfiguration && this.props.user.data.dicomViewerConfiguration[configType])
            newConfig = { ...this.props.user.data.dicomViewerConfiguration[configType] };
        else
            // Use the defualt config 
            newConfig = defaultViewerConfigurations[configType];


        // Initialize new viewers
        let newViewers = { 0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null };

        let seriesNames = this.props.project.dicomData.map(elem => elem.seriesName);

        // Load viewers
        for (let viewerIdx = 0; viewerIdx < Object.keys(newConfig.viewers).length; viewerIdx++) {

            // Find matching series name/idx
            let seriesName = findMatchingSeries(newConfig.viewers[viewerIdx], seriesNames);
            let seriesIdx = this.getSeriesIdx(seriesName);

            // Dimensions and sibling
            let viewerDims = this.getViewerDims(viewerIdx, newConfig.configuration);
            let siblingIdx = this.getViewerSibling(viewerIdx, newConfig.configuration);

            // Check for precursor viewer (copy over data)
            let previousViewerIdx = -1;
            for (let i of Object.keys(this.state.viewers)) {

                let viewerData = this.state.viewers[i];

                if (viewerData && viewerData.seriesIdx === seriesIdx) {
                    previousViewerIdx = i;
                    break;
                }
            }

            // Create new viewer
            let viewer = {
                active: false, // Mouse in? 
                cursor: null, // Workspace coords
                sliceIntersection: [], // Intersecting slice points
                seriesIdx: seriesIdx,
                sliceIdx: 0, // Currently displayed slice
                siblingIdx: siblingIdx, // Sibling viewer idx
                visibility: { ...newConfig.viewers[viewerIdx].visibility }, // Initial visibility of objects in viewer
                currentOperation: {},
                plane: {}, // Saved replica of the dicom's deconstructed plane

                left: viewerDims.left,
                top: viewerDims.top,
                width: viewerDims.width,
                height: viewerDims.height
            }

            // Copy over persisting data
            if (previousViewerIdx >= 0) {
                viewer.sliceIdx = this.state.viewers[previousViewerIdx].sliceIdx;
                viewer.currentOperation = this.state.viewers[previousViewerIdx].currentOperation;
            }

            newViewers[viewerIdx] = viewer;

        }

        // Recompute viewer slice intersections
        await this.recomputeViewerData(newViewers, newConfig.configuration);


        // Set viewerConfig & viewers
        this.setState({
            viewerConfiguration: newConfig,
            viewers: newViewers
        });

    }

    generateEmptyViewer = (visibility, viewerDims, siblingIdx) => {
        return {
            active: false, // Mouse in? 
            cursor: null, // Workspace coords
            sliceIntersection: [], // Intersecting slice points
            seriesIdx: 0,
            sliceIdx: 0, // Currently displayed slice
            siblingIdx: siblingIdx, // Sibling viewer idx
            visibility: visibility, // Initial visibility of objects in viewer
            currentOperation: {},
            plane: {}, // Saved replica of the dicom's deconstructed plane

            left: viewerDims.left,
            top: viewerDims.top,
            width: viewerDims.width,
            height: viewerDims.height
        }
    }

    // Open the correct series/slice to display the annotation or metric
    displaySpecificSlice = async (objectData) => {

        // Unpack data
        let seriesName = objectData.seriesName;
        let sliceIdx = objectData.sliceIdx;

        // Not enough data? 
        if (!seriesName || !Number.isInteger(sliceIdx)) {
            toast.error('No series / slice reference for object.');
            return;
        }

        // Check if the series is already open
        for (let i of Object.keys(this.state.viewers)) {

            let viewer = this.state.viewers[i];
            if (viewer === null)
                continue;

            let viewerSeriesName = this.props.project.dicomData[viewer.seriesIdx].seriesName;

            // Match?
            if (viewerSeriesName === seriesName) {

                // Set the slice idx
                await this.updateViewerSlice(i, sliceIdx);

                return;
            }
        }

        // Otherwise, set the first viewer
        const seriesNameValid = await this.updateViewerSeries(0, seriesName);

        if (seriesNameValid)
            await this.updateViewerSlice(0, sliceIdx);

        return;


    }

    // Open the correct viewer configuration to display an injury 
    displayInjuryMetrics = async (injuryData) => {

        // Initialize new viewers
        let viewers = { 0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null };

        let relevantMetrics = injuryData.rationale.metrics.filter((metric, idx) => metric.seriesName);
        let relevantDicoms = injuryData.rationale.dicoms.filter((metric, idx) => metric.seriesName);

        // TODO @Marcel: Check for non-display flag from bot instead!
        relevantMetrics = relevantMetrics.filter(metric => metric.name !== 'Bot region of interest')

        // Get unique locations (so that multiple metrics will be shown on the same viewer)
        let uniqueLocations = relevantMetrics.map(m => m.seriesName + '.' + m.sliceIdx);
        uniqueLocations = uniqueLocations.filter((loc, idx) => uniqueLocations.indexOf(loc) === idx);
        uniqueLocations = uniqueLocations.map(loc => {
            const splitLocation = loc.split('.');
            return {
                seriesName: splitLocation[0],
                seriesIdx: this.getSeriesIdx(splitLocation[0]),
                sliceIdx: parseInt(splitLocation[1])
            }
        })

        const MIN_VIEWERS = 2;

        let seriesNames = this.props.project.dicomData.map(elem => elem.seriesName);

        // First we need to decide how many viewers are necessary, to get choose the configuration
        // also, asserts there are enough series to show.  Rare occation
        
        let numViewers = uniqueLocations.length < MIN_VIEWERS ? MIN_VIEWERS : uniqueLocations.length;

        if (numViewers > 6)
            numViewers = 6;
        if (numViewers % 2 !== 0 && numViewers !== 1)
            numViewers += 1;

        // Choose configuration
        let configuration;
        if (numViewers === 1)
            configuration = viewerConfigurations.SINGLE;
        else if (numViewers === 2)
            configuration = viewerConfigurations.DOUBLE_VERTICAL;
        else if (numViewers === 4)
            configuration = viewerConfigurations.QUADRANTS;
        else if (numViewers === 6)
            configuration = viewerConfigurations.TRIPLE_HORIZONTAL;

        configuration = defaultViewerConfigurations[configuration];

        // Now, each metric needs to be placed in a viewer, and the config needs to be updated. 
        for (let v = 0; v < numViewers; v++) {

            // Init viewer idx
            let viewerIdx = v;

            // Find the seriesName, seriesIdx, and sliceIdx
            let seriesName, seriesIdx, sliceIdx;

            if (v < uniqueLocations.length) {
                // Metric related viewer
                if (v < uniqueLocations.length) {
                    seriesName = uniqueLocations[v].seriesName;
                    seriesIdx = uniqueLocations[v].seriesIdx;
                    sliceIdx = uniqueLocations[v].sliceIdx;

                    // Update config to match
                    let newSeriesConfiguration = identifySeries(seriesName);
                    configuration[viewerIdx] = newSeriesConfiguration;
                }

                // Other
                else {
                    seriesName = findMatchingSeries(configuration.viewers[viewerIdx], seriesNames);
                    seriesIdx = this.getSeriesIdx(seriesName);
                    sliceIdx = 0;
                }
            }
            else {
                // add from rational.dicoms or other series

                // get current set
                const currSeriesList = Object.keys(viewers).map(idx => viewers[idx] ? seriesNames[viewers[idx].seriesIdx] : null).filter(x => x !== null);
                const currAxisList = currSeriesList.filter(name => name && name.length > 0).map(name => identifySeries(name).axis);

                // get series in rationale.dicoms, not in rationale.metrics
                const uniqueDicoms = relevantDicoms.filter(x => x.seriesName && !currSeriesList.includes(x.seriesName));

                // get series in series names, that does not share
                const uniqueSeries = seriesNames.filter(name => !currAxisList.includes(identifySeries(name).axis));
                const unusedSeries = seriesNames.filter(name => !currSeriesList.includes(name));
                
                if (uniqueDicoms.length > 0) {
                    seriesName = uniqueDicoms[0].seriesName;
                    seriesIdx = this.getSeriesIdx(seriesName);
                    sliceIdx = uniqueDicoms[0].sliceIdx;
                }
                else if (uniqueSeries.length > 0) {
                    seriesName = uniqueSeries[0];
                    seriesIdx = this.getSeriesIdx(uniqueSeries[0]);
                    sliceIdx = 0;
                }
                else if (unusedSeries.length > 0) {
                    seriesName = unusedSeries[0];
                    seriesIdx = this.getSeriesIdx(unusedSeries[0]);
                    sliceIdx = 0;
                }
                else {
                    seriesName = seriesNames[0];
                    seriesIdx = this.getSeriesIdx(seriesNames[0]);
                    sliceIdx = 0;
                }
            }

            // Dimensions and sibling
            const viewerDims = this.getViewerDims(viewerIdx, configuration.configuration);
            const siblingIdx = this.getViewerSibling(viewerIdx, configuration.configuration);

            // Create new viewer
            let newViewer = this.generateEmptyViewer(configuration.viewers[viewerIdx].visibility, viewerDims, siblingIdx);

            // Set series and slice
            newViewer.seriesIdx = seriesIdx;
            newViewer.sliceIdx = sliceIdx;

            // Update viewers
            viewers[viewerIdx] = newViewer;

        }

        // Compute slice intersections
        await this.recomputeViewerData(viewers, configuration.configuration);

        // Set viewers/config in state
        this.setState({
            viewers: viewers,
            viewerConfiguration: configuration
        })

    }

    // Change the series in the currently focused viewer
    switchSeriesAxis = async (viewerIdx, axis) => {

        // Get last series name
        let curViewer = this.state.viewers[viewerIdx];
        const lastSeriesName = this.props.project.dicomData[curViewer.seriesIdx].seriesName;

        // Get all series names
        const seriesNames = this.props.project.dicomData.map(elem => elem.seriesName);

        // Get rotated sereis name 
        const newSeriesName = getRotatedSeriesName(lastSeriesName, axis, seriesNames);

        await this.updateViewerSeries(viewerIdx, newSeriesName)
    }


    // Update a given viewer's series
    updateViewerSeries = async (viewerIdx, seriesName) => {

        // Copy initial viewer 
        let viewer = this.state.viewers[viewerIdx];

        // New series data
        let newIdx = await this.getSeriesIdx(seriesName);
        if (newIdx < 0) {
            toast.error('Invalid series name.');
            return false;
        }

        viewer.seriesIdx = newIdx
        viewer.sliceIdx = 0;
        viewer.currentOperation = {};

        // Series configuration update
        let newSeriesConfiguration = identifySeries(this.props.project.dicomData[newIdx].seriesName);

        let updatedViewers = { ...this.state.viewers, [viewerIdx]: viewer };

        // Update slice / anatomy intersections
        updatedViewers = await this.refreshViewerProjections(updatedViewers, viewerIdx);

        // Update state
        await this.setState(prevState => ({
            viewers: updatedViewers,

            viewerConfiguration: {
                ...prevState.viewerConfiguration,
                [viewerIdx]: newSeriesConfiguration
            }
        }))

        return true;

    }

    updateViewerSlice = async (viewerIdx, sliceIdx) => {

        // Copy viewer data
        let viewer = this.state.viewers[viewerIdx];

        // Make sure the slice is valid 
        if (sliceIdx < 0 || this.props.project.dicomData[viewer.seriesIdx].slices.length <= sliceIdx)
            return false;

        // Set the slice / current op
        viewer.sliceIdx = sliceIdx;
        viewer.currentOperation = {};

        let updatedViewers = { ...this.state.viewers, [viewerIdx]: viewer };

        // Refresh slice intersect / anatomy projectsions
        updatedViewers = await this.refreshViewerProjections(updatedViewers, viewerIdx);

        this.setState(prevState => ({
            viewers: updatedViewers
        }));

        return true;

    }

    // Update single viewer's data
    updateViewer = (viewerIdx, newViewer) => {

        // Update a dicom viewer manually
        this.setState(prevState => ({
            viewers: {
                ...prevState.viewers,
                [viewerIdx]: newViewer
            }
        }));

    }


    // ------ RECOMPUTATION HELPER FUNCTIONS ------

    // Compute the plane intersections for a set of viewers
    recomputeViewerData = (viewers, viewerConfigType) => {

        // Need to compute intersect? 
        const computeIntersect = (viewerConfigType === viewerConfigurations.SINGLE) ? false : true;


        Object.keys(viewers).map((viewerIdx) => {

            if (viewers[viewerIdx] === null)
                return null;

            let curViewer = viewers[viewerIdx];
            let baseSlice = this.props.project.dicomData[curViewer.seriesIdx].slices[curViewer.sliceIdx];

            // Slice intersection
            if (computeIntersect) {
                // Get Sibling data
                let siblingViewer = viewers[curViewer.siblingIdx];
                let intersectingSlice = this.props.project.dicomData[siblingViewer.seriesIdx].slices[siblingViewer.sliceIdx];

                // Compute slice intersection
                viewers[viewerIdx].sliceIntersection = this.computeDicomIntersection(baseSlice, intersectingSlice);
            }

            // Save plane to viewer
            const viewerPlane = plane.planeFromDicom(baseSlice);
            viewers[viewerIdx].plane = viewerPlane;

            return null;

        })

    }

    // Refresh slice intersect and anatomy locations for a given viewer
    refreshViewerProjections = (viewers, viewerIdx) => {

        // Get viewer plane
        let viewer = viewers[viewerIdx];
        let viewerSlice = this.props.project.dicomData[viewer.seriesIdx].slices[viewer.sliceIdx];

        // Update viewer plane
        viewer.plane = plane.planeFromDicom(viewerSlice);

        // New Intersections?
        if (this.state.viewerConfiguration.configuration !== viewerConfigurations.SINGLE) {

            let siblingViewer = this.state.viewers[viewer.siblingIdx];

            // Update slice intersections for both the viewer and the sibling.
            let viewerSlice = this.props.project.dicomData[viewer.seriesIdx].slices[viewer.sliceIdx];
            let siblingSlice = this.props.project.dicomData[siblingViewer.seriesIdx].slices[siblingViewer.sliceIdx];

            viewer.sliceIntersection = this.computeDicomIntersection(viewerSlice, siblingSlice);
            siblingViewer.sliceIntersection = this.computeDicomIntersection(siblingSlice, viewerSlice);

            viewers[viewerIdx] = viewer;
            viewers[viewer.siblingIdx] = siblingViewer;

        }

        // Return new viewers
        return viewers;

    }

    // Compute the plane intersectin bounds, given two dicom slices 
    computeDicomIntersection = (baseSlice, intersectingSlice) => {

        // Get the planes 
        let currentViewerPlane = plane.planeFromDicom(baseSlice);
        let intersectingPlane = plane.planeFromDicom(intersectingSlice);

        // Calculate intersection
        let intersectingPoints = plane.getPlaneIntersection(currentViewerPlane, intersectingPlane);

        if (intersectingPoints.length < 2)
            return [];

        // Convert to workspace
        let p1 = plane.worldToPlane(intersectingPoints[0], currentViewerPlane);
        let p2 = plane.worldToPlane(intersectingPoints[1], currentViewerPlane);

        // Save to viewer
        return [p1, p2];

    }


    // Get projected global annotations onto the given dicom plane. 
    projectGlobalAnnotations = (dicomPlane, globalAnnotations) => {


        // Project all points
        let projectedAnnotations = globalAnnotations.map( (annotation, idx) => {

            // Transform the data
            const worldCoord = plane.starkCoordsToWorld(annotation.points[0]);
            const distanceToPlane = plane.distanceFromPointToPlane(worldCoord, dicomPlane);

            // Add Project
            if (distanceToPlane <= ANATOMY_LABEL_THRESHOLD) {
                // Projections onto the DicomPLane occur within the dicom viewer. 
                // This function only filters, and passes world coord
                return {...annotation, points:[worldCoord], annotationIdx: idx};
            }

            return null;
        });

        return projectedAnnotations.filter(elem => elem !== null);

    }


    // ------ STATE HANDLING FUNCTIONS ------

    // Toggle open/closed the config editor menu
    toggleConfigMenu = () => {

        this.setState({ configEditorOpen: !this.state.configEditorOpen });
    }

    // Note: Only use this function for local updates! Broadcasts to other viewers.
    setCursorPosition = async (positionData) => {

        await this.setState({
            cursorPosition: {
                ...positionData,
                siblingBrowserCursor: false
            }
        });

        // await this.broadcastCursor3D(positionData['3D']);

    }

    // Save new annotation
    saveAnnotation = async (seriesName, sliceIdx, annotationData) => {

        // Save
        await this.props.saveAnnotation(seriesName, sliceIdx, annotationData);

        // Broadcast
        let newAnnotData;
        
        if (seriesName === 'global')
            newAnnotData = this.props.project.dicomAnnotations[seriesName];
        else
            newAnnotData = this.props.project.dicomAnnotations[seriesName][sliceIdx];

        this.broadcastAnnotations(seriesName, sliceIdx, newAnnotData);

    }

    // live updates when moving activatedPoints
    updateAnnotation = async (seriesName, sliceIdx, annotationIdx, newAnnotation) => {

        await this.props.updateAnnotation(seriesName, sliceIdx, annotationIdx, newAnnotation);

        // Uncomment this line to have continuous update across browsers
        // this.broadcastAnnotations(seriesName, sliceIdx, this.props.project.dicomAnnotations[seriesIdx][sliceIdx]);

    }

    deleteAnnotation = async (seriesName, sliceIdx, annotationIdx) => {
        
        // Delete
        await this.props.deleteAnnotation(seriesName, sliceIdx, annotationIdx);

        // Broadcast
        let newAnnotData;

        if (seriesName === 'global')
            newAnnotData = this.props.project.dicomAnnotations[seriesName];
        else
            newAnnotData = this.props.project.dicomAnnotations[seriesName][sliceIdx];

        this.broadcastAnnotations(seriesName, sliceIdx, newAnnotData);

    }

    displaceLabel3D = async (displacement, annotationIdx) => {

        // Get the annotation 
        let annotation = this.props.project.dicomAnnotations.global[annotationIdx];

        // Update the position 
        annotation.points[0] = vec3.add(annotation.points[0], displacement);

        // Save annotation
        await this.props.updateAnnotation('global', null, annotationIdx, annotation);

    }

    updateLabel3DText = async (annotationIdx, newText) => {

        // Get the annotation 
        let annotation = this.props.project.dicomAnnotations.global[annotationIdx];
        annotation.text = newText;

        // Update the annotation
        await this.props.updateAnnotation('global', null, annotationIdx, annotation);
    
        // Queue Save
        this.props.queueAnnotationSave();

        // Broadcast to siblings
        this.broadcastAnnotations('global', null, this.props.project.dicomAnnotations.global);

    }

    // Queue an injury save
    queueInjurySave = async (injuryLocation, timeout = 5000) => {

        // Broadcast the current injuries
        this.broadcastInjuries(injuryLocation);

        // Call parent
        this.props.queueInjurySave(timeout);

    }

    // Open new dicom viewer window
    newDicomViewerWindow = () => {

        // URL
        let url = '/dicom/' + this.props.project._id + '/' + this.state.sessionUUID;

        // TODO @Marcel: Remove Dev
        if (process.env.REACT_APP_DEV === 'true' || this.props.project.patient.name === 'dev') {
            url = '/dicom/dev/' + this.state.sessionUUID;
        }

        // Open
        let params = 'height=' + window.innerHeight + ',width=' + window.innerWidth + ',modal=yes,alwaysRaised=yes';
        let windowName = '__blank' + Math.floor(Math.random() * 100);
        window.open(url, windowName, params);

    }

    // Open new report generator window
    newReportWindow = async () => {

        if (this.props.inGenerator)
            return;

        // Fetch siblings 
        await this.identifySiblingInstances();


        // Check if report gen exists. 
        let windowExists = this.state.siblings.find(elem => {
            return elem.whoAmI === "ReportGeneratorPage";
        })

        if (windowExists) {
            toast.error("Linked report page already exists!");
            return;
        }

        // URL
        let url = '/report/' + this.props.project._id + '/' + this.state.sessionUUID;

        // TODO @Marcel: Remove Dev
        if (process.env.REACT_APP_DEV === 'true' || this.props.project.patient.name === 'dev') {
            url = '/report/dev/' + this.state.sessionUUID;
        }

        // Open
        let params = 'height=' + window.innerHeight + ',width=' + window.innerWidth + ',modal=yes,alwaysRaised=yes,toolbar=yes';
        let windowName = '__blank' + Math.floor(Math.random() * 100);
        window.open(url, windowName, params);
    }

    // Begin operation of setting anatomy placements
    beginAnatomySetting = () => {

        if (this.props.inGenerator)
            this.props.beginAnatomySetting();

        return;

    }

    // Wrapper for injury metric deletion / broadcast
    deleteInjuryMetric = (injuryLocation, injuryType, metricIdx, updateSummary = true) => {

        // Call parent 
        this.props.deleteInjuryMetric(injuryLocation, injuryType, metricIdx, updateSummary);

        // Broadcast
        this.broadcastInjuries(injuryLocation);

    }


    render = () => {


        return (

            <div>
                <HotKeys keyMap={this.hotkeyMap} handlers={this.hotkeyHandlers}>

                    {/* Dicom Viewer Header */}
                    {!this.props.inGenerator ?
                        <DicomHeader
                            project={this.props.project}
                            sessionUUID={this.state.sessionUUID}
                            siblings={this.state.siblings}
                            identifySiblingInstances={this.identifySiblingInstances}
                            shiftProjectIndex={this.shiftProjectIndex}
                            newDicomViewerWindow={this.newDicomViewerWindow}
                            newReportWindow={this.newReportWindow}

                        /> : ''}

                    {/* Dicom Viewer Toolbar (displayed within Header) */}
                    <DicomViewerToolbar
                        activeTool={this.state.activeTool}
                        viewerConfiguration={this.state.viewerConfiguration.configuration}
                        inGenerator = {this.props.inGenerator}
                        requestingAnatomy = {this.props.requestingAnatomy}
                        // Functions
                        onToolUpdate={this.setDicomViewerTool}
                        cancelCurrentOp={this.cancelCurrentOp}
                        setViewerConfiguration={this.updateViewerConfiguration}
                        activeRequest={this.state.activeRequest}
                        withinOperation = {Object.values(this.state.viewers).find(elem => (elem && elem.currentOperation.points))}
                        configEditorOpen={this.state.configEditorOpen}
                        toggleConfigMenu={this.toggleConfigMenu}
                        beginAnatomySetting = {this.beginAnatomySetting}
                        right={this.props.inGenerator ? "118px" : '118px'}
                    />

                    {/* Dicom Viewer Container */}
                    <div className="dicomViewerContainer" ref="dicomViewerContainer" style={{ width: this.props.inGenerator ? 'calc(30% + 2px)' : 'calc(100% + 2px)' }}>

                        {/* Viewer Default Configuration Menu */}
                        {this.state.configEditorOpen &&
                            <ViewerConfigMenu
                                user={this.props.user}
                                toggleConfigMenu={this.toggleConfigMenu}
                                saveDefaultConfiguration={this.props.saveDefaultConfiguration}
                            />
                        }

                        {/* For each viewer... */}
                        {Object.keys(this.state.viewers).map((viewerIdx) => {

                            let viewerData = this.state.viewers[viewerIdx];
                            const proj = this.props.project;

                            // Prepared for render ?
                            if (viewerData === null || !proj || proj.dicomData === undefined || !proj.dicomData[viewerData.seriesIdx])
                                return '';

                            let seriesNames = proj.dicomData.map((elem) => { return elem.seriesName });

                            let seriesName = proj.dicomData[viewerData.seriesIdx].seriesName;

                            // Injury Metrics
                            let injuryMetrics = this.props.injuryMetrics[seriesName] ? (this.props.injuryMetrics[seriesName][viewerData.sliceIdx] ? 
                                                this.props.injuryMetrics[seriesName][viewerData.sliceIdx] : []) : [];
                            
                            
                            // Injury metrics displayed? 
                            if (!viewerData.visibility.injuries)
                                injuryMetrics = [];


                            // NOTE: Filters are performed at the outter level, so that no operations involving filtered items can be performed w/in Viewer

                            // Filter out non-visible injury locations / types

                            // Filtering for viewer in Report Gen
                            if (this.props.inGenerator) {
                                injuryMetrics = injuryMetrics.filter(metric => {
                                    return (!this.props.blockedLocations.includes(metric.fullData.location) &&
                                        !this.props.blockedInjuryTypes.includes(metric.fullData.type) &&
                                        !this.props.blockedInjuries.find(elem => (elem.location === metric.fullData.location && elem.type === metric.fullData.type)))
                                })
                            }

                            // Filtering otherwise (recieved via Report Gen broadcast)
                            else {
                                injuryMetrics = injuryMetrics.filter(metric => {
                                    return (!this.state.blockedInjuryLocations.includes(metric.fullData.location) &&
                                        !this.state.blockedInjuryTypes.includes(metric.fullData.type) &&
                                        !this.state.blockedInjuries.find(elem => (elem.location === metric.fullData.location && elem.type === metric.fullData.type)))
                                })
                            }

                            // Filter hovered data from Report Editor
                            const hoveredEditorObjects = this.props.hoveredEditorObjects ? this.props.hoveredEditorObjects.filter( objectData => (objectData.seriesName === seriesName && objectData.sliceIdx === viewerData.sliceIdx)) : [];


                            // Filter / project global annotations 
                            // IMPORTANT: The global annotations passed to the DicomViewer are a subset of the annotations. 
                            // - By no means should project.dicomAnnotations.global be updated based on the index in this list, or the data in this list
                            
                            // NOTE: The global annotations in the DicomViewer have had the following data conversions performed: 
                            // - "Stark" coordinates to Dicom Global Coords (ratation and reflection in xy)
                            // - "annotationIdx" added to their data

                            // For these reasons, by do NOT update global annotations by setting their values based on the DicomViewer values. 
                            // Instead, pass a difference (see "displaceLabel3D()") in 3D Stark Coords to a function in Dicom Viewer Page, where the point will be updated based on "annotationIdx".

                            // This allows for smooth, planar transforms, from inherently 2D Dicom Viewers.

                            let globalAnnotations = [];
                            if (viewerData.visibility.placements)
                                globalAnnotations = (proj.dicomAnnotations && proj.dicomAnnotations.global) ? this.projectGlobalAnnotations(viewerData.plane, proj.dicomAnnotations.global) : [];


                            // Pass dicom viewer data
                            return (
                                <DicomViewer
                                    // Properties
                                    key = {viewerIdx} 
                                    viewerIdx = {viewerIdx}
                                    data = {viewerData}
                                    currentOperation = {viewerData.currentOperation}
                                    seriesData = {this.props.project.dicomData[viewerData.seriesIdx]}
                                    dicomImages = {this.props.dicomImages[seriesName] ? this.props.dicomImages[seriesName] : []}
                                    cursorPosition = {this.state.cursorPosition}

                                    loading = {!this.props.dicomImages[seriesName]}
                                    activeTool = {this.state.activeTool}
                                    activeRequest = {this.state.activeRequest}
                                    viewerConfiguration = {this.state.viewerConfiguration.configuration} // For recentering / canvas adjustment
                                    seriesNames = {seriesNames} // For series selection

                                    // Filter annotations
                                    annotations = { (this.props.project.dicomAnnotations &&
                                                    this.props.project.dicomAnnotations[seriesName] &&
                                                    this.props.project.dicomAnnotations[seriesName][viewerData.sliceIdx]) ? 
                                                    this.props.project.dicomAnnotations[seriesName][viewerData.sliceIdx] : []}
                                    globalAnnotations = { globalAnnotations }
                                    // Filtered injuries
                                    injuryMetrics = {injuryMetrics}

                                    // Hovered Editor data
                                    hoveredEditorObjects = {hoveredEditorObjects}
                                    
                                    // Functions
                                    setCursorPosition={this.setCursorPosition}
                                    updateViewerSeries={this.updateViewerSeries}
                                    updateViewerSlice={this.updateViewerSlice}
                                    updateViewer={this.updateViewer}
                                    saveAnnotation={this.saveAnnotation}
                                    updateAnnotation={this.updateAnnotation}
                                    deleteAnnotation={this.deleteAnnotation}
                                    displaceLabel3D = {this.displaceLabel3D}
                                    updateLabel3DText = {this.updateLabel3DText}
                                    updateInjuryMetric={this.props.updateInjuryMetric}
                                    deleteInjuryMetric = {this.deleteInjuryMetric}
                                    saveInjuriesToCloud={this.props.saveInjuriesToCloud}
                                    syncAnnotationsToCloud={() => this.props.syncAnnotationsToCloud(viewerIdx)}
                                    queueInjurySave={this.queueInjurySave}
                                    queueAnnotationSave={this.props.queueAnnotationSave}

                                    broadcastAnnotations={this.broadcastAnnotations}
                                    broadcastGlobalAnnotations={this.broadcastGlobalAnnotations}
                                    broadcastInjuries={this.broadcastInjuries}
                                    broadCastRequestedAnnotation={this.broadCastRequestedAnnotation}

                                    switchSeriesAxis={this.switchSeriesAxis}
                                />)
                        })}

                    </div>
                </HotKeys>
            </div>
        )
    }

}

export default withRouter(DicomViewerPage);