// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// utils
import formatDate from '../utilities/formatDate'

// Styling
import '../styles/header.css'
import HeaderButton from './HeaderButton';

// constants
import { hotkeys } from '../constants/hotkeys';

class Header extends Component {

    state = {

        // Profile menu
        profileOpened: false, 

        // Help menu
        hotkeysExpanded: true,

        helpBarOpen: false,


    }

    componentDidMount = async () => {

        //  Componnent did mount function

    }

    // ----- PROFILE MENU -----
    
    getProfileStyleModifier = () => {
        if (this.state.profileOpened)
            return {
                display: 'block'
            };

        else
            return {
                display: 'none'
            };
    }

    
    // ----- HELP BAR -----

    toggleHelpBar = () => {
        // call props function

        this.setState(prevState => ({
            helpBarOpen: !prevState.helpBarOpen
        }))
    }

    getHelpStyleModifier = (helpBarOpen) => {
        if (helpBarOpen)
            return {
                
            }
        else
            return {
                width: '0px'
            }
    }

    toggleMenuSection = (section) => {
        if (section === 'hotkeys') {
            this.setState({
                hotkeysExpanded: !this.state.hotkeysExpanded
            });
        }
    }

    getHotkeyMenuObject = () => {

        return (
            <div className='hotkeyContainer'>

                <h2 className='hotkeyHeader' onClick={() => this.toggleMenuSection('hotkeys')}>
                    {this.state.hotkeysExpanded ? "▾" : "▸"}&nbsp;&nbsp;&nbsp;Hotkeys
                </h2>

                {this.state.hotkeysExpanded ? <div>
                    <h3 className='hotkeySubHeader'>Annotation Tools</h3>
                    {hotkeys.annotationTools.map((k, idx) => this.getHotkeyObject(k, idx))}

                    <h3 className='hotkeySubHeader'>Measurement Tools</h3>
                    {hotkeys.measurementTools.map((k, idx) => this.getHotkeyObject(k, idx))}

                    <h3 className='hotkeySubHeader'>Other Tool Controls</h3>
                    {hotkeys.otherToolControls.map((k, idx) => this.getHotkeyObject(k, idx))}

                    <h3 className='hotkeySubHeader'>Change Viewer Configuration</h3>
                    {hotkeys.viewerConfigurations.map((k,idx) => this.getHotkeyObject(k, idx))}

                    <h3 className='hotkeySubHeader'>Switch DICOM Series Axis</h3>
                    {hotkeys.dicomSeriesAxis.map((k,idx) => this.getHotkeyObject(k, idx))}

                    <h3 className='hotkeySubHeader'>Page Operations</h3>
                    {hotkeys.pageOperations.map((k,idx) => this.getHotkeyObject(k, idx))}

                    <h3 className='hotkeySubHeader'>Miscellaneous</h3>
                    {hotkeys.miscellaneous.map((k,idx) => this.getHotkeyObject(k, idx))}
                    </div>
                : ''}

            </div>
        );


    }

    getHotkeyObject = (hotkey, idx=0) => {
        return (
            <div className='hotkeyRow' key={idx}>
                <div className='hotkeyDescription'>
                    {hotkey.icon ? <img src={hotkey.icon} alt={hotkey.description}/> : hotkey.description}
                </div>

                <div className='hotkeyPattern'>
                    {this.getHotkeyPatternObject(hotkey.pattern)}
                </div>
            </div>
        );
    }

    getHotkeyPatternObject = pattern => {
        const splitPattern = pattern.split('+');

        if (splitPattern.length === 0)
            throw new Error('Hotkey pattern must be a string')

        let result = <kbd>{splitPattern[0][0].toUpperCase() + (splitPattern[0].length > 1 ? splitPattern[0].substring(1) : '')}</kbd>

        for (let i = 1; i < splitPattern.length; i++) {
            result = <span>{result} + <kbd>{splitPattern[i][0].toUpperCase() + (splitPattern[i].length > 1 ? splitPattern[i].substring(1) : '')}</kbd></span>
        }

        return result;
    }

    render = () => {

        return (

            <div>

                <div className="header">

                    {/* Corporate logo */}
                    <img className="headerLogo" title={"Multus Project Options"} src="/resources/multus/logo.png" alt="logo" onClick={() => { 
                        this.setState({profileOpened: !this.state.profileOpened})
                    }}/>

                    {/* Profile container */}
                    <div className="profileContainer" style={this.getProfileStyleModifier()}>
                        {this.props.project ? <div>
                            <div className="profileUserName"> Patient : {this.props.project.patient.name} </div>
                            <div className='profileCase'> <b>Case</b>: {this.props.project.case} </div>
                            <div className='profileStudy'> <b>Study</b>: {this.props.project.studyType} </div>
                            <div className='profileDOB'> <b>DOB</b>: {formatDate(this.props.project.patient.dateOfBirth, 'yyyy-mm-dd')} </div>
                            <div className='profileDOE'> <b>DOE</b>: {formatDate(this.props.project.studyDate, 'yyyy-mm-dd')} </div>
                            <div className='profilePhys'> <b>Physician</b>: {this.props.project.attendingPhysician.name} </div>
                        </div>: ''}

                        <div className="profileButton logoutButton" onClick={() => console.log("TODO: Navigate to portal project.")}>
                            View Project
                        </div>
                    </div>


                    {/* Project Navigation Buttons */}
                    <div style={{ float: 'left' }}>

                        {/* Prev */}
                        <HeaderButton tooltip={"Previous Project"} icon={"/resources/icons/previous.png"} onButtonClick ={()=>this.props.shiftProjectIndex(-1)}/>    

                        {/* Next */}
                        <HeaderButton tooltip={"Next Project"} icon={"/resources/icons/next.png"} onButtonClick ={()=>this.props.shiftProjectIndex(1)}/>    

                    </div>

                    {/* Info Button */}
                    <div style={{ float: 'right' }} title='Help Menu'>
                        {/* Info */}
                        <HeaderButton tooltip={"Help"} icon={"/resources/icons/help3.png"} onButtonClick ={()=>this.toggleHelpBar()}/>    
                    </div>
                    
                    {/* Page Buttons */}
                    {this.props.children}
                    
                </div>

                <div className='helpBar' style={this.getHelpStyleModifier(this.state.helpBarOpen)}>
                    {this.getHotkeyMenuObject()}
                </div>

            </div>
        )
    }
}

export default withRouter(Header);