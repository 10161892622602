// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Select from 'react-select';
import Loading from 'react-loading';
import { HotKeys } from 'react-hotkeys';

// Components
import Canvas from '../../DicomCanvas';
import AnnotationTogglebar from '../SubComponents/AnnotationTogglebar';
import LabelTextArea from '../SubComponents/LabelTextArea';
import Label3D from '../SubComponents/Label3D';

// Styles
import "../../../styles/DicomViewer/dicomViewer.css";

// Utils
import { vec3, vec2 } from '../../../utilities/vectors';
import { canvasUtils } from '../../../utilities/canvas';
import { plane } from '../../../utilities/plane';
import mathUtils from '../../../utilities/mathUtils';

// Tools
import { AngleTool, IntersectAngleTool, LengthTool, Label2DTool, Label3DTool, ArrowTool, Rectangle2DTool, Circle2DTool, DeleteTool} from './tools';

// Helper functions
import { getActiveAnnotationPoints, getActiveInjuryPoints, updateHoveredData, updateHoveredObject} from './hovered';

// Constants
import { textSchemes } from '../../../constants/canvasConstants';
import { toolToType, typeToTool, globalAnnotationTypes } from '../../../constants/tools';

const measurementTypes = ['angle', 'length']


class DicomViewer extends Component {

    // Memory for delta computation
    prevMousePos = {
        x: 0,
        y: 0
    }

    state = {

        workspace: {
            x: 50, //this.props.dicomImages[this.props.data.sliceIdx]
            y: 250,
            width: 400
        },

        currentOperation: {

        },

        // Mouse over data
        hoveredPoints: [],
        activatedPoints: [],
        hoveredObject: [],

        // What button is held down?
        buttonDown: -1,

    }

    constructor(props) {
        super(props);

        // HOVERED HELPERS
        this.bindHoveredHelpers();

        // TOOL FUNCTIONS
        this.bindTools();

    }

    // #region Hotkeys

    hotkeyMap = {
        // viewer series 
        viewerSeries_axial: 'a',
        viewerSeries_sagittal: 's',
        viewerSeries_coronal: 'c',
    };

    hotkeyHandlers = {
        // viewer series 
        viewerSeries_axial: e => this.triggerHotkey(e, () => this.props.switchSeriesAxis(this.props.viewerIdx, 'ax')),
        viewerSeries_sagittal: e => this.triggerHotkey(e, () => this.props.switchSeriesAxis(this.props.viewerIdx, 'sag')),
        viewerSeries_coronal: e => this.triggerHotkey(e, () => this.props.switchSeriesAxis(this.props.viewerIdx, 'cor')),
    };

    triggerHotkey = (event, callback) => {
        event.stopPropagation();
        event.preventDefault();

        if (window.event)
            window.event.cancelBubble = true;

        callback();
    }

    // #endregion Hotkeys



    componentDidMount = async () => {

        // Center the workspace
        await this.centerWorkspace();

    }


    componentDidUpdate = async (prevProps, prevState) => {

        // Recenter workspace?
        if (prevProps.viewerConfiguration !== this.props.viewerConfiguration)
            this.centerWorkspace();
    }


    // ------ HELPER FUNCTION BINDING ------

    // Bind helper functions for hovered data
    bindHoveredHelpers = () => {
        this.getActiveAnnotationPoints = getActiveAnnotationPoints.bind(this);
        this.getActiveInjuryPoints = getActiveInjuryPoints.bind(this);
        this.updateHoveredData = updateHoveredData.bind(this);
        this.updateHoveredObject = updateHoveredObject.bind(this);
    }

    // Bind all tool functions form helper file
    bindTools = () => {

        this.Tools.Length = {
            handleMouseDown: LengthTool.handleMouseDown.bind(this),
            isComplete: LengthTool.isComplete.bind(this),
            updatePoint: LengthTool.updatePoint.bind(this),
            isWithin: LengthTool.isWithin.bind(this),
            getArea: LengthTool.getArea.bind(this),
        }       
        
        this.Tools.Angle = {
            handleMouseDown: AngleTool.handleMouseDown.bind(this),
            isComplete: AngleTool.isComplete.bind(this),
            updatePoint: AngleTool.updatePoint.bind(this),
            isWithin: AngleTool.isWithin.bind(this),
            getArea: AngleTool.getArea.bind(this),
        }

        this.Tools.IntersectAngle = {
            handleMouseDown: IntersectAngleTool.handleMouseDown.bind(this),
            isComplete: IntersectAngleTool.isComplete.bind(this),
            updatePoint: IntersectAngleTool.updatePoint.bind(this),
            isWithin: IntersectAngleTool.isWithin.bind(this),
            getArea: IntersectAngleTool.getArea.bind(this),
        }

        this.Tools.Rectangle = {
            handleMouseDown: Rectangle2DTool.handleMouseDown.bind(this),
            isComplete: Rectangle2DTool.isComplete.bind(this),
            updatePoint: Rectangle2DTool.updatePoint.bind(this),
            isWithin: Rectangle2DTool.isWithin.bind(this),
            getArea: Rectangle2DTool.getArea.bind(this),
        }

        this.Tools.Circle = {
            handleMouseDown: Circle2DTool.handleMouseDown.bind(this),
            handleMouseUp: Circle2DTool.handleMouseUp.bind(this),
            isComplete: Circle2DTool.isComplete.bind(this),
            updatePoint: Circle2DTool.updatePoint.bind(this),
            isWithin: Circle2DTool.isWithin.bind(this),
            getArea: Circle2DTool.getArea.bind(this),
        }

        this.Tools.Arrow = {
            handleMouseDown: ArrowTool.handleMouseDown.bind(this),
            isComplete: ArrowTool.isComplete.bind(this),
            updatePoint: ArrowTool.updatePoint.bind(this),
            isWithin: ArrowTool.isWithin.bind(this),
            getArea: ArrowTool.getArea.bind(this),
        }

        this.Tools.Label = {
            handleMouseDown: Label2DTool.handleMouseDown.bind(this),
            isComplete: Label2DTool.isComplete.bind(this),
            updatePoint: Label2DTool.updatePoint.bind(this),
            isWithin: Label2DTool.isWithin.bind(this),
            getArea: Label2DTool.getArea.bind(this),
        }

        this.Tools.Label3D = {
            handleMouseDown: Label3DTool.handleMouseDown.bind(this),
            isComplete: Label3DTool.isComplete.bind(this),
            updatePoint: Label3DTool.updatePoint.bind(this),
            isWithin: Label3DTool.isWithin.bind(this),
            getArea: Label3DTool.getArea.bind(this),
        }

        this.Tools.Delete = {
            handleMouseDown: DeleteTool.handleMouseDown.bind(this),
        }
    }


    // ------ WORKSPACE OPERATIONS ------

    // Workspace Centering
    centerWorkspace = async () => {

        // Center the workspace
        let dicomViewerDims = this.refs.dicomViewer.getBoundingClientRect();
        let left = dicomViewerDims.width / 2 - this.state.workspace.width / 2;
        let top = dicomViewerDims.height / 2 - this.state.workspace.width / 2;
        let initialWorkspace = this.state.workspace;
        initialWorkspace.x = left;
        initialWorkspace.y = top;

        await this.setState({
            workspace: initialWorkspace
        });

    }

    // Workspace Zooming
    zoomWorkspace = (zoomCenter, zoomMultiplier) => {

        let workspaceRect = this.refs.workspace.getBoundingClientRect();

        // get cursor location in terms of workspace percent
        let widthPercent_cursorLoc = (zoomCenter.x - workspaceRect.x) / workspaceRect.width;
        let heightPercent_cursorLoc = (zoomCenter.y - workspaceRect.y) / workspaceRect.height;

        this.setState(prevState => ({
            workspace: {
                ...prevState.workspace,
                x: this.state.workspace.x - this.state.workspace.width * ((zoomMultiplier - 1) * widthPercent_cursorLoc),
                y: this.state.workspace.y - workspaceRect.height * ((zoomMultiplier - 1) * heightPercent_cursorLoc),
                width: this.state.workspace.width * zoomMultiplier
            }
        }))

    }

    // Move workspace
    moveWorkspace = async (offset) => {

        await this.setState(prevState => ({
            workspace: {
                ...prevState.workspace,
                x: prevState.workspace.x + offset.x,
                y: prevState.workspace.y + offset.y
            }
        }))

    }

    // Void the viewer's cursor if needed
    voidCursor = () => {

        if (this.props.cursorPosition.viewerIdx !== this.props.viewerIdx)
            return;

        // Reset button state
        this.setState({ buttonDown: -1 });

        // Eliminate cursor position
        this.props.setCursorPosition({
            viewerIdx: -1,
            "2D": { x: 0, y: 0, z: 0 },
            "3D": { x: 0, y: 0, z: 0 }
        })
    }


    // ------ MOUSE EVENTS ------    

    // Workspace Scroll
    onWorkspaceMouseScroll = async (e) => {

        // Dicom Scroll and zoom handled by viewer!
        
    }

    onWorkspaceMouseDown = async (e) => {
        if (e.button !== 0)
            e.preventDefault(); 
    }

    onWorkspaceMouseMove = async (e) => {
        e.preventDefault();
    }


    onWorkspaceMouseEnter = (e) => {

        // Don't initiaze dicom panning
        if (this.state.buttonDown === 2)
            this.setState({ buttonDown: -1 });

    }

    // Set the workspace and worldspace cursor pos.
    setCursorPosition = (workspaceCoord) => {

        // Get workspace, and worldspace coords
        const worldSpaceCoord = this.workspaceTo3D(workspaceCoord);

        // Update hoved object
        if (this.props.activeTool === "Delete")
            this.updateHoveredObject(workspaceCoord);

        // Update hovered points / text boxes
        else 
            this.updateHoveredData(workspaceCoord);

        // Set position
        this.props.setCursorPosition({
            viewerIdx: this.props.viewerIdx,
            "2D": workspaceCoord,
            "3D": worldSpaceCoord
        })

    }

    // ------ VIEWER EVENT HANDLING ------
    // NOTE: Viewer events cover all event except workspace specific operations (such as panning)

    onViewerMouseScroll = async (e) => {

        let mouseCoord = { x: e.pageX, y: e.pageY };

        // Dicom Zoom
        if (e.shiftKey) {
            let zoomCenter = mouseCoord;
            let zoomMultiplier;
            
            if (e.deltaY === 0)
                zoomMultiplier = (e.deltaX > 0 ? 0.9 : 1.1);
            else
                zoomMultiplier = (e.deltaY > 0 ? 0.9 : 1.1);

            this.zoomWorkspace(zoomCenter, zoomMultiplier);
            return;
        }

        // Dicom Scroll
        else {
            let direction = (e.deltaY > 0 ? -1 : 1);
            let newIdx = this.props.data.sliceIdx + direction;

            if (newIdx < this.props.dicomImages.length && newIdx >= 0) {

                // Clear current slice data
                await this.setState({ 
                    hoveredPoints: [],
                    activatedPoints: [], 
                });                

                // Update slice
                await this.props.updateViewerSlice(this.props.viewerIdx, newIdx);

                // Update global cursor position
                await this.setCursorPosition(this.pageCoordsToWorkspace(mouseCoord));
            }

            return
        }

    }


    onViewerMouseDown = async (e) => {
        
        if (e.button !== 0)
            e.preventDefault();

        // Handle tool operations
        if (e.button === 0) {

            // Persist event?
            e.persist();

            //focus this viewer
            if (this.refs.dicomViewer) {
                this.refs.workspace.focus();
            }

            let handled = false;
            
            if (this.props.activeTool !== "Delete") {

                // Set any hovered points to active points
                await this.setActiveFromHovered();

            }

            // Handle tool operation
            if (this.state.activatedPoints.length === 0 && this.state.hoveredPoints.length === 0 && !handled)
                await this.handleToolMouseDown(e);

        }

        // Initialize Dicom Pan Location?
        else if (e.button === 2) {
            e.preventDefault();
            this.prevMousePos.x = e.pageX;
            this.prevMousePos.y = e.pageY;
        }

        this.setState({ buttonDown: e.button});

    }

    onViewerMouseRightClick = (e) => {
        e.preventDefault();
    }

    // Viewer Mouse Up
    onViewerMouseUp = (e) => {

        e.preventDefault();
        this.setState({ buttonDown: -1 });

        // Radial tool event handling
        if (this.props.currentOperation.dataType === "circle") {
            let workspaceCoord = this.pageCoordsToWorkspace({x: e.pageX, y: e.pageY});
            this.Tools.Circle.handleMouseUp(workspaceCoord);
        }

        // Clear the current active points
        if (this.state.activatedPoints.length > 0) {

            // Broadcasting / cloud save annotations 
            if (this.state.activatedPoints[0].location === 'Annotations') {                
                this.props.broadcastAnnotations(this.props.seriesData.seriesName, this.props.data.sliceIdx, this.props.annotations);
                this.props.syncAnnotationsToCloud();
            }

            // Broadcasting / cloud saving 
            else if (this.state.activatedPoints[0].location === 'GlobalAnnotations'){
                this.props.broadcastGlobalAnnotations();
                this.props.syncAnnotationsToCloud();
            }

            else if (this.state.activatedPoints[0].location === 'Injuries') {

                // Get the metric data
                let metricData = this.props.injuryMetrics[this.state.activatedPoints[0].listIdx];

                // We'll need to broadcast the annotations to the at a given location...
                let location = metricData.location;
                this.props.broadcastInjuries(location);

                // Cloud save injuries
                this.props.saveInjuriesToCloud();
                
            }
            

            this.setState({activatedPoints: []});
        }
    }


    onViewerMouseLeave = (e) => {

        // Reset the button down
        this.setState({ buttonDown: -1 });

        // Void the current cursor
        this.voidCursor();

    }

    onViewerMouseMove = async (e) => {

        if (this.props.loading)
            return;

        e.preventDefault();
        e.stopPropagation();

        // Get coords
        const pageCoord = { x: e.pageX, y: e.pageY }
        const workspaceCoord = this.pageCoordsToWorkspace(pageCoord);

        if (this.state.buttonDown === 2) {
            
            // Dicom Pan operation!
            const offset = {x: pageCoord.x - this.prevMousePos.x, y: pageCoord.y - this.prevMousePos.y};
            this.moveWorkspace(offset);

            this.prevMousePos.x = pageCoord.x;
            this.prevMousePos.y = pageCoord.y;

            return;
        }
    
        // Point Movement
        else if (this.state.buttonDown === 0) {

            // Move any current active points
            if (this.state.activatedPoints.length !== 0)
                await this.moveActivatedPoints(workspaceCoord);

            // Update  the cursor position
            await this.setCursorPosition(workspaceCoord);

        }

        else 
            // Update  the cursor position
            await this.setCursorPosition(workspaceCoord);

    }


    // ------ ACTIVATED POINT HANDLING ------

    // Move points action
    moveActivatedPoints = async (workspaceCoord) => {

        // For each point 
        for (let activatedPoint of this.state.activatedPoints) {

            // Annotations
            if (activatedPoint.location === "Annotations") 
                // Update project annotation
                this.genericUpdateAnnotationPoint(activatedPoint, workspaceCoord);

            // Global Annotations
            if (activatedPoint.location === 'GlobalAnnotations')
                // Update project annotations by difference projection
                this.genericUpdateGlobalPoint(activatedPoint, workspaceCoord);

            // Injuries
            else if (activatedPoint.location === 'Injuries')
                // Update injury metric
                this.genericUpdateInjuryPoint(activatedPoint, workspaceCoord);

            // Current Operation
            else if (activatedPoint.location === "CurrentOp") {

                // Measurement are the only current op that should be editable!
                let opType = this.props.currentOperation.dataType;
                if (!measurementTypes.includes(opType))
                    return;

                let toolType = typeToTool[opType];
                if (Object.keys(this.Tools).includes(toolType))
                    await this.Tools[toolType].updatePoint(activatedPoint, workspaceCoord);

            }
        }
    }

    // Set activated points based on hovered points
    setActiveFromHovered = () => {

        this.setState(prevState => ({

            hoveredPoints: [],
            activatedPoints: prevState.hoveredPoints

        }))
    }


    // Set active points manually
    setActivePoints = (activePoints) => {

        this.setState({ activatedPoints: activePoints });

    }



    // ------ TOOL HANDLING OPERATIONS ------

    // Save the current opperation to annotations
    saveCurrentOperation = async () => {

        // Send response for requested metric? 
        if (this.props.activeRequest !== null) {

            let broadcastData = {...this.props.currentOperation};
            broadcastData.seriesIdx = this.props.data.seriesIdx;
            broadcastData.sliceIdx = this.props.data.sliceIdx;

            // Send metric
            this.props.broadCastRequestedAnnotation(broadcastData);

            // Clear the current operation
            this.updateCurrentOperation({});

            return;
        }

        // Measurement?: Don't save and don't clear
        if (measurementTypes.includes(this.props.currentOperation.dataType))
            return;


        // Global annotation?
        else if (globalAnnotationTypes.includes(this.props.currentOperation.dataType)) 
            await this.props.saveAnnotation('global', null, this.props.currentOperation);
        
        // Annotation: Save the operation to the project 
        else
            await this.props.saveAnnotation(this.props.seriesData.seriesName, this.props.data.sliceIdx, this.props.currentOperation);

        // Clear the current operation
        this.updateCurrentOperation({});

    }

    // Initialize an operation (click)
    initializeCurrentOperation = async (initialPoint) => {

        let currentOp = {dataType: toolToType[this.props.activeTool], points: [initialPoint]};

        // Intersect angle needs to set the type, and a temp flag
        if (this.props.activeTool === 'IntersectAngle')
            currentOp.intersectAngle = true;

        await this.updateCurrentOperation(currentOp);

    }

    // Update the current viewer operation
    updateCurrentOperation = async (newOp) => {

        let newViewer = {...this.props.data};
        newViewer.currentOperation = newOp;
        await this.props.updateViewer(this.props.viewerIdx, newViewer);

    }


    // Tool Mouse Down
    handleToolMouseDown = async (e) => {

        // Get workspace coord 
        let workspaceCoord = this.pageCoordsToWorkspace({ x: e.pageX, y: e.pageY });

        // Deletion operation? 
        if (this.props.activeTool === "Delete") {
            await this.Tools.Delete.handleMouseDown(e);
            return;
        }

        // New operation?
        if (this.props.currentOperation.dataType !== toolToType[this.props.activeTool] && this.props.activeTool.length > 0) 
            // Clear previous operation
            await this.updateCurrentOperation({});

        // Handle mouse down through active tool
        let opType = this.props.activeTool;

        if (Object.keys(this.Tools).includes(opType))
            this.Tools[opType].handleMouseDown(workspaceCoord);

        return;

    }


    genericUpdateAnnotationPoint = async (pointData, newCoord) => {

        // Get the current annotation
        // let newAnnotation = this.props.seriesData.slices[this.props.data.sliceIdx].annotations[pointData.listIdx];
        let newAnnotation = this.props.annotations[pointData.listIdx];

        // Update the point in question

        if (pointData.type === "Radius")
            newAnnotation.radius = vec2.distance(newAnnotation.points[0], newCoord);

        else if (pointData.type === "Point")
            newAnnotation.points[pointData.pointIdx] = newCoord;

        else if (pointData.type === "PointProjection" && newAnnotation.dataType === 'rectangle') {
            // Projected points are coreners not actually specified by the rectangle...
            // ... so move the corners specified accordingly.
            let xPointIdx = pointData.projection.x;
            let yPointIdx = pointData.projection.y;
            newAnnotation.points[xPointIdx].x = newCoord.x;
            newAnnotation.points[yPointIdx].y = newCoord.y;
        }

        // Update the annotation in project data
        await this.props.updateAnnotation(this.props.seriesData.seriesName, this.props.data.sliceIdx, pointData.listIdx, newAnnotation);

        return;

    }

    // Update a corrdinate in 3D annotation space, based on the newCoord (workspace)
    genericUpdateGlobalPoint = async (pointData, newCoord) => {

        // Get the annotation point, (as projected onto this slice)
        const annotation = this.props.globalAnnotations[pointData.objectIdx];

        // Get the old projected coord
        const prevWorkspaceCoord = plane.worldToPlane(annotation.points[pointData.pointIdx], this.props.data.plane);

        // Get the new and old worldspace(stark) coords
        let prevWorld = plane.planeToWorld(prevWorkspaceCoord, this.props.data.plane);
        prevWorld = plane.worldToStarkCoords(prevWorld); 
        let newWorld = plane.planeToWorld(newCoord, this.props.data.plane);
        newWorld = plane.worldToStarkCoords(newWorld); 

        // Calc diff.
        const worldDisplacement = vec3.subtract(newWorld, prevWorld);

        // Aply the displacement to the label!
        this.props.displaceLabel3D(worldDisplacement, annotation.annotationIdx);

    }


    // Note @Marcel: Potentially merge with annotation update, if data similarities arrise. 
    genericUpdateInjuryPoint = async (pointData, newCoord) => {

        // Get the metric data
        let metricData = this.props.injuryMetrics[pointData.listIdx];

        // Update the metric
        let metric = {...metricData.metric};
        metric.points = [...metric.points];

        if (pointData.type === 'Point') 
            metric.points[pointData.pointIdx] = newCoord;

        else if (pointData.type === "Radius") 
            metric.radius = vec2.distance(metric.points[0], newCoord);

        else if (pointData.type === "PointProjection" && metric.dataType === 'rectangle') {
            // Projected points are coreners not actually specified by the rectangle...
            // ... so move the corners specified accordingly.
            let xPointIdx = pointData.projection.x;
            let yPointIdx = pointData.projection.y;
            metric.points[xPointIdx].x = newCoord.x;
            metric.points[yPointIdx].y = newCoord.y;
        }

        

        // Recompute measure? 

        // Angle
        if (metric.dataType === 'angle') 
            metric.value = await mathUtils.pointsToAngle(metric.points);

        else if (metric.dataType === 'length') {

            // Recompute distance
            const p1World = this.workspaceTo3D(metric.points[0]);
            const p2World = this.workspaceTo3D(metric.points[1]);
            const length = vec3.distance(p1World, p2World);
            metric.value = length;

        }

        // Update metric in state
        await this.props.updateInjuryMetric(metricData.location, metricData.objectIdx, metricData.metricIdx, metric, true);

    }


    Tools = {

        // Measurement
        "Length": this.LengthTool,
        "Angle": this.AngleTool,
        "IntersectAngle": this.IntersectAngleTool,

        // Annotation
        "Label": this.Label2DTool,
        "Label3D": this.Label3DTool,
        "Rectangle": this.Rectangle2DTool,
        "Circle": this.Circle2DTool,
        "Arrow": this.ArrowTool,
        "Delete": this.DeleteTool,

    }


    // Tool Helper functions

    operationActive = () => {

        // Is the current operation hovered over? 
        if (this.state.activatedPoints.find(elem => elem.location === "CurrentOp") !== undefined)
            return true;
        
        else if (this.state.hoveredPoints.find(elem => elem.location === "CurrentOp") !== undefined)
            return true;
        
        else
            return false;
    }

    // Helper to see if a tool operation is finished
    operationIsComplete = (operationData) => {

        let toolType = typeToTool[operationData.dataType];

        if (Object.keys(this.Tools).includes(toolType))
            return this.Tools[toolType].isComplete(operationData);

        else 
            return true;

    }

    handleToolMouseDrag = async (e) => {

    }


    // ------ SERIES DROPDOWN ------

    getSeriesDropdownMenu = () => {

        let seriesList = this.props.seriesNames.map((elem, idx) => { return { label: elem, value: idx } });

        return (
            <div style={{ position: "absolute" }} 
                onMouseMove={this.onSubComponentMouseMove}
                onMouseDown={(e) => e.stopPropagation()}
                title={ this.props.seriesData.seriesName + " (" + (this.props.data.sliceIdx + 1) + ")"}
            >
                <Select
                    ref="dicomSeriesSelect"
                    key="dicomSeriesSelect"
                    className="dicomSeriesSelect-container"
                    classNamePrefix="dicomSeriesSelect"
                    options={seriesList}
                    onChange={this.onSeriesDropdownChange}
                    value={{ label: this.props.seriesData.seriesName + " (" + (this.props.data.sliceIdx + 1) + ")", value: this.props.data.seriesIdx }}
                />
            </div>
        )
    }

    onSeriesDropdownChange = (selectedOption) => {

        // Update the dicom series displayed
        this.props.updateViewerSeries(this.props.viewerIdx, this.props.seriesNames[selectedOption.value]);
    }

    // Mouse move overried
    onSubComponentMouseMove = (e) => {
        e.stopPropagation();
        this.voidCursor();
    }


    // ------ IMAGE SWITCHING OPERATIONS ------

    // Update the aspect ratio when a new image loads.
    updateAspectRatio = async () => {

        let imageRef = this.refs.activeDicomImage;
        let aspectRatio = imageRef.naturalWidth / imageRef.naturalHeight;

        await this.setState(prevState => ({
            workspace: {
                ...prevState.workspace,
                aspectRatio: aspectRatio
            }
        }))

    }

    // ------ VIEW TRANSFORMATIONS  ------

    pageCoordsToWorkspace = (pageCoord) => {

        let viewerRect = this.refs.dicomViewer.getBoundingClientRect();
        let Xw = (pageCoord.x - viewerRect.x - this.state.workspace.x) / this.state.workspace.width;
        let Yw = (pageCoord.y - viewerRect.y - this.state.workspace.y) / (this.state.workspace.width / this.state.workspace.aspectRatio);
        return { x: Xw, y: Yw };
    }

    workspaceTo3D = (workspaceCoord) => {

        if (this.props.data.plane)
            return plane.noralizedPlaneToWorld(workspaceCoord, this.props.data.plane)

        else 
            return null;

    }

    worldToWorkspace = (worldCoord) => {

        // Project worldspace coord onto plane

        if (this.props.data.plane) 
            return plane.worldToPlane(worldCoord, this.props.data.plane);

        else
            return null;
        
    }

    workspaceToViewer = (location) => {

        let x = (location.x * this.state.workspace.width) + this.state.workspace.x;
        let y = (location.y * (this.state.workspace.width / this.state.workspace.aspectRatio)) + this.state.workspace.y;

        return { x: x, y: y };

    }


    // ------ CANVAS PROPERTY HANDLING ------


    getAnnotations = () => {

        // Annotations turned off?
        if (!this.props.data.visibility.annotations)
            return [];

        // Return annotations passed from props
        if (!this.props.annotations) 
            return [];
        else
            return this.props.annotations;

    }

    // Get the dimensions of the box needed to fit the given text.
    getLabel2DDimensions = (text) => {

        // Initialize Render Params

        let textParams = textSchemes.labelText;

        // Font parameters
        let fontSize = textParams.fontSize;
        let fontFamily = textParams.fontFamily;
        let fontStyle = textParams.fontStyle;
        let font = fontStyle + " " + fontSize + "px " + fontFamily;

        // Box parameters
        let backgroundMargin = textParams.backgroundMargin;
        let maxWidth = textParams.maxWidth;
        let lineHeight = textParams.lineHeight;

        // Get context from dicom canvas
        let ctx = this.refs.dicomCanvas.refs.mainCanvas.getContext('2d');

        // Set context font
        ctx.font = font;

        // Split the lines based on maxWidth
        let lines = canvasUtils.getWrappedLines(ctx, text, maxWidth);

        // Compute dimensions of box needed. 
        let boxDims = canvasUtils.getTextBoxDims(ctx, lines, text, fontSize, lineHeight, maxWidth, backgroundMargin);

        return [Math.ceil(boxDims[0]), Math.ceil(boxDims[1])];
    }


    // ------ ANNOTATION EDITOR HANDLERS ------

    updateAnnotationText = async (annotationIdx, newText) => {

        // Get new display dims
        const displayDims = this.getLabel2DDimensions(newText);

        // Update the annotation text
        let annotations = this.getAnnotations();
        let annotation = annotations[annotationIdx];
        annotation.text = newText;
        
        // Update display dimensions
        annotation.width = displayDims[0];
        annotation.height = displayDims[1];

        await this.props.updateAnnotation(this.props.seriesData.seriesName, this.props.data.sliceIdx, annotationIdx, annotation);
    
        // Broadcast to siblings
        this.props.broadcastAnnotations(this.props.seriesData.seriesName, this.props.data.sliceIdx, this.props.annotations);

        // Queue annotation save
        this.props.queueAnnotationSave();
        
    }

    // Delete annotation at the given index
    deleteAnnotation = async (annotationIdx, global=false) => {

        // Global? 
        if (global)
            await this.props.deleteAnnotation('global', null, annotationIdx);
        else
            await this.props.deleteAnnotation(this.props.seriesData.seriesName, this.props.data.sliceIdx, annotationIdx);

        // Clear all annotation references! (the indices have changed).
        await this.setState({
            hoveredObject: {},
            hoveredPoints: [],
            activatedPoints: [],
        });

    }

    

    updateInjuryText = async (metricData, newText) => {
        
        // Update metric text
        let metric = metricData.metric;
        metric.text = newText;

        // Update display dimensions 
        const displayDims = this.getLabel2DDimensions(newText);
        
        // Update display dimensions
        metric.width = displayDims[0];
        metric.height = displayDims[1];

        // Update injury metric
        await this.props.updateInjuryMetric(metricData.location, metricData.objectIdx, metricData.metricIdx, metric, true);

        // Queue cloud save
        this.props.queueInjurySave(metricData.location);

    }
    

    updateVisibilities = (name) => {

        let newViewer = {...this.props.data};
        newViewer.visibility[name] = !newViewer.visibility[name];
        this.props.updateViewer(this.props.viewerIdx, newViewer);

    }



    render = () => {

        return (
            <div>
                <HotKeys keyMap={this.hotkeyMap} handlers={this.hotkeyHandlers}> 

                    {/* Viewer Container */}
                    <div className={"dicomViewer" + (this.props.loading? " dicomViewerLoading" : "")} ref="dicomViewer" 
                        style={{
                            left: this.props.data.left,
                            top: this.props.data.top,
                            width: this.props.data.width,
                            height: this.props.data.height,
                            zIndex: 1,
                        }}

                        // Viewer mouse events
                        onWheel = {this.onViewerMouseScroll.bind(this)}
                        onMouseDown = {this.onViewerMouseDown.bind(this)}
                        onContextMenu={this.onViewerMouseRightClick.bind(this)}
                        onMouseMove = {this.onViewerMouseMove.bind(this)}
                        onMouseUp = {this.onViewerMouseUp.bind(this)}
                        onMouseLeave = {this.onViewerMouseLeave.bind(this)}
                    >

                        {/* Loading Icon */}
                        {this.props.loading && 
                        <div className="viewerLoadingContainer">    
                            <div className="viewerLoadingIcon">
                                <Loading type="spin" color="rgba(0,240,240,0.8)" height="120px" width="120px" />
                            </div>
                        </div> }


                        {/* Series selection dropdown menu */}
                        {this.getSeriesDropdownMenu()}

                        {/* Annotation toggle bar */}
                        <AnnotationTogglebar 
                            visibilities={this.props.data.visibility} 
                            updateVisibilities={this.updateVisibilities}
                            onMouseMove = {this.onSubComponentMouseMove}
                        />

                        {/* Workspace (image location and width)*/}
                        <div id="workspace" className='workspace' ref={"workspace"} style={{
                                left: this.state.workspace.x,
                                top: this.state.workspace.y,
                                width: this.state.workspace.width,
                            }}

                            // mouse events
                            onWheel={this.onWorkspaceMouseScroll.bind(this)}
                            onMouseDown={this.onWorkspaceMouseDown.bind(this)}
                            onMouseMove={this.onWorkspaceMouseMove.bind(this)}
                            onMouseEnter={this.onWorkspaceMouseEnter.bind(this)}
                            onDragStart={(e)=> e.preventDefault()}
                        >

                            {/* Dicom Image */}
                            {!this.props.loading ?
                                <img src={this.props.dicomImages[this.props.data.sliceIdx] ? this.props.dicomImages[this.props.data.sliceIdx] : ""}
                                    className="dicomImage"
                                    ref="activeDicomImage"
                                    onLoad={this.updateAspectRatio}
                                    alt={this.props.seriesData.seriesName + ": " + this.props.data.sliceIdx}
                                /> 
                            : ''}


                        </div>

                        {/* Render annotations as TextArea */}

                        {!this.props.loading && this.getAnnotations().map( ( annotation, idx ) => {

                            if (annotation.dataType !== 'label')
                                return '';

                            const pageAnchor = this.workspaceToViewer(annotation.points[0]);

                            return <LabelTextArea
                                    key={idx}
                                    annotationText={annotation.text}
                                    editable = {(this.state.activatedPoints.length === 0 && this.state.hoveredPoints.length === 0) ? true : false}
                                    isInjury = {false}
                                    left={pageAnchor.x}
                                    top={pageAnchor.y}
                                    width={annotation.width}
                                    height={annotation.height}
                                    deleteActive={this.props.activeTool === 'Delete'}
                                    voidCursor={this.voidCursor}
                                    setText={(newText) => this.updateAnnotationText(idx, newText)}
                                    deleteLabel = {(e) => this.deleteAnnotation(idx)}

                                    />

                        })}

                        {/* Injury Label TextAreas */}
                        {!this.props.loading && this.props.injuryMetrics.map( ( metricData, idx ) => {

                            if (metricData.metric.dataType !== 'label')
                                return '';

                            const pageAnchor = this.workspaceToViewer(metricData.metric.points[0]);

                            const editorHovered = (this.props.hoveredEditorObjects[0] && 
                            this.props.hoveredEditorObjects[0].location === "Injuries" && 
                            this.props.hoveredEditorObjects[0].injuryLocation === metricData.location && 
                            this.props.hoveredEditorObjects[0].injuryType === metricData.fullData.type && 
                            this.props.hoveredEditorObjects[0].metricIdx === metricData.metricIdx)

                            return <LabelTextArea
                                    key={idx}
                                    annotationText={metricData.metric.text}
                                    editable = {(this.state.activatedPoints.length === 0 && this.state.hoveredPoints.length === 0) ? true : false}
                                    left={pageAnchor.x}
                                    top={pageAnchor.y}
                                    width={metricData.metric.width}
                                    height={metricData.metric.height}
                                    deleteActive={this.props.activeTool === 'Delete'}
                                    editorHovered={editorHovered}
                                    indicatorText={metricData.fullData.type}
                                    voidCursor={this.voidCursor}
                                    setText={(newText) => this.updateInjuryText(metricData, newText)}
                                    deleteLabel = {(e) => this.props.deleteInjuryMetric(metricData.location, metricData.fullData.type, metricData.metricIdx)}
                                    />

                        })}


                        {/* 3D Labels */}
                        {!this.props.loading && this.props.globalAnnotations.map( (annotation, idx) => {
                            
                            // Project onto workspace
                            const workspaceCoord = plane.worldToPlane(annotation.points[0], this.props.data.plane);
                            const anchor = this.workspaceToViewer(workspaceCoord);
                            
                            return <Label3D
                                key={idx}
                                annotation = {annotation}
                                center = {anchor}
                                activeDrag = {this.state.activatedPoints.length >= 1 && this.state.activatedPoints[0].location === 'GlobalAnnotations' && this.state.activatedPoints[0].objectIdx === idx}
                                deleteActive={this.props.activeTool === 'Delete'}

                                onSubComponentMouseMove = {this.onSubComponentMouseMove}
                                setText = {this.props.updateLabel3DText}
                                setActivePoint = {() => {this.setActivePoints([ { location: 'GlobalAnnotations', objectIdx: idx, pointIdx: 0} ])}}
                                deleteAnnotation = {() => this.deleteAnnotation(annotation.annotationIdx, true)}
                            />
                                
                            

                        } )}

                        {/* Rendering Canvas */}
                        <Canvas
                            ref="dicomCanvas"
                            workspace={this.state.workspace}

                            cursorPosition={this.props.cursorPosition}
                            globalCursorPosition={this.worldToWorkspace(this.props.cursorPosition["3D"])}
                            sliceIntersection={this.props.data.sliceIntersection}
                            hoveredPoints={this.state.hoveredPoints}
                            activatedPoints={this.state.activatedPoints}
                            hoveredObject={this.state.hoveredObject}
                            hoveredEditorObjects={this.props.hoveredEditorObjects}

                            visibilities={this.props.data.visibility}
                            annotations={this.getAnnotations()}
                            injuries={this.props.injuryMetrics}
                            renderLabels={false}
                            currentOperation={this.props.currentOperation}
                            globalAnnotations={[]} // Blank when not rendering
                            // globalAnnotations={this.props.globalAnnotations.map(a => {return {...a, points: a.points.map(point => plane.worldToPlane(point, this.props.data.plane))}})}

                            inFocus={this.props.cursorPosition.viewerIdx === this.props.viewerIdx}
                            showGlobalCursor={this.props.cursorPosition.viewerIdx >= 0 || this.props.cursorPosition.siblingBrowserCursor}
                            viewerConfiguration={this.props.viewerConfiguration} // For canvas resizing

                            hotkeyMap={this.hotkeyMap}
                            hotkeyHandlers={this.hotkeyHandlers}
                            switchSeriesAxis={this.props.switchSeriesAxis}
                        />
                        
                    </div>
                </HotKeys>
            </div>
        )
    }

}


DicomViewer.defaultProps = {
    left: 0,
    top: 0,
    width: "50%",
    height: "50%"
}

export default DicomViewer;