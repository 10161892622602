// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

// #region new

export const getInjurySummary = (injuryType, injury) => {
    // Fill the template
    try {

        // Compile properties
        let properties = {};
        for (let property of injuryType.properties) {
            try {
                let val = injury[property.name];

                if (val === "None" || val === "null" || val === "N/A")
                    val = null;

                if (val === "true" || val === "True")
                    val = true;

                if (val === "false" || val === "False")
                    val = false;

                properties[property.name] = val;
            }
            catch (err) {
                throw new Error('could not process property "' + property.name + '"');
            }
        }

        // Compile metrics
        let metrics = {};
        for (let metric of injuryType.metrics) {
            for (let injMetric of injury.rationale.metrics) {
                if (injMetric.name === metric.name) {
                    try {
                        if (injMetric.value && !isNaN(injMetric.value))
                            metrics[metric.name] = injMetric.value.toFixed(1);
                        else
                            metrics[metric.name] = '<' + metric.name + '>';
                    }
                    catch (err) {
                        throw new Error('could not process metric "' + metric.name + '": ' + err);
                    }
                }
            }
        }

        // eslint-disable-next-line
        const summary = eval(injuryType.template);
        return summary;

    }
    catch (err) {
        throw new Error('Error deconstructing injury object with template: ' + err.message);
    }
}


// #endregion new


// #region old

// main function.
// calls recursive functions to analyze the injury template, verifying with the given injury object
export const getInjuryText = (injuryObj, template) => {

    try {
        let injuryText = processVariablesInTemplate(injuryObj, template); // process variables
        injuryText = processExpressionsInTemplate(injuryText); // process expressions

        return injuryText;
    }
    catch (err) {
        throw new Error('Error deconstructing injury object with template: ' + err.message);
    }
}


// returns array of idx where the substr is found in string s
const findAllInString = (s, substr) => {

    try {
        let arr = [];
        let idx = s.indexOf(substr);

        while (idx !== -1) {
            arr.push(idx);
            let newIdx = s.substring(idx + substr.length).indexOf(substr);
            idx = newIdx === -1 ? -1 : newIdx + idx + substr.length;
        }

        return arr;
    }
    catch (err) {
        throw new Error(err.message + ' (in findAllInString())');
    }
}

// recursively analyze conditional phrase
const analyzeCondition = rawCondition => {

    // returns arr object if it has the proper count.  Throws error otherwise.
    let verifyElementCount = (arr, cnt) => {
        if (!arr.length === cnt)
            throw new Error('Invalid condition.');

        return arr
    }

    // trim and convert to number or bool, else return string
    let refineType = arr => {
        return arr.map(elem => {
            try {

                if (typeof (elem) === 'string') {
                    elem = elem.trim();
                    if (elem.length === 0)
                        throw new Error('Condition is incomplete.')
                }

                if (!isNaN(elem))
                    return Number(elem);
                else if (elem === 'true' || elem === 'True')
                    return true;
                else if (elem === 'false' || elem === 'False')
                    return false;
                else
                    return elem;
            }
            catch (err) {
                throw err;
            }
        });
    }

    // analyze condition
    try {
        let splitCondition, condition;

        condition = rawCondition.trim();

        // check for '!' as inverter
        if (condition[0] === '!') {

            if (condition[1] === '(' && condition[condition.length - 1] === ')') {
                return !analyzeCondition(condition.substring(2, condition.length - 1));
            }
            else if (condition[1] === '(' || condition[condition.length - 1] === ')') {
                throw new Error('Parenthesis mismatch in condition "' + condition + '"');
            }
            else {
                return !analyzeCondition(condition.substring(1));
            }
        }

        // split multiple conditions that are separated by && or ||
        if (condition.indexOf('&&') !== -1) {
            let resultList = condition.split('&&').map(cond => { return analyzeCondition(cond); });
            return resultList.filter(elem => elem === true).length === resultList.length;
        }
        else if (condition.indexOf('||') !== -1) {
            let resultList = condition.split('||').map(cond => { return analyzeCondition(cond); });
            return resultList.filter(elem => elem === true).length > 0;
        }
        else if (condition.indexOf('&') !== -1) {
            throw new Error('Mismatched "&", they must be in a pair.  Use "&&" for AND, "||" for OR.')                
        }
        else if (condition.indexOf('|') !== -1) {
            throw new Error('Mismatched "|", they must be in a pair.  Use "&&" for AND, "||" for OR.')            
        }

        // convert booleans
        if (condition === true || condition === 'true') {
            return true;
        }
        else if (condition === false || condition === 'false') {
            return false;
        }

        // find comparison operators and return the comparison value accordingly
        if (condition.indexOf('!=') !== -1) {
            splitCondition = verifyElementCount(refineType(condition.split('!='), 2));
            return splitCondition[0] !== splitCondition[1];
        }
        else if (condition.indexOf('==') !== -1) {
            splitCondition = verifyElementCount(refineType(condition.split('=='), 2));
            return splitCondition[0] === splitCondition[1];
        }
        else if (condition.indexOf('<=') !== -1) {
            splitCondition = verifyElementCount(refineType(condition.split('<='), 2));
            return splitCondition[0] <= splitCondition[1];
        }
        else if (condition.indexOf('>=') !== -1) {
            splitCondition = verifyElementCount(refineType(condition.split('>='), 2));
            return splitCondition[0] >= splitCondition[1];
        }
        else if (condition.indexOf('<') !== -1) {
            splitCondition = verifyElementCount(refineType(condition.split('<'), 2));
            return splitCondition[0] < splitCondition[1];
        }
        else if (condition.indexOf('>') !== -1) {
            splitCondition = verifyElementCount(refineType(condition.split('>'), 2));
            return splitCondition[0] > splitCondition[1];
        }
        else {
            throw new Error('Unknown comparison operator or value')
        }
    }
    catch (err) {
        throw err;
    }
}

// analyze individual expression
// at this level, all variables have been replaced and there are no nested expressions
// expr is a teriary statement (( condition ? resIfTrue : resIfFalse ))
const processExpression = expr => {

    // split into condition, result if true, and result if false
    let splitExpression = expr.split('?');

    if (splitExpression.length === 1)
        throw new Error('No "?" found in the expression "' + expr + '"');

    let splitPredicate = splitExpression[1].split(':');

    if (splitPredicate.length === 1)
        throw new Error('No ":" found in the expression "' + expr + '"');

    let rawCondition = splitExpression[0].trim();
    let resIfTrue = splitPredicate[0].trim();
    let resIfFalse = splitPredicate[1].trim();

    try {
        let result = analyzeCondition(rawCondition) ? resIfTrue : resIfFalse;
        return result;
    }
    catch (err) {
        throw new Error('Error analyzing condition: ' + rawCondition + '\n' + err.message);
    }
}

// returns inner most expression
// expressions are surrounded by double parentheses - (( expression ))
const findFirstInnerExpression = text => {

    let leftParens = findAllInString(text, '((');
    let rightParens = findAllInString(text, '))');

    if (leftParens.length !== rightParens.length)
        throw new Error('Parentheses mismatch. Make sure all expressions are surrounded by double parentheses.\nie. (( expression ))');

    if (leftParens.length === 0)
        return undefined;

    let idxLeft = -1;
    let idxRight = rightParens[0];

    for (let i = 0; i < leftParens.length; i++) {
        if (rightParens[0] < leftParens[i]) {
            idxLeft = leftParens[i - 1];
            break;
        }
    }

    if (idxLeft === -1)
        idxLeft = leftParens[leftParens.length - 1];

    return {
        txtBefore: text.substring(0, idxLeft),
        expression: text.substring(idxLeft + 2, idxRight),
        txtAfter: text.substring(idxRight + 2)
    };
}

// main function for processing expressions
const processExpressionsInTemplate = text => {

    try {
        let nextExpr = findFirstInnerExpression(text);

        // Case no expressions: 
        if (nextExpr === undefined)
            return text;

        let txtResult = '';

        while (nextExpr !== undefined) {
            txtResult = nextExpr.txtBefore;
            txtResult += processExpression(nextExpr.expression);
            txtResult += nextExpr.txtAfter;

            // find next expression in remaining string
            nextExpr = findFirstInnerExpression(txtResult);
        }

        return txtResult;
    }
    catch (err) {
        let msg = 'Expression format: (( condition ? if true : if false ))'
        throw new Error('Could not analyze expression.\n' + err.message + '\n\n' + msg)
    }
}

// replaces variables within double curly braces - {{ variableName }}
// checks for variables existence within the injuryObject
const processVariablesInTemplate = (injuryObj, template) => {

    // get left and right curlies to find variable locations
    let leftCurlies = findAllInString(template, '{{');
    let rightCurlies = findAllInString(template, '}}');

    if (leftCurlies.length !== rightCurlies.length)
        throw new Error('Curly braces mismatch. Make sure all variables are surrounded by double curly braces.\nie. {{ variableName }}');

    if (leftCurlies.length === 0)
        return template;

    // add initial string
    let txtResult = template.substring(0, leftCurlies[0]);

    for (let i = 0; i < leftCurlies.length; i++) {

        //get var between curlies
        let varName = template.substring(leftCurlies[i] + 2, rightCurlies[i]).trim();

        // find vars in injuryObj
        if (Object.keys(injuryObj).filter(x => x.toLowerCase() === varName.toLowerCase()).length > 0) {
            varName = Object.keys(injuryObj).filter(x => x.toLowerCase() === varName.toLowerCase())[0];
            txtResult += injuryObj[varName].toString();
        }
        else if (injuryObj.rationale.metrics.filter(x => x.name.toLowerCase() === varName.toLowerCase()).length > 0) {
            txtResult += injuryObj.rationale.metrics.filter(x => x.name.toLowerCase() === varName.toLowerCase())[0].value.toString();
        }
        else {
            throw new Error('The variable "' + varName + '" is not recognized.\n\nMake sure all variable names are spelled correctly and surrounded by double curly braces.\nie. {{ variableName }}');
        }

        // add next string
        txtResult += template.substring(rightCurlies[i] + 2, i === leftCurlies.length - 1 ? template.length : leftCurlies[i + 1]);
    }

    return txtResult;
}

// #endregion old
