// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory


// Dicom Viewer Visibility Constants

const defaultVisiblity = {

    injuries: true,
    placements: true,
    annotations: true,
    measurements: true,
    intersections: true,
    globalCursor: true,

}

export const viewerVisibilityProperties = [
    { name: "injuries", iconPath: "/resources/icons/injury.png", toolTip: "Toggle Diagnoses" },
    { name: "placements", iconPath: "/resources/icons/label.png", toolTip: "Toggle Placements" },
    { name: "annotations", iconPath: "/resources/icons/Annotate2.png", toolTip: "Toggle Annotations" },
    { name: "measurements", iconPath: "/resources/icons/Measure.png", toolTip: "Toggle Measurements" },
    { name: "intersections", iconPath: "/resources/icons/Intersection.png", toolTip: "Toggle Slice Intersection" },
    { name: "globalCursor", iconPath: "/resources/icons/Cursor.png", toolTip: "Toggle Global Cursor" }
];

export const axisOptions = ['None', 'ax', 'sag', 'cor'];
export const tOptions = ['None', 1, 2];
export const indicatorOptions = [];



// Dicom Viewer configuration constants


export const viewerConfigurations = {
    "SINGLE": 'SINGLE',
    "DOUBLE_VERTICAL": 'DOUBLE_VERTICAL',
    "DOUBLE_HORIZONTAL": 'DOUBLE_HORIZONTAL',
    "QUADRANTS": 'QUADRANTS',
    "TRIPLE_VERTICAL": 'TRIPLE_VERTICAL',
    "TRIPLE_HORIZONTAL": 'TRIPLE_HORIZONTAL'
}


export const viewerLayouts = {

    [viewerConfigurations.SINGLE]: {
        rows: 1,
        cols: 1
    },

    [viewerConfigurations.DOUBLE_VERTICAL]: {
        rows: 2,
        cols: 1
    },

    [viewerConfigurations.DOUBLE_HORIZONTAL]: {
        rows: 1, 
        cols: 2
    },

    [viewerConfigurations.QUADRANTS]: {
        rows: 2, 
        cols: 2
    }, 

    [viewerConfigurations.TRIPLE_VERTICAL]: {
        rows: 3,
        cols: 2
    },

    [viewerConfigurations.TRIPLE_HORIZONTAL]: {
        rows: 2, 
        cols: 3
    }

}

// Formatting Note: 
    // * Viewer frames are defined from left to right, top to bottom.





const defaultViewers = {
    0: {axis: 'ax', t: 1, visibility: {...defaultVisiblity}},
    1: {axis: 'ax', t: 2, visibility: {...defaultVisiblity}},
    2: {indicator: 'scout', visibility: {...defaultVisiblity}},
    3: {axis: 'sag', t: 2, visibility: {...defaultVisiblity}},
    4: {axis: 'sag', t: 1, visibility: {...defaultVisiblity}},
    5: {axis: 'sag_stir', visibility: {...defaultVisiblity}}
}


export const defaultViewerConfigurations = {

    [viewerConfigurations.SINGLE]: {
        configuration: viewerConfigurations.SINGLE,
        viewers: {
            0: {...defaultViewers[0]}
        }
    },

    [viewerConfigurations.DOUBLE_VERTICAL]: { 
        configuration: viewerConfigurations.DOUBLE_VERTICAL,
        viewers: {
            0: {...defaultViewers[0]},
            1: defaultViewers[1]
        }    
    },

    [viewerConfigurations.DOUBLE_HORIZONTAL]: {
        configuration: viewerConfigurations.DOUBLE_HORIZONTAL, 
        viewers: {
            0: {...defaultViewers[0]},
            1: defaultViewers[1]
        }    
    },

    [viewerConfigurations.QUADRANTS]: {
        configuration: viewerConfigurations.QUADRANTS,  
        viewers: {
            0: {...defaultViewers[0]},
            1: defaultViewers[1],
            2: defaultViewers[2],
            3: defaultViewers[3]
        }
    },

    [viewerConfigurations.TRIPLE_VERTICAL]: {
        configuration: viewerConfigurations.TRIPLE_VERTICAL, 
        viewers: {
            0: {...defaultViewers[0]},
            1: defaultViewers[1],
            2: defaultViewers[2],
            3: defaultViewers[3],
            4: defaultViewers[4],
            5: defaultViewers[5]
        } 
    },

    [viewerConfigurations.TRIPLE_HORIZONTAL]: {
        configuration: viewerConfigurations.TRIPLE_HORIZONTAL, 
        viewers: {
            0: {...defaultViewers[0]},
            1: defaultViewers[1],
            2: defaultViewers[2],
            3: defaultViewers[3],
            4: defaultViewers[4],
            5: defaultViewers[5]
        }
    },

}