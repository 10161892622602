// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import '../../../styles/DicomViewer/label3D.css'


export default class label3D extends Component {


    state = {



    }

    // Bool to keep track of the focused state
    focused = false;

    // Int to keep track of the button down
    buttonDown = -1;

    componentDidMount = () => {

        // Prevent focus on single click
        this.refs.globalAnnotation.addEventListener('mousedown', (e) => { 
            if (!this.focused)
                e.preventDefault();
        })

        // Keep track of focused state
        this.refs.globalAnnotation.addEventListener('focus', (e) => { 
            this.focused = true;
        })

        this.refs.globalAnnotation.addEventListener('blur', (e) => { 
            this.focused = false;
        })

        // Prevent focus on single click
        this.refs.labelContainer.addEventListener('mousedown', (e) => { 
            if (!this.focused)
                e.preventDefault();
        })

    }


    // Reset button state
    resetButton = (e) => {
        this.buttonDown = -1;
    }


    // MOUSE EVENT HANDLERS

    onMouseDown = (e) => {
        this.buttonDown = e.button;

        // Deletion click? 
        if (this.props.deleteActive && e.button === 0)
            this.props.deleteAnnotation();

    }


    onMouseMove = (e) => {


        // Enable DicomViewer point movement if needed
        if (this.buttonDown === 0 && !this.props.activeDrag){
            this.props.setActivePoint();
        }

        // Prevent crosshairs if focused
        else if (this.focused)
            this.props.onSubComponentMouseMove(e);
        
    }

    // Handle double click action
    onDoubleClick = (e) => {

        // Manually focus the text box
        this.refs['globalAnnotation'].focus();

    }


    // LABEL CHANGE HANDLERS

    onTextChange = (newValue) => {

        // Update the 3D Label Text
        this.props.setText(this.props.annotation.annotationIdx, newValue.target.value);
        
    }



    render = () => {

        return (

            // Container (for position and auto sizing)
            <div className='label3DContainer'
                ref='labelContainer'
                style={{
                    left: this.props.center.x, 
                    top: isNaN(this.props.center.y) ? 0 : this.props.center.y, 
                }} 
            >

                {/* Input (Main display for label) */}
                <input  className={'label3D' + (this.props.deleteActive ? ' label3DDelete' : '')}
                        
                        key={this.props.annotation.annotationIdx}
                        ref={"globalAnnotation"}
                        onMouseDown = {this.onMouseDown}
                        onMouseMove = {this.onMouseMove}
                        onDoubleClick = {this.onDoubleClick}
                        value = {this.props.annotation.text}
                        onChange = {this.onTextChange}

                        onMouseUp = {this.resetButton}
                        onMouseLeave = {this.resetButton}
                />

                {/* Hidden Div for Box Sizing */}
                <div className='label3DDouble' ref='annotationDouble' >
                        {this.props.annotation.text}
                </div>



            </div>


        )


    }




}