// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Select from 'react-select';

import '../../../styles/DicomViewer/viewerConfigMenu.css'

// Constants
import { viewerConfigurations, viewerLayouts, defaultViewerConfigurations, viewerVisibilityProperties } from '../../../constants/viewerConfigurations';
import { axisOptions, tOptions } from '../../../constants/viewerConfigurations';

// Styled dropdown options
const axisOpts = axisOptions.map((elem, idx) => { return { label: elem, value: idx } });
const tOpts = tOptions.map((elem, idx) => { return { label: elem, value: idx } });

export default class ViewerConfigMenu extends Component {


    state = {

        openConfig: Object.keys(viewerConfigurations)[0],
        activeQuadrant: 0,

        // Default config settings (to be edited and saved)
        configurations: null

    }


    componentDidMount = async () => {

        // Initialized openConfig
        await this.setState({ openConfig: Object.keys(viewerConfigurations)[0] });

        // If the user is missing configurations... populate the needed configurations!
        await this.initializeConfigurations();

    }

    // Initialize configurations from user, and defaults
    initializeConfigurations = async () => {

        // const configTypeToName = Object.keys(viewerConfigurations).reduce(function (obj, key) {
        //     obj[viewerConfigurations[key]] = key;
        //     return obj;
        // }, {});

        let configurations = {};

        // If no data exists for the user: populate with default configuration shells.
        if (!this.props.user.data || !this.props.user.data.dicomViewerConfiguration) {
            this.setState({ configurations: defaultViewerConfigurations });
            return;
        }

        // Populate defaults where needed. 
        configurations = { ...defaultViewerConfigurations, ...this.props.user.data.dicomViewerConfiguration };

        // Set state
        await this.setState({ configurations: configurations });

    }


    // Returns the styling for a dicom viewer, given idx and current config
    getQuadrantDims = (viewerConfiguration) => {

        let quadrants = {};

        let viewerPageDims = viewerLayouts[viewerConfiguration];

        for (let r = 0; r < viewerPageDims.rows; r++) {
            for (let c = 0; c < viewerPageDims.cols; c++) {

                // Index in page reading 
                let index = c + (r * viewerPageDims.cols);

                let style = {};

                // Quadrant formatting
                style.left = `${100 * c / viewerPageDims.cols}%`;
                style.top = `${100 * r / viewerPageDims.rows}%`;
                style.width = `${100 / viewerPageDims.cols}%`;
                style.height = `${100 / viewerPageDims.rows}%`;

                quadrants[index] = style;

            }
        }

        return quadrants;

    }

    setOpenConfig = (config) => {

        this.setState({
            openConfig: config,
            activeQuadrant: 0
        })
    }

    // Update the configuration for the selected config type and quadrant.
    updateActiveConfigProp = (propertyName, propertyValue) => {

        // Get currnet configs (spread for in-app mutibility issues...)
        let configurations = this.state.configurations;
        configurations[this.state.openConfig] = {...configurations[this.state.openConfig]}
        configurations[this.state.openConfig].viewers = {...configurations[this.state.openConfig].viewers}

        // Get the configuration at this quadrant
        let quadrantConfig = {...configurations[this.state.openConfig].viewers[this.state.activeQuadrant]};

        if ((propertyName === 'axis' && propertyValue === 'None') || (propertyName === 't' && propertyValue === 'None') || (propertyName === 'indicator' && propertyValue.length === 0))
            // remove null or empty values
            delete quadrantConfig[propertyName]
        else
            // Update config
            quadrantConfig[propertyName] = propertyValue;

        configurations[this.state.openConfig].viewers[this.state.activeQuadrant] = quadrantConfig;

        // Set state
        this.setState({ configurations: configurations });

    }

    toggleActiveConfigVisibility = async (propertyName) => {

        // Get quad config
        let configurations = this.state.configurations;
        let quadrantConfig = configurations[this.state.openConfig].viewers[this.state.activeQuadrant];

        // Update config
        quadrantConfig.visibility = {...quadrantConfig.visibility};
        quadrantConfig.visibility[propertyName] = !quadrantConfig.visibility[propertyName];

        configurations[this.state.openConfig].viewers[this.state.activeQuadrant] = quadrantConfig;

        await this.setState({ configurations: configurations });

    }

    // Save default config
    saveConfiguration = async () => {

        // Cloud save
        await this.props.saveDefaultConfiguration(this.state.configurations);

        // Close window
        this.props.toggleConfigMenu();
    }


    render = () => {

        let quadrants = this.getQuadrantDims(viewerConfigurations[this.state.openConfig]);
        let activeConfig = this.state.configurations === null ? {} : this.state.configurations[this.state.openConfig].viewers[this.state.activeQuadrant];

        console.log('act conf', activeConfig)

        return (

            <div className='viewerConfigMenuContainer'>

                {/* Header */}
                <div className='viewerConfigHeader'>
                    Viewer Default Configurations
                </div>


                {/* For each of the configuration types...  */}

                {/* Side panel along the left edge */}

                <div className='configSidePanel'>

                    {Object.keys(viewerConfigurations).map((configName, idx) => {

                        return (<div key={idx} className={'sidePanelButtonContainer' + (this.state.openConfig === configName ? ' sidePanelButtonContainerActive' : '')}>

                            <img
                                className='sidePanelButton'
                                src={'/resources/icons/' + configName + '.png'}
                                alt={configName}
                                onClick={() => this.setOpenConfig(configName)}
                            />

                        </div>)

                    })}

                </div>


                {/* Quadrant selector */}

                <div className='quadrantSelector'>

                    {Object.keys(quadrants).map( (quadIdx, key)=> {

                        let quadStyle = quadrants[quadIdx];

                        return <div className={'selectorQuadrant' + (this.state.activeQuadrant === parseInt(quadIdx) ? ' selectorQuadrantActive' : '')}
                            key = {key}
                            style={{
                                left: quadStyle.left,
                                top: quadStyle.top,
                                width: quadStyle.width,
                                height: quadStyle.height
                            }}
                            onClick={() => this.setState({ activeQuadrant: parseInt(quadIdx) })}
                        >

                        </div>
                    })}

                </div>

                {/* Quadrant editor */}
                {/* this should be a series of dropdowns for the location, t, and the direction*/}
                {/* None should be a valid option for all.  */}

                <div className='viewerEditor'>

                    {/* Axis */}
                    <div className='propertyRow'>

                        <div className='propertyRowLabel'>
                            Axis
                        </div>

                        <Select
                            ref="propertySelect"
                            key="propertySelect"
                            className="propertySelect-container"
                            classNamePrefix="propertySelect"
                            options={axisOpts}
                            onChange={(selectedOption) => this.updateActiveConfigProp('axis', selectedOption.label)}
                            placeholder={"Select " + activeConfig.axis}
                            value={{ label: (activeConfig.axis ? activeConfig.axis : 'None'), value: axisOptions.indexOf((activeConfig.axis ? activeConfig.axis : 'None')) }}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                        />

                    </div>

                    {/* T Contrast */}
                    <div className='propertyRow'>

                        <div className='propertyRowLabel'>
                            T
                        </div>

                        <Select
                            ref="propertySelect"
                            key="propertySelect"
                            className="propertySelect-container"
                            classNamePrefix="propertySelect"
                            options={tOpts}
                            onChange={(selectedOption) => this.updateActiveConfigProp('t', selectedOption.label)}
                            placeholder={"Select " + activeConfig.t}
                            value={{ label: (activeConfig.t ? activeConfig.t : 'None'), value: axisOptions.indexOf((activeConfig.t ? activeConfig.t : 'None')) }}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                        />

                    </div>

                    {/* Indicator */}
                    <div className='propertyRow'>

                        <div className='propertyRowLabel'>
                            Indicator
                        </div>

                        <input 
                            type='text'
                            ref="propertyRowInput"
                            key="propertyRowInput"
                            className='propertyRowInput'
                            onChange={(newValue) => this.updateActiveConfigProp('indicator', newValue.target.value)}
                            value={activeConfig.indicator ? activeConfig.indicator : ''}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                        />

                    </div>

                </div>

                {/* Visibility Toggle Bar */}

                <div className="visibilityToolbar">

                    {activeConfig.visibility && viewerVisibilityProperties.map((toggleableProp, idx) => {

                        let visible = activeConfig.visibility[toggleableProp.name];

                        return (<div
                            className={"visibilityButtonContainer" + (visible ? " visibilityButtonContainerActive" : "")}
                            key={idx}
                        >

                            <img
                                className={"visibilityButton" + (visible ? " visibilityButtonActive" : "")}
                                title={toggleableProp.toolTip}
                                src={toggleableProp.iconPath}
                                alt={toggleableProp.name}
                                onClick={() => this.toggleActiveConfigVisibility(toggleableProp.name)}
                            />

                        </div>)

                    })}

                </div>


                {/* Save Button */}

                <div className='saveConfigButton'
                     onClick={this.saveConfiguration}>
                    Save Edits
                </div> 

                {/* Close button */}
                <img
                    className='viewerConfigClose'
                    src='/resources/icons/close.png'
                    alt='cancel'
                    title='Cancel injury creation'
                    onClick={this.props.toggleConfigMenu}
                />


            </div>

        )



    }



}