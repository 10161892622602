// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import InjuryDrop from './InjuryDrop';

// Styles
import "../../../styles/ReportGenerator/dropdown.css";
import "../../../styles/ReportGenerator/reportEditor.css";


class InjuryLocationDrop extends Component {

    state = {

        // New Diagnosis selection dropdown.
        newDiagnosisOpen: false,

    }

    toggleDropdown = () => {
        this.props.toggleInjuryLocation(this.props.injuryLocation);
    }

    // Delete a given injury (given object idx within location) 
    deleteInjury = (objectIdx) => {
        this.props.deleteInjury(this.props.injuryLocation, objectIdx);
    }

    // Update a given injury (given object idx within location) 
    updateInjury = (newInjury, objectIdx, cloudSave=false, updateSummary=true) => {
        this.props.updateInjury(this.props.injuryLocation, objectIdx, newInjury, cloudSave, updateSummary);
    }

    // Toggle visibility at this location
    toggleLocationVisibility = (e) => {

        e.stopPropagation();
        this.props.toggleLocationVisibility();
    }

    // Toggle injury type visibility @ this location
    toggleInjuryVisibility = (injuryType) => {
        this.props.toggleInjuryVisibility({
            location: this.props.injuryLocation,
            type: injuryType
        });
    }

    toggleInjuryDrop = (injury) => {
        this.props.toggleInjuryDrop(this.props.injuryLocation, injury.type, injury);
    }

    // Create a new injury
    createNewInjury = (injuryType) => {

        this.setState({ newDiagnosisOpen: false });

        // Call parent
        this.props.createNewInjury(injuryType, this.props.injuryLocation);

    }

    editInjury = (e, injType) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.editInjuryType(injType);
    }

    // Injury Comparison by type function
    injuryCompare = (a, b) => {
        if ( a.type < b.type ){
            return -1;
          }
          if ( a.type > b.type ){
            return 1;
          }
          return 0;
    }



    render = () => {

        // Sort injuries by type
        let sortedInjuries = this.props.injuries.sort( this.injuryCompare );
        
        // New injuries possible to create 
        const newInjuryTypes = Object.values(this.props.injuryTypes).filter( injuryType => {

            // if injury type has a user, force match
            if (injuryType.creator && injuryType.creator.length > 0 && injuryType.creator !== this.props.user._id)
                return false

            // Don't duplicate injuries
            if (this.props.injuries.find(elem => elem.type === injuryType.name ))
                return false;

            // Global injuries only go to global loc
            else if (injuryType.global && this.props.injuryLocation !== this.props.studyType)
                return false;

            // Global injuries don't go to local loc
            else if (!injuryType.global && this.props.injuryLocation === this.props.studyType)
                return false;
            
            return true;
        });

        return (
            <div
                className={'locationDropContainer' + (this.props.dropdownData.open ? ' locationDropContainerOpened' : '')}
            >

                <div className='locationDropHeader'
                     onClick={this.toggleDropdown}
                     onDoubleClick={this.props.openAllInjuries}
                >
                    <div className='locationHeaderTitle'>
                        {this.props.dropdownData.open ? "▾" : "▸"}&nbsp;&nbsp;&nbsp;{this.props.injuryLocation}
                    </div>

                    <img className={'objectButton'} 
                         src={'/resources/icons/' + (this.props.isBlocked ? 'in' : '') + 'visible.png'}
                         alt={this.props.isBlocked ? 'Invisible' : 'Visible'}
                         title='Toggle injury visibility'
                         onClick={this.toggleLocationVisibility}
                    />

                </div>


                {/* Dropdown for each injury! */}
                {this.props.dropdownData.open && sortedInjuries.map( (injury, idx) => (
                    
                    <InjuryDrop 
                        key = {idx}
                        injury = {injury}
                        injuryType = {this.props.injuryTypes[injury.type]}
                        dropdownOpen = {this.props.dropdownData.openChildren.find(type => type === injury.type)}
                        objectIdx = {idx}
                        isBlocked = {this.props.blockedInjuries.find( inj => (inj.location===this.props.injuryLocation && inj.type===injury.type))}
                        // Injury Functions
                        deleteInjury = {this.deleteInjury}
                        updateInjury = {this.updateInjury}
                        cloudSaveInjury = {this.props.saveInjuriesToCloud}
                        queueInjurySave = {this.props.queueInjurySave}
                        resetQueuedInjurySave = {this.props.resetQueuedInjurySave}
                        requestInjuryMetric = {this.props.requestInjuryMetric}
                        // Toggling Functions
                        toggleInjuryVisibility={this.toggleInjuryVisibility}
                        toggleInjuryDrop = {this.toggleInjuryDrop}
                        // Displaying 
                        openSliceInViewer={this.props.openSliceInViewer}
                        setEditorHoveredObjects={this.props.setEditorHoveredObjects}
                    />

                ))}

                {/* Create new injury */}
                {this.props.dropdownData.open && newInjuryTypes.length > 0 && 
                    <div 
                        className="objectDropContainer"
                        title={"Create new diagnosis at: " + this.props.injuryLocation}
                        onClick={(e) =>  e.stopPropagation()}
                    >
                        {/* New Injury Header */}
                        <div className="objectDropHeader"
                            onClick = {() => this.setState({ newDiagnosisOpen: !this.state.newDiagnosisOpen})}
                        >
                            <img 
                                className="objectCreate"
                                src="/resources/icons/Cursor.png"
                                alt="New"
                            />
                            <div className="objectDropTitle">New Diagnosis</div>
                        </div>

                        {/* New Injury Options */}
                        {this.state.newDiagnosisOpen && <div className='objectDropdown'>
                            <div className='injuryOptionsContainer'>
                                {/* Injury Type Options */}
                                {newInjuryTypes.map( (injType, idx) => {                                    
                                    return(
                                        <div className='injuryTypeOption'
                                            key={idx}
                                            onClick={() => {this.createNewInjury(injType)}}
                                            title= {"Create new " + injType.name}
                                        >
                                            
                                            <div className='injuryTypeOption_title'>
                                                {injType.name}
                                            </div>

                                            <img
                                                className='injuryTypeOption_edit'
                                                onClick={(e) => this.editInjury(e, injType)}
                                                title={'Edit ' + injType.name}
                                                alt={'Edit ' + injType.name}
                                                src='/resources/icons/settings.png'
                                            />

                                        </div>
                                    )
                                })}
                            </div>
                        </div>}

                    </div>
                }
            </div>
        )


    }

}

export default InjuryLocationDrop