// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import '../../../styles/ReportGenerator/annotationDropdown.css';
import "../../../styles/ReportGenerator/dropdown.css";

import titleCase from '../../../utilities/titleCase';

// TODO @Marcel: Generalize this!
const annotations = {
    'arrow': { name: 'Arrow', icon: 'Arrow.png' },
    'circle': { name: 'Circle', icon: 'Circle.png' },
    'rectangle': { name: 'Box', icon: 'Rectangle.png' },
    'length': { name: 'Length', icon: 'Length.png' },
    'angle': { name: 'Angle', icon: 'Angle.png' },
    'label': { name: 'Label', icon: 'Label.png' },
    'label3D': {name: 'Label3D', icon: 'Label3D.png'}
}


export default class AnnotationLocationDrop extends Component {


    state = {

        dropdownOpen: false,

    }

    toggleDropdown = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }))
    }

    // Set new open annotation
    setOpenAnnotation = (seriesName, sliceIdx) => {
        this.props.openSliceInViewer(seriesName, sliceIdx);
    }


    // Rendering for global annotation dropdown
    renderGlobalAnnotationDrop = (propAnnotations) => {

        // Render each annotation
        return propAnnotations.map( (annotation, annotationIdx) => {
            return <div className='objectDropContainer'>

                <div key={annotationIdx} className='objectDropHeader'
                        onClick = {() => this.props.openPointSlices(annotation.points[0])}
                > 

                    <div className='objectDropTitle'>
                        
                        <img
                            className="annotationIcon"
                            src={"/resources/tools/" + annotations[annotation.dataType].icon}
                            alt={annotation.dataType}
                        />
                        
                        {annotations[annotation.dataType].name}{annotation.dataType === 'label3D' ? (' : ' + annotation.text) : ''}

                        {/* Delete */}
                        <img
                            className="annotationDelete"
                            src="/resources/icons/close.png"
                            alt='delete'
                            title="Delete annotation"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.deleteAnnotation(this.props.seriesName, null, annotationIdx);
                            }}
                        />
                        
                    </div>

                </div>

                
                
            </div>})

        
    } 

    // Rendering for local annotation dropdown
    renderLocalAnnotationDrop = (propAnnotations) => {

        // Render each annotation
        return Object.keys(propAnnotations).map( (sliceIdx) => {
                    
            let annotationData = this.props.annotations[sliceIdx];

            if (!annotationData || annotationData.length === 0)
                return '';
            
            return (<div className='objectDropContainer' key={sliceIdx}>

                {annotationData.map( (annotation, annotationIdx) => {
                    
                    return (
                        <div key={annotationIdx} className='objectDropHeader'
                             onClick = {() => this.setOpenAnnotation(this.props.seriesName, parseInt(sliceIdx))}
                        > 

                            <div className='objectDropTitle'>
                                
                                <img
                                    className="annotationIcon"
                                    src={"/resources/tools/" + annotations[annotation.dataType].icon}
                                    alt={annotation.dataType}
                                />
                                
                                {annotations[annotation.dataType].name} on DICOM slice #{parseInt(sliceIdx)+1}

                                {/* Delete */}
                                <img
                                    className="annotationDelete"
                                    src="/resources/icons/close.png"
                                    alt='delete'
                                    title="Delete annotation"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.props.deleteAnnotation(this.props.seriesName, sliceIdx, annotationIdx)
                                    }}
                                />
                                
                            </div>

                        </div>
                    )

                })}

                
                
            </div>)

        })


    }




    render = () => {

        return (

            <div className={'locationDropContainer' + (this.state.dropdownOpen ? ' locationDropContainerOpened' : '')}> 

                {/* Header */}
                <div className='locationDropHeader'
                    onClick={this.toggleDropdown}
                >

                    <div className='locationHeaderTitle'>
                        {this.state.dropdownOpen ? "▾" : "▸"}&nbsp;&nbsp;&nbsp;{titleCase(this.props.seriesName)}
                    </div>

                </div>

                {/* Annotation location dropdown */}
                {this.state.dropdownOpen && (
                    this.props.seriesName === 'global' ?
                        this.renderGlobalAnnotationDrop(this.props.annotations) : 
                        this.renderLocalAnnotationDrop(this.props.annotations)
                )}                


            </div>

        )

    }

}