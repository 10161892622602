// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory


export const vec3 = {


    add : (v1, v2) => {
        return {x: v1.x+v2.x, y: v1.y+v2.y, z: v1.z+v2.z};
    }, 

    subtract : (v1, v2) => {
        return {x: v1.x-v2.x, y: v1.y-v2.y, z: v1.z-v2.z};
    },

    scale : (v, scale) => {
        return {x: v.x*scale, y: v.y*scale, z: v.z*scale};
    }, 

    negate : (vector) => {
        return { x: -vector.x, y: -vector.y, z: -vector.z};
    },

    distance : (v1, v2) => {
        return Math.sqrt(Math.pow(v1.x - v2.x, 2) + Math.pow(v1.y - v2.y, 2) + Math.pow(v1.z - v2.z, 2));
    },

    magnitude : (v) => {
        return Math.sqrt(Math.pow(v.x,2) + Math.pow(v.y, 2) + Math.pow(v.z, 2));
    },

    normalize : (v) => {
        let magnitude = vec3.magnitude(v);
        return {x: v.x/magnitude, y: v.y/magnitude, z: v.z/magnitude}; 
    },

    dot : (v1, v2) => {
        return (v1.x*v2.x + v1.y*v2.y + v1.z*v2.z);
    },

    cross : (v1, v2) => {
        return {x: v1.y*v2.z - v1.z*v2.y, y: v1.z*v2.x - v1.x*v2.z, z: v1.x*v2.y - v1.y*v2.x};
    }


}

export const vec2 = {

    add : (v1, v2) => {
        return { x: v1.x + v2.x, y: v1.y + v2.y };
    }, 

    subtract : (v1, v2) => {
        return {x: v1.x-v2.x, y: v1.y-v2.y};
    },

    scale: (v, scale) => {
        return {x: v.x*scale, y: v.y*scale};
    },

    negate : (vector) => {
        return { x: -vector.x, y: -vector.y};
    },

    midPoint : (v1, v2) => {
        return {x: (v1.x + v2.x)/2, y: (v1.y + v2.y)/2, z: (v1.z + v2.z)/2};
    }, 

    distance : (v1, v2) => {
        return Math.sqrt(Math.pow(v1.x - v2.x, 2) + Math.pow(v1.y - v2.y, 2));
    },

    dot : (v1, v2) => {
        return (v1.x*v2.x + v1.y*v2.y);
    },

    magnitude : (v) => {
        return Math.sqrt(Math.pow(v.x,2) + Math.pow(v.y, 2));
    },

    avg : (v1, v2) => {
        return {x: (v1.x + v2.x)/2, y: (v1.y + v2.y)/2};
    },

    normalize : (v) => {
        let magnitude = vec2.magnitude(v);
        return {x: v.x/magnitude, y: v.y/magnitude}; 
    },

}