// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

export default (title) => {
    let titleArray = title.split(' ');
    for (let i=0; i<titleArray.length; i++)
        titleArray[i] = titleArray[i][0].toUpperCase() + titleArray[i].slice(1);

    title = titleArray.join(' ');
    return title;
}