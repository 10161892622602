// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
// import { toast } from 'react-toastify';
import { HotKeys } from 'react-hotkeys';

import ReportGeneratorHeader from './ReportGeneratorHeader';
import ReportEditor from '../ReportEditor/ReportEditor';
import ReportPreview from '../ReportPreview/ReportPreview';
import DicomViewerPage from '../../DicomViewer/Page/DicomViewerPage';
import InjuryTypeGenerator from '../SubComponents/injuryTypeGenerator';

import uuid4 from 'uuid4';
import sleep from '../../../utilities/sleep';


import '../../../styles/ReportGenerator/reportGenerator.css'

// Constants 
import { toolToType } from '../../../constants/tools';
import { toast } from 'react-toastify';


class ReportGeneratorPage extends Component {


    state = {
        injuryTypeGenerator: {
            isVisible: false,
            injuryType: null,
        },

        // Dicom Viewers in paralell
        inViewerMode: true,

        // Injury Visibilities
        blockedInjuryLocations: [],
        blockedInjuryTypes: [],
        blockedInjuries: [],

        // Last injury opened in the Report Editor
        openEditorObject: null,

        // Objects hovered over in the Report Editor
        hoveredEditorObjects: [],

        // Dropdown state of the Report Editor
        dropdownState: {},

        // Injury Creation
        newInjury: null,

        // Internal DicomViewerPage data
        // Active request made to all Dicom Viewers
        activeRequest: null,
        // Data for how to save the active request
        newMetricData: null,

    }

    // #region Hotkeys

    hotkeyMap = {
        // actions
        newDicomViewerWindow: 'Alt+d',
    };

    hotkeyHandlers = {
        // actions
        newDicomViewerWindow: e => this.triggerHotkey(e, () => this.newDicomViewerWindow()),
    };

    triggerHotkey = (event, callback) => {
        event.stopPropagation();
        event.preventDefault();

        if (window.event)
            window.event.cancelBubble = true;

        callback();
    }

    // #endregion Hotkeys

    componentDidMount = async () => {

        // Load a specified project? 
        if (this.props.match.params.projectId)
            await this.loadProject(this.props.match.params.projectId, true);              
        
        // Load default project?
        else {
            await this.props.loadDefaultProject();   
            await this.resetStudyType(this.props.project.studyType);
        } 

        // Set any inhereted session UUID
        await this.setSessionUUID(this.props.match.params.uuid);

        // Initialize Broadcasting
        await this.initializeBroadcasting();
        
        // Identify sibling instances
        this.identifySiblingInstances();

    }


    // ------ PROJECT NAVIGATION FUNCTIONS ------

    // Shift the project index
    shiftProjectIndex = async (shiftValue) => {

        // Get next project index
        let newProjectId = await this.props.getNextProjectId(shiftValue);

        // Load the new project
        await this.loadProject(newProjectId);

        // Broadcast new project._id
        this.broadcastProjectChange(newProjectId);

    }

    // Load a new project
    loadProject = async (newProjectId, initialLoad = false) => {

        // Viewers will be cleared within the attached DicomViewerPage

        // Call parent
        await this.props.loadProject(newProjectId);

        // Reset study type dependent data
        await this.resetStudyType(this.props.project.studyType);

        if (!initialLoad) {
            // Update the url
            let urlPrefix = '/report/';
            this.props.history.push(urlPrefix + newProjectId + '/' + this.state.sessionUUID);
        }

        // Viewers will be updated in the atached DicomViewerPage

    }

    // Helper to reset study type dependent data
    resetStudyType = async (studyType) => {

        // Reset the dropdown state! 
        let dropdownState = {};
        for (let location of this.props.studyTypes[studyType].anatomy.map(elem => elem.anatomyName))
            dropdownState[location] = {open: false, openChildren: []};

        await this.setState({ dropdownState: dropdownState });

        return;

    }


    // ------ BROWSER COMMUNICATION HANDLING FUNCTIONS ------

    // ACTIONS: 

    // IdentifySelf
        // Request sent from a single instance, to identify other instances.
        // [No Params]

    // WhoAmI
        // The response sent from a sibling to make itself known.
        // [pId]: The ID of the project displayed.
        // [whoAmI]: The page-type of the instance.

    // CancelAnnotationRequest
        // Cancel all annotation requests in sibling windows. 


	setSessionUUID = async (manualUUID = undefined) => {

		// Manual setting? 
		if (manualUUID !== undefined && manualUUID !== null) {
			await this.setState( {sessionUUID: manualUUID});
            sessionStorage.setItem("ViewerUUID", manualUUID);
		}

        // Check for a session saved UUID (handles case of original refreshing)
		else if (sessionStorage.getItem('ViewerUUID')) {
            await this.setState({ sessionUUID: sessionStorage.getItem('ViewerUUID')});
		}

		else {
			let viewerUUID = uuid4().toString();
			sessionStorage.setItem("ViewerUUID", viewerUUID);
            await this.setState({ sessionUUID: viewerUUID})
		}

		console.log("SessionUUID: ", this.state.sessionUUID);

	}

	initializeBroadcasting = () => {

		window.addEventListener('storage', this.receiveData);
	}


	broadcastData = (data) => {

		// Broadcast data over the UUID 
		localStorage.setItem(this.state.sessionUUID, JSON.stringify(data));
		localStorage.removeItem(this.state.sessionUUID);

    }
    
    broadcastCommand = (cmdStr, data) => {
        data.action = cmdStr;
        this.broadcastData(data);
    }

    broadcastAnnotations = async (seriesName, sliceIdx, newAnnotation) => {

        this.broadcastCommand('SyncAnnotations', {
            seriesName,
            sliceIdx,
            annotationData: newAnnotation
        });
    }



    // ------ BROADCAST RECEIVING ------

	receiveData = (e) => {

		if (e.key === this.state.sessionUUID && e.newValue !== null) {

			// Receive data over the UUID 
			let data = JSON.parse(e.newValue);


            // Receive requested annotation
            if (data.action === 'CompletedAnnotation') 
                this.receiveRequestedAnnotation(data);

            // Clear the current annotation request
            else if (data.action === 'CancelAnnotationRequest')
                this.clearActiveRequest();

            // Identify self to sibling
            else if (data.action === "IdentifySelf")
                this.pingSibling();
            
            // Receiving identification from sibling
            else if (this.state.identifyingSiblings && data.action === "WhoAmI") {
                let siblings = this.state.siblings;
                siblings.push({pId: data.pId, whoAmI: data.whoAmI});
                this.setState({siblings: siblings});
            }

		}
    }

    // Send data back to sibling identifying type, etc. 
    pingSibling = () => {
        this.broadcastData({action: "WhoAmI", pId: this.props.project._id, whoAmI: "ReportGeneratorPage"});
    }


    // ------ BROADCAST SENDING ------
    
    // Request an annotation from siblings
    requestAnnotation = async (annotationType, annotationName, injuryName) => {

        let data = {
            action : 'AnnotationRequest',
            request: {
                dataType: annotationType,
                name: annotationName,
                injuryName: injuryName
            }
        }

        // Broadcast
        this.broadcastData(data);

        // Set state for internal viewerPage
        this.setState({
            activeRequest: data.request
        })
    }

    // Cancel active request in all siblings.
    cancelAnnotationRequests = async () => {

        this.broadcastData( { action: "CancelAnnotationRequest" } );
        this.clearActiveRequest();
    }

    // Helper to clear the active request in self 
    clearActiveRequest = async () => {
        this.setState({ activeRequest: null, newMetricData: null });
    }


    // Update list of sibling instances
    identifySiblingInstances = async () => {

        // Set the stage for sibling identification
        await this.setState({
            identifyingSiblings: true,
            siblings: []
        })

        // Ping siblings
        this.broadcastData({ action: "IdentifySelf" });

        // Wait...
        let timeout = 1;

        await sleep(1000 * timeout);
        this.setState({ identifyingSiblings: false });

        console.log("Identified Siblings: ", this.state.siblings);

    }

    toggleInjuryTypeGenerator = async (injuryType = null) => {
        // must set injury type first
        await this.setState(prevState => ({
            injuryTypeGenerator: {
                ...prevState.injuryTypeGenerator,
                injuryType: injuryType,
            }
        }));

        // make visible
        await this.setState(prevState => ({
            injuryTypeGenerator: {
                ...prevState.injuryTypeGenerator,
                isVisible: !prevState.injuryTypeGenerator.isVisible,
            }
        }));
    }

    // Broadcast a shift in project idx to siblings
    broadcastProjectChange = async newProjectId => {
        await this.broadcastCommand('ProjectChange', {
            newProjectId
        })
    }

    broadcastInjuries = async (injuryLocation) => {

        // Get the objects 
        let injuryLocationData = await this.props.project.ai.injury.injuries.find(elem => elem.location === injuryLocation);

        if (!injuryLocationData)
            return;

        this.broadcastCommand('SyncInjuries', {
            location: injuryLocation,
            injuryData: injuryLocationData
        });
    }


    // ------ INJURY CREATION HELPERS ------



    // Create a new instance of an injury, at the given location
    createNewInjury = async (injuryType, location) => {

        // Don't allow creation w/o loc
        if (!location)
            return;

        // Init new injury
        let newInjury = { 
            type: injuryType.name,
            location: location
        };

        // Populate default properties
        for (let property of injuryType.properties) {
            newInjury[property.name] = property.defaultValue;
        }

        // Init rationale
        newInjury.rationale = { dicoms: [], metrics: [] };

        // Open only this injury
        this.openSingleInjury(newInjury.location, newInjury.type);

        // Save via parent
        await this.props.saveNewInjury(newInjury);

        // Broadcast injury
        this.broadcastInjuries(newInjury.location);


    }


    // Function to request an annotation for a given injury 
    requestInjuryMetric = async (injuryLocation, injuryType, metricType) => {


        // Only allow the requesting of types that map to tools 
        if (!Object.values(toolToType).includes(metricType.type))
            return;

        // Initialized the new metric
        let metric = {
            dataType: metricType.type,
            name: metricType.name,
        }

        // Set the save location in the state. 
        await this.setState({
            newMetricData: {
                location: "Injuries",
                newMetric: metric,
                injuryLocation: injuryLocation,
                injuryType: injuryType,
            }
        })

        // Request metric
        await this.requestAnnotation(metric.dataType, metric.name, injuryType);

        return true;
    }

    receiveRequestedAnnotation = async (resData) => {


        // Grab new metric data
        let newMetricData = this.state.newMetricData;

        // Clear the active request, and newMetricData
        await this.setState({
            activeRequest: null,
            newMetricData: null
        });

        if (newMetricData.location === 'Injuries') {


            // Unpack the annotation data
            const annotationData = resData.annotation;
            let annotation = {};
            let allowedProps = ['value', 'radius', 'points', 'width', 'height', 'text'];

            // Make sure the metric is of the type expected!
            if (annotationData.dataType !== newMetricData.newMetric.dataType) {
                toast.error("Incorrect metric of type " + annotationData.dataType + " for injury metric of type " + newMetricData.newMetric.dataType);
                return;
            }
                
            // Sereis, Slice
            if (annotationData.seriesIdx !== undefined)
                annotation.seriesName = this.props.project.dicomData[annotationData.seriesIdx].seriesName;
            if (annotationData.sliceIdx !== undefined)
                annotation.sliceIdx = annotationData.sliceIdx;
            
            // Pass on all allowed properties... 
            for (let property of allowedProps)
                if (annotationData[property])
                    annotation[property] = annotationData[property]


            // Get metric from active request
            const injuryLocation = newMetricData.injuryLocation
            const injuryType = newMetricData.injuryType 
            let metric = {...newMetricData.newMetric, ...annotation};


            // Update the injury 
            await this.addMetricToInjury(injuryLocation, injuryType, metric);
            
            // Broadcast
            this.broadcastInjuries(injuryLocation);
        
        }

        // Recieving anatomy placement ?
        else if (newMetricData.location === 'GlobalAnnotations' && newMetricData.isAnatomyPlacement)
            this.receiveAnatomyPlacement(newMetricData, resData.annotation);

    }

    // Add a metric to the injury at a given location, with given type.
    addMetricToInjury = (injuryLocation, injuryType, newMetric) => {

        // Locate the injury 
        const injuries = this.props.project.ai.injury.injuries;
        const injuryLocationData = injuries.find(elem => elem.location === injuryLocation);

        let objectIdx = -1;
        let injuryObj = null;

        for (let i=0; i<injuryLocationData.objects.length; i++) {
            if (injuryLocationData.objects[i].type === injuryType) {
                injuryObj = injuryLocationData.objects[i];
                objectIdx = i;
                break;
            }
        }

        // Couldn't find injury? 
        if (objectIdx < 0) {
            return false;
        }

        // Initialize rationale / metrics if needed 
        if (!injuryObj.rationale)
            injuryObj.rationale = {};
        if (!injuryObj.rationale.metrics)
            injuryObj.rationale.metrics = [];
            
        // Update the injury metric
        injuryObj.rationale.metrics.push(newMetric)

        // Updat the injury dicoms
        if (newMetric.seriesName && Number.isInteger(newMetric.sliceIdx)) {
            if (!injuryObj.rationale.dicoms)
                injuryObj.rationale.dicoms = [];
            injuryObj.rationale.dicoms.push( {seriesName: newMetric.seriesName, sliceIdx: newMetric.sliceIdx} );
        }

        // Save the injury 
        this.updateInjury(injuryLocation, objectIdx, injuryObj, true, true);

    }

 
    // ------ INJURY UPDATING ------

    deleteInjury = async (injuryLocation, objectIdx) => {

        // Delete from project
        await this.props.deleteInjury(injuryLocation, objectIdx);

        // Broadcast update
        this.broadcastInjuries(injuryLocation);

    }

    // Update a given injury (NOTE: Broadcasts to siblings)
    updateInjury = async (injuryLocation, objectIdx, newInjury, cloudSave=false, updateSummary=true) => {

        // Update in project 
        await this.props.updateInjury(injuryLocation, objectIdx, newInjury, cloudSave, updateSummary);

        // Broadcast update
        this.broadcastInjuries(injuryLocation);

    }

    // Queue injury save to cloud 
    queueInjurySave = async (injuryLocation, timeout = 5000) => {

        // Broadcast the current injuries
        this.broadcastInjuries(injuryLocation);

        // Call parent
        this.props.queueInjurySave(timeout);

    }

    // Pass a series and slice to be opened in the attached DicomViewerPage.
    openSliceInViewer = async (seriesName, sliceIdx) => {

        await this.setState({ openEditorObject: {

            location: 'Object',
            data: {
                seriesName: seriesName,
                sliceIdx: sliceIdx,
            }

        }})

    }


    // ------ INJURY VISIBILITY ------

    // Toggle the visibility at an injury location
    toggleLocationVisibility = async (injuryLocation) => {

        if (this.state.blockedInjuryLocations.find(loc => loc === injuryLocation)) {

            // Remove the location from blocked
            let newBlocked = this.state.blockedInjuryLocations.filter((elem, idx) => elem !== injuryLocation);
            await this.setState({ blockedInjuryLocations: newBlocked });
        }

        else {

            // Add the location to blocked
            let newBlocked = this.state.blockedInjuryLocations;
            newBlocked.push(injuryLocation);
            await this.setState({ blockedInjuryLocations: newBlocked });
        }


        // Broadcast the new data
        this.broadcastData({
            action: 'InjuryVisibilities',
            blockedLocations: this.state.blockedInjuryLocations,
            blockedInjuries: this.state.blockedInjuries,
        })

    }

    // Toggle the visibility of a specific injury
    toggleInjuryVisibility = async (injuryData) => {

        if (this.state.blockedInjuries.find(curInj => {
            return (curInj.location === injuryData.location && curInj.type === injuryData.type);
        })) {
            // Remove the location from blocked
            let newBlocked = this.state.blockedInjuries.filter((curInj, idx) => (curInj.location !== injuryData.location || curInj.type !== injuryData.type));
            await this.setState({ blockedInjuries: newBlocked });
        }

        else {
            // Add the location to blocked
            let newBlocked = this.state.blockedInjuries;
            newBlocked.push(injuryData);
            await this.setState({ blockedInjuries: newBlocked });
        }

        // Broadcast the new data
        this.broadcastData({
            action: 'InjuryVisibilities',
            blockedLocations: this.state.blockedInjuryLocations,
            blockedInjuries: this.state.blockedInjuries,
        })

    }


    // ------ TOGGLE INJURY DROPDOWNS ------

    toggleLocationDrop = async (injuryLocation) => {

        // Toggle open state
        let dropdownState = this.state.dropdownState;
        dropdownState[injuryLocation].open = !dropdownState[injuryLocation].open;

        // Set state
        await this.setState({ 
            dropdownState: dropdownState ,
        });

        // If opening: Move to nearest slices
        if (dropdownState[injuryLocation].open)
            this.openAnatomySlices(injuryLocation);

        // Update metric visibilities 
        this.setVisibilityFromDropdownState();
    }

    // Open slices near anatomy
    openAnatomySlices = async (anatomyLocation) => {

        // Remove any anatomy indicator if present 

        // Get anatomy display name
        const anatomyData = this.props.studyTypes[this.props.project.studyType].anatomy.find(elem => elem.anatomyName === anatomyLocation);
        if (!anatomyData || !anatomyData.abbreviation)
            return;
        anatomyLocation = anatomyData.abbreviation;
        

        // Find the anatomy name in the global annotations
        const p = this.props.project;
        if (!p || !p.dicomAnnotations || !p.dicomAnnotations.global)
            return;

        const globalAnnotations = p.dicomAnnotations.global;
        const anatomyAnnot = globalAnnotations.find(elem => (elem.text === anatomyLocation && elem.dataType === 'label3D'));

        if (!anatomyAnnot)
            return;

        // Get the 3D point 
        const anatomyCenter = anatomyAnnot.points[0];

        // Set the 3D point as the open editor object. 
        await this.setState({ openEditorObject: {location: '3DPoint', data: anatomyCenter} });

    }

    // Open slices near any given 3D point (stark coords)
    openPointSlices = async (pointCenter) => {

        await this.setState({ openEditorObject: {location: '3DPoint', data: pointCenter} })

    }



    toggleInjuryDrop = async (injuryLocation, injuryName, injuryData = null) => {

        // Toggle open state
        let dropdownState = this.state.dropdownState;
        let openChildren = dropdownState[injuryLocation].openChildren;

        // Case: Closing dropdown
        if (openChildren.find(elem => elem === injuryName)) {

            // Close dropdown
            openChildren = openChildren.filter( (elem, idx) => (elem !== injuryName));
        }

        // Case: Opening dropdown
        else {
            // Open dropdown
            openChildren.push(injuryName);

            // Set the metrics to be visible in the dicom viewer page
            if (injuryData !== null)
                this.setState({ openEditorObject: {location: 'Injuries', data: injuryData } });

        }

        dropdownState[injuryLocation].openChildren = openChildren;

        // Set state
        await this.setState({ dropdownState: dropdownState });

        // Update injury visibilities
        this.setVisibilityFromDropdownState();

    }


    setVisibilityFromDropdownState = async (recentLocation = undefined, recentInjuryName = undefined) => {

        if (recentInjuryName && !recentLocation)
            throw new Error('injury name must have a matching location')

        // Get all injury locations and types
        const allLocations = this.props.studyTypes[this.props.project.studyType].anatomy.map(elem =>elem.anatomyName);
        const allInjuries = this.props.project.ai.injury.injuries.filter(inj => allLocations.includes(inj.location))
            .map(inj => inj.objects).flat().map(inj => {return {location: inj.location, type: inj.type}})

        // Init new blocked data
        let newBlockedLocations = [];
        let newBlockedInjuries = [];

        // Case : only show the opened location
        if (recentLocation && !recentInjuryName) {

            // Block all injuries at other locations
            newBlockedLocations = allLocations.filter(loc => loc !== recentLocation);
            newBlockedInjuries = [];
        }

        // Case : only show the opened injury
        else if (recentLocation && recentInjuryName) {

            // Block all injuries at other locations
            newBlockedLocations = allLocations.filter(loc => loc !== recentLocation);
            // Block all other injuries at this location
            newBlockedInjuries = allInjuries.filter(inj => recentLocation === inj.location && recentInjuryName !== inj.type);

        }

        // Case : show whatever is open 
        else if (!recentLocation && !recentInjuryName) {

            const dropdownState = this.state.dropdownState;

            for (let location of Object.keys(dropdownState)) {

                // If location not open: block
                if (!dropdownState[location].open) {
                    newBlockedLocations.push(location)
                    continue;
                }

                const openChildren = dropdownState[location].openChildren;

                // If no injuries open: don't block any injuries 
                if (openChildren.length === 0)
                    continue;

                // Else, block all unopen children
                const blockedInjuries = allInjuries.filter(inj => (location === inj.location &&  !openChildren.includes(inj.type)));
                newBlockedInjuries = newBlockedInjuries.concat(blockedInjuries);

            }

        }
        
        // Set the state
        await this.setState({
            blockedInjuryLocations: newBlockedLocations,
            blockedInjuries: newBlockedInjuries
        });

        // Broadcast the new data
        this.broadcastData({
            action: 'InjuryVisibilities',
            blockedLocations: this.state.blockedInjuryLocations,
            blockedInjuries: this.state.blockedInjuries,
        })
    }

    // Open all injury dropdowns at a given location
    openAllInjuries = async (locationName) => {

        // Get all injuries
        let injuries=(this.props.project && this.props.project.ai.injury.injuries) ? this.props.project.ai.injury.injuries : [];

        let dropdownState = this.state.dropdownState;

        for (let location of Object.keys(dropdownState)) {

            if (location === locationName) {

                // Open dropdown
                dropdownState[locationName].open = true;

                // Open all sub dropdowns
                let injuryLocation = injuries.find(elem => elem.location === locationName);

                if (injuryLocation && injuryLocation.objects && injuryLocation.objects.length > 0) {
                    // Set all to open
                    dropdownState[locationName].openChildren = injuryLocation.objects.map(injury => injury.type);
                }

            }

            else {
                // Close all other dropdowns
                dropdownState[location] = { open: false, openChildren: []};
            }

        }

        // Set state
        await this.setState({ 
            dropdownState: dropdownState,
         });

         // Move to the slices nearest the anatomy
         this.openAnatomySlices(locationName);

        
        // Refresh injury visibilities 
        this.setVisibilityFromDropdownState();

    }

    // Open a single injury, and close all others
    openSingleInjury = async (locationName, injuryType, injuryData = null) => {

        // Get dropdown state
        let dropdownState = this.state.dropdownState;

        for (let location of Object.keys(dropdownState)) {

            if (location === locationName) {

                // Open dropdown, and single injury
                dropdownState[locationName].open = true;
                dropdownState[locationName].openChildren = [injuryType];

            }

            else 
                // Close all other dropdowns
                dropdownState[location] = { open: false, openChildren: []};

        }

        // Set the metrics to be visible in the dicom viewer page
        if (injuryData !== null)
            this.setState({ openEditorObject: {location: 'Injuries', data: injuryData } });

        // Set state
        await this.setState({ dropdownState: dropdownState });

        // Updaet injury visibilities 
        this.setVisibilityFromDropdownState();

    }


    // ------ HOVERED OBJECT DATA ------

    // Update the data on hoved objects in the report editor
    setEditorHoveredObjects = ( objects ) => {
    
        this.setState({ hoveredEditorObjects: objects })

    }


    
    // ------ CUSTOM INJURY CREATION ------

    // Initialize the creation of a new injury type
    createCustomInjury = () => {

        // Init creation of new injury type

        this.toggleInjuryTypeGenerator();

    }

    saveGeneratedInjuryType = (injurySchema, isEditing = false) => {

        // toggle window
        this.setState({
            injuryTypeGenerator: {
                isVisible: false,
                injuryType: null,
            }
        });
        this.props.saveGeneratedInjuryType(injurySchema, isEditing);
    }

    editInjuryType = async (injuryType) => {
        this.toggleInjuryTypeGenerator(injuryType);
    }


    // ------ WINDOW OPENING FUNCTIONS ------

    // Open new dicom viewer window
    newDicomViewerWindow = () => {

        // URL
        let url = '/dicom/' + this.props.project._id + '/' + this.state.sessionUUID;

        // TODO @Marcel: Remove Dev
        if (process.env.REACT_APP_DEV === 'true' || this.props.project.patient.name === 'dev') {
            url = '/dicom/dev/' + this.state.sessionUUID;
        }

        // Open
        let params = 'height=' + window.innerHeight + ',width=' + window.innerWidth + ',modal=yes,alwaysRaised=yes';
        let windowName = '__blank' + Math.floor(Math.random() * 100);
        window.open(url, windowName, params);

    }

    deleteAnnotation = async (seriesName, sliceIdx, annotationIdx) => {
        await this.props.deleteAnnotation(seriesName, sliceIdx, annotationIdx);

        if (seriesName === 'global')
            await this.broadcastAnnotations('global', null, this.props.project.dicomAnnotations['global']);

        else
            this.broadcastAnnotations(seriesName, sliceIdx, this.props.project.dicomAnnotations[seriesName][sliceIdx]);
        
    }

    // ANATOMY PLACEMENT REQUESTING
    
    receiveAnatomyPlacement = async (newMetricData, annotationData) => {

        // Clean up the annotation
        delete annotationData.seriesIdx;
        delete annotationData.sliceIdx;
        annotationData = { ...annotationData, ...newMetricData.newMetric};

        // Save to global annotations 
        await this.props.saveAnnotation('global', null, annotationData);

        // Broadcast
        await this.broadcastAnnotations('global', null, this.props.project.dicomAnnotations['global']);

        // Get the next anatomy placement to request 
        const anatomyPlacements = this.props.studyTypes[this.props.project.studyType].orderedPlacements;
        const currentIdx = anatomyPlacements.indexOf(annotationData.text);
        const nextIdx = currentIdx + 1
        
        if (anatomyPlacements.length > nextIdx) 
            this.requestAnatomyPlacement(anatomyPlacements[nextIdx]);

        // Otherwise done!
    }

    // Request anatomy location
    requestAnatomyPlacement = async (anatomyName) => {

        // Initialize the new metric
        let metric = {
            dataType: 'label3D',
            text: anatomyName,
        }

        // Set the save location in the state. 
        await this.setState({
            newMetricData: {
                location: "GlobalAnnotations",
                newMetric: metric,
                isAnatomyPlacement: true,
                injuryLocation: 'blah',
                injuryType: 'blah',
            }
        })

        // Request metric
        await this.requestAnnotation(metric.dataType, metric.text, 'Anatomy Placement');

    }

    // Begin an antomy setting operation
    beginAnatomySetting = async () => {
        
        // Get the first anatomy name 
        const firstAnatomy = this.props.studyTypes[this.props.project.studyType].orderedPlacements[0];

        await this.requestAnatomyPlacement(firstAnatomy);

    }

    
    render = () => {

        document.title = "Radiology Report Editor - Multus Medical"

        // Make sure injuries exist
        let injuries=(this.props.project && this.props.project.ai.injury.injuries) ? this.props.project.ai.injury.injuries : [];

        return (
            <div className='generatorPage'>
                <HotKeys keyMap={this.hotkeyMap} handlers={this.hotkeyHandlers}>

                    {/* Report Generator Header */}
                    <ReportGeneratorHeader 
                        project={this.props.project}
                        sessionUUID={this.state.sessionUUID}
                        injuryTypes = {Object.values(this.props.injuryTypes)}
                        injuryCloudSynced = {this.props.injuryCloudQueue.synced}
                        annotationCloudSynced = {this.props.annotationCloudQueue.synced}
                        // Functions
                        shiftProjectIndex = {this.shiftProjectIndex}   
                        createCustomInjury = {this.createCustomInjury}
                        toggleInjuryTypeGenerator={this.toggleInjuryTypeGenerator}
                        setViewerMode={(e) => this.setState({inViewerMode: e})}
                        newDicomViewerWindow={this.newDicomViewerWindow}

                    />

                    {this.state.inViewerMode &&
                    <DicomViewerPage 
                            user = {this.props.user}
                            project = {this.props.project}
                            dicomImages={this.props.dicomImages}
                            injuryMetrics={this.props.injuryMetrics}
                            loadProject={this.props.loadProject}
                            loadDefaultProject={this.props.loadDefaultProject}
                            blockedLocations = {this.state.blockedInjuryLocations}
                            blockedInjuryTypes = {this.state.blockedInjuryTypes}
                            blockedInjuries = {this.state.blockedInjuries}
                            openEditorObject = {this.state.openEditorObject}
                            hoveredEditorObjects = {this.state.hoveredEditorObjects}
                            activeRequest = {this.state.activeRequest}
                            inGenerator = {true}
                            requestingAnatomy = {this.state.newMetricData && this.state.newMetricData.isAnatomyPlacement}
                            // Annotation Functions
                            saveAnnotation={this.props.saveAnnotation}
                            updateAnnotation={this.props.updateAnnotation}
                            syncAnnotationsToCloud={this.props.syncAnnotationsToCloud}
                            deleteAnnotation={this.props.deleteAnnotation}
                            queueAnnotationSave={this.props.queueAnnotationSave}
                            // Injury Functions
                            updateInjuryMetric={this.props.updateInjuryMetric}
                            deleteInjuryMetric = {this.props.deleteInjuryMetric}
                            saveInjuriesToCloud={this.props.saveInjuriesToCloud}
                            queueInjurySave={this.props.queueInjurySave}
                            resetQueuedInjurySave={this.props.resetQueuedInjurySave}
                            // Communication
                            receiveSiblingAnnotations={this.props.receiveSiblingAnnotations}
                            receiveSiblingInjuries = {this.props.receiveSiblingInjuries}
                            completeGeneratorRequest={this.receiveRequestedAnnotation}
                            clearActiveRequest={this.clearActiveRequest}
                            // User
                            saveDefaultConfiguration = {this.props.saveDefaultConfiguration}
                            // Other
                            beginAnatomySetting = {this.beginAnatomySetting}


                    />}

                    {/* Report Generator Container */}
                    <div className={'generatorContainer' + (this.state.inViewerMode ? ' generatorContainerViewerMode' : '')}>

                        {/* Editor */}
                        <div className='reportEditorContainer'>
                            <ReportEditor 
                                user={this.props.user}
                                dropdownState = {this.state.dropdownState}
                                annotations={(this.props.project && this.props.project.dicomAnnotations) ? this.props.project.dicomAnnotations : []}
                                injuries={injuries}
                                injuryTypes = {this.props.injuryTypes}
                                blockedInjuryLocations = {this.state.blockedInjuryLocations}
                                blockedInjuries = {this.state.blockedInjuries}
                                studyType = {this.props.project ? this.props.project.studyType : null}
                                // Injury Functions
                                createNewInjury = {this.createNewInjury}
                                updateInjury = {this.updateInjury}
                                queueInjurySave = {this.queueInjurySave}
                                resetQueuedInjurySave = {this.props.resetQueuedInjurySave}
                                saveInjuriesToCloud = {this.props.saveInjuriesToCloud}
                                deleteInjury={this.deleteInjury}
                                editInjuryType={this.editInjuryType}
                                // Toggling Functions
                                toggleLocationVisibility={this.toggleLocationVisibility}
                                toggleInjuryVisibility={this.toggleInjuryVisibility}
                                toggleInjuryLocation = {this.toggleLocationDrop}
                                toggleInjuryDrop = {this.toggleInjuryDrop}
                                openAllInjuries = {this.openAllInjuries}
                                // Communication
                                requestInjuryMetric = {this.requestInjuryMetric}
                                openSliceInViewer = {this.openSliceInViewer}
                                openPointSlices = {this.openPointSlices}
                                // Render Functions
                                setEditorHoveredObjects = {this.setEditorHoveredObjects}
                                // annotation functions
                                deleteAnnotation={this.deleteAnnotation}
                                //clear functions
                                clearAllInjuries={this.props.clearAllInjuries}
                                clearAllAnnotations={this.props.clearAllAnnotations}
                            />
                        </div>

                        {/* Preview */}
                        <div className='reportPreviewContainer'>
                            <ReportPreview 
                                project = {this.props.project}
                                studyTypes = {this.props.studyTypes}
                                injuries={injuries}
                                injuryTypes = {this.props.injuryTypes}
                                openAllInjuries={this.openAllInjuries}
                                openSingleInjury={this.openSingleInjury}
                                approveReport={this.props.approveReport}
                            />
                        </div>

                    </div>

                    {/* Injury Type Generator */}
                    {this.state.injuryTypeGenerator.isVisible ?
                        <InjuryTypeGenerator 
                            isVisible={this.state.injuryTypeGenerator.isVisible}
                            height={this.state.injuryTypeGenerator.height}
                            closeInjuryGenerator={this.toggleInjuryTypeGenerator}
                            saveGeneratedInjuryType= {this.saveGeneratedInjuryType}
                            injuryTypes={this.props.injuryTypes}
                            injuryToLoad={this.state.injuryTypeGenerator.injuryType}
                        /> : ''
                    }
                </HotKeys>
            </div>
        )
    }

}

export default ReportGeneratorPage;