// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

export const toolToType = {

    'Label': 'label',
    'Label3D': 'label3D',
    'Rectangle': 'rectangle',
    'Arrow': 'arrow',
    'Circle': 'circle',
    'Angle': 'angle',
    'IntersectAngle': 'angle',
    'Length': 'length'
}

export const typeToTool = {

    'label': 'Label',
    'label3D': 'Label3D',
    'rectangle': 'Rectangle',
    'arrow': 'Arrow',
    'circle': 'Circle',
    'angle': 'IntersectAngle',
    'length': 'Length'
}

export const globalAnnotationTypes = [
    'label3D'
]; 