// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory


// Aggregate injury summaries at a location
export const getLocationSummary = (locationData, studyTypeName, studyTypeData) => {

    // All injuries
    const locInjuries = locationData.objects.filter(elem => elem.summary);

    // NON GLOBAL: Find any intro text if possible
    let preText = '';
    if (locationData.location !== studyTypeName) {
        const locationNaming = studyTypeData.anatomy.find(elem => elem.anatomyName === locationData.location)
        if (locationNaming)
            preText = locationNaming.abbreviation + ': ';
    }

    // Aggregate the summary
    let locSummary = preText + (locInjuries.length > 0 ? locInjuries.map(o => o.summary).join('. ') : '');

    // Add final period
    if (locSummary.length > preText.length) 
        locSummary += '.';

    return locSummary;

}


