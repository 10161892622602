// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import '../../../styles/DicomViewer/labelTextArea.css'

class LabelTextArea extends Component {


    state = {

        hovered: false,

    }

    
    // Prevent cursor rendering over annotation editor!
    onContainerMouseMove = (e) => {
        
        // If not in editing state: don't operate
        if (!this.props.editable) {
            e.preventDefault();
            return;
        }

        // Prevent cursor from following
        e.stopPropagation();
        this.props.voidCursor();

    }


    handleMouseDown = (e) => {

        // If not in editing state: don't operate
        if (!this.props.editable) {
            e.preventDefault();
        }

        // Delete operation? 
        else if (this.props.deleteActive) {
            e.preventDefault();
            this.props.deleteLabel();
        }

        else
            e.stopPropagation();

    }

    render = () => {
        
        return (

            <div className="labelContainer"
                style={{
                    left: this.props.left, 
                    top: isNaN(this.props.top - this.props.height) ? 0 : this.props.top - this.props.height, 
                    width: this.props.width, 
                    height: this.props.height
                }}
                onMouseMove={this.onContainerMouseMove}
                onMouseDown={this.handleMouseDown}
                onWheel={(e) => {e.stopPropagation()}}
                onMouseEnter={() => this.setState({hovered: true})}
                onMouseLeave={() => this.setState({hovered: false})}
            >

                {/* Text Area  */}
                <textarea   className={"labelTextArea" + ((this.state.hovered && this.props.deleteActive) ? ' labelTextAreaDelete' : this.props.editorHovered ? ' labelTextAreaHovered' : '')} 
                            ref = 'labelTextArea'
                            spellCheck = {false}
                            style = {{width: (this.props.width - 10), height: this.props.height, cursor: (this.props.editable? '' : 'none')}}
                            value={this.props.annotationText}
                            onChange={async (e) => {
                                this.cursor = e.target.selectionStart;
                                await this.props.setText(e.target.value);
                                this.refs.labelTextArea.selectionEnd = this.cursor;
                            }} 
                />

                {/* Indicator text for reference */}
                {this.props.indicatorText && 
                    <div className='labelIndicator'>
                        {this.props.indicatorText}
                    </div>
                }
                
                

            </div>
        )
    }
}

export default LabelTextArea;