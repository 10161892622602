// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Switch from 'react-switch';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { getInjurySummary } from '../../../utilities/injuryTemplateFill'

// Styles
import "../../../styles/ReportGenerator/dropdown.css";
import "../../../styles/ReportGenerator/injuryDrop.css";

// Utils
import titleCase from '../../../utilities/titleCase';

const TEXT_SAVE_INTERVAL = 5000;


class InjuryDrop extends Component {


    state = {

        // Typing timer for cloud save
        typingTimer: null,


    }

    // Toggle the injury dropdown
    toggleDropdown = () => {
        this.props.toggleInjuryDrop(this.props.injury);
    }

    // Toggle visibility of the injury
    toggleVisibility = (e) => {

        e.stopPropagation();

        // Pass filter to outter
        this.props.toggleInjuryVisibility(this.props.injury.type);
    }

    deleteInjury = (e) => {

        e.stopPropagation();

        this.props.deleteInjury(this.props.objectIdx);

    }


    // Generic function for updating an injury field
    updateInjuryField = (fieldName, newValue, cloudSave=false) => {

        // Update injury 
        let injury = {...this.props.injury, [fieldName]: newValue};

        // Pass to parent
        this.props.updateInjury(injury, this.props.objectIdx, cloudSave, fieldName === 'summary' ? false : true);

    }

    // Function to open injury metric in Dicom Viewer
    editInjuryMetric = (metric) => {

        // Find the series name and slice idx
        const seriesName = metric.seriesName;
        const sliceIdx = metric.sliceIdx; 

        if (!seriesName || !Number.isInteger(sliceIdx))
            toast.error('No series / slice reference for object.');

        // Open the slice
        else 
            this.props.openSliceInViewer(seriesName, sliceIdx);

    }

    // Function to clear injury metric 
    deleteInjuryMetric = (metric) => {
        
        const metricName = metric.name;

        // Delete the property
        let injury = {...this.props.injury};
        injury.rationale.metrics = injury.rationale.metrics.filter( elem=> elem.name !== metricName);
        
        // Search for other metrics referencing this dicom.
        const sameRefMetrics = injury.rationale.metrics.filter( elem=> {
            return (elem.seriesName === metric.seriesName && elem.sliceIdx === metric.sliceIdx);
        });

        // Found no same reference dicoms? Delete the dicom reference. 
        if (!sameRefMetrics || sameRefMetrics.length === 0)
            injury.rationale.dicoms = injury.rationale.dicoms.filter( elem=> (elem.seriesName !== metric.seriesName && elem.sliceIdx !== metric.sliceIdx));

        // Pass to parent
        this.props.updateInjury(injury, this.props.objectIdx, true);


    }


    generateReportSummary = async () => {
        
        const injuryType = this.props.injuryType;
        const injury = this.props.injury;

        try {
            // Get the template
            if (!injuryType || !injuryType.template) 
                return toast.error("No injury template found for " + injury.type);
            
            const summary = getInjurySummary(injuryType, injury);
            await this.updateInjuryField("summary", summary, true);
        }
        catch (error) {
            toast.error("Injury summary generation error: " + error.message);
        }
    }

    // Update the Report Generator to know the hovered metric
    setHoveredObject = (metricData, idx) => {

        // Case of nulling
        if (metricData === null)
            this.props.setEditorHoveredObjects([]);

        // Else, add this object!
        else {
            // Data specifying the location of the metric
            const data = {
                location: "Injuries",
                seriesName: metricData.seriesName,
                sliceIdx: metricData.sliceIdx,
                injuryLocation: this.props.injury.location,
                injuryType: this.props.injury.type,
                metricIdx: idx,
            };

            this.props.setEditorHoveredObjects([data]);

        }

        return;
    }


    // ------ Injury Property Rendering ------

    // Bool Fields
    renderBoolField = (fieldName, fieldValue) => {

        let title = titleCase(fieldName);

        let value = typeof fieldValue !== 'boolean' ? false : fieldValue;

        return (
            <div className='injuryFieldContainer' key={fieldName}>

                <div className='propertyField'>
                    {title}
                </div>

                <div className='booleanFieldValue'>
                    <Switch
                        className='booleanFieldSwitch' 
                        checked={value} 
                        onChange={() => this.updateInjuryField(fieldName, !value, true)}
                        height={18} width={36}
                    />
                </div>

            </div>
        )

    }

    // Text Fields
    renderTextField = (fieldName, fieldValue) => {

        let title = titleCase(fieldName);

        return (
            <div className='injuryFieldContainer' key={fieldName}>

                <div className='propertyField'>
                    {title}
                </div>

                <div className='textFieldValue'>
                    <input
                        className='textFieldInput'
                        type='text' value={fieldValue}
                        onChange={(e) => this.updateInjuryField(fieldName, e.target.value)}
                        onKeyDown={this.props.resetQueuedInjurySave}
                        onKeyUp={() => this.props.queueInjurySave(this.props.injury.location, TEXT_SAVE_INTERVAL)}
                        placeholder={"Enter " + fieldName + " value"} 
                    />
                </div>

            </div>
        )

    }

    // Number Fields
    renderNumberField = (fieldName, fieldValue) => {

        let title = titleCase(fieldName);

        return (
            <div className='injuryFieldContainer' key={fieldName}>

                <div className='propertyField'>
                    {title}
                </div>

                <div className='textFieldValue'>
                    <input
                        className='textFieldInput'
                        type='text' value={fieldValue}
                        onChange={(e) => {
                            if (!isNaN(e.target.value))
                                this.updateInjuryField(fieldName, e.target.value)
                        }}
                        onKeyDown={this.props.resetQueuedInjurySave}
                        onKeyUp={() => this.props.queueInjurySave(this.props.injury.location, TEXT_SAVE_INTERVAL)}
                        placeholder={"Enter " + fieldName + " value"} 
                    />
                </div>

            </div>
        )

    }

    // Option Fields
    renderOptionField = (fieldName, fieldValue, options) => {

        let title = titleCase(fieldName);

        // Map the options
        let optionsList = options.map((elem, idx) => { return { label: elem, value: idx } });

        return (
            <div className='injuryFieldContainer' key={fieldName}>

                <div className='propertyField'>
                    {title}
                </div>

                <Select
                    ref="optionSelect"
                    key="optionSelect"
                    className="optionSelect-container"
                    classNamePrefix="optionSelect"
                    options={optionsList}
                    onChange={(selectedOption) =>this.updateInjuryField(fieldName, selectedOption.label, true)}
                    placeholder={"Select " + fieldName}
                    value={{ label: fieldValue , value: optionsList.indexOf(fieldValue) }}
                />
                

            </div>
        )

    }

    // Metric field

    renderMetricField = (metric, idx) => {

        // Format display data
        let dataDisplay = '';

        if (metric.dataType === 'angle') {
            dataDisplay = metric.value.toFixed(2).toString() + '°';
        }

        else if (metric.dataType === 'length') {
            dataDisplay = metric.value.toFixed(2).toString() + ' mm';
        }

        // else if (metric.dataType === 'rectangle') {
        //     dataDisplay = metric.points.map( elem => [elem.x.toFixed(2), elem.y.toFixed(2)].toString() + ", ");
        // }

        // else if (metric.dataType === 'circle') {
        //     dataDisplay = "C: " + [metric.points[0].x.toFixed(2), metric.points[0].y.toFixed(2)].toString() + " , Rad: " + metric.radius.toFixed(2);
        // }

        else if (metric.dataType === 'probability')
            dataDisplay = (100.0 * metric.value).toFixed(2) + "%";

        return ( 
            <div className='injuryFieldContainer' key={idx}>

                {/* Field */}
                <div className='injuryMetricField'
                     onMouseEnter = {() => this.setHoveredObject(metric, idx)}
                     onMouseLeave = {() => this.setHoveredObject(null, idx)}
                >
                    <div className='injuryMetricField_metricName'>{titleCase(metric.name)}</div>
                    {metric.seriesName && metric.seriesName.length > 0 && <div className='injuryMetricField_seriesName'>{'(' + metric.seriesName + ')'}</div>}
                </div>
                
                {/* Value */}
                <div className='injuryMetricValue'>
                    {dataDisplay}
                </div>

                {/* Edit */}
                {metric.dataType === 'probability' ? '' : <img
                    className='injuryMetricEdit' 
                    src='/resources/icons/edit.png'
                    alt='edit'
                    title='Edit injury metric' 
                    onClick={() => this.editInjuryMetric(metric)}
                />}

                {/* Delete */}
                <img
                    className="injuryMetricDelete"
                    src="/resources/icons/close.png"
                    alt='delete'
                    title="Delete injury metric"
                    onClick={() => {this.deleteInjuryMetric(metric)}}
                />
                    
            </div>
        );


    }

    // Possible un-initialized metric field
    renderPossibleMetric = (metricType) => {

        return ( 
            <div className='injuryFieldContainer' key={metricType.name}>

                {/* Field */}
                <div className='injuryMetricField'>
                    {titleCase(metricType.name)}
                </div>

                {/* Add */}
                <img
                    className="injuryMetricAdd"
                    src="/resources/icons/Cursor.png"
                    alt='delete'
                    title="Add injury metric"
                    onClick={() => this.requestInjuryMetric(metricType)}
                />
                    
            </div>
        );

    }

    // Request a specific injury metric, to be saved to this injury
    requestInjuryMetric = (metricType) => {

        // Package the injury location, for referenct

        // Call parent for request. 
        this.props.requestInjuryMetric(this.props.injury.location, this.props.injury.type, metricType);

    }

    render = () => {

        return (
            <div className='objectDropContainer'>

                {/* Header */}
                <div className='objectDropHeader'
                    onClick = {this.toggleDropdown}
                    style={this.props.dropdownOpen ? {
                        backgroundColor: 'rgb(60,60,60)'
                    } : {}}
                >
                    <div className='objectDropTitle'>
                        {this.props.dropdownOpen ? "▾" : "▸"}&nbsp;&nbsp;&nbsp;{this.props.injury.type}
                    </div>

                    {/* Visibility button */}
                    <img className='objectButton'
                        style={{ right: '30px' }}
                        src={'/resources/icons/' + (this.props.isBlocked ? 'in' : '') + 'visible.png'}
                        alt={this.props.isBlocked ? 'Invisible' : 'Visible'}
                        title='Toggle injury visibility'
                        onClick={this.toggleVisibility}
                    />

                    {/* Delete button */}
                    <img className='objectButton' 
                        src='/resources/icons/delete.png' 
                        alt='Delete' 
                        title='Delete injury'
                        onClick={this.deleteInjury}
                    />
                
                </div>

                {/* Dropdown menu */}
                {this.props.dropdownOpen && 
                <div className='objectDropdown'
                    onClick={e => e.stopPropagation()}
                >
                                        
                    {/* Injury Attributes */}
                    {Object.keys(this.props.injury).map( (injuryKey, idx) => {

                        // Don't render rationale
                        if (["type", "summary", "rationale", "location"].includes(injuryKey) || !this.props.injuryType)
                            return '';

                        const value = this.props.injury[injuryKey];
                        const property = this.props.injuryType.properties.find( (prop ) => prop.name === injuryKey);

                        // Don't render non-known properties
                        if (!property)
                            return '';

                        const type = property.type;

                        if (type === 'text') 
                            return this.renderTextField(injuryKey, value);
                        
                        else if (type === 'boolean') 
                            return this.renderBoolField(injuryKey, value);
                        
                        else if (type === 'option')
                            return this.renderOptionField(injuryKey, value, property.options);

                        else if (type === 'number')
                            return this.renderNumberField(injuryKey, value);

                        else 
                            return '';
                      
                    })}

                    {/* Injury Metrics */}
                    {this.props.injury.rationale && this.props.injury.rationale.metrics && 
                     this.props.injury.rationale.metrics.map( (metric, idx) => {

                        // TODO @Marcel: Check for non-display flag from bot instead!
                        if (metric.name === 'Bot region of interest')
                            return '';
                        
                        return this.renderMetricField(metric, idx);

                     })}

                     {/* Un-Initialized Injury Metrics */}
                     {this.props.injuryType.metrics.map ( metricType => {
                        
                        // Check if the metric exists
                        let metricFound;
                        if (!this.props.injury.rationale || !this.props.injury.rationale.metrics)
                            metricFound = false;
                        else
                            metricFound = this.props.injury.rationale.metrics.find( (elem) => elem.name === metricType.name);

                        // Metric exists
                        if (metricFound)
                            return '';

                        return this.renderPossibleMetric(metricType);

                     })}

                    {/* Injury Summary */}
                    <textarea
                        className="injurySummary"
                        value={this.props.injury.summary}
                        onChange={(e) => this.updateInjuryField("summary", e.target.value)}
                        onKeyDown={this.props.resetQueuedInjurySave}
                        onKeyUp={() => this.props.queueInjurySave(this.props.injury.location, TEXT_SAVE_INTERVAL)}
                    ></textarea>
                    
                    {/* Regenerate Summary */}
                    <img
                        className="injurySummaryRegenerate"
                        src="/resources/icons/refresh.png"
                        alt="Regenerate"
                        title="Regenerate diagnostic summary from properties and metrics."
                        onClick={(e) => {
                            e.stopPropagation();
                            this.generateReportSummary();
                        }}
                    />

                </div>}

            </div>
            

        )

    }


}


export default InjuryDrop;