// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import HeaderButton from '../../HeaderButton';
import ViewerSelectPopup from '../SubComponents/ViewerSelectPopup';

import { viewerConfigurations } from '../../../constants/viewerConfigurations';

import '../../../styles/header.css';

const tools = [
    { name: "Label", toolTip: "Attach an annotation to a single slice" },
    { name: "Label3D", toolTip: "Attach a label to a location visible on all series" },
    { name: "Arrow", toolTip: "Draw an arrow to a point on a single slice" },
    { name: "Circle", toolTip: "Draw a circle on a single slice" },
    { name: "Rectangle", toolTip: "Draw a rectangle/box on a single slice" },
    {name: "IntersectAngle", toolTip: "Measure an angle between two lines" },
    { name: "Angle", toolTip: "Measure an angle between three points" },
    { name: "Length", toolTip: "Measure the distance between two points" },
    { name: "Delete", toolTip: "Delete an annotation" }
];

class DicomViewerToolbar extends Component {

    state = {

        // Viewer selection pop up
        viewerSelectOpened: false,
    }

    // ----- TOOL HANDLING -----
    
    onToolClick = async (toolName) => {

        // Unselect tool
        if (this.props.activeTool === toolName)
            this.props.onToolUpdate("");

        // Select tool
        else
            this.props.onToolUpdate(toolName);

    }

    // ----- VIEWER SELECTION -----

    getViewerSelectStyleModifier = () => {
        if (this.state.viewerSelectOpened)
            return {
                display: 'block'
            };

        else
            return {
                display: 'none'
            };
    }

    // Get the attribute name of the viewer config that matches the config
    getAttrNameFromViewerConfigurations = (config) => {
        for(let attributeName in viewerConfigurations) {
            if(viewerConfigurations[attributeName] === config) {
                return attributeName;
            }
        }
    }

    // Set the number of viewers
    setViewerConfiguration = (attributeName) => {

        // Set the global dicom viewer config
        this.props.setViewerConfiguration(viewerConfigurations[attributeName]);

        // Close pop up
        this.toggleViewerSelectPopup();
    }

    toggleViewerSelectPopup = () => {
        this.setState({viewerSelectOpened: !this.state.viewerSelectOpened});
    }

    render = () => {

        return (
            <div>

                <div className='viewerToolsContainer' style={{position: 'absolute', right: this.props.right}}>

                    {/* Active Request? */}
                    {this.props.activeRequest && 
                        <div className='activeRequestIndicator'>
                            Creating <span style={{color: 'rgba(180,180,255,0.8'}}>{this.props.activeRequest.name}</span> Annotation for {this.props.activeRequest.injuryName}
                            </div>    
                    }

                    {/* Cancel Indicator */}
                    {(this.props.activeRequest || this.props.withinOperation ) &&
                        <div className='cancelButton' onClick={this.props.cancelCurrentOp}>
                            Cancel
                        </div>
                    }

                    {/* Dicom Viewer Tools */}
                    {tools.map((tool, idx) => {

                        // Render location annotation setting?
                        if (tool.name === 'Label3D' && this.props.inGenerator) {

                            // Decide upon name 
                            const toolName = this.props.requestingAnatomy ? 'Location' : 'Label3D';

                            // Render with double click
                            return <HeaderButton
                                key={"headerBtn_" + toolName}
                                name={toolName}
                                toolTip={tool.toolTip}
                                icon={"/resources/tools/" + toolName + ".png"}
                                onButtonClick = {() => this.onToolClick('Label3D')}
                                onButtonDoubleClick = {this.props.beginAnatomySetting}
                                active={this.props.activeTool === 'Label3D'}
                            />  

                        }

                        return <HeaderButton
                            key={"headerBtn_" + tool.name}
                            name={tool.name}
                            toolTip={tool.toolTip}
                            icon={"/resources/tools/" + tool.name + ".png"}
                            onButtonClick = {() => this.onToolClick(tool.name)}
                            active={this.props.activeTool === tool.name}
                        />    
                    })}

                    {/* Dicom viewer select button*/}
                    <HeaderButton
                        id={"btnViewerSelect"}
                        ref={"btnViewerSelect"}
                        name={"btnViewerSelect"}
                        toolTip={"Select Viewer Configuration"}
                        icon={"/resources/icons/" + this.getAttrNameFromViewerConfigurations(this.props.viewerConfiguration) + ".png"} 
                        onButtonClick = { () => {this.toggleViewerSelectPopup()}}
                        active={this.state.viewerSelectOpened}
                    />  

                    {/* Config Editor Menu button*/}
                    <HeaderButton
                        id={"btnConfigEditor"}
                        ref={"btnConfigEditor"}
                        name={"btnConfigEditor"}
                        toolTip={"Edit Default Configurations"}
                        icon={"/resources/icons/editConfig.png"} 
                        onButtonClick = { this.props.toggleConfigMenu}
                        active={this.props.configEditorOpen}
                    />  

                    {/* Dicom viewer menu */}
                    <ViewerSelectPopup 
                        ref="ViewerSelectPopup" 
                        getStyleModifier={this.getViewerSelectStyleModifier}
                        setViewerConfiguration={this.setViewerConfiguration}
                    /> 

                </div>  



            </div>
        )
    }

}


export default DicomViewerToolbar;