// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import InjuryLocationDrop from './InjuryLocationDrop';
import AnnotationLocationDrop from './AnnotationDrop';
import ConfirmButton from './../../ConfirmButton';

// Styles
import "../../../styles/ReportGenerator/reportGenerator.css";
 
class ReportEditor extends Component {


    state = {

    }

    // Injury Comparison my location function
    injuryCompare = (a, b) => {
        if ( a.location < b.location ){
            return -1;
          }
          if ( a.location > b.location ){
            return 1;
          }
          return 0;
    }

    render = () => {
        
        return (
            <div>
                
                {/* Injury Location Dropdown */}

                <div className='reportEditorSectionHeader'>
                    
                    Diagnostic Indicators

                    {/* Delete ALL button */}
                    <div className='btnConfirm' >
                        <ConfirmButton
                            interval={150}
                            maxRatio={2}
                            height={20}
                            float={'right'}
                            confirmTerm='Clear'
                            txtStyle={{ bottom: '10.5px' }}
                            callback={this.props.clearAllInjuries}
                        />
                    </div>

                </div>

                {Object.keys(this.props.dropdownState).map ( (dropdownLocation, idx) => {

                    // Find any injuies within this dropdown 
                    let injuries = this.props.injuries.find( elem => elem.location === dropdownLocation);

                    return <InjuryLocationDrop
                        user={this.props.user}
                        key = {idx}
                        injuryLocation = {dropdownLocation}    
                        injuries = {injuries ? injuries.objects : []}
                        dropdownData = {this.props.dropdownState[dropdownLocation]}
                        injuryTypes = {this.props.injuryTypes}
                        isBlocked = {this.props.blockedInjuryLocations.includes(dropdownLocation)}
                        blockedInjuries = {this.props.blockedInjuries}
                        studyType = {this.props.studyType}
                        // Injury Functions
                        createNewInjury = {this.props.createNewInjury}
                        deleteInjury = {this.props.deleteInjury}
                        updateInjury = {this.props.updateInjury}
                        saveInjuriesToCloud = {this.props.saveInjuriesToCloud}
                        queueInjurySave = {this.props.queueInjurySave}
                        resetQueuedInjurySave = {this.props.resetQueuedInjurySave}
                        requestInjuryMetric={this.props.requestInjuryMetric}
                        editInjuryType={this.props.editInjuryType}
                        // Toggling Functions
                        toggleLocationVisibility={() => this.props.toggleLocationVisibility(dropdownLocation)}
                        toggleInjuryVisibility={this.props.toggleInjuryVisibility}
                        toggleInjuryLocation = {this.props.toggleInjuryLocation}
                        toggleInjuryDrop = {this.props.toggleInjuryDrop}
                        openAllInjuries = {() => this.props.openAllInjuries(dropdownLocation)}
                        // Displaying 
                        openSliceInViewer = {this.props.openSliceInViewer}
                        setEditorHoveredObjects = {this.props.setEditorHoveredObjects}
                    />

                })}


                {/* Annotation Location Dropdowns */}

                <div className='reportEditorSectionHeader'>
                    
                    Non-Diagnostic Annotations
                     
                    {/* Delete ALL button */}
                    <div className='btnConfirm' >
                        <ConfirmButton
                            interval={250}
                            maxRatio={2}
                            height={20}
                            float={'right'}
                            confirmTerm='Clear'
                            txtStyle={{ bottom: '10.5px' }}
                            callback={this.props.clearAllAnnotations}
                        />
                    </div>

                </div>


                {Object.keys(this.props.annotations).map( (seriesName, idx) => {

                    const seriesAnnotations = this.props.annotations[seriesName];

                    // Dropdowns for sereis locations
                    if (    (seriesName === 'global' && seriesAnnotations.length>0) ||
                            (seriesAnnotations && Object.values(seriesAnnotations).find(elem=>elem.length>0))) {

                        // Render Series Location dropdown. 
                        return <AnnotationLocationDrop 
                            key = {idx}
                            seriesName = {seriesName}
                            annotations = {seriesAnnotations}
                            openSliceInViewer = {this.props.openSliceInViewer}
                            openPointSlices = {this.props.openPointSlices}
                            deleteAnnotation={this.props.deleteAnnotation}
                        />

                    }

                    else
                        return '';

                })}


            </div>
        )
    }


}

export default ReportEditor;