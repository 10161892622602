// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Select from 'react-select';

import '../../../styles/ReportGenerator/injuryTypeGenerator.css';

const propertyOptions = [
    {
        name: 'text',
        dropdownType: 'text',
        options: [],
    },
    {
        name: 'number',
        dropdownType: 'number',
        options: [] // empty array for textbox,
    },
    {
        name: 'boolean',
        dropdownType: 'boolean',
        options: [ 'true', 'false' ],
    },
    {
        name: 'option',
        dropdownType: 'option',
        options: []
    }
];

const metricOptions = [
    {
        name: 'angle',
        dropdownType: 'angle',
    },
    {
        name: 'probability',
        dropdownType: 'probability',
    },
    {
        name: 'length',
        dropdownType: 'length',
    },
    {
        name: 'circle',
        dropdownType: 'circle',
    },
    {
        name: 'rectangle',
        dropdownType: 'rectangle',
    },
    {
        name: 'label',
        dropdownType: 'label',
    },
];


class InjuryTypeGeneratorProperty extends Component {

    state = {
        dataTypeOptions: [],
    }

    componentDidMount = async () => {

        // TODO @Luke: add custom options here once they exist
        await this.setState({
            dataTypeOptions: this.props.propType === 'metric' ? metricOptions : propertyOptions,
        });
    }

    // #region helper functions
    
    // convert string to Select dropdown object 
    getDropdownItemObjectFromString = str => {
        try {
            return {
                label: str.substring(0, 1).toUpperCase() + str.substring(1),
                value: str
            };
        }
        catch (err) {
            return {}
        }
    }

    // get matching dataTypeOption by name
    getDataTypeOptionByName = name => {
        try {
            return this.state.dataTypeOptions.filter(option => option.name === name)[0];
        }
        catch (err) {
            console.log('error getting option', err)
            return undefined;
        }
    }

    // get dropdown object for data type
    getDataTypeDropdownItemObject = () => {
        try {
            let opt = this.getDataTypeOptionByName(this.props.dataType)
            
            if (!opt)
                return {};

            let res = this.getDropdownItemObjectFromString(opt.dropdownType);
            return res;
        }
        catch (err) {
            console.log('error getting dropdown object:', err)
            return {}
        }
    }

    changePropertyDataType = async (newValue) => {

        // if 'option' type is chosen, start that process and skip the rest
        if (newValue === 'option') {
            this.props.startPropertyOptionChooser(this.props.propIdx, this.props.dataType !== 'option');
            return;
        }

        let opt = this.getDataTypeOptionByName(newValue);
        let defaultValue = opt.options && opt.options.length > 0 ? opt.options[0] : '';

        // set default value, options, and datatype
        this.props.changePropertyDefaultValue(this.props.propType, this.props.propIdx, defaultValue);
        this.props.changePropertyDataType(this.props.propType, this.props.propIdx, newValue, opt.options);
    }

    // if no default available, use first option
    getDefaultValue = () => {
        return this.props.options && this.props.options.length && this.props.defaultValue.length === 0 ? this.props.options[0] : this.props.defaultValue;
    }

    render = () => {
        return (
            <div className='injuryTypeGeneratorProperty'>

                {/* Key Editor */}
                <div className = 'keyContainer'>
                    <div className='propertyKeyLabel txtLabel'>
                        Key
                    </div>

                    <div className='propertyKeyTextBox'>
                        <input className='generatorInput'
                            id="propertyKey_textBox"
                            ref="propertyKey_textBox"
                            value={this.props.propName}
                            onChange={async (evt) => {
                                await this.props.changePropertyName(this.props.propType, this.props.propIdx, evt.target.value);
                            }}
                        />
                    </div>
                </div>

                {/* Type Editor */}
                <div className = 'typeContainer'>

                    <div className='propertyTypeLabel txtLabel'>
                        Type
                    </div>

                    {/* Property Select dropdown */}
                    <div className='propertyTypeSelect'>
                        <Select 
                            ref="generatorSelect"
                            key="generatorSelect"
                            className="generatorSelect-container"
                            classNamePrefix="generatorSelect"
                            value={this.getDataTypeDropdownItemObject()}                     
                            closeMenuOnSelect={true}
                            options={this.state.dataTypeOptions.map(elem => {return this.getDropdownItemObjectFromString(elem.dropdownType)})} 
                            onChange={newValue => this.changePropertyDataType(newValue.value)}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}

                            // components={{DropdownIndicator: () => null}}
                        />
                    </div>

                    {/* Edit Options button */}
                    {this.props.options && this.props.options.length > 0 && this.props.dataType === 'option' &&
                        <img
                            className='injuryGeneratorButton btnEditOptions'
                            src='/resources/icons/edit.png'
                            alt='edit options'
                            title='Edit options'
                            onClick={() => this.props.startPropertyOptionChooser(this.props.propIdx, this.props.dataType !== 'option')}
                        />
                    }

                </div>

                {/* Default Value for Properties */}
                {this.props.propType !== 'metric' && 
            
                    <div className='defaultContainer'>

                        <div className='defaultLabel txtLabel'>
                            Default
                        </div>

                        {/* Render Dropdown Opt? */}
                        {this.props.options && this.props.options.length > 0 &&                        
                            <div className='defaultSelect'>
                                <Select
                                    ref="generatorSelect"
                                    key="generatorSelect"
                                    className="generatorSelect-container"
                                    classNamePrefix="generatorSelect"
                                    value={this.getDropdownItemObjectFromString(this.getDefaultValue())}
                                    closeMenuOnSelect={true}
                                    options={this.props.options ? this.props.options.map(elem => this.getDropdownItemObjectFromString(elem)) : []}
                                    onChange={newValue => this.props.changePropertyDefaultValue(this.props.propType, this.props.propIdx, newValue.value)}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    menuPortalTarget={document.body}
                                />
                            </div>                        
                        }

                        {/* Render Text Box Opt? */}
                        {(!this.props.options || !this.props.options.length > 0) &&                        
                            <div className='defaultTextBox' > 
                                <input className='generatorInput'
                                    id="defaultValueElement"
                                    ref="defaultValueElement"
                                    value={this.getDefaultValue()}
                                    onChange={async (evt) => this.props.changePropertyDefaultValue(this.props.propType, this.props.propIdx, evt.target.value)}
                                />                        
                            </div>
                        }

                    </div>            
                }

                <img
                    className = 'injuryGeneratorButton propertyDeleteButton' 
                    src = '/resources/icons/close.png'
                    alt = 'cancel'
                    title = 'Delete property'
                    onClick = {() => this.props.removeProperty(this.props.propType, this.props.propIdx)}    
                />


                {/* Delete Property Button */}

            </div>
        );
    }
}

export default InjuryTypeGeneratorProperty;




