// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import { vec2, vec3 } from './vectors';

export const plane = {


    // Convert coordinates from Stark coordinate system, to 3D-World
    starkCoordsToWorld: (starkCoords) => {

        let worldCoord = {...starkCoords, x: starkCoords.y, y: -starkCoords.x};
        return vec3.scale(worldCoord, 1000);

    },

    worldToStarkCoords: (worldCoord) => {

        let starkCoord = vec3.scale(worldCoord, (1/1000));
        return {...starkCoord, x: -starkCoord.y, y: starkCoord.x};

    },

    planeFromDicom : (dicomSlice) => {

        let TL = dicomSlice.imagePosition;
        
        let rightLength = dicomSlice.imagePixelSpacing.x * dicomSlice.columns;
        let downLength = dicomSlice.imagePixelSpacing.y * dicomSlice.rows;

        let right = vec3.scale(dicomSlice.imageOrientation[0], rightLength);
        let down = vec3.scale(dicomSlice.imageOrientation[1], downLength);

        let TR = vec3.add(TL, vec3.scale(dicomSlice.imageOrientation[0], rightLength));
        let BL = vec3.add(TL, vec3.scale(dicomSlice.imageOrientation[1], downLength));
        let BR = vec3.add(TR, vec3.scale(dicomSlice.imageOrientation[1], downLength));


        let unitDown = vec3.normalize(dicomSlice.imageOrientation[1]);
        let unitRight = vec3.normalize(dicomSlice.imageOrientation[0]);

        let normal = vec3.normalize(vec3.cross(down, right));

        return {TL, TR, BL, BR, right, down, normal, rightLength, downLength, unitRight, unitDown};
    },

    // Distance from line to point in 2D
    distanceToLine : (l1, l2, p) => {

        const v1 = vec2.subtract(l2, l1);
        const v2 = vec2.subtract(p, l1);
        const magV1 = vec2.magnitude(v1);
        const magV2 = vec2.magnitude(v2);

        const cosTheta = (vec2.dot(v1, v2) / (magV1 * magV2));

        // Are we within bounds of the line? 
        const normalizedProjection = (magV2 * cosTheta) / magV1;
        if (normalizedProjection < 0 || normalizedProjection > 1)
            return 100000;

        // Now check the distance to the line
        const theta = Math.acos(cosTheta);
        let distToLine = magV2 * Math.sin(theta);

        return distToLine;

    },


    getLinePlaneIntersection : (sliceInfo, p1, p2) => {

        let throughAxis = vec3.subtract(p2,p1);
        let num = vec3.dot(sliceInfo.normal, vec3.subtract(p1, sliceInfo.TL));
        let denom = vec3.dot(vec3.negate(throughAxis), sliceInfo.normal);
        let t = num / denom;

        if (t<0 || t>1) return;

        let intersectionCoord = vec3.add(p1, vec3.scale(throughAxis, t));
        return intersectionCoord;

    },


    getAngleBetweenPlanes : (plane1, plane2) => {
        
        let cos = vec3.dot(plane1.normal, plane2.normal);
        let theta = Math.acos(cos);        
        return theta;

    },


    getPlaneIntersection : (basePlane, intersectingPlane) => {

        // Get angle between planes
        let theta = plane.getAngleBetweenPlanes(basePlane, intersectingPlane);

        // If slices are nearly parallel, there is no intersection
        if (isNaN(theta) || Math.abs(theta) < 0.8) {
            return [];
        }

        // Get intersections
        let intersection1 = {};
        let intersection2 = {};

        intersection1 = plane.getLinePlaneIntersection(basePlane, intersectingPlane.TL, intersectingPlane.TR);
        intersection2 = plane.getLinePlaneIntersection(basePlane, intersectingPlane.BL, intersectingPlane.BR);

        // The horizontal plane bounds don't intersect! Use vertical intersections
        if (!intersection1) {
            intersection1 = plane.getLinePlaneIntersection(basePlane, intersectingPlane.TL, intersectingPlane.BL);
            intersection2 = plane.getLinePlaneIntersection(basePlane, intersectingPlane.TR, intersectingPlane.BR);
        }

        // Horizontal plane bounds intersect
        else
            intersection2 = plane.getLinePlaneIntersection(basePlane, intersectingPlane.BL, intersectingPlane.BR);

        // Intersections not found
        if (!intersection1 || !intersection2)
            return [];

        return [intersection1, intersection2];

    },

    // Project a point onto the given plane (returns normalized plane coords)
    worldToPlane : (worldCoord, plane) => {

        // Project worldspace coord onto plane

        // TL to point
        const planeToPoint = vec3.subtract(worldCoord, plane.TL);
        // Dist from plane
        const dist = vec3.dot(planeToPoint, plane.normal);

        // Get worldspace plane projectsion / displacement from TL
        const projectedPoint = vec3.subtract(worldCoord, vec3.scale(plane.normal, dist));
        const planeDisplacementWorld = vec3.subtract(projectedPoint, plane.TL);

        // Project onto image axis, and normalize
        const downProj = vec3.dot(planeDisplacementWorld, plane.unitDown) / (plane.rightLength);
        const rightProj = vec3.dot(planeDisplacementWorld, plane.unitRight) / (plane.downLength);

        return { x: rightProj, y: downProj };

    },


    noralizedPlaneToWorld: (noramlizedCoord, plane) => {

        // Get components
        const rightComp = vec3.scale(plane.right, noramlizedCoord.x);
        const leftComp = vec3.scale(plane.down, noramlizedCoord.y);

        // Return based on displacement
        return vec3.add(plane.TL, vec3.add(rightComp, leftComp));

    },

    // convert plane coordinate to world coordinates
    planeToWorld: (planeCoord, plane) => {
        return vec3.add(vec3.add(plane.TL, vec3.scale(plane.right, planeCoord.x)), vec3.scale(plane.down, planeCoord.y))
    },

    // projects point to plane, then converts back to world coords
    distanceFromPointToPlane: (worldCoord, inputPlane) => {

        // TL to point
        let planeToPoint = vec3.subtract(worldCoord, inputPlane.TL);
        
        // Dist from plane
        let dist = Math.abs(vec3.dot(planeToPoint, inputPlane.normal));

        return dist;
        
    },
}