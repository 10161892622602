// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

export const hotkeys = {

    annotationTools: [
        // annotation tools
        {
            description: 'Label Tool',
            pattern: 'Control+l',
        },
        {
            description: 'Arrow Tool',
            pattern: 'Control+a',
        },
        {
            description: 'Circle Tool',
            pattern: 'Control+c',
        },
        {
            description: 'Rectangle Tool',
            pattern: 'Control+r',
        },
        {
            description: 'Delete Tool',
            pattern: 'Control+d',
        },
    ],

    // measurement tools
    measurementTools: [
        {
            description: 'Angle Tool',
            pattern: 'Shift+a',
        },
        {
            description: 'Length Tool',
            pattern: 'Shift+l',
        },
    ],

    // measurement tools
    otherToolControls: [
        {
            description: 'Escape current active tool',
            pattern: 'Escape',
        },
    ],

        // viewer configurations
    viewerConfigurations: [
        {
            description: 'Single',
            icon: '/resources/icons/SINGLE.png',
            pattern: 'Control+1',
        },
        {
            description: 'Double Horizontal',
            icon: '/resources/icons/DOUBLE_HORIZONTAL.png',
            pattern: 'Control+2',
        },
        {
            description: 'Double Vertical',
            icon: '/resources/icons/DOUBLE_VERTICAL.png',
            pattern: 'Control+3',
        },
        {
            description: 'Quadrants',
            icon: '/resources/icons/QUADRANTS.png',
            pattern: 'Control+4',
        },
        {
            description: 'Triple Horizontal',
            icon: '/resources/icons/TRIPLE_HORIZONTAL.png',
            pattern: 'Control+5',
        },
        {
            description: 'Triple Vertical',
            icon: '/resources/icons/TRIPLE_VERTICAL.png',
            pattern: 'Control+6',
        },
    ],

    dicomSeriesAxis: [
        // viewer series 
        {
            description: 'Switch to axial DICOM series',
            pattern: 'a',
        },
        {
            description: 'Switch to sagittal DICOM series',
            pattern: 's',
        },
        {
            description: 'Switch to coronal DICOM series',
            pattern: 'c',
        },
    ],

        // actions
    pageOperations: [
        {
            description: 'New Dicom Viewer Window',
            pattern: 'Alt+d',
        },
        {
            description: 'New Report Generator Window',
            pattern: 'Alt+r',
        },
        {
            description: 'Prev Project',
            pattern: 'Control+Shift+left',
        },
        {
            description: 'Next Project',
            pattern: 'Control+Shift+right',
        },
    ],

    miscellaneous: [
        {
            description: 'Open Help Panel',
            pattern: 'Control+i'
        }
    ]
}