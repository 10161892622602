// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import axios from 'axios';

axios.defaults.withCredentials = true;

// Set API URL based on environment
const apiUrl = window.location.href.includes('localhost') ? 'http://localhost:8081/' : window.location.href.includes('staging') ? 'https://radapi-staging.multusmedical.com/' : 'https://radapi.multusmedical.com/';


// Error handling function for API Res
const handleError = (res, noAuth) => {

    console.log(res, noAuth)

    console.log(res.data)
    
    // No server res
    if (!res || !res.data) 
        console.log("No server response.");

    const data = res.data;

    // Authorization error - redirect to unAuth page
    if (!data.auth && !noAuth)
        return window.location = '/unauthorized';

    // General error
    if (data.error)
        console.log(data.error);

    return data;

}


export default {

    // Get request
    get: async (endpoint, data={}, noAuth=false) => {

        let res = await axios.get(apiUrl + "api/" + endpoint, data);

        return handleError(res, noAuth);

    },

    // Post request
    post: async (endpoint, data={}, noAuth=false, config={}) => {

        let res = await axios.post(apiUrl + "api/" + endpoint, data, config);

        return handleError(res, noAuth);

    },

    // Download
    download: async (endpoint, data, config = {}) => {

        let canceled = false;

        let res = await axios.post(apiUrl + "api/" + endpoint, data, { ...config, responseType: 'blob' }).catch(e => {if (axios.isCancel(e)) canceled = true});
        
        if (canceled) 
            return null;


        
        return handleError(res, true);

    },

    // Login
    login: async (username, password) => {

        let res = await axios.post(apiUrl + "auth/local", {
            username: username,
            password: password
        });

        if (res.data.error)
            console.log(res.data.error);

        return res.data;

    },

    // Logout
    logout: async () => {

        let res = await axios.get(apiUrl + "auth/logout");

        if (res.data.error)
            console.log(res.data.error);

        return res.data;

    }

}