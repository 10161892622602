// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import HttpsRedirect from 'react-https-redirect';
import Loading from 'react-loading';

// Styles
import './styles/global.css';
import 'react-toastify/dist/ReactToastify.css';

// Components
import DicomViewerPage from './components/DicomViewer/Page/DicomViewerPage';
import ReportGeneratorPage from './components/ReportGenerator/Page/ReportGeneratorPage';
import UnauthorizedPage from './components/UnauthorizedPage';

// Constants
// TODO @Marcel: Remove Dev
import {dummyProjectData} from './constants/dummyData';
import { dummyInjuryTypes, dummyStudyTypes } from './constants/dummyData';

// API
import api from './api';
import axios from 'axios';

// Functions
import { getInjurySummary } from './utilities/injuryTemplateFill';
import { getLocationSummary } from './utilities/summary';

class App extends Component {

	state = {

		// Session ID (for cross browser communication)
		sessionUUID: null,

		// Loading 
		loading: false,

		// Project Data
		project: null,
		projectIds: null,

        // User data
        user: {
            data: {
                customInjuries: {}
            }
		},
		
		// Study Types 
		studyTypes: {},

		// Loaded dicom images
		dicomImages: {}, // SeriesName -> [slice]

		// General injury types
		defaultInjuryTypes: dummyInjuryTypes,
		
		// Pending injury changes status
        injuryCloudQueue: {
            synced: 'true', 
            typingTimer: null
		},
		annotationCloudQueue: {
			synced: 'true',
			typingTimer: null
		}

    }


	componentDidMount = async () => {


		// Case: UnAuth
		if (window.location.pathname === '/unauthorized')
			return;

		// Case: Base URL
		else if (window.location.pathname === '/') {
			// TODO @Marcel: Remove dev mode
			if (process.env.REACT_APP_DEV === 'true')
				window.location = '/report/dev';
			else
				window.location = '/report';
		}

		// Load essential app data
		await this.fetchAppData();

		// Load the first project (performed by match params of the route).

	}


	// Fetch all DB data needed for app functionality
	fetchAppData = async () => {

		// Set loading
		this.setState({ loading : true });

		// TODO @Marcel: Remove dev mode
		if (process.env.REACT_APP_DEV === 'true'){

			// Reorganize studyTypes 
			let studyTypes = {};
			for (let studyType of dummyStudyTypes) 
				studyTypes[studyType.name] = studyType;

			// Load Dummy Data
			this.setState({
				user: { data: { customInjuries: {}}},
				defaultInjuryTypes: dummyInjuryTypes,
				studyTypes: studyTypes,
				projectIds: ['5e3494819c3ffed9e94b8865', '5e3494819c3ffed9e94b8865']
			});
		}

		else {

			// Authenticate User
			let res = await api.get('whoami');

			if (!res || !res.auth)
				return

			await this.setState({
				user: res.auth
			})

			// Load Injury Types
			res = await api.get('getInjuryTypes');

			if (res && res.data) {
				this.setState({
					defaultInjuryTypes: res.data
				})
			}

			// Load Study Types 
			res = await api.get('getStudyTypes');

			if (res && res.data) {

				// Reorganize the data 
				let studyTypes = {};

				for (let studyType of res.data) 
					studyTypes[studyType.name] = studyType;

				this.setState({
					studyTypes: studyTypes
				})
			}

			// Retrieve the list of projects
			res = await api.get("getAvailableProjects");

			if (!res) {
				this.setState({ loading : false});
				return;
			}

			await this.setState({projectIds: res.data});
		}

		// Done loading
		this.setState({ loading : false });

	}


	// ------ PROJECT HANDLING FUNCTIONS ------

	// Shift project index
	getNextProjectId = async (shiftValue) => {

		// Shift along this.state.projects by the desired shift
		
		// Get new project Id
		let currProjId = this.state.project._id;
		let currProjIdIdx = this.state.projectIds.indexOf(currProjId)
		
		let nextIdx = (currProjIdIdx + shiftValue) % this.state.projectIds.length
		let nextProjectId = this.state.projectIds[nextIdx]

		console.log("Shifting project " + shiftValue);
		console.log('\tfrom', currProjId, 'to', nextProjectId)

		return nextProjectId
	} 

	loadProject = async (projectId) => {

		// Clear any old dicom files/ project
		this.setState({
			project: null,
			dicomImages: {}
		})

		// DEV
		if (process.env.REACT_APP_DEV === 'true' || projectId === 'dev' || projectId === '5e3494819c3ffed9e94b8865') {
			// Load the dev project and data
			console.log("Loading dev project...");
			await this.setState({project: dummyProjectData});
		}

		// LIVE
		else {

			console.log("Loading Project: ", projectId);

			// Get project data
			let res = await api.post('getProject', {"pId": projectId});

			if (!res || !res.data || !res.success)
				// Handle non- project load.
				return false;

			const proj = res.data;

			// Initialize ai.injury or ai.injury.injuries as needed 
			if (!proj.ai.injury)
				proj.ai.injury = {};
			if (!proj.ai.injury.injuries)
				proj.ai.injury.injuries = [];

			// Set the project state
			await this.setState({ project: proj});
		}

		// Load all Dicom images 
		this.loadProjectImages();

		this.setState({ loading : false });

		return true;

	}

	loadSeriesImages = async (seriesData, cancelToken) => {

		const initialProject = this.state.project._id;

		const seriesName = seriesData.seriesName;

		// Load all slice images async
		const seriesImages = await Promise.all(seriesData.slices.map(async (sliceData, idx) => {

			// DEV
			if (process.env.REACT_APP_DEV === "true" || this.state.project.patient.name === "dev")
				return '/dicom/' + sliceData.imagePath;

			// LIVE
			// Download image and save
			let imageData = await api.download("downloadDicom", {'dicomKey': sliceData.imageKey }, {cancelToken: cancelToken} );
			return window.URL.createObjectURL(new Blob([imageData], { type: 'image/jpeg' }));

		}));

		// Make sure this is the intended project
		if (!this.state.project || this.state.project._id !== initialProject)
			return;

		await this.setState(prevState => ({
			dicomImages: {
				...prevState.dicomImages,
				[seriesName]: seriesImages
			}
		}));

	}

	loadProjectImages = async () => {

		// Create axios cancel token
		let cancelToken = axios.CancelToken.source();
		const initialProject = this.state.project._id;

		// Poll for project change until done
		const cancelInterval = setInterval(() => {
			if (!this.state.project || !this.state.project._id === initialProject) {
				cancelToken.cancel("Project change cancelation");
				clearInterval(cancelInterval);
			}
		}, 1000)

		await Promise.all(this.state.project.dicomData.map( async seriesData => {
			await this.loadSeriesImages(seriesData, cancelToken.token);
			return null;
		}));

		// Clear project poller
		clearInterval(cancelInterval);

	}

	loadDefaultProject = async () => {

		console.log("Loading Default Project");

		return await this.loadProject(this.state.projectIds[0]);

	}


	// ------ REPORT APPROVAL ------

	approveReport = async () => {

		// Approve Report 
		let res = await api.post('approveAndSignReport', {pId: this.state.project._id}); 

		if (!res || !res.success || !res.data) {
			toast.error("Unable to sign report");
			return;
		}

		if (!res.data.ai || !res.data.ai.injury)
			return;

		// Update project signed state
		let currProj = this.state.project;
        currProj.ai.injury.signed = res.data.ai.injury.signed;

        this.setState({
            project: currProj
        });
	}


	// ------ CLOUD SAVE QUEUEING ------
    
    // Queue an injury cloud save operation
    queueInjurySave = (timeout = 5000) => {

        // Clear timer, and reset.
        clearTimeout(this.state.injuryCloudQueue.typingTimer);
        let typingTimer = setTimeout(this.saveInjuriesToCloud, timeout);

        // Set state timer
        this.setState({ 
            injuryCloudQueue: {
                synced: 'false',
                typingTimer: typingTimer 
            }
        });
    }

    // Clear the cloudsave opperation
    resetQueuedInjurySave = () => {
        clearTimeout(this.state.injuryCloudQueue.typingTimer);
    }
	
	// Save injury data to cloud
	saveInjuriesToCloud = async () => {

		// Clear any queued save
		clearTimeout(this.state.injuryCloudQueue.typingTimer);

		// Set saving flag
        await this.setState(prevState => ({
            injuryCloudQueue: {
                ...prevState.injuryCloudQueue,
                synced: 'syncing'
            }
        }))

		// Create project diff
		let injuries = this.state.project.ai.injury.injuries;

		// Sync Proj
		let res = await api.post('syncInjuries', {pId: this.state.project._id, injuries: injuries});

        // update project ai.injury.signed from syncInjuries result
        let currProj = this.state.project;
        currProj.ai.injury.signed = res.data.ai.injury.signed;

		let synced = 'true';
        if (!res.success)
            synced = 'error';

        // Clear timer
        this.setState({ 
            injuryCloudQueue: {
                synced: synced,
                typingTimer: null 
            },
            project: currProj,
        });

		return;

	}



	
	// ------ ANNOTATION HANDLING FUNCTIONS ------


	// Helper to add annotation to dicom annotation 
	addAnnotationToList = async (seriesName, sliceIdx, annotationData, dicomAnnotations) => {

		// Init if needed
		if (!dicomAnnotations)
			dicomAnnotations = {};

		// Global? Save to the base level
		if (seriesName === 'global') {

			// Init annotations? 
			if (!dicomAnnotations[seriesName])
				dicomAnnotations[seriesName] = [];

			// Add annotation 
			dicomAnnotations[seriesName].push(annotationData);
		}

		// Else: Localized 
		else {

			// Init annotations? 
			if (!dicomAnnotations[seriesName])
				dicomAnnotations[seriesName] = {};

			// Init slice annotations? 
			if (!dicomAnnotations[seriesName][sliceIdx])
				dicomAnnotations[seriesName][sliceIdx] = [annotationData];
			else 
				dicomAnnotations[seriesName][sliceIdx].push(annotationData);
		
		}

		return dicomAnnotations;

	}

	// Receive annotation data from a sibling window
    receiveSiblingAnnotations = async (seriesName, sliceIdx, annotationData = undefined) => {
		
		// Update dicom annotations
		let dicomAnnotations = this.state.project.dicomAnnotations; 

		if (annotationData !== undefined) {

			// Case Global Annotation
			if (seriesName === 'global') 
				dicomAnnotations[seriesName] = annotationData;

			else {
				if (!dicomAnnotations[seriesName]) 
					dicomAnnotations[seriesName] = {}
				
				dicomAnnotations[seriesName][sliceIdx] = annotationData;

			}
		}

        // Set state
		await this.setState(prevState => ({
			project: {
				...prevState.project,
				dicomAnnotations: dicomAnnotations
			}
		}))
	}

	// Save annotation to dicom series / slice
	saveAnnotation = async (seriesName, sliceIdx, annotationData) => {

		// Save the annotation to the local data
		let dicomAnnotations= this.state.project.dicomAnnotations;
		dicomAnnotations = await this.addAnnotationToList(seriesName, sliceIdx, annotationData, dicomAnnotations);

		// Set state
		await this.setState(prevState => ({
			project: {
				...prevState.project,
				dicomAnnotations: dicomAnnotations
			}
		}))

		// Save the annotation to the cloud
		this.syncAnnotationsToCloud();

	}

	// Update annotation on a dicom series / slice
	updateAnnotation = async (seriesName, sliceIdx, annotationIdx, newAnnotation) => {

		// Save the annotation to the local data
		let dicomAnnotations= this.state.project.dicomAnnotations;

		// Global annotations
		if (seriesName === 'global') {

			if (!dicomAnnotations[seriesName] || ! dicomAnnotations[seriesName][annotationIdx])
				return;

			dicomAnnotations[seriesName][annotationIdx] = newAnnotation;

		}

		// Else: Local
		else {

			// Doesn't exist?
			if (!dicomAnnotations[seriesName] || !dicomAnnotations[seriesName][sliceIdx])
				return;

			dicomAnnotations[seriesName][sliceIdx][annotationIdx] = newAnnotation;

		}

		await this.setState(prevState => ({
			project: {
				...prevState.project,
				dicomAnnotations: dicomAnnotations
			}
		}));

		// Cloud update will occur when the operation is complete	
		
		return;
	}

	// Delete the given annotation
	deleteAnnotation = async (seriesName, sliceIdx, annotationIdx) => {

		let dicomAnnotations = this.state.project.dicomAnnotations;

		// Global? 
		if (seriesName === 'global') {

			// Data doesn't exist?
			if (!dicomAnnotations[seriesName] || !dicomAnnotations[seriesName][annotationIdx])
				return;

			// Remove annotation
			dicomAnnotations[seriesName] = dicomAnnotations[seriesName].filter((elem, idx) => idx !== annotationIdx);

		}

		// Else, Local
		else {

			// Data doesn't exist?
			if (!dicomAnnotations[seriesName] || !dicomAnnotations[seriesName][sliceIdx])
				return;

			// Remove annotation
			dicomAnnotations[seriesName][sliceIdx] = dicomAnnotations[seriesName][sliceIdx].filter((elem, idx) => idx !== annotationIdx);

		}

		await this.setState(prevState => ({
			project: {
				...prevState.project,
				dicomAnnotations: dicomAnnotations
			}
		}));

		// Sync to cloud
		this.syncAnnotationsToCloud();

	}
	
	// Queue an injury cloud save operation
    queueAnnotationSave = (timeout = 5000) => {

        // Clear timer, and reset.
        clearTimeout(this.state.annotationCloudQueue.typingTimer);
        let typingTimer = setTimeout(this.syncAnnotationsToCloud, timeout);

        // Set state timer
        this.setState({ 
            annotationCloudQueue: {
                synced: 'false',
                typingTimer: typingTimer 
            }
        });
    }

    // Sync dicom data to the cloud
    syncAnnotationsToCloud = async () => {

		// Clear any queued save
		clearTimeout(this.state.annotationCloudQueue.typingTimer);

		// Set saving flag
        await this.setState(prevState => ({
            annotationCloudQueue: {
                ...prevState.annotationCloudQueue,
                synced: 'syncing'
            }
        }))

		// Create project diff
		let projectDiff = { dicomAnnotations: this.state.project.dicomAnnotations }

		// Sync Proj
		let res = await api.post('syncProject', { pId: this.state.project._id, projectDiff: projectDiff});

		let synced = 'true';
        if (!res.success)
            synced = 'error';

        // Clear timer
        this.setState({ 
            annotationCloudQueue: {
                synced: synced,
                typingTimer: null 
            }
		});
		
    }



	// ------ INJURY HANDLING FUNCTIONS ------

	// Update an injury metric with new data
    updateInjuryMetric = async (injuryLocation, objectIdx, metricIdx, newMetric, updateSummary = true) => {

		// Copy injuries
		let injuries = [...this.state.project.ai.injury.injuries];

		// Find and update the metric
		for (let i=0; i<injuries.length; i++) {
			if (injuries[i].location === injuryLocation) {
				
				// Update metric
				injuries[i].objects[objectIdx].rationale.metrics[metricIdx] = newMetric;

                // Update summary
                if (updateSummary) {
					injuries[i].objects[objectIdx]['summary'] = await this.generateInjurySummary(injuries[i].objects[objectIdx]);
					injuries[i]['summary'] = await getLocationSummary(injuries[i], this.state.project.studyType, this.state.studyTypes[this.state.project.studyType]);

                }
			}
		}

		// Update the project 
		let newProj = this.state.project;
		newProj.ai.injury.injuries = injuries;

		await this.setState({ 
			project: newProj
		})

		return;

	}

	// Delete a given injury metric
	deleteInjuryMetric = async (injuryLocation, injuryType, metricIdx, updateSummary = true) => {

		// Copy injuries
		let injuries = [...this.state.project.ai.injury.injuries];

		// Find the location
		for (let i=0; i<injuries.length; i++) {
			if (injuries[i].location === injuryLocation) {
				
				// Find the injury
				for (let j=0; j<injuries[i].objects.length; j++) {
					
					if (injuries[i].objects[j].type === injuryType) {
						
						// Found !
						injuries[i].objects[j].rationale.metrics = injuries[i].objects[j].rationale.metrics.filter( (elem, idx)=> idx !== metricIdx);
					
						// Update summary
						if (updateSummary) {
							injuries[i].objects[j]['summary'] = await this.generateInjurySummary(injuries[i].objects[j]);
							injuries[i]['summary'] = await getLocationSummary(injuries[i], this.state.project.studyType, this.state.studyTypes[this.state.project.studyType]);
						}
					}
				} 
			}
		}

		// Update the project 
		let newProj = this.state.project;
		newProj.ai.injury.injuries = injuries;

		await this.setState({ 
			project: newProj
		})

		// Sync with cloud
		this.saveInjuriesToCloud();

		return;

	}

	// Delete a given injury object
	deleteInjury = async (injuryLocation, objectIdx) => {
		
		// Copy injuries
		let injuries = [...this.state.project.ai.injury.injuries];

		// Find and delete the injury
		for (let i=0; i<injuries.length; i++) {
			if (injuries[i].location === injuryLocation) 
				injuries[i].objects = injuries[i].objects.filter((elem, idx) => idx !== objectIdx);
				
				// Recalculate location summary
				injuries[i]['summary'] = await getLocationSummary(injuries[i], this.state.project.studyType, this.state.studyTypes[this.state.project.studyType]);
		}

		// Update the project 
		let newProj = this.state.project;
		newProj.ai.injury.injuries = injuries;

		await this.setState({ 
			project: newProj
		})

		// Sync with cloud
		this.saveInjuriesToCloud();

		return;

	}

	// Update a given injury object
	updateInjury = async (injuryLocation, objectIdx, newInjury, cloudSave=false, updateSummary=true) => {

		// Update summary
        if (updateSummary) 
			newInjury['summary'] = await this.generateInjurySummary(newInjury);

		// Copy injuries
		let injuries = [...this.state.project.ai.injury.injuries];

		// Find and update the injury
		for (let i=0; i<injuries.length; i++) {
			if (injuries[i].location === injuryLocation) {
				injuries[i].objects[objectIdx] = newInjury;

				// Recalculate location summary
				injuries[i]['summary'] = await getLocationSummary(injuries[i], this.state.project.studyType, this.state.studyTypes[this.state.project.studyType]);
			}
		}

		// Update the project 
		let newProj = this.state.project;
		newProj.ai.injury.injuries = injuries;

		await this.setState({ 
			project: newProj
		})

		// Cloud Save? 
		if (cloudSave)
            await this.saveInjuriesToCloud();

		return;
	}

	// Save a newly created injury
	saveNewInjury = async (newInjury) => {

		// Get location
		let location = newInjury.location

		// Copy injuries
		let injuries = [...this.state.project.ai.injury.injuries];

		// Find location and save the injury
		let handled = false;
		for (let i=0; i<injuries.length; i++) {
			if (injuries[i].location === location) {
				injuries[i].objects.push(newInjury);

				// Regenerate injry summary
				injuries[i]['summary'] = await getLocationSummary(injuries[i], this.state.project.studyType, this.state.studyTypes[this.state.project.studyType]);

				handled = true;
			}
		}

		// Create new location if not found!
		if (!handled) {
			let newInjuryLocation = { location : location, objects: [newInjury]};
			injuries.push(newInjuryLocation);
		}

		// Update the project 
		let newProj = this.state.project;
		newProj.ai.injury.injuries = injuries;

		await this.setState({ 
			project: newProj
		})

		// Sync with cloud
		await this.saveInjuriesToCloud();

		return;

	}

	// Generate injury summary from template
    generateInjurySummary = async (injury) => {

        const injuryType = this.getInjuryTypes()[injury.type];

        try {
            // Get the template
            if (!injuryType || !injuryType.template)
                return toast.error("No injury template found for " + injury.type, {toastId: 3});
                
			// Generate summary
            const summary = getInjurySummary(injuryType, injury);
            return summary;
        }
        catch (error) {
            toast.error("Injury summary generation error: " + error.message);
        }
    }


	// Apply injuries from a sibling window
	receiveSiblingInjuries = async (location, injuryData) => {

		// Copy injuries
		let injuries = [...this.state.project.ai.injury.injuries];

		// Find location and save the data
		let handled = false;
		for (let i=0; i<injuries.length; i++) {
			if (injuries[i].location === location) {
				injuries[i] = injuryData;
				handled = true;
			}
		}

		// Create new location if not found!
		if (!handled) {
			let newInjuryLocation = { location : location, objects: injuryData};
			injuries.push(newInjuryLocation);
		}

		// Update the project 
		let newProj = this.state.project;
		newProj.ai.injury.injuries = injuries;

		await this.setState({ 
			project: newProj
		})

		return;

	}


	// Sort injury metrics into displayable viewer format
	getSortedInjuryMetrics = (project) => {

		// Injury Metrics include: 
		// { 
			// injuryData: {} (complete injury data)
			// metricData: {} (complete metric data (relevent subset of injury))
			
			// location: str (for updating, the anatomical location of the injury)
			// objectIdx: int (for updateing, index in injuryLocation.objects)
			// metricIdx: int (for updating, index of the metric within the object)
		
		// }

		// This data is used to display injury metrics, and update them in their locations in the project data.

		// Injury Mapping
		let injuryMetrics = {}
		if (project && project.ai.injury.injuries) {

			for (let injuryLocationData of project.ai.injury.injuries) {
				for (let i=0; i<injuryLocationData.objects.length; i++) {
					let injuryData = injuryLocationData.objects[i];

					if (!injuryData.rationale || !injuryData.rationale.metrics)
						continue;

					// If any metrics are included within the injury... pass the injury to the series/ slice.
					for (let m=0; m<injuryData.rationale.metrics.length; m++) {

						let metric = injuryData.rationale.metrics[m];

						// TODO @Marcel: Check for non-display flag from bot instead!
                        if (metric.name === 'Bot region of interest')
                            continue;
						
						let injuryMetric = {
							fullData: injuryData, 
							metric: metric,
							location: injuryLocationData.location,
							objectIdx: i,
							metricIdx: m,
						}

						if (metric.seriesName === 'n/a')
							metric.seriesName = 'T2_SAG'

						// Add to injury mapping
						if (!Object.keys(injuryMetrics).includes(metric.seriesName))
							injuryMetrics[metric.seriesName] = {};

						if (!injuryMetrics[metric.seriesName][metric.sliceIdx]) 
							injuryMetrics[metric.seriesName][metric.sliceIdx] = [];
						
						injuryMetrics[metric.seriesName][metric.sliceIdx].push(injuryMetric);

					}

				}
			}
		}

		return injuryMetrics;

	}

    // Save injury generated by InjuryTypeGenerator
    saveGeneratedInjuryType = async (injurySchema, isEditing = false) => {

        let res;

        if (isEditing) {
            
            const prevInjury = this.state.defaultInjuryTypes.find(x => x.name === injurySchema.name);
            
            // injury type owned by user - update 
            if (prevInjury.creator && this.state.user._id === prevInjury.creator) {
                injurySchema.creator = this.state.user._id;
                res = await api.post('updateInjuryType', {injuryTypeId: prevInjury._id, injuryType: injurySchema});
            }

            // injury type not owned - create new with user as creator, change name?
            else if (!prevInjury.creator) {
                injurySchema.creator = this.state.user._id;
                injurySchema.name = injurySchema.name + ' (custom)';
                res = await api.post('createInjuryType', { injuryType: injurySchema });
            }

            // injury type owned by someone else - cannot update.  Should not get here
            else {
                throw new Error('cannotsave injury')
            }
        }
        else {
            // not editing - create new injury
            res = await api.post('createInjuryType', { injuryType: injurySchema });
        }
        		
		// Set the update injury types 
		if (!res || !res.success || !res.data) {
			toast.error("Unable to save injury type");
			return
		}

		// Update injury types
		this.setState({
			defaultInjuryTypes: res.data
		});
	}

	// Save dicom viewer config defaults 
	saveDicomViewerConfiguration = async (defaultConfigurations) => {

		// Update the user in the state
		let user = this.state.user;
		if (!user.data)
			user.data = {};

		user.data.dicomViewerConfiguration = defaultConfigurations;

		await this.setState({ user: user });

		// Sync user to cloud.
		let userDiff = {data: user.data};
		await api.post('syncUser', {userId: user._id, userDiff: userDiff});

	}
	
	// Get all injury types (generic and user)
    getInjuryTypes = () => {
		
        // Aggregate injury types 
        let injuryTypes = {};
        for (let injuryType of this.state.defaultInjuryTypes)
            injuryTypes[injuryType.name] = injuryType;

        return injuryTypes;
    }

	// Clear all injuries in current project
    clearAllInjuries = async () => {

        let proj = this.state.project;
        proj.ai.injury.injuries = [];
        this.setState({project: proj});

        this.saveInjuriesToCloud();
    }

	// Clear all non-diagnostic annotations in current injury
    clearAllAnnotations = async () => {

        let proj = this.state.project;
        proj.dicomAnnotations = {};
        this.setState({ project: proj });
        
        this.syncAnnotationsToCloud();
    }
    
	render = () => {

		// Sort injury metrics 
		let injuryMetrics = this.getSortedInjuryMetrics(this.state.project);
		let injuryTypes = this.getInjuryTypes();


		return (
			<HttpsRedirect>
			<Router>
                <div className="rootContainer">

					{/* Toast */}
					<ToastContainer
						position="top-center"
						autoClose={4000}
					/>

					{/* Loading */}
					{this.state.loading ? <div className="loadingContainer">
						<div className="loadingIcon">
							<Loading type="spin" color="rgba(0,240,240,0.8)" height="120px" width="120px" />
						</div>
					</div> : ''}

					
					{/* Dicom Viewer Page */}
					{this.state.user && this.state.projectIds && 
					<Route path="/dicom/:projectId?/:uuid?" exact render={props=> <DicomViewerPage {...props}
						user = {this.state.user}
						project = {this.state.project}
						dicomImages={this.state.dicomImages}
						injuryMetrics={injuryMetrics}
						// Project Functions
						loadProject={this.loadProject}
						loadDefaultProject={this.loadDefaultProject}
						getNextProjectId={this.getNextProjectId}
						// Annotation Functions
						saveAnnotation={this.saveAnnotation}
						updateAnnotation={this.updateAnnotation}
						receiveSiblingAnnotations = {this.receiveSiblingAnnotations}
						syncAnnotationsToCloud={this.syncAnnotationsToCloud}
						deleteAnnotation={this.deleteAnnotation}
						queueAnnotationSave={this.queueAnnotationSave}
						// Injury Functions
						updateInjuryMetric={this.updateInjuryMetric}
						deleteInjuryMetric = {this.deleteInjuryMetric}
						saveInjuriesToCloud={this.saveInjuriesToCloud}
						receiveSiblingInjuries = {this.receiveSiblingInjuries}
						queueInjurySave={this.queueInjurySave}
						resetQueuedInjurySave={this.resetQueuedInjurySave}
						// User Functions
						saveDefaultConfiguration = {this.saveDicomViewerConfiguration}

					/>} />
					}

					
					{/* Report Generator Page */}
					{this.state.user && this.state.projectIds &&
					<Route path='/report/:projectId?/:uuid?' exact render={props=> <ReportGeneratorPage {...props}
						user = {this.state.user}
						project = {this.state.project}
						studyTypes = {this.state.studyTypes}
						dicomImages = {this.state.dicomImages}
						injuryMetrics={injuryMetrics}
						injuryTypes={injuryTypes}
						injuryCloudQueue={this.state.injuryCloudQueue}
						annotationCloudQueue={this.state.annotationCloudQueue}
						// Project Functions
						loadProject={this.loadProject}
						loadDefaultProject={this.loadDefaultProject}
						getNextProjectId={this.getNextProjectId}
						approveReport={this.approveReport}
						// Annotation Functions
						saveAnnotation={this.saveAnnotation}
						updateAnnotation={this.updateAnnotation}
						receiveSiblingAnnotations={this.receiveSiblingAnnotations}
						syncAnnotationsToCloud={this.syncAnnotationsToCloud}
						deleteAnnotation={this.deleteAnnotation}
						queueAnnotationSave={this.queueAnnotationSave}
						// Injury Functions
						saveNewInjury={this.saveNewInjury}
						updateInjuryMetric={this.updateInjuryMetric}
						updateInjury={this.updateInjury}
						queueInjurySave={this.queueInjurySave}
						resetQueuedInjurySave={this.resetQueuedInjurySave}
						saveInjuriesToCloud={this.saveInjuriesToCloud}
						receiveSiblingInjuries = {this.receiveSiblingInjuries}
						deleteInjury={this.deleteInjury}
						deleteInjuryMetric = {this.deleteInjuryMetric}
						// User Functions
						saveGeneratedInjuryType={this.saveGeneratedInjuryType}
						saveDefaultConfiguration = {this.saveDicomViewerConfiguration}
                        //clear functions
                        clearAllInjuries={this.clearAllInjuries}
                        clearAllAnnotations={this.clearAllAnnotations}

					/>} />
					}

					{/* Unauthorized Page */}
					<Route path='/unauthorized' exact render={props=> <UnauthorizedPage {...props} />} />

                </div>
			</Router>
			</HttpsRedirect>
		)
	}

}

export default App;
