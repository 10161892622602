// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import '../styles/unauth.css';


export default class UnauthorizedPage extends Component {


    render = () => {

        return (

            <div className='unAuthRoot'>

                <div className='unAuthContainer'>

                    <div className='unAuthLogoContainer'>
                        <img className='unAuthLogo' alt="MultusMedical" src='/resources/multus/logo.png' />

                    </div>

                    <div className='unAuthTextContainer'>

                        <div className='unAuthHeader'>
                            Unauthorized
                        </div>

                        <div className='unAuthText'>

                            Please login to your MultusPortal account to access this page.

                        </div>

                    </div>

                    
                </div>

            </div>


        )

    }


}