// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

export default (date, format = 'yyyy-mm-dd') => {
    
    if (!date || date === "")
        return "N/A";

    let dateAsString = date;

    if (typeof dateAsString !== "string")
        dateAsString = date.toISOString()

    date = new Date(Date.parse(dateAsString.split('T')[0] + "T00:00"));

    const yearString = date.getFullYear().toString();
    const monthStr = (date.getMonth() + 1).toString().padStart(2, '0');
    const dateStr = date.getDate().toString().padStart(2, '0');

    return format.toLowerCase().replace('yyyy', yearString).replace('mm', monthStr).replace('dd', dateStr);
}